import { StoreIndexNames, StoreNames } from "./StoreNames";

export class DbService
{

    db:IDBDatabase;


    constructor(private directoryTag:string)
    {

    }

    open()
    {
        if(!this.db)
        {
                        


            return new Promise<IDBDatabase>((resolve, reject) => {
                var request = indexedDB.open("erichdb-" + 
                    this.directoryTag.toLowerCase());


                request.onerror = (event) => {
                    alert("IndexedDB is required for offline access");
                    console.log("Why didn't you allow my web app to use IndexedDB?!");
                    reject(event);
                };
                
                request.onsuccess = (event)=> {
                  
                    this.db = (event.target as IDBRequest<IDBDatabase>).result;
                    
                    resolve(this.db);
                };


                            
                request.onupgradeneeded = function(event:IDBVersionChangeEvent) {
                    var db = (event.target as IDBRequest<IDBDatabase>).result;
                
                    console.log("old version = ", event.oldVersion);
                    console.log("new version = ", event.newVersion);
                    // Create an objectStore to hold information about our customers. We're
                    // going to use "ssn" as our key path because it's guaranteed to be
                    // unique - or at least that's what I was told during the kickoff meeting.
                    var objectStore = db.createObjectStore(StoreNames.Items, { keyPath: "id" });

                    objectStore.createIndex(StoreIndexNames.ItemsMainIndex, 
                        ["tag", "name", "id"], {unique:false});
                    
                    
                    // entities offline
                    objectStore = db.createObjectStore(StoreNames.Entities, 
                        { 
                            keyPath: "id", 
                        autoIncrement:true });
                    objectStore.createIndex(StoreIndexNames.EntitiesMainIndex, 
                        ["type", "tag", "name"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.EntitiesHasOfflineChangesIndex, 
                        ["hasOfflineChanges"], {unique:false});



                    objectStore = db.createObjectStore(StoreNames.Orders, {
                        keyPath:"id", 
                        // no keypath
                        autoIncrement: true
                    });
                    objectStore.createIndex(StoreIndexNames.OrdersMainIndex, 
                        ["type", "inputTime", "creationTime"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.OrdersFromEntityIdIndex, 
                        ["fromEntity.id"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.OrdersToEntityIdIndex, 
                        ["toEntity.id"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.OrdersShipmentSourceOrderIdIndex, 
                        ["shipmentSourceOrder.id"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.OrdersHasOfflineChangesIndex, 
                        ["hasOfflineChanges"], {unique:false});



                
                    objectStore = db.createObjectStore(StoreNames.JournalEntries, {
                        keyPath:"id", 
                        // no keypath
                        autoIncrement: true
                    });
                    objectStore.createIndex(StoreIndexNames.JournalEntriesMainIndex, 
                        ["journalType", "inputTime", "creationTime"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.JournalEntriesFromEntityIdIndex, 
                        ["fromEntity.id"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.JournalEntriesToEntityIdIndex, 
                        ["toEntity.id"], {unique:false});
                    objectStore.createIndex(StoreIndexNames.JournalEntriesHasOfflineChangesIndex, 
                        ["hasOfflineChanges"], {unique:false});

/*
                    var objectStore = db.createObjectStore(StoreNames.UserDirectoryContexts, {
                        keyPath:
                    });*/

                    
                
                    objectStore = db.createObjectStore("testobjects",
                        { keyPath: "id", autoIncrement:true });

                        
                    objectStore = db.createObjectStore("testobjects2",{

                        keyPath:"a.name"
                    });

                };

            });

        }

        return Promise.resolve(this.db);
    }

}
