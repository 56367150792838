import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ModelTypeEdit } from "./Edit";
import { EntityTypeService } from "./EntityTypeService";
import { ModelTypeHome } from "./Home";
import { ModelTypeTypeNameIds } from "./ModelTypeTypeNameIds";
import { ModelTypesBaseProps } from "./Root";

export const EntityTypeRootProps: ModelTypesBaseProps = 
{
    serviceType: EntityTypeService,
    modelType:{
        customModel:{
            name:ModelTypeTypeNameIds.EntityType,
        
            displayName:"Entity Type",
            collectionDisplayName:"Entity Types",
            fields:[]
        }
    }

};





export function EntityTypesRoot()
{
    var match = useRouteMatch();
    return <Switch>
        <Route exact path={`${match.path}/`}>
            <ModelTypeHome {...EntityTypeRootProps}/>
        </Route>
        <Route path={`${match.path}/edit`}>
            <ModelTypeEdit {...EntityTypeRootProps}/>
        </Route>
        {/* <Route relativePath='/e/:modelType' component={CustomFieldsRoot}/> */}
        
    </Switch>;

}
