
import React from "react";
import { DirectoryContext } from "..";

export function DirectoryCurrencyCodeSelect(props:{
    name?:string,
    className?:string,
    value:string,
    onChange:(evt:{target:{value:string}})=>void,
})
{

    var ctx = React.useContext(DirectoryContext);


    return <select
        name={props.name}
        className={props.className}
        value={props.value}
        onChange={props.onChange}
        >
            {ctx.data.directoryConfiguration.currencies.map(c => 
            <option
            key={c.code} value={c.code}>{c.code}</option>)}
            
            </select>;
    
}