import { StoreIndexNames, StoreNames } from "./StoreNames";
import { StringUpperBound } from "../ErichConstants";
import { DbService } from "./DBService";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { GetJournalListFilter } from "../journals/JournalService";
import { objectStoreDeleteAsync } from "./ObjectStoreExtensions";

export class JournalEntryStore
{

    constructor(private dbService:DbService)
    {

    }



    async getList(filter:GetJournalListFilter)
    {
        var db = await this.dbService.open();

        var objectStore = db.transaction(StoreNames.JournalEntries)
          .objectStore(StoreNames.JournalEntries);


        var index:IDBIndex = objectStore.index(StoreIndexNames.JournalEntriesMainIndex);

        let searchPredicate = (journalEntry:JournalEntry) =>
        {
            if(filter.searchString
                )
            {
                if(journalEntry.tag?.toLowerCase()!==filter.searchString.toLowerCase())
                {
                    return false;
                }
            }

            if(filter.openLowerBoundOrderId && journalEntry.id === filter.openLowerBoundOrderId)
            {
                return false;
            }
            return true;
        };

        if(filter.openLowerBoundOrderId)
        {

          var lastItem:JournalEntry = await objectStore
            .hokGetAsync(filter.openLowerBoundOrderId);

          if(lastItem)
          {
            var upperBoundKey = [
                lastItem.journalTypeName,
                lastItem.inputTime,
                lastItem.creationTime];
            // IDBKeyRange.bound()
            var lowerBoundKey = [lastItem.journalTypeName, "", ""];
            
            var bound = IDBKeyRange.bound(lowerBoundKey, upperBoundKey, false, false);
            
             return await index.hokOpenCursorToListAsync<JournalEntry>({
               query:bound,
               direction:"prev",
               count:filter.take,
               where:searchPredicate
              });
          }
        }
        
        return await index.hokOpenCursorToListAsync<JournalEntry>({
            query:IDBKeyRange.bound([filter.journalType, "", ""], 
            [filter.journalType, StringUpperBound,StringUpperBound
            ], false, false),
            direction:"prev",
            count:filter.take,
            where:searchPredicate
          });
          /*
        if(filter.openLowerBoundEntityId)
        {

          var lastItem:Entity = await objectStore
            .hokGetAsync(filter.openLowerBoundEntityId);

          if(lastItem)
          {
            var lowerBoundKey = [
                lastItem.type,
                lastItem.tag,
                lastItem.name];
            // IDBKeyRange.bound()
            var upperBoundKey = [lastItem.type, StringUpperBound, StringUpperBound];
            
            var bound = IDBKeyRange.bound(lowerBoundKey, upperBoundKey, true, false);
            
             return await index.hokOpenCursorToListAsync<Entity>({
               query:bound,
               take:filter.take,
               where:searchPredicate
              });
          }
        }

        
        return await index.hokOpenCursorToListAsync<Entity>({
          query:IDBKeyRange.bound([filter.type, "", ""], 
          [filter.type, StringUpperBound,StringUpperBound
          ], false, false),
          take:filter.take,
          where:searchPredicate
        });*/
    }



    async get(id:string|number)
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.JournalEntries, "readonly");
      var entityStore = txn.objectStore(StoreNames.JournalEntries);

      return await entityStore.hokGetAsync(id) as JournalEntry;
    }

    private internalPutEntry(store:IDBObjectStore, o:JournalEntry)
    {
      return new Promise<JournalEntry>((resolve, reject)=>{
        
        var req = store.put(o);

        req.onsuccess = function(evt){
          
          if(!o.id)
          {
            resolve({

              ...o,
              id:req.result as any
            });
            
          }else
          {
            resolve(o);
          }
        };

        req.onerror = function(evt)
        {
          reject(evt);

        };
      });
    }

    async put(entity:JournalEntry)
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.JournalEntries, "readwrite");
      var orderStore = txn.objectStore(StoreNames.JournalEntries);

      return await this.internalPutEntry(orderStore, entity);
    }

    
    async putAll(entries:JournalEntry[])
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.JournalEntries, "readwrite");
      var store = txn.objectStore(StoreNames.JournalEntries);

      return await Promise.all(
        entries.map(e => 
        
        this.internalPutEntry(store, e)


      ));

    }

    
    async delete(id:string|number)
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.JournalEntries, "readwrite");
      var journalStore = txn.objectStore(StoreNames.JournalEntries);

      return await objectStoreDeleteAsync(journalStore, id);
    }
}