import { serviceBasePath } from "../ErichConfig";
import { HttpService } from "./HttpService";

export class DirectoryHttpService extends HttpService
{

    constructor(private directoryTag:string)
    {
        super();

        this.apiBasePath = `${serviceBasePath}/d/${encodeURIComponent(this.directoryTag)}/api`;
    }
    
}