import { OrderType } from "../models/entities/orders/order-type";
import { Order } from "../models/entities/orders/order";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { OrderService } from "./OrderService";


export interface DetailsState
{
    
        order?:Order
    
}
export class DetailsController extends DirectoryComponent
<{

    orderType:OrderType
},DetailsState>
{


    id:string;


    constructor(props)
    {
        super(props);
        this.state={};
    }


    componentDidMount()
    {
        
        this.id = this.getQueryParam("id");
        var service = new OrderService(this.context);
        
        service.find(this.id)
        .then(e=>{
            this.setState({
                order:e
            });
        });
    }

}