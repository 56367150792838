import React from "react";
import { useContext } from "react";
import { DataSelectInput, DataSelectInputPropsBase, ISyntheticEvent } from "hok-react";
import { DirectoryContext } from "../directory-shared/DirectoryContext";
import { EDataSelectListConfiguration } from "../components/EDataSelectList";
import { getTagDisplayName, inventoryEntityEquals } from "../functions/DataUtils";
import { EntityGroupService } from "./EntityGroupService";
import { EntityGroup } from "./entity-group";
import { EDataSelectWrapper } from "../components/DataSelectWrapper";

export function EntityGroupSelect
<

G = never>
(props:
    
    DataSelectInputPropsBase<EntityGroup, G> &
    {

        className?:string;
        style?:React.CSSProperties;

onChange?: (evt: ISyntheticEvent<{
    value: EntityGroup;
    name?: string;
    setInputValue?:(value:string)=>void;
}>) => void;


})
{

    

    var oContext = useContext(DirectoryContext);
    var takeCount = 10;

    
    var config:EDataSelectListConfiguration<EntityGroup> = {

        takeCount: takeCount,
        getDisplayName:getTagDisplayName,
        equals:inventoryEntityEquals,
        getItems: (filter) =>{

                    
            var itemService = new EntityGroupService(oContext);

            var q = {

                skip:filter.currentIndex,
                take:takeCount,
                searchString: filter.searchString
            };

            // filter.take = takeCount;
        

            return itemService.getList(q);
        }  
    };


    return <EDataSelectWrapper<EntityGroup, G>
         
    group={props.group}
    groupKey={props.groupKey}
    value={props.value}
    onChange={props.onChange}
    name={props.name}
    readOnly={props.readOnly}
    required={props.required}


    
    className={props.className}
    style={props.style}
    configuration={config}
        />;
}