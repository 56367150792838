import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { EDataSelectListConfiguration } from "../components/EDataSelectList";
import { DirectoryContext } from "../directory-shared/DirectoryContext";
import { getTagDisplayName, inventoryEntityEquals } from "../functions/DataUtils";
import { EntityService } from "./EntityService";
import { Entity } from "../models/entities/entities/entity";
import { DataSelectInputPropsBase } from "hok-react";
import { GetEntityListQueryModel } from "./GetEntityListQueryModel";
import { ErichDataSelect } from "../components/EDataSelect2/ErichDataSelect";
import { SyncEventType } from "../db/SyncEventType";
import { SyncEntryUpdateEvent } from "../db/SyncService";
import { EDataSelect } from "../components/EDataSelect";
import { EDataSelectWrapper } from "../components/DataSelectWrapper";

export function EntitySelect
<G = never>
(props: DataSelectInputPropsBase<Entity, G> &
    {
className?:string;
style?:React.CSSProperties;
entityTypeNameId?:string;
dropdownClassName?:string;

})
{
    var oContext = useContext(DirectoryContext);
    var takeCount = 10;

    
    var config:EDataSelectListConfiguration<Entity> = {

        takeCount: takeCount,
        getDisplayName:getTagDisplayName,
        equals:inventoryEntityEquals,
        getItems: (filter) =>{

                    
            var itemService = new EntityService(oContext);

            var q:GetEntityListQueryModel = {

                skip:filter.currentIndex,
                take:takeCount,
                searchString: filter.searchString,
                type: props.entityTypeNameId,
                includeReferenceData:true
            };

            // filter.take = takeCount;
            return itemService.getList(q);
        }  
    };

    var [resetCtr, setResetCtr]=useState(0);
    

    useEffect(()=>{
        // change the ctr when synced
        
        
        // only broadcast entity changes

        const handleOnUpdateEntry = (evt:SyncEntryUpdateEvent<Entity[]>)=>{

            if(evt.entryType === "Entity")
            {
                setResetCtr((ctr)=>{
                    
                    return ctr+1;
                
                });

            }
        };
        
        oContext.syncService.addEventListener(SyncEventType.UpdateEntries, handleOnUpdateEntry);
        

        return ()=>{

            
            oContext.syncService.removeEventListener(SyncEventType.UpdateEntries, handleOnUpdateEntry);
        };
    }, []);

    
    return <EDataSelectWrapper<Entity, G> 
                group={props.group}
                groupKey={props.groupKey}
                value={props.value}
                onChange={props.onChange}
                name={props.name}
                readOnly={props.readOnly}
                required={props.required}

                
                
                className={props.className}
                // dropdownClassName={props.dropdownClassName}
                style={props.style}
                configuration={config}

            />;
    
    
    // return <ErichDataSelect<Entity, G> 
    //             group={props.group}
    //             groupKey={props.groupKey}
    //             value={props.value}
    //             onChange={props.onChange}
    //             name={props.name}
    //             readOnly={props.readOnly}
    //             required={props.required}

                
                
    //             inputClassName={props.className}
    //             // dropdownClassName={props.dropdownClassName}
    //             inputStyle={props.style}
    //             configuration={config}

    //             resetDependencyList={[resetCtr]}
    //         />;


}