import { ISyntheticEvent } from "hok-react";
import React from "react";
import { DirectoryComponent } from "../../../directory-shared/DirectoryComponent";
import { hokDebounce } from "../../../functions/hokDebounce";
import { Entity } from "../../../models/entities/entities/entity";
import { Order } from "../../../models/entities/orders/order";
import { OrderType } from "../../../models/entities/orders/order-type";
import { OrderService } from "../../OrderService";
import { useEditOrder } from "../useEditOrder";

export class EditOrderHeaderController extends DirectoryComponent
<{orderType:OrderType,
value:Order,
readOnly:boolean;
updateOrder:<K extends keyof Order>(order:Pick<Order, K>) => void;
editOrder:ReturnType<typeof useEditOrder>;
onCreateNewToEntityClick:()=>void;
},{

    hasDuplicateReferenceId?:boolean
}>
{

    constructor(props)
    {
        super(props);
        this.state={};
    }
    

    handleOrderPropertyChange = (evt:ISyntheticEvent<{
        type?:string,
        value:any, name:string,
        checked?:boolean
    }>) =>{

        
        this.props.updateOrder({[evt.target.name]:
            evt.target.type === "checkbox" ? 
            evt.target.checked   : 
            evt.target.value
        
        } as any);
    };

    handleFromEntityChange = async (evt:ISyntheticEvent<{value:Entity}>)=>{

        this.props.editOrder.clearEntityItems();
        // this.setState({itemIdFromEntityItemMap:{}});
        this.props.updateOrder({fromEntity:evt.target.value});
    };


    handleToEntityChange = async (evt:ISyntheticEvent<{value:Entity}>)=>{
        this.props.updateOrder({toEntity:evt.target.value});
    };

    handleShipmentSourceOrderChange = async (evt:ISyntheticEvent<{value:Order}>)=>{
        
        let sourceOrder = evt.target.value;

        let nextOrder = {...this.props.value, 
            sourceOrder
        };
        this.props.editOrder.setOrder(
            nextOrder);

/*
        var orderWithShipment = {order:sourceOrder, shipmentOrders:[]};
        var complimentLines = createComplimentOrderLines(
            orderWithShipment);

        let nextOrder = 
        populateShipmentOrderFromSourceOrder(
            sourceOrder, {...this.props.value, sourceOrder}, complimentLines);
        this.props.editOrder.setOrder(nextOrder);
*/
        if(sourceOrder)
        {

            this.props.editOrder.sourceOrderHelper
                .updateThisShipmentWithSourceOrder(nextOrder, sourceOrder);
            
        }
    };

    
    updateFullAddress = (address:string)=>{

        var value = this.props.value;
        this.props.updateOrder({

            deliveryAddress:{
                ...value.deliveryAddress,
                fullAddress:address
            }
        })

    };






    handleTagChange = (evt:ISyntheticEvent<{value:string}>)=>{

        var refId = evt.target.value;

        
        hokDebounce(async ()=>{
            if(!refId)
            {
                this.setState({hasDuplicateReferenceId:false});
                return; // just leave it
            }
            var service = new OrderService(this.context);

            var res = await service
            .existsTag(this.props.orderType.customModel.name, refId, 
                this.props.value.id);
            
            this.setState({hasDuplicateReferenceId:res});
        });

        
        this.props.updateOrder({
            tag:refId
        });
    };






}