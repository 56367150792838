

import React, { CSSProperties } from 'react';
// @ts-ignore
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

import { IRouteProps, Route } from 'react-router-dom';
import { Home } from '../pages/Home';
import { DirectoryApp, MainModalProvider, ToastProvider, ItemsRoot, InventoryCountsHome, EntityItemLedgerEntriesHome, SalesAgentsRoot, ItemCategoriesRoot, PaymentTermsConfigurationsRoot, EntityTypesRoot, OrderTypesRoot, JournalTypesRoot, DirectoryRoot, CustomFieldsRoot, EntitiesRoot, OrderRoot, JournalsRoot, ToastContext } from '..';
import { ErichRoles } from '../directory-shared/roles';
import { DirectoryContextModel } from '../hooks/useDirectoryApp';
import { EddyTest } from '../eddy-test/Test';
import { DirectoryConfigurationEdit } from '../directory-configuration/Edit';
import { ReservationEntriesHome } from '../entity-items/ReservationEntriesHome';


export class DirectoryLayout extends React.Component<
IRouteProps<{directoryTag:string}>,
{
    loaded?:boolean,
    user?:{roles:string[]},
    directoryContext?:DirectoryContextModel
}
>
{

    renderRoutes(
        path:string,
        ctx:DirectoryContextModel)
    {
        return <>
        <Route path={`${path}/Test`} component={EddyTest}/>
        <Route exact path={`${path}/`} component={Home}/>
        <Route path={`${path}/items`} component={ItemsRoot}/>


        <Route path={`${path}/inventory-counts`} component={InventoryCountsHome}/>
        <Route path={`${path}/reservation-entries`} component={ReservationEntriesHome}/>
        <Route path={`${path}/item-ledger-entries`} component={EntityItemLedgerEntriesHome}/>
        
        <Route path={`${path}/SalesAgents`} component={SalesAgentsRoot}/>
        <Route path={`${path}/ItemCategories`} component={ItemCategoriesRoot}/>
        <Route path={`${path}/PaymentTermsConfigurations`} component={PaymentTermsConfigurationsRoot}/>
        
        {/* developer only */}
        <Route path={`${path}/EntityType`} component={EntityTypesRoot}/>
        <Route path={`${path}/OrderType`} component={OrderTypesRoot}/>
        <Route path={`${path}/JournalType`} component={JournalTypesRoot}/>
        <Route path={`${path}/edit-directory-configuration`}>
            <ToastContext.Consumer>{toastContext =>
            
            <DirectoryConfigurationEdit toastContext={toastContext}/>
            }</ToastContext.Consumer>
            </Route>
        <Route path={`${path}/custom-fields`} component={CustomFieldsRoot}/>
        
        
        {/* <ModelTypesRoutes /> */}
        

        {ctx.data.entityTypes.map(e => 
        {
            return <Route key={e.customModel.name} path={`${path}/${e.customModel.name}`}>
                
                <EntitiesRoot entityType={e}/>
            </Route>
        })}
        
        {ctx.data.orderTypes
        .filter(otype => 
            

            ctx.directoryService.checkUserRoles(
                ErichRoles.Administrator+ "," +
            otype.customModel.name + "," + otype.customModel.name +"_Read"))
        .map(e => 
            <Route key={e.customModel.name} path={`${path}/${e.customModel.name}`}>
            <OrderRoot orderType={e}/>
            </Route>
            )}

        
        {ctx.data.journalTypes.map(e => 
            <Route key={e.customModel.name} path={`${path}/${e.customModel.name}`}>
            <JournalsRoot journalType={e}/>
            </Route>
            )}
            

                    
        {/* <Route path={`${path}/test-page`} component={TestPage}/> */}
{/* 

        <Route path={`${path}/physical-inventory`} 
            component={PhysicalInventoryRoot}/>
        <Route path={`${path}/balance-entries`} 
            component={BalanceEntriesRoot}/>
        <Route path={`${path}/payables/edit`} component={PayablesEdit}/>
        
        <Route path={`${path}/payables`} component={PayablesRoot}/>
        
        <Route path={`${path}/payment-terms`} component={PaymentTermsRoot}/>
          */}
    </>;
    }
    

    stickyStyle:CSSProperties = {
        position:"sticky",
        top:0, zIndex:1};

        
    render()
    {
        var path = this.props.match.path;


        return (
            <DirectoryApp {...this.props}>
                {ctx =>
            <MainModalProvider>
            <ToastProvider>

                
            {ctx.isOffline && 
            <div className="bg-dark text-center text-light" style={this.stickyStyle}>offline</div>
            }
    
            {ctx.isSyncing && 
            <div className="bg-info text-center" style={this.stickyStyle}>syncing...</div>
            }

            {ctx.syncErrors &&
            
            <div className="bg-danger text-center" style={this.stickyStyle}>
                <ul>
                {Object.keys(ctx.syncErrors).map(k => {
                    
                    return <React.Fragment key={k}>
                        {ctx.syncErrors[k].map( (err,idx)=><li key={idx}>{k} - {err}</li>)}
                        </React.Fragment>;
                })}
                </ul>
                {ctx.syncErrors.syncErrorMessage} - contact developer</div>

            }

            <NavMenu directoryContext={ctx}
            />
            <Container id="directory-layout-container" fluid="lg">
            {/* {this.props.children} */}
                {this.renderRoutes(path,ctx)}
            
            
            </Container>
        


    
        </ToastProvider>
        </MainModalProvider>
    
}
        </DirectoryApp>
        );
    }
}




