import { FormGroup, FormGroupContext, FormInput, Input, ValidationSummary } from "hok-react";
import * as React from "react";
import { MeasureUnitsEditController } from "./MeasureUnitsEditController";
import {Toast } from 'reactstrap';
import { DirectoryLink } from "../..";
import { ItemMeasureUnit } from "../../models/entities/items/item-measure-unit";

export class MeasureUnitsEdit extends MeasureUnitsEditController
{
  toastRef = React.createRef<Toast>();


    render(){

      var item = this.state.item;

      if(!item)
      {
          return <div>loading</div>;
  
  
      }
  

return <>
<div>Base Unit of Measure: {item.baseUnitOfMeasureCode}</div>

      
<form onSubmit={e=> this.handleSubmit(e)}>

<ValidationSummary errors={this.state.errors} name=""/>
<FormGroup
  value={this.state.measureUnits}
  onChange={e=> this.setState({measureUnits:e.target.value})}
>{g => <div>


    
<table>

  <thead>
    <tr>
      <th>Code</th>
      <th>
        Base Unit of Measure Qty (Qty in {this.state.item.baseUnitOfMeasureCode})
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>


  {this.state.measureUnits.map((measureUnit,idx)=>
  <FormGroup
    key={idx}
    group={g}
    groupKey={idx}
  >{(cGroup:FormGroupContext<ItemMeasureUnit>)=><>

  

  <tr>
    <td>
      <Input group={cGroup} groupKey="code"
      required
      />
    </td>
    <td>
        <Input className="w-100" 
          group={cGroup}
          groupKey="baseUnitOfMeasureQuantity"
          required={Boolean(cGroup.value.code)}
          customValidity={

        !this.isValidQuantity(cGroup.value)?"Invalid Quantity":""
          }
        />

      
    </td>
    <td>
      
          <span className="btn btn-light"
          onClick={e => this.removeItem(measureUnit)}
          >
          Delete
          </span>
    </td>

  </tr> {/* end of row */}
  </>}
  </FormGroup>)
}

  </tbody>
</table>

</div>
  
}</FormGroup>
<button type="button" className="" onClick={this.add}>Add Unit of Measure</button>
<br/><br/>

<div className="form-group">
    <input type="submit" value="Save" className="btn btn-primary"
    disabled={this.state.isSubmitting}
    />
    
    &nbsp;&nbsp;

    <DirectoryLink to="/items">Back to List</DirectoryLink>
<br/>
<ValidationSummary errors={this.state.errors} name=""></ValidationSummary>
  

<br/>
</div>


</form>
  
  </>;
} //end of render
}