
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { OrderLineAndOrder } from "../models/view-models/OrderLineAndOrder";

export class OrderLineService
{
    

    constructor(private http:DirectoryHttpService)
    {

    }

    
    getOrderLineAndOrders(req:{   
        skip:number,
        take:number,
        searchString?:string,

        orderType?:string,
        fromEntityId?:string,
        toEntityId?:string,
        itemId?:string
    })
    {
        return this.http.post<OrderLineAndOrder[]>("/OrderLines/GetOrderLineAndOrders", {
            body:req
        });
    }
}