import { FormGroup, FormGroupContext, ISyntheticEvent, ValidationSummary } from "hok-react";
import React, { FormEvent } from "react";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";


export type IEditFormChildren<T> =(g:FormGroupContext<T, {errors:{[key:string]:string[]}}>) => 
JSX.Element | JSX.Element[];

export class EditForm<T> extends DirectoryComponent<
    {
        isEdit:boolean,
        title:string,
        colClassName?:string,
        value:T,
        onChange:(evt:ISyntheticEvent<{value:T}>) => void;
        errors:{[key:string]:string[]};
        onSubmit:(evt:FormEvent<HTMLFormElement>) => void;
        isSubmitting:boolean;


        children: IEditFormChildren<T> 

    }>

{

    render()
    {
        var props = this.props;

        return <>
            <h1>{props.isEdit?"Edit":"Create"}</h1>

            <h4>{props.title}</h4>
            <hr />
            <div className="row">
            <div className={`${props.colClassName??"col-md-4"}`}>
            <form method="post" onSubmit={props.onSubmit}>
                <ValidationSummary errors={props.errors} name=""/>

                <FormGroup value={props.value} 
                onChange={props.onChange}
                childrenProps={{errors:props.errors}}
                >
                {props.children}
                </FormGroup>
                
                <div className="form-group">
                    <input type="submit" value="save" className="btn btn-primary"
                    disabled={props.isSubmitting}
                    />
                </div>
            </form>
            </div>
            </div>




                    
            <div>



                    
                <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
            </div>
        </>;

    }
    
    
}