import { GetOnOrderItemChangeDataModal } from "../../entity-groups/EntityGroupService";
import { EntityItem } from "../../models/entities/entities/entity-item";
import { Item } from "../../models/entities/items/item";




export interface EditOrderEntityItemsState
{

    itemMap:{[key:string]:Item};
    itemIdFromEntityItemMap:{[key:string]:EntityItem};
    itemIdFromEntityGroupEntityItemsMap:{[key:string]:EntityItem[]};
}



export function useEditOrderEntityItemsReducer(state: EditOrderEntityItemsState, action:{
    type:"clear"|"save",
    orderItemChangeData?:GetOnOrderItemChangeDataModal
}) : EditOrderEntityItemsState
{
    if(action.type==="clear")
    {
        return {
            itemMap:state.itemMap,
            itemIdFromEntityGroupEntityItemsMap:{},
            itemIdFromEntityItemMap:{}
        };
    }else if(action.type==="save")
    {

        var item = action.orderItemChangeData.item;

        
        var next : EditOrderEntityItemsState = {

            itemMap:{...state.itemMap, 
                [item.id]:item
            
            },
            itemIdFromEntityItemMap:{

                ...state.itemIdFromEntityItemMap,
                //...action.getOnOrderItemChangeData.entityItems

            },
            itemIdFromEntityGroupEntityItemsMap:{

                ...state.itemIdFromEntityGroupEntityItemsMap,
                ...action.orderItemChangeData.itemIdEntityGroupEntityItems
                // [item.id]:action.getOnOrderItemChangeData.entityGroupEntityItems
                //...action.getOnOrderItemChangeData.entityGroupEntityItems
            },
        };

        for(let i of action.orderItemChangeData.entityItems)
        {
            next.itemIdFromEntityItemMap[i.itemId] = i;
        }

        return next;
    }
}

