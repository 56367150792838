import { DataSelectInput, DataSelectInputPropsBase, extendFormGroupProps, ISyntheticEvent } from "hok-react";
import { CustomFieldOptionBase } from "hoksource";
import React from "react";
import { useMemo } from "react";
import { EDataSelectWrapper } from "./DataSelectWrapper";
import { EDataSelectListConfiguration } from "./EDataSelectList";

export function CustomFieldOptionsSelect
<G = never>
(props: DataSelectInputPropsBase<string, G> &
    {
className?:string;
style?:React.CSSProperties;
options:CustomFieldOptionBase[];

})
{
    

    props = extendFormGroupProps(props as any);
    
    var takeCount = Infinity;

    var selectMap = useMemo(()=>{

        var p :{[key:string]:CustomFieldOptionBase}= {};

        for(var i of props.options)
        {
            p[i.value] = i;
        }

        return p;
    },[props.options]);

    console.log("select map",selectMap);

    var onChange = useMemo(()=>{

        return (evt:ISyntheticEvent<{value:string}>) =>{
            props.onChange({

                target:{

                    ...evt.target,
                    value:evt.target.value
                }
            });

        };
    },
    // eslint-disable-next-line
    [props.onChange]);
    
    var config:EDataSelectListConfiguration<string> = {

        takeCount: takeCount,
        getDisplayName:(e)=>e?selectMap[e].text:"",
        // equals:inventoryEntityEquals,
        getItems: (filter) =>{
            console.log("options: ",props.options)
            
            return Promise.resolve(
                props.options.filter(e => e.text.indexOf(filter.searchString) >= 0)
                .slice(filter.currentIndex)
                .map(e => e.value)
                );
        }  
    };



    return <EDataSelectWrapper<string, G> 
                // group={props.group}
                // groupKey={props.groupKey}
                readOnly={props.readOnly}
                required={props.required}


                
                
                value={props.value}
                onChange={onChange}
                configuration={config}
                className={props.className}
                style={props.style}
            />;
}