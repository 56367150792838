import React, { useState } from "react";
import { useEffect } from "react";
import { IRouteProps } from "react-router-dom";
import { useQuery } from "../hooks/useQuery";
import { oauth2HttpClient, oauth2RedirectUri } from "../http/oauth2HttpClient";

export function saveOAuthTokens()
{
    
    localStorage.userTokens = JSON.stringify({
        accessToken:oauth2HttpClient.accessToken,
        refreshToken:oauth2HttpClient.refreshToken
    });
}

export function SigninOAuth2(props:IRouteProps)
{
    var q = useQuery();

    var code = q.get("code");

    var [error,setError] = useState<string>();


    useEffect(()=>{

        var t= async ()=>{

            var res = await oauth2HttpClient.oAuth2Service.requestAccessToken({
                code:code,
                redirect_uri:oauth2RedirectUri,
                client_id:"NOT_USED_YET"});

            if(res.error)
            {
                // do nothing
                setError(res.error);
            }else
            {
                oauth2HttpClient.accessToken = res.access_token;
                oauth2HttpClient.refreshToken = res.refresh_token;


                saveOAuthTokens();

                /*
                localStorage.userTokens = JSON.stringify({
                    accessToken:oauth2HttpClient.accessToken,
                    refreshToken:oauth2HttpClient.refreshToken
                });*/

                props.history.replace("/");
                
            }

        };

        t();
    
        
    },
    // eslint-disable-next-line
    []);

    if(error)
    {
        return <div>error {error}</div>
    }
    return <div>Authorizing...</div>;
}


export async function logout(directoryTag:string)
{

    
    // cheat
    localStorage.clear();
    /*
    localStorage.removeItem(LocalStorageAppInitDataKey);
    localStorage.removeItem("userTokens");
    */
   // implement deletion of all database
    if(!indexedDB["databases"])
    {
        
        
        var deleteReq = 
        indexedDB.deleteDatabase("erichdb-" + directoryTag.toLowerCase());
        
        deleteReq.onsuccess = 
        deleteReq.onerror = ()=>{

            window.location.href="/";

        };
    }else
    {
        var databases:[{name:string}] = await indexedDB["databases"]();
        var deletePromises:Promise<any>[] = [];
        for(let db of databases)
        {
            if(db.name.startsWith("erichdb-"))
            {
                deletePromises.push(new Promise((resolve) => {
                                
                    var deleteReq = 
                    indexedDB.deleteDatabase(db.name);
                    
                    deleteReq.onsuccess = 
                    deleteReq.onerror = resolve;
    
                }));
            }
        }
    
        await Promise.all(deletePromises);
        window.location.href="/";
        
    }
}