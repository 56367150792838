import { extendFormGroupProps, FormGroupOrValueProps } from "hok-react";
import React, { useContext, useEffect, useRef } from "react";
import { toLocaleDateString } from "../functions/DataUtils";
import { OrderSelectModalContext } from "../modals/OrderSelectModalProvider";
import { Order } from "../models/entities/orders/order";


export interface OrderSelectProps<
G = never
> extends FormGroupOrValueProps<Order, G>
{
    
    placeholder?:string;
    className?:string;
    readOnly?:boolean;
    required?:boolean;
    orderTypeNameId:string;
    // configuration:OrderSelectListConfiguration<T>;
    
}



export function OrderSelect<

G = never
>(props:OrderSelectProps<G>)
{
    var modalCtx = useContext(OrderSelectModalContext);
    var btnRef = useRef<HTMLButtonElement>(null);

    props = extendFormGroupProps<Order,G,
    OrderSelectProps<G>
    >(props);

    var val = props.value;


    function openDialog()
    {

        modalCtx.showOrderSelectModal({
            orderTypeNameId:props.orderTypeNameId,
            currentValue:props.value,
            onSelect:(order:Order)=>{
                props.onChange({target:{value:order,
                name:props.name
                }});
            }
        });
    }

    useEffect(()=>{

        if(props.required)
        {

            console.log(props.value);
            
            btnRef.current.setCustomValidity("Please fill out this field.");
            
            if(!props.value)
            {
                btnRef.current.setCustomValidity("Please fill out this field.");
            }else
            {
                
                btnRef.current.setCustomValidity("");
    
            }

        }
    },[props.value,props.required]);


    return <button 
    type="button"
    className={`hok-data-select-button ${props.className||''}`}
    onClick={openDialog}
    disabled={props.readOnly}
    // title={props.configuration.getDisplayName(props.value)}
    ref={btnRef}

    onKeyUp={(evt)=>{

        if(evt.key === "ArrowDown")
        {
          openDialog();
        }
    }}>
    {
        props.value&&
        <div>{
            `${props.value.tag||""} - 
            ${toLocaleDateString(val.inputTime)} - 
            ${props.value.typeName} To ${props.value.toEntity.name}`
            }
        </div>
    }
    {

        !props.value&&
<span style={{color:"grey"}}>None</span>

        
        // (
        // props.placeholder?
        // <span style={{color:"grey"}}>{props.placeholder}</span> : 
        // <span style={{color:"grey"}}>&nbsp;</span>
        // )
    }
    
</button>
}