import { FormInput, ISyntheticEvent } from "hok-react";
import React from "react";
import { hokDebounce } from "../functions/hokDebounce";
import { ItemSelect } from "../items/ItemSelect";
import { ItemService } from "../items/ItemService";
import { Item } from "../models/entities/items/item";
import { ItemBom } from "../models/entities/items/item-bom";
import { AssemblyOrder } from "../models/entities/orders/assembly-order";
import { Order } from "../models/entities/orders/order";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { createAssemblyOrderLines } from "./Edit/OrderLines/AssembleToOrderFunctions";
import { DefaultOrderLineBase, OrderLine } from "../models/entities/orders/order-line";
import { getNextSortOrder } from "../functions/getNextSortOrder";

interface AssemblyOrderInputProps
{
    order:Order,
    value:AssemblyOrder,
    readOnly:boolean,
    
    updateOrder<K extends keyof Order>(order:Pick<Order, K>,
        cb?:()=>void
        ):void,
}

export class AssemblyOrderInput extends DirectoryComponent<AssemblyOrderInputProps, {

}>
{



    lastBom:ItemBom;
    itemWithBom:Item;

    

    proccessAssemblyOrderLines(prev:AssemblyOrder, next:AssemblyOrder)
    {
        if(this.itemWithBom.defaultBom)
        {
            if(next.quantity>0)
            {
                    
                // set the orderlines
                if(prev?.item?.id !== next.item.id) // different bom
                {

                    this.props.updateOrder({
                        lines:
                        createAssemblyOrderLines(this.props.order, this.itemWithBom, next.quantity)
                    });
                }
                else // same item
                {
                    var nextLines:OrderLine[] = [];
                    var prevLinesCopy = this.props.order.lines.slice();

                    // same bom
                    for(let i of this.itemWithBom.defaultBom.lines)
                    {
                        var idx = prevLinesCopy.findIndex(e => e.item?.id === i.item.id);

                        if(idx >=0)
                        {
                            nextLines.push({
                                ...prevLinesCopy[idx],
                                quantity:i.quantity * next.quantity
                            });

                            prevLinesCopy.splice(idx,1);
                        }else
                        {
                            var nextSortOrder=  getNextSortOrder(nextLines);
                            nextLines.push({
                                ...DefaultOrderLineBase,
                                item:i.item,
                                quantity:i.quantity * next.quantity,
                                childLines:[],
                                sortOrder:nextSortOrder
                            });
                        }
                    }

                    nextLines=nextLines.concat(prevLinesCopy);

                    this.props.updateOrder({
                        lines:nextLines
                    });
                }

            }
        }

        this.lastBom = this.itemWithBom?.defaultBom; // could be null;

    }

    async processAssemblyOrder(prev:AssemblyOrder, next:AssemblyOrder)
    {

        if(next.item) // process it
        {
            if(this.itemWithBom?.id !== next.item.id)
            {
                // fetch itemBom
                var itemService = new ItemService(this.context);
                hokDebounce(async ()=>{


                    try{

                        var res = await itemService.find(next.item.id);
    

                        this.itemWithBom = res;

                                
                        this.proccessAssemblyOrderLines(prev,next);
    
                    } catch(e)
                    {
                        // do nothing
                    }
                });
            } else {

                this.proccessAssemblyOrderLines(prev,next);


            }

        }

        this.props.updateOrder({assemblyOrder:next});
    }

    handleItemChange = (evt:ISyntheticEvent<{value:Item}>)=>
    {
        // query item from db

        this.processAssemblyOrder(
            this.props.value,
            {...this.props.value, item:evt.target.value});
        
    };

    handleQuantityChange = (evt:ISyntheticEvent<{value:number}>)=>
    {

        this.processAssemblyOrder(
            this.props.value,
            {...this.props.value, quantity:evt.target.value});
    };


    render(){

        var value = this.props.value;

        return <div className="row">
        <div className="col-md-4">
            <div className="form-group">
                <label className="control-label">
                    Item to Assemble
                </label>
                <ItemSelect 
                    value={value.item}
                    className='form-control' 
                    onChange={this.handleItemChange}
                    readOnly={this.props.readOnly}
                    required
                ></ItemSelect>
            </div>
        </div>
    
        <div className="col-md-4">
            <FormInput 
                value={value.quantity}
                labelText="Quantity to Assemble"
                onChange={this.handleQuantityChange}
                readOnly={this.props.readOnly}
                required
                
                customValidity={value.quantity>0?
                    "":
                    "Quantity must be greater than 0."}
            />
        </div>
    </div>;
    }


}