import { ISyntheticEvent } from "hok-react";
import { requestLock, ValidationProblemDetails } from "hoksource";
import React from "react";
import { Entity } from "../models/entities/entities/entity";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { JournalType } from "../models/entities/journals/journal-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EditStateBase } from "../types/EditStateBase";
import { JournalService } from "./JournalService";
import { SyncLockName } from "../ErichConstants";



export interface JournalEntriesInputModalConfig
{
  title:string;
  fromEntity?:Entity;
  toEntity?:Entity;
  applyToJournalEntries?:JournalEntry[];
}

interface JournalEntriesInputModelProps
{
    show:boolean;
    className?:string;

    config:JournalEntriesInputModalConfig;
        
    onClose:(result:{succeeded:boolean})=>void;


    journalType:JournalType;

}


export class JournalEntriesInputModalController extends DirectoryComponent
<{
    show:boolean;
    className?:string;
    config:JournalEntriesInputModalConfig;
    onClose:(result:{succeeded:boolean})=>void;
    journalType:JournalType;
},EditStateBase<JournalEntry[]>>
{
    constructor(props)
    {
        super(props);
        this.state= {input:[]};
    }


    componentDidUpdate(prevProps: JournalEntriesInputModelProps)
    {
            
        if(prevProps.config !== this.props.config)
        {
            // init data
            if(this.props.config)
            {


                var entries = [this.createNewEntry()];
                this.updateLastEntryWithApplyToJournalEntries(entries);

                if(entries[0].amount)
                {
                    entries.push(this.createNewEntry());
                }
                // reinit input
                this.setState({input:entries});
            }
        }
    }


    
    computeTotalBalance(entries:JournalEntry[])
    {
        var sum = 0;
        for(let i of entries)
        {
            if(!isNaN(i.balance))
            {
                sum += Number(i.balance);
            }

        }
        return sum;
    }

    computeTotalAmount(entries:JournalEntry[])
    {
        var sum = 0;
        for(let i of entries)
        {
            if(!isNaN(i.amount))
            {
                sum += Number(i.amount);
            }

        }
        return sum;
    }

    updateLastEntryWithApplyToJournalEntries = (journalEntries:JournalEntry[])=>{

        var config = this.props.config;
        var {applyToJournalEntries } = config;

        if(applyToJournalEntries)
        {
            var applyToEntriesTotalAmount = this.computeTotalBalance(applyToJournalEntries);

            var lastIndex = journalEntries.length-1;
            var sum = 0;
            for(let i = 0; i <  lastIndex; ++i)
            {
                var je = journalEntries[i];
                if(!isNaN(je.amount))
                {
                    sum += Number(je.amount);
                }

            }


            var inputEntriesTotalAmount = sum;
    
            var nextAmount = - applyToEntriesTotalAmount - inputEntriesTotalAmount;
    
            
            var last : JournalEntry = {
                ...journalEntries[lastIndex],
            };
            if(nextAmount * applyToEntriesTotalAmount < 0) // different sign
            {
                var type = this.props.journalType.entryTypeInputOptions.find(e => 
                    nextAmount * e.amountSign > 0 // same sign
                    );
                if(lastIndex === 0)
                {
                    last.amount = nextAmount;
                }
                last.entryType = type?.value;
            }else
            {
                last.amount = "" as any; //
                last.entryType = "";
            }
            
            journalEntries[lastIndex] = last;
        }

    };

    
    createNewEntry = () =>
    {
        
        var journalType = this.props.journalType;
        var config = this.props.config;
        var {applyToJournalEntries } = config;

        var defaultCurrencyCode = this.context.data.directoryConfiguration.currencyCode;

        var entry = {
            journalTypeName:journalType.customModel.name,
            entryType:journalType.entryTypeInputOptions[0]?.value || "", // get the max 
            inputTime:new Date().hokToLocaleDateTimeOffsetString(),
            // amount:"" as any, // computeRequiredAmount
            entryMode:journalType.entryModeInputOptions[0]?.value || "",
            currencyCode:defaultCurrencyCode
        } as JournalEntry;


        if(applyToJournalEntries)
        {

            if(applyToJournalEntries.length)
            {
                var first = applyToJournalEntries[0];
                entry.fromEntity = first.fromEntity;
                entry.toEntity = first.toEntity;
                entry.currencyCode = first.currencyCode;

                // check type for same sign use it
                // get max input time

                var maxDate:Date = null;
                var maxDateString = null;
                for(let i of applyToJournalEntries)
                {
                    var d = new Date(i.inputTime);
                    var ds = d.hokToLocaleDateTimeOffsetString();
                    if(!maxDate || maxDate.getTime() < d.getTime())
                    {
                        maxDate = d;
                        maxDateString = ds;
                    }
                }

                entry.inputTime = maxDateString;
            }



        }


        return entry;
    }

    handleEntryChange = (evt:ISyntheticEvent<{value:JournalEntry}>, index:number) =>{


        var nextEntry = evt.target.value;
        var prev = this.state.input;
        var nextEntries = [...prev.slice(0,index), nextEntry, ...prev.slice(index+1)];
        
        //this.state.input.slice();


        if(index === nextEntries.length-1) // edited last entry
        {

            var last = nextEntries[nextEntries.length-1];
            if(last.amount)
            {
                //value = [...value, this.createNewEntry()];
                nextEntries.push(this.createNewEntry());
            }
        }

        if(index < nextEntries.length-1) // not the last
        {
            
            this.updateLastEntryWithApplyToJournalEntries(nextEntries);
        }
        this.setState({input:nextEntries});
    };



    
    handleRemoveEntryClick = (evt:ISyntheticEvent<{value:JournalEntry}>, index:number) =>{


        if(!window.confirm("Are you sure you want to discard this entry?"))
        {
            return;
        }

        var next = this.state.input.slice();
        next.splice(index,1);

        this.setState({input:next});
    };

    


    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>{

        evt.preventDefault();



        
        this.setState({
            errors:null,
            isSubmitting:true,
        });

        var list = this.state.input.filter(e => e.amount);

        list = list.map(e => ({

            ...e,
            dueTime:e.dueTime || null
        }));

        var service = new JournalService(this.context);

        try{
                
            
            await requestLock(SyncLockName, async()=>{ // lock it here

                return await service.saveAndApplyJournalEntries({
                    journalEntries:list,
                    applyToJournalEntryIds: 
                        this.props.config.applyToJournalEntries?.map(e => e.id)
                });


            });
            this.props.onClose({succeeded:true});

        } catch (err:any)
        {
            
            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }



        this.setState({isSubmitting:false});
        
        

    };

}