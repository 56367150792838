
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { DirectoryContextData } from "../models/directory-context-data";
import { DirectoryConfiguration } from "../models/entities/directories/directory-configuration";

export class DirectoryService
{

    
    constructor(private http:DirectoryHttpService)
    {

    }

    data:DirectoryContextData;

    getDirectoryInitData()
    {

      
      return this.http.post<DirectoryContextData>("/Directory/GetDirectoryInitData").then(res =>
        {
          return this.data = res
        });
    }

    
    checkUserRoles(roles)
    {
        roles=roles.split(',');
        for(var i of roles)
        {
            if(this.data.directoryUserRoles.indexOf(i.trim())>=0)
            {
                return true;
            }
        }

        return false;
    }





    // developer only
    getDirectoryConfiguration()
    {

        return this.http.post<DirectoryConfiguration>("/Directory/GetDirectoryConfiguration");
    }

    

    saveDirectoryConfiguration(directoryConfiguration:DirectoryConfiguration)
    {

        
        return this.http.post<DirectoryConfiguration>(`/Directory/SaveDirectoryConfiguration`, {
            body:directoryConfiguration
        });
    }
}