import {  ValidationSummary } from "hok-react";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { JournalEntriesInput } from "./JournalEntriesInput";
import { JournalEntriesInputModalController } from "./JournalEntriesInputModalController";


export class JournalEntriesInputModal extends JournalEntriesInputModalController
{


  render(){

    
    var props = this.props;
    // var journalType = this.props.journalType;

    
    var inputTotalAmount = this.state.input
    .filter(e => e.amount)
    .reduce((sum, curr)=> sum + (+curr.amount),0);

    var applyToJournalEntryBalance = props.
    config?.
    applyToJournalEntries?.reduce((sum, curr)=> sum + curr.balance,0);
    
    var applyToJournalEntryAmount = props.
    config?.
    applyToJournalEntries?.reduce((sum, curr)=> sum + curr.amount,0);
    

    const toggle = ()=>props.onClose(null);


    return <Modal isOpen={props.show} toggle={toggle} className={props.className}
      style={{maxWidth:"90vw"}}
      // size="xl"
      keyboard={false}
      autoFocus={false}
    >
      <form onSubmit={this.handleSubmit}>
    <ModalHeader toggle={toggle}>{this.props.config?.title}</ModalHeader>
    <ModalBody>
    <div style={{"overflowX":"auto", "paddingBottom":"10vh", height:"45vh"}}>

<style>{`

    .journal-entries-input td
    {
        vertical-align:top;
    }

`}</style>
        <JournalEntriesInput journalType={this.props.journalType}
            value={this.state.input}
            onEntryChange={this.handleEntryChange}
            onRemoveEntryClick={this.handleRemoveEntryClick}
            // createNewEntry={this.createNewEntry}
        />
      
    </div>
      
    </ModalBody>
    <ModalFooter>
      
    {inputTotalAmount &&
      <div className="text-end w-100">
        
        Input Total Amount {inputTotalAmount}
      </div>
      }
    {applyToJournalEntryBalance &&
      <div className="text-end w-100">
        
        Apply To Journal Entries Balance {applyToJournalEntryBalance}
      </div>
      }
      {applyToJournalEntryAmount &&
      <div className="text-end w-100">
        
        Apply To Journal Entries Amount {applyToJournalEntryAmount}
      </div>
      }
      <div>
      <ValidationSummary errors={this.state.errors} name=""/>
      <Button color="primary" 
      type="submit"
      disabled={this.state.isSubmitting}
      >Save</Button>{' '}
      <Button color="secondary" onClick={toggle}>Cancel</Button>
      </div>
    </ModalFooter>
    </form>
  </Modal>;
}

}