import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { DirectoryContext } from "../../directory-shared/DirectoryContext";
import { getNextSortOrder } from "../../functions/getNextSortOrder";
import { Order } from "../../models/entities/orders/order";
import { OrderLine } from "../../models/entities/orders/order-line";
import { OrderType } from "../../models/entities/orders/order-type";
import { OrderService } from "../OrderService";
import { createNewLine } from "./EditDefs";
import { createComplimentOrderLines, findSimilarOrderline, isSimilarOrderLine, populateShipmentOrderFromSourceOrder } from "./updateShipmentOrderFromOrderAndShipments";

export function useSourceOrderHelper(props:{
    orderType:OrderType,
    thisOrder:Order,
    setOrder:Dispatch<SetStateAction<Order>>
})
{

    var context = useContext(DirectoryContext);
    var [sourceShipmentOrders, setSourceShipmentOrders] = useState<Order[]>();
    var [complimentOrderLines, setComplimentOrderLines] = useState<OrderLine[]>();

    const loadShipmentWithSourceOrder = async(sourceOrderId:string)=>{
                
        var service = new OrderService(context);
        var sourceOrderWithShipments =
        await service.findOrderWithPostedShipments(sourceOrderId);
        setSourceShipmentOrders(sourceOrderWithShipments.shipmentOrders);
        var complimentLines = createComplimentOrderLines(sourceOrderWithShipments);
        setComplimentOrderLines(complimentLines);

        return {sourceOrderWithShipments, complimentLines};

    };

    return useMemo(()=>{
        return {
            sourceShipmentOrders,

            loadShipmentWithSourceOrder,
            
            updateThisShipmentWithSourceOrder:async (
                order:Order,
                sourceOrder:Order)=>{

                var res = await loadShipmentWithSourceOrder(sourceOrder.id);

                props.setOrder((prev)=>{
                    
                    let nextOrder = 
                    populateShipmentOrderFromSourceOrder(
                        res.sourceOrderWithShipments.order, {...order,
                        sourceOrder:res.sourceOrderWithShipments.order
                        }, res.complimentLines);

                    nextOrder.lines = [...nextOrder.lines, createNewLine(props.orderType, getNextSortOrder(nextOrder.lines))];
                    return nextOrder;
                });

            },
            computeQuantityToHandle(orderLine:OrderLine)
            {
                var nth = 0;
                for(let i of props.thisOrder.lines)
                {
                    if(i===orderLine)
                    {
                        break;
                    }
                    else if(isSimilarOrderLine(i, orderLine))
                    {
                        ++nth;
                    }
                }

                var so = findSimilarOrderline(props.thisOrder.sourceOrder?.lines, orderLine, nth);
                
                return so?.quantity??0;
            },
            computeQuantityHandled(orderLine:OrderLine)
            {

                
                var nth = 0;
                for(let i of props.thisOrder.lines)
                {
                    if(i===orderLine)
                    {
                        break;
                    }
                    else if(isSimilarOrderLine(i, orderLine))
                    {
                        ++nth;
                    }
                }

                var sourceOrderLine =
                    findSimilarOrderline(props.thisOrder.sourceOrder?.lines, orderLine, nth);
                var complimentOrderLine = findSimilarOrderline(complimentOrderLines, orderLine, nth);
                
                return (sourceOrderLine?.quantity??0) - (complimentOrderLine?.quantity??0);


            },
        };
    },
    [props.thisOrder,
        sourceShipmentOrders,
        complimentOrderLines]);
}