import { CustomFieldInput, DateTimeOffsetInput, FormGroup, Input } from "hok-react";
import React from "react";
import { isNegative } from "../../../functions/validations";
import { ItemSelect } from "../../../items/ItemSelect";
import { ItemUnitOfMeasureSelect } from "../../../items/ItemUnitOfMeasureSelect";
import { OrderLine } from "../../../models/entities/orders/order-line";
import { OrderTypeNameIds } from "../../../models/enums/OrderTypeNamesIds";
import { BasicOrderLineInputController } from "./BasicOrderLineInputController";

export class BasicOrderLineInput extends BasicOrderLineInputController
{

    renderStockError()
    {
        var value = this.props.value;

        if(!this.ifNotEnoughStock(value))
        {
            return <div>
                {this.props.editOrder.itemIdFromEntityGroupEntityItemsMap[value.item?.id]?.length ? 
                <>
                    {
    
                        this.props.editOrder.itemIdFromEntityGroupEntityItemsMap[value.item?.id].map((e,idx) => 
                            <div key={e.entityId}>
                            {e.entity.displayName} : {e.availableQuantity}
                            </div>
                            )
    
    
                    }
                </>
                :
                <>
                
                on hand: 
                {this.props.editOrder.itemIdFromEntityItemMap[value.item?.id].availableQuantity}
                
                </>
                }
    
            </div>;
        }
            

        return null;
    }

    render(){

        var parentLine = this.props.parentLineValue;
        var value = this.props.value;
        var orderType = this.orderType;

        var isShipmentType = Boolean(orderType.shipmentSourceOrderTypeName);
    return <><tr>


    <td className="text-right">
        {parentLine && 
        <div className="d-inline-block" style={{width:"10%"}}>
            &#x25CF;
        </div>
        }
        
        <div className="d-inline-block" style={{width:parentLine?"90%":"100%"}}>
            <ItemSelect
                value={value.item}
                onChange={this.handleChange}
                className="w-1001 form-control form-control-sm"
                // readOnly={value.id?true:false}
                name="item"
                readOnly={this.props.readOnly || isShipmentType}
                required={this.props.required}
            />

        </div>
        
    </td>




{orderType.customModel.name === OrderTypeNameIds.PhysicalInventoryOrder ? 

    <>

    <td>
        <Input 
            type='number'
            name='physicalQuantity'
            className="form-control form-control-sm"
            value={value.physicalQuantity}
            onChange={this.handleChange}
            style={{width:"7em"}}
            readOnly={this.props.readOnly}
            required={value.item?true:false}
            customValidity={value.physicalQuantity<0?"Physical Quantity should be non negative.":""}
        />
        <div className='text-danger'>
            {isNaN(parseFloat(value.physicalQuantity as any)) &&
                <div>Physical Quantity is required</div>
            }
            {value.physicalQuantity<0 &&
                <div>Physical Quantity should be non negative.</div>
            }
        </div>



        
    </td>
    {this.props.editOrder.order.postingTime &&
        <>
        <td className="text-center">
            {value.physicalQuantity + value.quantity}
        </td>
        <td className="text-center">
            {-value.quantity}
        </td>
        </>
        }
    </>
:
    
<>
    <td>
        <Input 
            type='number'
            name='quantity'
            className="form-control form-control-sm"
            value={value.quantity}
            onChange={this.handleChange}
            style={{width:"7em"}}
            readOnly={this.props.readOnly}
            required={value.item?true:false}
        />
        
        {parentLine && 
        <div className="d-inline-block mr-3" style={{whiteSpace:"nowrap"}}>
            x{parentLine.quantity}= {value.quantity * parentLine.quantity}
            &nbsp;&nbsp;&nbsp;
        </div>
        }
        <div className='text-danger'>
            {isNaN(parseFloat(value.quantity as any)) &&
                <div>Quantity is required</div>
            }
            {orderType.negativeLineQuantities && !(value.quantity<0) &&
                <div>Quantity should be less than 0.</div>
            }
            {this.renderStockError()}
        </div>
    </td>
</>
}


<td style={{width:"4em"}}>
    <ItemUnitOfMeasureSelect 
        item={value.item}
        value={value.unitOfMeasureCode}
        onChange={this.handleChange}
        className="form-control form-control-sm"
        readOnly={this.props.readOnly}
        name="unitOfMeasureCode"
        // required={value.item?true:false}
    />

</td>



{orderType.withPrice &&
        
        <>
        <td className="text-center">
            
            {value.item?.unitPrice}
            {value.item?.baseUnitOfMeasureCode && 
            <span>/{value.item?.baseUnitOfMeasureCode}</span>}
        </td>
            <td>
                <Input type="number" 
                    value={value.unitPrice}
                    onChange={this.handleChange}
                    name="unitPrice"
                        
                    className="form-control form-control-sm"
                    style={{width:"7em"}}
                    readOnly={this.props.readOnly}
                    required={value.item?true:false}
                    customValidity={this.unitPriceCustomValidity}
                />
                <div className='text-danger'>
                    {value.item && isNegative(value.unitPrice) &&
                        <div>UnitPrice is invalid</div>
                    }
                    
                </div>
            </td>
            <td>
                {value.quantity && value.unitPrice &&
                ((parentLine?parentLine.quantity:1) * value.quantity * value.unitPrice).toFixed(2)
                }

            </td>
        </>

    }

    {/* shipment type */}
    {orderType.shipmentSourceOrderTypeName &&
    
    <>
        <td>{!parentLine && 
        this.props.editOrder.sourceOrderHelper.computeQuantityToHandle(value)
        }</td>
        <td>
            {!parentLine && 
            this.props.editOrder.sourceOrderHelper.computeQuantityHandled(value)
            }
        </td>
    </>
    }



{orderType.enableExpirationTime &&

<td>
    <DateTimeOffsetInput type="date"
        value={value.expirationTime}
        onChange={this.handleChange}
        name="expirationTime"
        
        className="form-control form-control-sm"
        // style={{width:"7em"}}
        readOnly={this.props.readOnly}

    />
</td>


}


{this.props.editOrder.lineCustomModel && 
<FormGroup value={this.props.value}
onChange={this.handleLineChange}
>
{lineG => 
this.props.editOrder.lineCustomModel.fields.map((e,idx) => 

<td 

key={e.id}>
    
<CustomFieldInput<any, OrderLine> field={e}
group={lineG}
readOnly={this.props.readOnly}
required={value.item && e.required}

className="form-control form-control-sm"
inputOnly />

</td>)
}
</FormGroup>
}


    
<td>
{!this.props.readOnly &&
<>
    {value.item && // enable for item
    orderType.enableChildLines && !parentLine 
    && <><span className="cursor-pointer btn-link"
        onClick={this.handleAddChild}>
            Add Child Item
        </span>
        
    <span> | </span>
        </>
    }
    {(value.item || parentLine) && // item has value or child 
    <span className="cursor-pointer btn-link"
    onClick={this.handleDeleteClick}>
    Delete
    </span>}

</>}
</td>

</tr>


</>;
}
}