import React from "react";
import { DirectoryContextModel } from "../hooks/useDirectoryApp";
import { DirectoryContext } from "./DirectoryContext";

export class DirectoryComponent
<P = {}, S = {}, SS = any> extends React.Component<P, S, SS>
{

    context:DirectoryContextModel;


    static contextType=DirectoryContext;

    getQueryParam(name:string)
    {
        return this.context.searchParams.get(name);
    }

    get http()
    {
        return this.context.http;
    }

    goBack = () =>
    {
        return this.context.history.goBack();
    }

    get history()
    {
        return this.context.history;
    }

    
    
}