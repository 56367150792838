import { ValidationProblemDetails } from "hoksource";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";

import { EditStateBase } from "../types/EditStateBase";
import { OrderType } from "../models/entities/orders/order-type";
import { OrderTypeService } from "./OrderTypeService";


export class EditCustomStatusController extends DirectoryComponent<{},
EditStateBase<OrderType> &
{
}>
{

    constructor(props)
    {
        super(props);
        this.state = {
            input:null
        };
    }



    id:string;
    
    componentDidMount()
    {
        this.id = this.getQueryParam("id");

        
        if(this.id)
        {
                
            var service = new OrderTypeService(this.http);

            service.findById(this.id).then(e => {

                this.setState({
                    input:e
                });
            });

        }
    }

    
    addCustomStatus = ()=>{
        var newVal = this.state.input.customStatuses?.slice() || [];


        var max = -1;
        for(let i of newVal)
        {
            if(max < i.sortOrder)
            {
                max = i.sortOrder;
            }
        }



        newVal.push({
            
            sortOrder:max+1
        });

        this.setState({input:{

            ...this.state.input,
            customStatuses:newVal
            
        }});
    };

    
    removeCustomStatus = (idx:number)=>{
        
        var newVal = this.state.input.customStatuses.slice();
        newVal.splice(idx,1);
        


        this.setState({input:{

            ...this.state.input,
            customStatuses:newVal
        }});

    };



    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>
    {

        
        evt.preventDefault();

        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
                
            var service = new OrderTypeService(this.http);

            await service.save(this.state.input);

            this.history.goBack();

        }catch(err)
        {


            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }


        this.setState({isSubmitting:false});
        
        

    }


}
