export enum ItemTypes
{
    Inventory = "Inventory",
    NonInventory = "Non-Inventory",
    Service = "Service"
}

export const ItemTypesList =[

    ItemTypes.Inventory,
    ItemTypes.NonInventory,
    // ItemTypes.Service // support later
];