import { useContext, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { DirectoryContext } from "../../../directory-shared/DirectoryContext";
import { useEditOrder } from "../useEditOrder";
import pdfLogo from '../../../img/bi-file-earmark-pdf.svg';
import { AttachmentComponent } from "./AttachmentComponent";

export function AttachmentsInput(props:{

    editOrder:ReturnType<typeof useEditOrder>
})
{

    var context = useContext(DirectoryContext);
    var [showModal, setShowModal] = useState<boolean>();

    var [selectedImageUrl,setSelectedImageUrl] = useState<{
        contentType?:string,
        file?:File,
        dataUrl:string}>();


    var attachments = props.editOrder.order.attachments;
    var attachmentsToAdd = props.editOrder.attachmentsHelper.attachmentsToAdd;
    
    const toggle = ()=>{

        setShowModal(!showModal);
    };



    const getAttachmentUrl=(id:string)=>
    {
        return `${context.http.apiBasePath}/Orders/GetAttachmentBlob?orderAttachmentId=${id}&access_token=${context.accessToken}`;

    }


    return <>
    <hr/>

    <Modal isOpen={showModal} toggle={toggle}
    //  className={className}
     size="xl"
     fade={false}
     >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
            {selectedImageUrl?.contentType==="application/pdf"?
            
            <object 
            type="application/pdf"
            data={selectedImageUrl.dataUrl} className="w-100"
            
            style={{height:"70vh"}}
            />
                :
                
            <img src={selectedImageUrl?.dataUrl} className="w-100"/>
            }
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    
        <h5>Attachments</h5>

<div>
        {attachments.map((attachment,idx)=>

        
<AttachmentComponent
key={idx}
imageUrl={attachment.contentType==="application/pdf"?pdfLogo:attachment.publicUrl}
fileName={attachment.fileName}
onClick={() => {
    // window.open(attachment.publicUrl);
    // return;
    setSelectedImageUrl({
        contentType:attachment.contentType,

        dataUrl:attachment.publicUrl
    }
        
        //getAttachmentUrl(attachment.id)
        );
    setShowModal(true);
}}
onRemove={()=>{

    
    var idx = props.editOrder.order.attachments.indexOf(attachment);
    props.editOrder.setOrder(o => ({

        ...o,
        attachments:[...o.attachments.slice(0,idx),
            ...o.attachments.slice(idx+1)]
    }));
    
}}
/>



            )}


        {props.editOrder.attachmentsHelper.attachmentsToAdd.map((img,idx)=>
        
        <AttachmentComponent
            key={idx}
            imageUrl={img.file? pdfLogo : img.imageUrl}
            fileName={img.fileName}
            onClick={() => {
                
                    setSelectedImageUrl({
                        contentType:img.file.type,
                        file:img.file,
                        dataUrl:img.imageUrl});
                    setShowModal(true);
                }}

                onRemove={()=>{

                    props.editOrder.attachmentsHelper.removeAttachmentToAdd(img);
                }}
            />
            
        )}
        </div>



        <div className="form-group">

            <label>Add Attachments:</label>
            <input type="file" 
            className="form-control"
            onChange={props.editOrder.attachmentsHelper.handleFileEvent}
            multiple
            // accept="image/png, image/jpeg"
            accept="image/jpeg,.pdf"
            />
            {props.editOrder.attachmentsHelper.error &&
            <div className="text-danger">{props.editOrder.attachmentsHelper.error}</div>
            }
        </div>

    </>;

}