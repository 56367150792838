import React, { Component } from 'react';
import { Collapse, Container, DropdownItem, DropdownMenu, 
  DropdownToggle, Navbar, NavbarBrand, NavbarToggler,
  // eslint-disable-next-line
   NavItem,
   NavLink,
   UncontrolledDropdown } from 'reactstrap';
import { DirectoryLink, logout, ModelTypeTypes } from '..';
import { ErichRoles } from '../directory-shared/roles';
import { serviceBasePath } from '../ErichConfig';
import { DirectoryContextModel } from '../hooks/useDirectoryApp';
// import '../../../src/css/NavMenu.css';
// import { Link } from 'react-router-dom';


export class NavMenu extends Component<
{
  directoryContext:DirectoryContextModel,
  } > {
  static displayName = NavMenu.name;

  state:{collapsed:boolean};

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  checkUserRoles(roles:string)
  {
    return this.props.directoryContext.directoryService.checkUserRoles(roles);
  }

  render () {


    var oCtxData = this.props.directoryContext.data;
    var directoryTag = this.props.directoryContext.directoryTag;

    
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container size="lg">
            <NavbarBrand tag={DirectoryLink} to="/">Erich</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
       

                

                
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="text-dark">
                    Orders
                  </DropdownToggle>
                  <DropdownMenu>

                    
                    
                {oCtxData.orderTypes
                .filter(otype => this.checkUserRoles(
                  ErichRoles.Administrator+ "," +
                    otype.customModel.name + "," + otype.customModel.name +"_Read"))
                .map(e => 
                
                <DirectoryLink key={e.customModel.name} to={`/${e.customModel.name}`} className="text-dark nav-link">
                <DropdownItem>{e.customModel.collectionDisplayName}</DropdownItem>
              </DirectoryLink>
                    )}
                    
                  </DropdownMenu>
                </UncontrolledDropdown>
                

                
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="text-dark">
                    Inventory
                  </DropdownToggle>
                  <DropdownMenu>
                    <DirectoryLink to="/inventory-counts" className="text-dark nav-link">
                      <DropdownItem>Inventory Counts</DropdownItem>
                    </DirectoryLink>
                    
                    <DirectoryLink to="/reservation-entries" className="text-dark nav-link">
                      <DropdownItem>Reservation Entries</DropdownItem>
                    </DirectoryLink>
                    
                    {/* directory roles admin only */}
                    {this.checkUserRoles(ErichRoles.Administrator)
                     && 
                    <DirectoryLink to="/item-ledger-entries" className="text-dark nav-link">
                      <DropdownItem>Item Ledger Entries</DropdownItem>
                    </DirectoryLink>
                    }
                    
                  </DropdownMenu>
                </UncontrolledDropdown>


                
                    {/* directory roles admin only */}
                    
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="text-dark">
                    Journals
                  </DropdownToggle>
                  <DropdownMenu>


                        
                      {oCtxData.journalTypes.map(e => 
                      <React.Fragment key={e.customModel.name}>


                    {this.checkUserRoles(ErichRoles.Administrator + "," + 
                    e.customModel.name)
                     && 
                  <DirectoryLink to={`/${e.customModel.name}`} className="text-dark nav-link">
                    <DropdownItem>{e.customModel.displayName}</DropdownItem>
                  </DirectoryLink>
                    }

                  {this.checkUserRoles(ErichRoles.Administrator)
                     && 
                     <>
                <DirectoryLink to={`/${e.customModel.name}/total-amounts`} className="text-dark nav-link">
                    <DropdownItem>{e.customModel.displayName} Totals</DropdownItem>
                  </DirectoryLink>

                  
                <DirectoryLink to={`/${e.customModel.name}/activity-logs`} className="text-dark nav-link">
                    <DropdownItem>{e.customModel.displayName} Activity Logs</DropdownItem>
                  </DirectoryLink>
                  
                  </>
                  }

                      </React.Fragment>
                    
                        )}

                  </DropdownMenu>
                </UncontrolledDropdown>

                

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="text-dark">
                    Database
                  </DropdownToggle>



                  <DropdownMenu>
                    
                {this.props.directoryContext.data.entityTypes
                
                
                .filter(otype => this.checkUserRoles(
                  ErichRoles.Administrator+ "," +
                    otype.customModel.name))
                
                .map(e => 
                
                <DirectoryLink key={e.customModel.name} to={`/${e.customModel.name}`} className="text-dark nav-link">
                <DropdownItem>{e.customModel.collectionDisplayName}</DropdownItem>
              </DirectoryLink>
                    )}

                    {this.checkUserRoles(ErichRoles.Administrator + ",Item")
                    
                    && 
                    
                    
                    <DirectoryLink to="/items" className="text-dark nav-link">
                      <DropdownItem>Items</DropdownItem>
                    </DirectoryLink>
                    
                    }
                    

                    {/* directory roles admin only */}
                    {this.checkUserRoles(ErichRoles.Administrator)
                     && 
                     <>
                      
                     
                      <DirectoryLink to={`/SalesAgents`} className="text-dark nav-link">
                        <DropdownItem>Sales Agents</DropdownItem>
                      </DirectoryLink>

                      <DirectoryLink to={`/PaymentTermsConfigurations`} className="text-dark nav-link">
                        <DropdownItem>PaymentTermsConfigurations</DropdownItem>
                      </DirectoryLink>
                    

                      <DirectoryLink to={`/ItemCategories`} className="text-dark nav-link">
                        <DropdownItem>Item Categories</DropdownItem>
                      </DirectoryLink>
                      
                      

                      
                     </>

                    } 
                    

{/*                     
                    
                    <DirectoryLink to="/payment-terms" className="text-dark nav-link">
                      <DropdownItem>Payment Terms</DropdownItem>
                    </DirectoryLink> */}

                    
                    
                  </DropdownMenu>
                </UncontrolledDropdown>



                
                    {/* erich global roles admin only */}
                {oCtxData.erichGlobalUserRoles.indexOf(ErichRoles.Administrator)>=0
                     && 
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="text-dark">
                    Dev Config
                  </DropdownToggle>



                  <DropdownMenu>

                  <DirectoryLink to={`/edit-directory-configuration`} className="text-dark nav-link">
                        <DropdownItem>Directory Configuration</DropdownItem>
                      </DirectoryLink>

                  <a className="text-dark nav-link"
                      target="_blank"
                      rel="noreferrer"
                      href={`${serviceBasePath}/d/${this.props.directoryContext.directoryTag}/DirectoryUsers`}
                      // href={this.props.directoryContext.directoryBasePath + "/DirectoryUsers"}
                      >
                        <DropdownItem>Users</DropdownItem>

                      </a>
                    
                    {
                      ModelTypeTypes.map((e,idx)=>
                      <DirectoryLink key={e.modelType.customModel.name} to={`/${e.modelType.customModel.name}`} className="text-dark nav-link">
                        <DropdownItem>{e.modelType.customModel.collectionDisplayName}</DropdownItem>
                      </DirectoryLink>
                      )
                    }

                    
                    <a className="text-dark nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href={`${serviceBasePath}/CdAdmin/d/${directoryTag}/CdModels`}
                    >
                      <DropdownItem>Custom Models</DropdownItem>

                    </a>


                    
                  </DropdownMenu>
                </UncontrolledDropdown>
                }



                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="text-dark">
                    Account
                  </DropdownToggle>



                  <DropdownMenu>

                      
                    <NavLink 
                    className="text-dark cursor-pointer"
                    onClick={()=>logout(oCtxData.directory.tag)}
                    // tag={Link} className="text-dark" to="/"
                    
                    >Logout</NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <NavItem>
                  <NavLink 
                  className="text-dark cursor-pointer"
                  onClick={logout}
                  // tag={Link} className="text-dark" to="/"
                  
                  >Logout</NavLink>
                </NavItem> */}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
