import { StoreIndexNames, StoreNames } from "./StoreNames";
import { GetEntityListQueryModel } from "../entities/GetEntityListQueryModel";
import { Entity } from "../models/entities/entities/entity";
import { StringUpperBound } from "../ErichConstants";
import { DbService } from "./DBService";

export class EntityStore
{

    constructor(private dbService:DbService)
    {

    }



    async getList(filter:GetEntityListQueryModel)
    {

        
        var db = await this.dbService.open();

        var objectStore = db.transaction(StoreNames.Entities)
          .objectStore(StoreNames.Entities);


        var index:IDBIndex = objectStore.index(StoreIndexNames.EntitiesMainIndex);
          
        let searchPredicate = (entity:Entity) =>
        {
          return (entity.tag?.toLowerCase().startsWith(filter.searchString.toLowerCase()) || 
            entity.name.toLowerCase().indexOf(filter.searchString.toLowerCase()) >= 0) &&
            entity.id !== filter.openLowerBoundEntityId
            
            ;

        };
        
        if(filter.openLowerBoundEntityId)
        {

          var lastItem:Entity = await objectStore
            .hokGetAsync(filter.openLowerBoundEntityId);

          if(lastItem)
          {
            var lowerBoundKey = [
                lastItem.typeName,
                lastItem.tag,
                lastItem.name];
            // IDBKeyRange.bound()
            var upperBoundKey = [lastItem.typeName, StringUpperBound, StringUpperBound];
            
            var bound = IDBKeyRange.bound(lowerBoundKey, upperBoundKey, true, false);
            
             return await index.hokOpenCursorToListAsync<Entity>({
               query:bound,
               count:filter.take,
               where:searchPredicate
              });
          }
        }

        
        return await index.hokOpenCursorToListAsync<Entity>({
          query:IDBKeyRange.bound([filter.type, "", ""], 
          [filter.type, StringUpperBound,StringUpperBound
          ], false, false),
          count:filter.take,
          where:searchPredicate
        });
    }



    async get(id:string|number)
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.Entities, "readonly");
      var entityStore = txn.objectStore(StoreNames.Entities);

      return await entityStore.hokGetAsync(id) as Entity;

    }



    async put(entity:Entity)
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.Entities, "readwrite");
      var entityStore = txn.objectStore(StoreNames.Entities);

      return new Promise<Entity>((resolve, reject)=>{
        
        var req = entityStore.put(entity);

        req.onsuccess = function(evt){
          
          if(!entity.id)
          {
            resolve({

              ...entity,
              id:req.result as any
            });
            
          }else
          {
            resolve(entity);
          }
        };

        req.onerror = function(evt)
        {
          reject(evt);

        };
      });
    }
    
}