import { ValidationProblemDetails } from "hoksource";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { Order } from "../models/entities/orders/order";
import { convertOrderForSaving } from "../orders/convertOrderForSaving";
import { ModelSyncServiceBase } from "./ModelSyncServiceBase";
import { objectStoreDeleteAsync } from "./ObjectStoreExtensions";
import { StoreIndexNames, StoreNames } from "./StoreNames";
import { SyncService } from "./SyncService";

export class OrderSyncService extends ModelSyncServiceBase<Order>
{

    constructor(
        private http:DirectoryHttpService,
        private db:IDBDatabase,
        syncService:SyncService)
    {
        super(syncService);
    }

    
    async getEntriesFromStore()
    {
            
        var orderStore = this.db.transaction(StoreNames.Orders)
        .objectStore(StoreNames.Orders);
        var index:IDBIndex = orderStore.index(StoreIndexNames
            .OrdersHasOfflineChangesIndex);
        var orders = await index.hokGetAllAsync<Order>({count:100});

        return orders;
    }

    
    async uploadEntries(entries: Order[])
    //: Promise<{ savedEntries: Entity[]; validationProblemDetails?: ValidationProblemDetails; }>
    {
        
        var toSave = entries.map(e => {

            if(typeof e.id === "string")
            {
              return e;
            }else
            {
              var {id, ...copy} = e;
              return copy as Order;
            }
  
          });
        var res = await this.http.post<{
            savedOrders:Order[];
            validationProblemDetails:ValidationProblemDetails;
        }>("/Orders/SaveOrders", {
            body:toSave.map(e => convertOrderForSaving(e)),
        });


        
        var txn = this.db.transaction([
            StoreNames.Orders
          ], "readwrite");
          var orderStore = txn.objectStore(StoreNames.Orders);
  
            
          
        // delete from local
        await Promise.all(entries.map(prev => 
          objectStoreDeleteAsync(orderStore, prev.id)
          ));


        return {
            savedEntries:res.savedOrders,
            validationProblemDetails:res.validationProblemDetails
        };

    }


    
}