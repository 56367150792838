import { DateTimeOffsetInput, FormGroup, FormInput, ValidationErrors, ValidationSummary } from "hok-react";
import { UserBasicInfo, ValidationProblemDetails } from "hoksource";
import React from "react";
import { IHistory } from "react-router-dom";
import { getAppInitData } from "../auth/getAppInitData";
import { serviceBasePath } from "../ErichConfig";
import { httpService } from "../http/HttpService";
import { ErrorsType } from "../models/errors-type";

export class EditUserBasicInfo extends React.Component<{
    history:IHistory
},{

    input:UserBasicInfo,
    errors?:ErrorsType,
    isSubmitting?:boolean
}>
{


    constructor(props)
    {
        super(props);

        this.state = {
            input:{}
        };
    }


    componentDidMount()
    {

        getAppInitData().then(res=>{

            if(res.userBasicInfo)
            {
                this.setState({input:res.userBasicInfo});
            }
        });
    }

    

    
    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>
    {

        
        evt.preventDefault();

        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
            
            await httpService.post<UserBasicInfo>(
                `${serviceBasePath}/api/User/SaveUserBasicInfo`, {

                    body:this.state.input
                });

            this.props.history.push("/");

        }catch(err)
        {


            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }


        this.setState({isSubmitting:false});
    }



    render()
    {
        return <>
        <br/>
        <div className="container">

        
<h4>User Basic Info</h4>
<div className="row">
    <div className="col-md-6">
        <form id="profile-form" method="post"
        onSubmit={this.handleSubmit}
        >
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input}
                onChange={e => this.setState({input:e.target.value})}>

                    {g => <>
                    
                        <FormInput group={g} 
                        
                        groupKey="firstName"
                        labelText="First Name"
                        name="FirstName"
                        errors={this.state.errors}
                        required
                        />
                        
                        <FormInput group={g} 
                        groupKey="middleName"
                        labelText="Middle Name"
                        name="MiddleName"
                        errors={this.state.errors}
                        />

                        <FormInput group={g} 
                        groupKey="lastName"
                        labelText="Last Name"
                        
                        name="LastName"
                        errors={this.state.errors}
                        />

                        <FormInput group={g} 
                        groupKey="sexLetter"
                        labelText="Sex"
                        
                        name="SexLetter"
                        errors={this.state.errors}
                        >

                            <option value="m">Male</option>
                            <option value="f">Female</option>
                        </FormInput>


                        <div className="form-group">
                            <label>Birthdate</label>
                            <DateTimeOffsetInput type="date"
                                group={g}
                                groupKey="birthdate"
                                className="form-control"
                            />
                            <ValidationErrors errors={this.state.errors} name="Birthdate"/>

                        </div>
                        

                    </>}
                </FormGroup>
            



            <button id="update-profile-button" type="submit" className="btn btn-primary">Save</button>
        </form>
    </div>
</div>


<br/><br/>
</div>
        </>;

    }



}