import { DirectoryContextModel } from "../hooks/useDirectoryApp";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { EntityItem } from "../models/entities/entities/entity-item";
import { Item } from "../models/entities/items/item";
import { EntityGroup } from "./entity-group";


export interface GetOnOrderItemChangeDataModal
{
    item:Item;
    entityItems:EntityItem[];
    // entityGroupEntityItems:EntityItem[];
    itemIdEntityGroupEntityItems:{[key:string]:EntityItem[]};

}

export class EntityGroupService
{

    http:DirectoryHttpService;
    constructor(private context:DirectoryContextModel)
    {
        this.http=context.http;

    }
        
    getList(filter:{

        skip:number,
        take:number,
        searchString:string,
        includeInactive?:boolean
    })
    {

        return this.http.post<EntityGroup[]>("/EntityGroups/GetList", {
            body:filter
        });
    }



    // obsolete this
    getItemGroupEntityItemsWithDefaultBom(entityId:string, itemId:string)
    {
        return this.http.post<{
            entityItem:EntityItem,
            entityGroupEntityItems:EntityItem[],
        }>("/EntityGroups/GetItemGroupEntityItemsWithDefaultBom", {
            hokQuery:{
                entityId,
                itemId
            }
        });
    }

    
    
    getOnOrderItemChangeData(entityId:string, itemId:string)
    {
        return this.http.post<GetOnOrderItemChangeDataModal>(
            "/EntityGroups/GetOnOrderItemChangeData", {
            hokQuery:{
                entityId,
                itemId
            },
/*
            whenOffline:async ()=>{

                var itemStore = new ItemStore(this.context.dbService);
                var item = await itemStore.find(itemId);



                return {item:item,
                    entityItems:[{
                        item:item,
                        entity:null,
                        availableQuantity:0,
                        reservedQuantity:0,
                        totalQuantity:0,
                        itemId:item.id,
                        entityId:entityId
                    }],
                    itemIdEntityGroupEntityItems:{}
                };
            }*/
        });
    }

}