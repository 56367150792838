import {  InfiniteScroll } from "hok-react";
import React from "react";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { EntitySelect } from "../entities/EntitySelect";
import { toLocaleDateString } from "../functions/DataUtils";
import { ItemSelect } from "../items/ItemSelect";
import { EntityTypeNameIds } from "../models/enums/EntityTypeNameIds";
import { AllOrderLinesHomeController } from "./AllOrderLinesHomeController";

export class AllOrderLinesHome extends AllOrderLinesHomeController
{

    render()
    {

        var orderType = this.props.orderType;
        
    return <>

    {/* <h1>{orderType.customModel.name} All Order Lines</h1> */}
    <br/>
    <div className="row">

        <div className="col-md-3 form-group">
            <label>Location</label>
            <EntitySelect 
            entityTypeNameId={EntityTypeNameIds.Location}
            value={this.state.fromEntity} onChange={e => this.setState({
                fromEntity:e.target.value
            })}
            
            className="form-control"
            />
        </div>
        {this.props.orderType.toEntityTypeName&&
            <div className="col-md-3 form-group">

                        
            <label>To {this.props.orderType.toEntityTypeName}</label>
            <EntitySelect entityTypeNameId={
                this.props.orderType.toEntityTypeName
                }
                value={this.state.toEntity}
                onChange={e => this.setState({
                    toEntity:e.target.value
                })}
                
                className="form-control"
            />
            </div>

        }
        
        
        <div className="col-md-3 form-group">
            <label>Item</label>
            <ItemSelect
                value={this.state.item}
                onChange={e=>this.setState({
                    item:e.target.value
                })}
                className="form-control"
            />
        </div>
        
    </div>

<table className="table">
    <thead>
        <tr>
                
            <th>
                Date
            </th>
            <th>
                Location
            </th>
            {
                this.props.orderType.toEntityTypeName && 
                <th>
                    To {this.props.orderType.toEntityTypeName}
                </th>
            }
            <th>
                Item
            </th>
            <th>
                Qty
            </th>
            <th>
                Unit Price
            </th>
            <th>Amount</th>
            <th>Is Posted</th>
            <th></th>
        </tr>
    </thead>
    <tbody>

    <InfiniteScroll
    targetElement={window}
    maxTakeCount={this.takeCount}
    getItems={this.getItems}
    resetDependencyList={[
        this.state.fromEntity,
        this.state.toEntity,
        this.state.item
    ]}
    resetDebounceDelay={400}
    >{infiniteScroll => 
    
    infiniteScroll.loadedItems?.map(e => 
        (

            
            <tr key={e.orderLine.id}>
                <td>{toLocaleDateString(e.order.inputTime)}</td>
        <td>
            {e.order.fromEntity.displayName}
        </td>
        
        {
            this.props.orderType.toEntityTypeName && 
            <td>
                {e.order.toEntity?.displayName}
            </td>
        }
        <td>
            {e.orderLine.item.displayName}
        </td>
        
        <td>
            {e.orderLine.quantity * e.orderLine.parentOrderLineQuantity}

{/*             
            {e.orderLine.quantity} 
            {e.orderLine.parentOrderLineId && 
                <span>*{e.orderLine.parentOrderLineQuantity}=&nbsp; 
                {e.orderLine.quantity * e.orderLine.parentOrderLineQuantity}
                </span>
            } */}

        </td>
        <td>{e.orderLine.unitPrice}</td>
        <td>{
        e.orderLine.quantity * e.orderLine.parentOrderLineQuantity *
        e.orderLine.unitPrice}</td>
        <td>{e.order.postingTime ? "Y":""}</td>
        <td><DirectoryLink to={`/${orderType.customModel.name}/Details?id=${e.order.id}`}>
            View Order
            </DirectoryLink></td>
    </tr>
        )
    )
    
    }


    </InfiniteScroll>

    </tbody>
</table>
</>;


    }
    
}