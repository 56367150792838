import { ValidationProblemDetails } from "hoksource";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EditStateBase } from "../types/EditStateBase";
import { SalesAgent } from "../models/entities/entities/sales-agent";
import { SalesAgentService } from "./SalesAgentService";


export class EditController extends DirectoryComponent<{},
EditStateBase<SalesAgent> &
{
}>
{

    constructor(props)
    {
        super(props);
        this.state = {
            input:{
                active:true,
            }
        };
    }



    id:string;
    
    componentDidMount()
    {
        this.id = this.getQueryParam("id");

        
        if(this.id)
        {
                
            var service = new SalesAgentService(this.http);

            service.find(this.id).then(e => {

                this.setState({
                    input:e
                });
            });

        }
    }

    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>
    {

        
        evt.preventDefault();

        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
                
            var service = new SalesAgentService(this.http);

            await service.save(this.state.input);

            this.history.goBack();

        }catch(err:any)
        {


            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }


        this.setState({isSubmitting:false});
        
        

    }


}
