import React from "react";
import { ModelTypesBaseProps } from "../model-types/Root";
import { OrderType } from "../models/entities/orders/order-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { OrderTypeService } from "./OrderTypeService";

export class ViewTemplates
extends DirectoryComponent
<ModelTypesBaseProps<OrderType>, {
    orderType:OrderType

}>
{

    id:string;
    componentDidMount()
    {
        this.id = this.getQueryParam("id");

        var service = new OrderTypeService(this.http);
        service.findById(this.id).then(e => {

            this.setState({orderType: e});
        });
    }


    render()
    {


        var modelTypeType = this.props.modelType;

        var orderType= this.state?.orderType;
        if(!orderType)
        {
            return <div>Loading...</div>;
        }
        return <>
         
<h1>Details</h1>

<div>
    <h4>Order Type</h4>
    <hr />
    <dl className="row">
        <dt className="col-sm-2">
            nameId
        </dt>
        <dd className="col-sm-10">
            {orderType.customModel.name}
        </dd>
        
    </dl>
</div>
<div>
    {/* {!journalEntry.orderId &&
    <>

    
<span className="btn btn-link cursor-pointer" onClick={this.handleDeleteClick}>Delete</span>

&nbsp;|&nbsp;
    </>} */}

    <span className="btn btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
</div>

<br/><br/>
    
<div>
    <h5>View Templates</h5>
    <p>
            <DirectoryLink to=
            {`/${modelTypeType.customModel.name}/edit-view-template?orderTypeId=${orderType.customModelId}`}>
                Create New View Template</DirectoryLink>
        </p>

    <table className="table">
        <thead>
            <tr>
                {/* <th>journal entry id</th> */}
                <th>name</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {orderType.viewTemplates?.map((e,idx) => 
            
             <tr key={e.id}>
                {/* <td>{other.id}</td> */}

                <td>
                    {e.name}
                </td>
                <td>


                <DirectoryLink to={
            `/${modelTypeType.customModel.name}/edit-view-template?orderTypeId=${orderType.customModelId}&id=${encodeURIComponent(e.id)}`}>
                
                Edit
                </DirectoryLink>
                </td>

            </tr>
            )}
        </tbody>
    </table>
</div>

        
        </>;

    }
}