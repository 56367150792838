import React from "react";
import { IRouteProps } from "react-router-dom";
import { DirectoryContextModel, useDirectoryApp } from "../hooks/useDirectoryApp";
import { DirectoryContext } from "./DirectoryContext";



export interface  DirectoryAppProps extends  
IRouteProps<{directoryTag:string}> {

    children:((ctx:DirectoryContextModel)=>React.ReactNode) | React.ReactNode ;
}

export function DirectoryApp(props:DirectoryAppProps) 
{

    var ctx = useDirectoryApp(props);

    if(!ctx.data)
    {

        
        return <div>Loading...</div>;
    }

    
    return (

        <DirectoryContext.Provider value={ctx}>
            {typeof props.children==="function" ? 
                (props.children as any)(ctx) 
                : props.children}
        </DirectoryContext.Provider>


    );


}