
import React from "react";
import { JournalEntriesInputController } from "./JournalEntriesInputController";
import { JournalEntryInput } from "./JournalEntryInput";


export class JournalEntriesInput extends JournalEntriesInputController
{


    render()
    {
        var journalType = this.props.journalType;
        var value = this.props.value;
        return <table className="journal-entries-input">
            <thead><tr>
                
                <th>Loc</th>
                <th>{journalType.toEntityTypeName}</th>
                <th>Type</th>
                <th>Amt</th>
                <th>Currency</th>
                <th>Payment Mode</th>
                <th>Time</th>
                <th>DueTime</th>
                <th>Ref Id</th>
                <th>External Doc Id</th>
                
                <th>Note</th>
                </tr>
            </thead>
                

            <tbody>
                {value?.map((entry,idx) => 

                    <JournalEntryInput key={idx} 
                    
                    // value={entry} 
                    // onChange={e => entryArrayGroup.setValueProperty(idx, e.target.value)}
                    value={entry}
                    onChange={evt => this.props.onEntryChange(evt, idx)}
                    onRemoveClick={evt => this.props.onRemoveEntryClick(evt, idx)}
                    entryTypeInputOptionsMap={this.entryTypeInputOptionsMap}
                    journalType={journalType}
                    autoFocusAmount={idx === 0}
                    
                    />

                )}

            </tbody>


        </table>;
    }
}