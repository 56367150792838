export function isNonNegative(n:number|string)
{
    

    if(typeof n === 'string')
    {
        n = parseFloat(n);

    }

    return n>=0;
}


export function isNegative(n:number|string)
{

    return n<0;
}