import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ModelTypeHome } from "../model-types/Home";
import { ModelTypeTypeNameIds } from "../model-types/ModelTypeTypeNameIds";
import { JournalType } from "../models/entities/journals/journal-type";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";



export class Home extends ModelTypeHome<JournalType>
{




    render()
    {

        var modelTypeType = this.props.modelType;

        return <>
            
        <h1>{modelTypeType.customModel.collectionDisplayName}</h1>
        <p>
            <DirectoryLink to={`/${modelTypeType.customModel.name}/edit`}>Create New</DirectoryLink>
        </p>



        
<table className="table">
<thead>
    <tr>
        <th>
            NameId
        </th>
        <th>
            Display Name
        </th>
        
        <th>
            Collection Display Name
        </th>
        <th></th>
    </tr>
</thead>
<tbody>
    {this.state?.list?.map((type,idx) =>
    <tr key={type.customModel.name}>

        <td>{type.customModel.name}</td>
        <td>{type.customModel.displayName}</td>
        <td>{type.customModel.collectionDisplayName}</td>
        <td>

            

        <UncontrolledDropdown tag="span">
        <DropdownToggle tag="span" caret className="cursor-pointer btn-link">
        Options
        </DropdownToggle>
        <DropdownMenu>

        <DirectoryLink to={
            `/${modelTypeType.customModel.name}/edit?id=${encodeURIComponent(type.customModelId)}`}>
                
        <DropdownItem>
                Edit
                </DropdownItem>
                </DirectoryLink>
            {modelTypeType.customModel.name === ModelTypeTypeNameIds.JournalType &&
        
                <>
                
        <DirectoryLink to={
            `/${modelTypeType.customModel.name}/edit-details?id=${encodeURIComponent(type.customModelId)}`}>
        <DropdownItem>
                Edit Details
                </DropdownItem>
                </DirectoryLink>
                
        <DropdownItem>
            
                </DropdownItem>

                
                </>
            }
            </DropdownMenu>
            </UncontrolledDropdown>
        </td>
    </tr>)}
</tbody>
</table>

        </>;




    }
}