import { ISyntheticEvent } from "hok-react";
import * as React from "react";
import { DirectoryComponent, ToastContextModel } from "../../..";
import { getNextSortOrder } from "../../../functions/getNextSortOrder";
import { Item } from "../../../models/entities/items/item";
import { ItemDefaultChildItem } from "../../../models/entities/items/item-default-child-item";
import { ItemService } from "../../ItemService";

interface ChildItemsEditState
{

    item?:Item;
    childItems?:ItemDefaultChildItem[];
    errors?:{[key:string]:string[]};
    isSubmitting?:boolean;

}



export class ChildItemsEditController extends DirectoryComponent<{
    itemId:string,
    toastContext:ToastContextModel
},ChildItemsEditState>{

    itemId:string;

      
      
    constructor(props)
    {
        super(props);
        this.state={};
    }



    initInput(item:Item, state:ChildItemsEditState = {})
    {
        
        state.item = item;
        state.childItems = item.defaultChildItems.concat([{

            quantity:1,
            sortOrder: getNextSortOrder(item.defaultChildItems)
            
        }]);

        return state;

    }

    

    componentDidMount() {
        
        this.itemId = this.props.itemId;//this.getQueryParam("id");


        
        var service = new ItemService(this.context);


        service.getItemWithDefaultChildItems(this.itemId).then(e => {

            var state:ChildItemsEditState = {
                item:e,

            };

            this.initInput(e,state);

            this.setState(state);
        });
    }

    
  
    componentWillUnmount() {
    }
  

    get childItems()
    {

        return this.state.childItems;
    }

    setChilditems(childItems:ItemDefaultChildItem[])
    {

        this.setState({childItems:childItems});
    }

    handleComponentItemChange(e:ISyntheticEvent<{value:Item}>, index:number)
    {
        var val = e.target.value;
        
        var components = this.state.childItems;
        if(val)
        {

            if(this.itemId === val.id)
            {
                alert("Invalid item component");
                return false;
            }

            for(let i = 0;i  < components.length; ++i)
            {
                if(index !== i)
                {
                    var cVal = components[i];

                    if(cVal.childItem && cVal.childItem.id === val.id)
                    {
                        alert("Item entry already exists.");
                        //return false;
                    }
                }
            }
        }

        var newComponents:ItemDefaultChildItem[] = [
            ...components.slice(0, index),
            {

                ...components[index],
                childItem:val
            },
            ...components.slice(index+1)
        ];

        if(index === components.length-1)
        {
            newComponents.push({
                quantity:1,
                sortOrder: getNextSortOrder(newComponents)
            });
        }

        console.log(index, newComponents, components);
        this.setChilditems(newComponents);
        return true;
    }


    removeItem(val:ItemDefaultChildItem)
    {

        var components = this.childItems;
        var index = components.indexOf(val);
        
        var newComponents:ItemDefaultChildItem[] = [
            ...components.slice(0, index),
            ...components.slice(index+1)
        ];

        this.setChilditems(newComponents);
    }

        
    get isAllowedSaving()
    {
        return !this.state.isSubmitting;
    }

    isValidComponentQuantity(val:ItemDefaultChildItem)
    {

        var quantity = parseFloat(val.quantity as any);

        
        return !val.childItem || quantity > 0;
    }

    
    isValidComponentUnitPrice(val:ItemDefaultChildItem)
    {

        var quantity = parseFloat(val.unitPrice as any);

        
        return !val.childItem || !quantity || quantity > 0;
    }

    validate()
    {

        var cmp = this.childItems;

        for(let i of cmp)
        {
            if(!this.isValidComponentQuantity(i))
            {
                return false;
            }

            if(!this.isValidComponentUnitPrice(i))
            {
                return false;
            }
            
        }

        return true;
    }


    async handleSubmit(evt:React.FormEvent<HTMLFormElement>)
    {

        evt.preventDefault();

        if(!this.validate())
        {
            return;
        }
        
        var service = new ItemService(this.context);

        this.setState({
            isSubmitting:true
        });
        
        try
        {

            var toSave = {
                ...this.state.item,
                defaultChildItems:this.state.childItems
                     .filter(s => s.childItem)
                     .map(c => ({...c, 
                        unitPrice:c.unitPrice as any === ""? null : c.unitPrice
                    }))
            };
            var item = await service.saveItemDefaultChilditems(toSave);

            this.setState(this.initInput(item));


            this.props.toastContext.setToast({
                header:"Info",
                body:"Saved",
                durationMs:2000
              });
        }
        catch(e)
        {
            console.log(e);
            alert("Something went wrong");
        }

        this.setState({
            isSubmitting:false
        });
    }


}
