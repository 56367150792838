import { InfiniteScroll } from "hok-react";
import React, { useContext, useEffect, useRef } from "react";
import { Modal,  ModalBody } from 'reactstrap';
import { OrderListFilterHeader, DirectoryContext, getTagDisplayName, toLocaleDateString, OrderService, OrderLinks } from "..";
import { Order } from "../models/entities/orders/order";
import { OrderType } from "../models/entities/orders/order-type";
import { useOrderListFilterContext } from "../orders/OrderList/useOrderListFilterContext";



const OrderSelectModal = (props:{
    className:string,
    showModal:boolean,
    setShowModal:(showModal:boolean)=>void,
    orderType:OrderType,
    value:Order,
    onSelect:(order:Order)=>void,
}) => {
    const {
      // buttonLabel,
      className,
      showModal,
      setShowModal,

      // component
    } = props;


    
    var filterCtx = useOrderListFilterContext({
        orderType:props.orderType, 
    });

    var containerRef = useRef<HTMLDivElement>();

    useEffect(()=>{


        filterCtx.resetFilter();
    },[showModal]);


    var orderType = props.orderType;

  
    const toggle = () => {
        
        setShowModal(!showModal)
    
    };
  
    return (
        <Modal isOpen={showModal} toggle={toggle} className={className}
        onClosed={()=>{
          setShowModal(false)}
        }
        // fade={false}
        size="xl"
        // onExit={()=>{

        //     console.log("Exiting Modal");
        // }}
        >
          <ModalBody>


{orderType && <>
          <OrderListFilterHeader orderListFilterContext={filterCtx}/>

<div className="" style={{height:"50vh",overflowY:"auto"}}
ref={containerRef}
>

<table className="table">
<thead>
<tr>
<th>From Location</th>
<th>To {orderType.toEntityTypeName}</th>

<th>RefId</th>
<th>Date</th>
<th>Due Date</th>
{orderType.withPrice&&
<th>Total</th>

}
<th>Custom Status</th>
<th></th>
</tr>
</thead>
<tbody>
<InfiniteScroll

targetElement={containerRef.current}
maxTakeCount={filterCtx.takeCount}
getItems={filterCtx.getItems}
resetDependencyList={[
    filterCtx.state
]}
resetDebounceDelay={400}
>
{infiniteScroll =>
    infiniteScroll.loadedItems.map(
        (e,idx) => <OrdersTableRow key={e.id} 
        orderType={orderType}
        order={e}
        currentValue={props.value}
        onSelect={evt => {

            props.onSelect(evt);
        }}
        
        />)
}
</InfiniteScroll>
</tbody>
</table>
</div>

</>}

          </ModalBody>
            {/* <props.component/> */}

        </Modal>
    );
  }



  
function OrdersTableRow(props:{
    orderType:OrderType,
    currentValue:Order,
    order:Order,
    onSelect:(order:Order)=>void,
    })
    {
    
    
    var i = props.order;
    
    return <tr>
    <td>{getTagDisplayName(i.fromEntity)}</td>
    <td>{getTagDisplayName(i.toEntity)}</td>
    <td>{i.tag}</td>
    <td>{toLocaleDateString(i.inputTime)}</td>
    <td>{toLocaleDateString(i.orderDueTime)}</td>
    
    {props.orderType.withPrice &&
    <td>{i.totalOrderAmount}</td>}  
    
    <td>
        {i.customStatus}

        {/* <select value={i.customStatus || ""}
    onChange={(evt)=>{
    
        var customStatus = evt.target.value;
        props.onChange({target:{value:{
            ...props.item,
            customStatus:customStatus
        }}});
    
        var service = new OrderService(dContext);
        service.setCustomStatus(i.id,customStatus);
    
    
    }}
    >
    <option></option>
    {props.orderType.customStatuses
    .map((e,idx) => <option key={idx} value={e.value}>{e.value}</option>)}
    </select> */}
    </td>
    <td>
        {props.currentValue?.id!== props.order.id ?
            <button onClick={()=>{props.onSelect(props.order)}}
                className="btn btn-primary"
            >Select</button>
            :
            <span>Selected</span>
            
        }
    </td>
    </tr>;
    }

  export default OrderSelectModal;