import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ModelTypeBase } from "../models/entities/model-type-base";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { ModelTypeTypeNameIds } from "./ModelTypeTypeNameIds";
import { ModelTypesBaseProps } from "./Root";



export class ModelTypeHome<T extends ModelTypeBase> extends DirectoryComponent
<ModelTypesBaseProps<T>,{

    list:T[]
}>
{



    componentDidMount()
    {

        var service = new this.props.serviceType(this.http);
        service.getList().then(list => {

            this.setState({list});
        });
    }
    

    render()
    {

        var modelTypeType = this.props.modelType;

        return <>
            
        <h1>{modelTypeType.customModel.collectionDisplayName}</h1>
        <p>
            <DirectoryLink to={`/${modelTypeType.customModel.name}/edit`}>Create New</DirectoryLink>
        </p>



        
<table className="table">
<thead>
    <tr>
        <th>
            NameId
        </th>
        <th>
            Display Name
        </th>
        
        <th>
            Collection Display Name
        </th>
        <th></th>
    </tr>
</thead>
<tbody>
    {this.state?.list?.map((type,idx) =>
    <tr key={type.customModel.name}>

        <td>{type.customModel.name}</td>
        <td>{type.customModel.displayName}</td>
        <td>{type.customModel.collectionDisplayName}</td>
        <td>

            

        <UncontrolledDropdown tag="span">
        <DropdownToggle tag="span" caret className="cursor-pointer btn-link">
            Options
        </DropdownToggle>
        <DropdownMenu>

        <DropdownItem>
        <DirectoryLink to={
            `/${modelTypeType.customModel.name}/edit?id=${encodeURIComponent(type.customModelId)}`}>Edit</DirectoryLink>
        </DropdownItem>
            {modelTypeType.customModel.name === ModelTypeTypeNameIds.JournalType &&
        
                <>
                
        <DropdownItem>
        <DirectoryLink to={
            `/${modelTypeType.customModel.name}/entry-type-input-options?id=${encodeURIComponent(type.customModelId)}`}>
                Entry Type Input Options</DirectoryLink>
                </DropdownItem>
                
        <DropdownItem>
        <DirectoryLink to={
            `/${modelTypeType.customModel.name}/entry-mode-input-options?id=${encodeURIComponent(type.customModelId)}`}>
                Entry Mode Input Options</DirectoryLink>
        
                </DropdownItem>

                
                </>
            }
            </DropdownMenu>
            </UncontrolledDropdown>
        </td>
    </tr>)}
</tbody>
</table>

        </>;




    }
}