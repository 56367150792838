import { ValidationProblemDetails } from "hoksource";
import { hokSetTimeoutAsync } from "../functions/hokSetTimeoutAsync";
import { SyncEntitiesUpdateModel, SyncService } from "./SyncService";

export abstract class ModelSyncServiceBase<T>
{
    // protected http:DirectoryHttpService;

    constructor(protected syncService:SyncService)
    {

    }

    abstract getEntriesFromStore():Promise<T[]>;
    abstract uploadEntries(entries:T[]):Promise<{
        savedEntries:T[],
        validationProblemDetails?:ValidationProblemDetails
    }>;

    // abstract entityType:string;


    public async uploadOfflineEntries()
    {
        

        var hasErrors = false;
        var errors:{[key:string]:string[]} = {};
        
            
        // first the entities
        while(true)
        {

            var toUpload = await this.getEntriesFromStore();


            if(toUpload.length){

                var res = await this.uploadEntries(toUpload);


                // implement broadcast here
                var changeEvent:SyncEntitiesUpdateModel<T> = {
                    type:"entriesupdate",
                    entityType:"Entity",
                    prevEntries:toUpload,
                    nextEntries:res.savedEntries
                };

                // broadcast and handle the update
                this.syncService.broadcastEntitiesUpdateEvent(changeEvent);
                this.syncService.handleSyncEntitiesUpdateEvent(changeEvent);







                if(res.validationProblemDetails)
                {
                    // merge the errors
                    for(let i in res.validationProblemDetails.errors)
                    {
                        if(!errors[i])
                        {
                        errors[i] = [];
                        }
                        errors[i] = errors[i].concat(res.validationProblemDetails.errors[i]);
                    }
                    hasErrors = true;
                }
        
                // delay upload every 500ms
                await hokSetTimeoutAsync(500);
            
            }else
            {
                break;
            }
        }

                

        if(hasErrors)
        {
            throw {
            errors:errors
            };
        }
    }


}