import { InfiniteScroll } from "hok-react";
import React from "react";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { EntitySelect } from "../entities/EntitySelect";
import { toLocaleDateString } from "../functions/DataUtils";
import { ItemSelect } from "../items/ItemSelect";
import { EntityTypeNameIds } from "../models/enums/EntityTypeNameIds";
import { EntityItemLedgerEntriesHomeController } from "./EntityItemLedgerEntriesHomeController";

export class EntityItemLedgerEntriesHome extends EntityItemLedgerEntriesHomeController
{

    render()
    {

        
    return <>

    <h1>Item Ledger Entries</h1>

    <div className="row">

        
        <div className="col-md-4 form-group">
            <label>Location</label>
            <EntitySelect entityTypeNameId={EntityTypeNameIds.Location}
            className="form-control"
                value={this.state.entity}
                onChange={e => this.setState({
                    entity:e.target.value
                })}
            />
        </div>
        
        <div className="col-md-4 form-group">
            <label>Item</label>
            <ItemSelect
            className="form-control"
                value={this.state.item}
                onChange={e=>this.setState({
                    item:e.target.value
                })}
            />
        </div>

        
        <div className="col-md-4 form-group">
            <label>Order Type</label>

            <select
                className="form-control"
                value={this.state.orderType}
                onChange={e => this.setState({
                    orderType:e.target.value
                })}
            >

                <option></option>
                {this.context.data.orderTypes.map(o =>
                    <option key={o.customModelId}
                    value={o.customModel.name}
                    >
                        {o.customModel.name}

                    </option>
                    )}

                
            </select>
        </div>
    </div>

<table className="table">
    <thead>
        <tr>
            <th>Posting Time</th>
            
            <th>Order Tag</th>
            <th>
                Entity
            </th>
            <th>
                Item
            </th>
            <th>
                Order Type
            </th>
            
            <th>
                Quantity
            </th>
            <th>
                Remaining Quantity
            </th>
            <th>Total Cost</th>
            <th>Average Unit Cost</th>
            <th>
                Expire Time
            </th>
        </tr>
    </thead>
    <tbody>

    <InfiniteScroll
    targetElement={window}
    maxTakeCount={this.takeCount}
    getItems={this.getItems}
    resetDependencyList={[
        this.state.entity,
        this.state.item,
        this.state.orderType
    ]}
    resetDebounceDelay={400}
    >{infiniteScroll => 
    
    infiniteScroll.loadedItems?.map(e => 
        (

            
            <tr key={e.id}>


            <td>{
            
            toLocaleDateString(e.postingTime)}</td>
            
            <td>{e.order?.tag}</td>
            <td>{e.entity.displayName}</td>
            <td>{e.item.displayName}</td>
            <td>
                {e.orderId?
                
                <DirectoryLink 
                to={`/${e.orderTypeName}/details?id=${e.orderId}`}
                >{e.orderTypeName}</DirectoryLink>
                :
                e.orderTypeName
                }
            </td>
            
            <td>
                {e.quantity}
            </td>
            <td>
                {e.remainingQuantity}
            </td>
            <td>{e.totalQuantityCostAmount}</td>
            
            <td>{e.totalQuantityCostAmount !== null ? e.totalQuantityCostAmount / e.quantity : ""}</td>

            <td>{toLocaleDateString(e.expirationTime)}</td>

    </tr>
        )
    )
    
    }


    </InfiniteScroll>

    </tbody>
</table>
</>;


    }
    
}