export function objectStorePutAsync<T>(
    objectStore:IDBObjectStore,
    entity:T){
    return new Promise((resolve, reject)=>{


        var req = objectStore.put(entity);

        req.onsuccess = e => resolve(req.result);
        req.onerror = reject;
    }) as Promise<IDBValidKey>;
};

export function objectStoreDeleteAsync(
    
    objectStore:IDBObjectStore,
    key:IDBValidKey)
{
    return new Promise((resolve, reject)=>{


        var req = objectStore.delete(key);

        req.onsuccess = resolve;
        req.onerror = reject;
    }) as Promise<any>;

}

