import { DateTimeOffsetInput, FormDateTimeOffsetInput } from "hok-react";
import { DirectoryComponent } from "../../../directory-shared/DirectoryComponent";
import { Order } from "../../../models/entities/orders/order";
import { PaymentTermsConfigurationSelect } from "../../../payement-terms-configurations/PaymentTermsConfigurationSelect";
import { SalesAgentSelect } from "../../../sales-agents/SalesAgentSelect";
import React from 'react'; 


interface SalesOrderInputProps
{
    value:Order,
    readOnly:boolean,
    
    updateOrder<K extends keyof Order>(order:Pick<Order, K>,
        cb?:()=>void
        ):void,
    discountRate:number
}

export class SalesOrderInput extends DirectoryComponent<SalesOrderInputProps, {
    addressOptions:string[]
}>
{

    constructor(props:SalesOrderInputProps)
    {
        super(props);

        this.state = {
            addressOptions:
            this.getAddressOptions(props)
        };
    }

    getAddressOptions(props:SalesOrderInputProps)
    {
        return props.value?.toEntity?.addresses?.map(e => e.fullAddress) ?? [];
    }

    componentDidUpdate(prevProps:SalesOrderInputProps)
    {
        if(prevProps.value?.toEntity?.addresses !==
            this.props.value?.toEntity?.addresses)
        {
            this.setState({

                addressOptions:
                this.getAddressOptions(this.props)
            });
        }
    }

    updateFullAddress = (address:string)=>{
        var value = this.props.value;
        this.props.updateOrder({

            deliveryAddress:{
                ...value.deliveryAddress,
                fullAddress:address
            }
        })
    };



    
    render(){

        var value = this.props.value;

        return <div className="row">

            
            {/* <div className="col-12">

                <div className="mb-3">
                    <label>Delivery Address</label>
                    <div>
                    <TextInputWithDropdown
                        inputClassName="form-control"
                        value={value.deliveryAddress?.fullAddress}
                        onChange={(evt)=>this.updateFullAddress(evt.target.value)}
                        readOnly={this.props.readOnly}
                        options={value.toEntity?.addresses?.map(e => e.fullAddress)}
                    />
                    </div>
                </div>
            </div> */}
        
    


        <div className="col-md-3">
            <div className="mb-3">
                <label className="control-label">
                    Sales Agent
                </label>
                <div>
                <SalesAgentSelect 
                    
                    className='form-control' 
                    value={value.salesAgent}
                    onChange={(evt)=>this.props.updateOrder({

                        salesAgent:evt.target.value
                    })}
                    readOnly={this.props.readOnly}

                />
                </div>
            </div>
        </div>

        
        <div className="col-md-3">
            
            <div className="mb-3">
                <label>
                    Payment Terms Configuration
                </label>
                <div>
                <PaymentTermsConfigurationSelect 
                    
                    className='form-control' 
                    value={value.paymentTermsConfiguration}
                    onChange={(evt)=>this.props.updateOrder({
                        paymentTermsConfiguration:evt.target.value
                    })}
                    readOnly={this.props.readOnly}

                ></PaymentTermsConfigurationSelect>
                </div>
            </div>
            
        </div>

        
        <div className="col-md-3">
            
            
        <div className="mb-3">
                <label>
                Payment Due Date
                </label>
                <div>
            <DateTimeOffsetInput
                type="date"
                className="form-control"
                value={value.paymentDueTime}
                onChange={(evt)=>this.props.updateOrder({
                    paymentDueTime:evt.target.value
                })}
                readOnly={this.props.readOnly}
            /></div>
            </div>
{/*                 
            <div className="form-group">
                <label className="control-label">
                    Payment Due Date
                </label>
                
            </div>
             */}
        </div>


        <div className="col-md-12 mb-3">Discount: {this.props.discountRate?(

            this.props.discountRate*100 +"%"
        ):"0%"}</div>

        
    </div>;
    }



}
