import React from "react";
import { Modal,  ModalBody } from 'reactstrap';



const DataSelectModal = (props) => {
    const {
      // buttonLabel,
      className,
      showModal,
      setShowModal,
      // component
    } = props;
  
    // const [modal, setModal] = useState(false);

    // useEffect(()=>{

    //     setModal(showModal);
    // },[showModal]);
  
    const toggle = () => {setShowModal(!showModal)};
  
    return (
        <Modal isOpen={showModal} toggle={toggle} className={className}
        onClosed={()=>{
          setShowModal(false)}
        }
        fade={false}
        backdropClassName="bg-none"

        // onExit={()=>{

        //     console.log("Exiting Modal");
        // }}
        >
          <ModalBody>
          {props.configuration?.component&&<props.configuration.component />}
          </ModalBody>
            {/* <props.component/> */}

        </Modal>
    );
  }



  export default DataSelectModal;