import { JournalType } from "../models/entities/journals/journal-type";
import { ModelTypeServiceBase } from "../model-types/ModelTypeBaseService";
import { DirectoryHttpService } from "../http/DirectoryHttpService";

export class JournalTypeService extends ModelTypeServiceBase<JournalType>
{

    constructor(http:DirectoryHttpService)
    {
        super(http);

        this.controllerName= "JournalTypes";


    }

}