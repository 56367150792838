export function getNextSortOrder(list:{sortOrder:number}[])
{

    if(list?.length)
    {
        return list[list.length-1].sortOrder+1;
    }


    return 0;

}