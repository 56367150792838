export enum StoreNames
{

    Items = "Items",
    Entities = "Entities",
    UserDirectoryContexts = "UserDirectoryContexts",
    Orders = "Orders",
    JournalEntries = "JournalEntries"
}


export enum StoreIndexNames
{
    ItemsMainIndex = "IX_ItemsMainIndex",
    EntitiesMainIndex = "IX_EntitiesMainIndex",
    EntitiesHasOfflineChangesIndex = "IX_EntitiesHasOfflineChangesIndex",


    OrdersMainIndex = "IX_OrdersMainIndex",
    OrdersFromEntityIdIndex = "IX_OrdersFromEntityIdIndex",
    OrdersToEntityIdIndex = "IX_OrdersToEntityIdIndex",
    OrdersShipmentSourceOrderIdIndex = "IX_OrdersShipmentSourceOrderIdIndex",
    OrdersHasOfflineChangesIndex = "IX_OrdersHasOfflineChangesIndex",

    
    JournalEntriesMainIndex = "IX_JournalEntriesMainIndex",
    JournalEntriesFromEntityIdIndex = "IX_JournalEntriesFromEntityIdIndex",
    JournalEntriesToEntityIdIndex = "IX_JournalEntriesToEntityIdIndex",
    JournalEntriesHasOfflineChangesIndex = "IX_JournalEntriesHasOfflineChangesIndex",
}