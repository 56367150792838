import { useContext, useMemo, useReducer, useRef, useState } from "react";
import { OrderTypeNameIds } from "../..";
import { DirectoryContext } from "../../directory-shared/DirectoryContext";
import { EntityGroupService } from "../../entity-groups/EntityGroupService";
import { CustomModel, CustomModelField } from "../../models/entities/custom-field";
import { Item } from "../../models/entities/items/item";
import { Order } from "../../models/entities/orders/order";
import { OrderType } from "../../models/entities/orders/order-type";
import { ModelTypeConstants } from "../../models/enums/DefaultModelTypeNameIds";
import { useEditOrderEntityItemsReducer } from "./useEditOrderEntityItemsReducer";
import { useOrderAttachmentsHelper } from "./useOrderAttachmentsHelper";
import { useSourceOrderHelper } from "./useSourceOrderHelper";

const initialEditOrderEntityItemsState = {

    itemMap:{},
    itemIdFromEntityGroupEntityItemsMap:{},
    itemIdFromEntityItemMap:{}
};



export function useEditOrder(
    orderType:OrderType,
    initialOrderValue:Order)
{
    
    var [state,dispatch] = useReducer(useEditOrderEntityItemsReducer, 
            initialEditOrderEntityItemsState);

    var [order, setOrder] = useState<Order>(initialOrderValue);
    
    var modifiedLineIdsDictionaryRef = useRef<{[key:string]:boolean}>({});
    var deletedLineIdsDictionaryRef = useRef<{[key:string]:boolean}>({});
    
    

    var dContext = useContext(DirectoryContext);

    var doInitFlagRef = useRef<boolean>(true);
    var lineCustomModelRef = useRef<CustomModel>();
    var lineCustomFieldsRef = useRef<CustomModelField[]>();

    var sourceOrderHelper = useSourceOrderHelper({
        orderType:orderType,
        thisOrder:order,
        setOrder:setOrder,
    });

    var attachmentsHelper = useOrderAttachmentsHelper();

    if(doInitFlagRef)
    {
        doInitFlagRef.current = false;

        var lineType = orderType.customModel.name+ModelTypeConstants.Line;        
        var lineCustomModel = dContext.data.customModels.find(e => e.name === lineType);

        lineCustomModelRef.current=lineCustomModel;
        lineCustomFieldsRef.current = lineCustomModel?.fields;  
    }



    var ret = useMemo(()=>({
        ...state,
        orderType,
        lineCustomModel:lineCustomModelRef.current,
        lineCustomFields:lineCustomFieldsRef.current,
        order,
        setOrder,
        sourceOrderHelper,
        attachmentsHelper,
        modifiedLineIdsDictionaryRef,
        deletedLineIdsDictionaryRef,


        discountRate:order?.toEntity?.customer?.discountRate,


        applyOrderValueExpressions:function(order:Order)
        {
            for(let i of orderType.customModel.fields)
            {
                if(i.valueExpression)
                {
                    // do magic here

                    let js = `
                    
                        (function(order){
                            return (${i.valueExpression})
                        })
                    `;
                    // eslint-disable-next-line
                    order[i.name.hokToLowerTheFirstChar()] = eval(js)(order);
                }
            }
        },
    

        getDiscountRate:function()
        {
            return order?.toEntity?.customer?.discountRate;
        },


        computeLineUnitPrice:function(item:Item, unitOfMeasureCode:string)
        {

            var unitPrice:number;

            // specific for PO
            if(orderType.customModel.name === OrderTypeNameIds.PurchaseOrder)
            {
                
                var unitOfMeasure =
                    item.measureUnits.find(e => e.code === unitOfMeasureCode);
                
                if(item?.unitCost !== null &&
                    item.unitCostCurrencyCode === order.currencyCode)
                {
                    unitPrice = item.unitCost * (unitOfMeasure?.baseUnitOfMeasureQuantity ?? 1);
                }

            }else if(orderType.automaticLineUnitPrice && 
                item?.unitPrice !== undefined &&
                item?.unitPrice !== null
                )
            {
                var unitOfMeasure =
                    item.measureUnits.find(e => e.code === unitOfMeasureCode);
                
                unitPrice = item.unitPrice * (unitOfMeasure?.baseUnitOfMeasureQuantity ?? 1);
                var discountRate = this.getDiscountRate();
                if(discountRate)
                {
                    unitPrice = (1-discountRate)*unitPrice;
                }
            }
            else
            {
                unitPrice = undefined as any;
            }

            return unitPrice;
        },






        getItemAndQuantityData:async (itemId:string)=>
        {

            var entityItemService = 
            new EntityGroupService(dContext);



            var fromEntity = order?.fromEntity;
            if(state.itemMap[itemId])
            {
                return {

                    item:state.itemMap[itemId],
                    entityItem:state.itemIdFromEntityItemMap[itemId]
                };
            }else
            {
                try{
                    var res = await entityItemService.getOnOrderItemChangeData(fromEntity?.id, itemId);
                    dispatch({type:"save", orderItemChangeData: res});
                    
                    return {
                        item:res.item,
                        entityItem:res.entityItems.find(e => e.itemId===itemId)
                    };
                }catch(e)
                {
                    
                    if(dContext.isOffline)
                    {
                        return null;
                    }
                    
                    throw e;
                }
            }


        },
        clearEntityItems:()=>{

            dispatch({type:"clear"});
        }
    }),
    // eslint-disable-next-line
    [
        state, order, sourceOrderHelper,
        attachmentsHelper
        // not used
        //orderType, dContext.http
    ]);


    return ret;
}
