import { Input, DateTimeOffsetInput } from "hok-react";
import { Collapse } from "reactstrap";
import { EntitySelect, NullOrEmptyStringCode } from "../..";
import { EntityTypeNameIds } from "../../models/enums/EntityTypeNameIds";
import { SalesAgentSelect } from "../../sales-agents/SalesAgentSelect";
import { useOrderListFilterContext } from "./useOrderListFilterContext";

export function OrderListFilterHeader(props:{
    orderListFilterContext:ReturnType<typeof useOrderListFilterContext>
})
{


    var fCtx = props.orderListFilterContext;

        var orderType = fCtx.orderType;
        // var isSaleType = orderType.customModel.name==OrderTypeNameIds.SalesOrder;
        return <>

        {/* <h1>{orderType.customModel.collectionDisplayName}</h1> */}
        
        <div className="row">
            
        <div className="col-md-3">
        
        <label className="">search&nbsp;</label>
        <Input value={fCtx.state.searchString}
        onChange={e => fCtx.setState({searchString:e.target.value})}
        
        className="form-control"
        />
        </div>
        
            <div className="col-md-3">
        
            <label className="">From&nbsp;</label>
            <EntitySelect value={fCtx.state.fromEntity}
                onChange={e=>fCtx.setState({fromEntity:e.target.value})}
                entityTypeNameId={EntityTypeNameIds.Location}
            className="form-control"
            />
            </div>
        
            {orderType.toEntityTypeName &&
            <div className="col-md-3">
        
            <label className="">To&nbsp;</label>
            <EntitySelect value={fCtx.state.toEntity}
                onChange={e=>fCtx.setState({toEntity:e.target.value})}
                entityTypeNameId={orderType.toEntityTypeName}
            className="form-control"
            />
            </div>
            }

            <div className="col-md-3">
                    
            <label className="">Custom Status&nbsp;</label>
            <select 
            className="form-control"
            value={fCtx.state.customStatus}
    onChange={(evt)=>{
        fCtx.setState({customStatus:evt.target.value})

    }}
>
    <option></option>
    <option value={NullOrEmptyStringCode}>(unset)</option>
    
    {orderType.customStatuses
    .map((e,idx) => <option 
    key={idx}
    value={e.value}>{e.value}</option>)}
    </select>

            </div>



            {/*
            <div className="col-md-4">
                <span *ngIf="isSaleType">
                    <label>
                    <input type="checkbox" [formControl]="incompleteFlagControl"/>
                    Incomplete Payments Only</label>
                </span>
            </div>
            <div className="col-md-4  d-flex" *ngIf="isSaleType">
                
            <label className="">Sales Agent</label>
                <app-inventory-sales-agent-select [organizationId]="organizationId"
                    [form]="salesAgentFilterControl"
                    controlclassName="form-control"
                    className="flex-grow-1"
                    ></app-inventory-sales-agent-select>
            </div> */}
        </div>





<div className="accordion">
  <div className="card">
    <div className="card-header cursor-pointer"
    
    onClick={()=>fCtx.setShowOtherFilters(!fCtx.showOtherFilters)}
    >
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left" type="button"
        >
          More Filters
        </button>
      </h2>
    </div>
    <Collapse isOpen={fCtx.showOtherFilters}>
    <div className="card-body">




    <div className="row">
            
    <div className="col-md-2">
                    Order Date
                </div>
            <div className="col-md-4 d-flex">
            
            <label className="">Start &nbsp;</label>
            <DateTimeOffsetInput 
            type="date"
            value={fCtx.state.beginInputTime}
            onChange={e => fCtx.setState({beginInputTime:e.target.value})}
            className="form-control flex-grow-1"
            />
            </div>
            
                <div className="col-md-4 d-flex">
            
                <label className="">End&nbsp;</label>
                <DateTimeOffsetInput 
                type="date"
                value={fCtx.state.endInputTime}
                onChange={e => fCtx.setState({endInputTime:e.target.value})}
                className="form-control flex-grow-1"
                />
                </div>

            </div>
            <div className="row">


                <div className="col-md-2">
                    Due Date
                </div>
                <div className="col-md-4 d-flex">
            
            <label className="">Start&nbsp;</label>
            <DateTimeOffsetInput 
            type="date"
            value={fCtx.state.beginOrderDueTime}
            onChange={e => fCtx.setState({beginOrderDueTime:e.target.value})}
            className="form-control flex-grow-1"
            />
            </div>
            
                <div className="col-md-4 d-flex">
            
                <label className="">End&nbsp;</label>
                <DateTimeOffsetInput 
                type="date"
                value={fCtx.state.endOrderDueTime}
                onChange={e => fCtx.setState({endOrderDueTime:e.target.value})}
                className="form-control flex-grow-1"
                />
                </div>
            
            </div>

        <div className="row">

            
        <div className="col-md-2">
                    Sales Agent
                </div>
                <div className="col-md-4">
                    <SalesAgentSelect
                        value={fCtx.state.salesAgent}
                        onChange={e => fCtx.setState({salesAgent:e.target.value})}
                        className="form-control"
                    />
                    </div>
        </div>
            
            <div>
                    <button>filter</button>
                </div>
    </div>
    </Collapse>
  </div>
  
</div>
        
        </> ;
}