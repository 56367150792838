import React, { useMemo, useRef, useState } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";

export interface ToastModel
{
    header?:string;
    body?:string;

    durationMs?:number;
}


export interface ToastContextModel
{
    pushToast(toast:ToastModel):void,
    setToast(toast:ToastModel):void

}


export const ToastContext = React.createContext<ToastContextModel>(null);


export function ToastProvider(props)
{

    var [toasts, setToasts] = useState<ToastModel[]>([]);
    var [toast, setToast] = useState<ToastModel>();
    var [showToast, setShowToast] = useState<boolean>(false);
    var toastTimeoutRef = useRef<number>();

    function removeToast(toast:ToastModel)
    {

        setToasts((toasts)=>{

            var index = toasts.indexOf(toast);

            if(index>=0)
            {
                return [

                    ...toasts.slice(0,index),
                    ...toasts.slice(index+1)
                ];
            }

            return toasts;
        });
    }

    function pushToast(toast:ToastModel)
    {

        setToasts((toasts)=>[...toasts, toast]);

        if(toast.durationMs)
        {
            setTimeout(()=>{
                removeToast(toast);
            },toast.durationMs);
        }

    }

    // eslint-disable-next-line
    var ctx = useMemo(()=>({

        pushToast,
        setToast:(toast:ToastModel)=>{

            if(toastTimeoutRef.current)
            {
                clearTimeout(toastTimeoutRef.current);
                toastTimeoutRef.current = undefined;
            }

            
            setToast(toast);
            setShowToast(true);

            if(toast.durationMs)
            {
                toastTimeoutRef.current = window.setTimeout(()=>{
                    setShowToast(false);
                },toast.durationMs);
            }
            
        }
    }),
    // eslint-disable-next-line
    [toasts]);







    return <ToastContext.Provider value={ctx}>

        {props.children}


        <div className="position-fixed" style={{bottom:"1em", right:"1em"}}>
            {toasts.map((toast,idx)=>
                    
                <Toast key={idx} className="bg-info" style={{width:"33vw"}}
                    fade={false}
                >
                    <ToastHeader toggle={e=>removeToast(toast)}>{toast.header}</ToastHeader>
                    <ToastBody >
                        {toast.body}
                    </ToastBody>
                </Toast>
            
            )}

            
            <Toast style={{width:"33vw"}}
            isOpen={showToast}
            >
                <ToastHeader toggle={e=>setShowToast(false)}>{toast?.header}</ToastHeader>
                {toast?.body && <ToastBody >
                    {toast?.body}
                </ToastBody>}
            </Toast>
            
            
        </div>
    </ToastContext.Provider>;


}