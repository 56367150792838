import { IdentityResult } from "hoksource";
import { DirectoryUser } from "../models/entities/directories/directory-user";
import { DirectoryUserDetail } from "../models/directory-user-detail";
import { DirectoryHttpService } from "../http/DirectoryHttpService";

export class DirectoryUserService
{

    
    constructor(private http:DirectoryHttpService)
    {

    }

    
    getUsers()
    {

      
      return this.http.post<DirectoryUser[]>("/DirectoryUsers/GetUsers");
    }


    addUser(email:string)
    {
      
      return this.http.post<DirectoryUser>("/DirectoryUsers/AddUser", {

        body:{
          email
        }
      });
    }


    getUserDetail(userId:string)
    {
      
      return this.http.post<DirectoryUserDetail>("/DirectoryUsers/GetUserDetail", {

        hokQuery:{
          userId
        }
      });
    }

    addUserRole(data:
      {userId:string,
      roleId:string})
    {
      
      return this.http.post<IdentityResult>("/DirectoryUsers/AddUserRole", {
        body:data
      });

    }




}