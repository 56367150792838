import { Order } from "../models/entities/orders/order";
import { OrderLine } from "../models/entities/orders/order-line";


export function convertLineForSaving(orderLine:OrderLine)
{
    var {item, ...lineCopy} = orderLine;

    lineCopy.itemId = item.id;
    if(orderLine.assemblyOrder)
    {

        lineCopy.assemblyOrder = convertOrderForSaving(orderLine.assemblyOrder);
    }

    if(orderLine.childLines)
    {
        lineCopy.childLines = orderLine.childLines.map(convertLineForSaving);
    }

    return lineCopy;
    
}



export function convertOrderForSaving(order:Order)
{
/*
    if(!order)
        return null;
        */


    var {
        sourceOrder,
        fromEntity,
        toEntity, 
        salesAgent,
        paymentTermsConfiguration,
        ...copy} = order;

    
    copy.sourceOrderId = order.sourceOrder?.id ?? null;
    copy.fromEntityId = order.fromEntity?.id;
    

    if(order.assemblyOrder?.item)
    {
        copy.assemblyOrder = {

            ...order.assemblyOrder,
            itemId:order.assemblyOrder?.item.id
        };
    }
    if(order.toEntity?.id)
    {
        copy.toEntityId = order.toEntity?.id;
    }
    if(order.salesAgent?.id)
    {
        copy.salesAgentId = order.salesAgent?.id;
    }
    if(order.paymentTermsConfiguration?.id)
    {
        copy.paymentTermsConfigurationId = order.paymentTermsConfiguration?.id;
    }

    copy.lines = copy.lines.map(convertLineForSaving);



    return copy as Order;
};