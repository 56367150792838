export function AttachmentComponent(props:{

    onClick:()=>void,
    fileName:string,
    imageUrl:string,
    onRemove:()=>void
})
{


    return <div

    className="d-inline-block cursor-pointer 
    text-center
    m-1
    align-top
    "
    style={{width:"80px", textOverflow:"ellipsis"}}
    onClick={props.onClick}
    title={props.fileName}
    >
        <div 
    
    style={{
        textAlign:"right",
        width:"80px",
        height:"80px",
        backgroundImage:`url('${props.imageUrl}')`,
        backgroundSize:"cover",
        backgroundPosition:"center"

    }}
    
    >
        <button type="button" onClick={(evt)=>{

            evt.stopPropagation();

            if(window.confirm("Are you sure you want to remove this?"))
            {
                props.onRemove();
                // props.editOrder.attachmentsHelper.removeAttachmentToAdd(img);
            }
        }}>X</button>

    </div>
    <div
    
    style={{width:"80px", overflow:"hidden",textOverflow:"ellipsis"}}
    >{props.fileName}</div>
    
    </div>;
}