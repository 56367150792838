import { IdentityResult } from "hoksource";
import { OrderStore } from "../db/OrderStore";
import { DirectoryContextModel } from "../hooks/useDirectoryApp";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { Order } from "../models/entities/orders/order";
import { SaveOrderInput } from "../models/inputs/SaveOrderInput";
import { convertOrderForSaving } from "./convertOrderForSaving";
import { OrderWithShipments } from "./OrderWithShipments";



export interface GetOrderListFilter
{
    
    type:string;
    inputTimeStart?:string;
    inputTimeEndExclusive?:string;

    orderDueTimeStart?:string;
    orderDueTimeEndExclusive?:string;


    skip:number,
    take:number,
    searchString?:string,
    fromEntityId?:string,
    toEntityId?:string,
    customStatus?:string;

    
    salesAgentId?:string;


    orderBy?:{propertyName:string, descending?:boolean}[];


    openLowerBoundOrderId?:string|number;

}
export class OrderService
{
    http:DirectoryHttpService;
    constructor(
        private context:DirectoryContextModel)
    {
        this.http = context.http;
    }


    existsTag(type:string, tag:string, notOrderId?:string)
    {
        var q :{
            type:string,
            tag:string,
            notOrderId?:string
        } = {
            type,
            tag: tag,
        };
        if(notOrderId)
        {
            q.notOrderId = notOrderId;
        }
        return this.http.post<boolean>(`/Orders/ExistsTag`,{

            hokQuery:q
        });
    }


    getList(q:GetOrderListFilter)
    {

        
      return this.http.post<Order[]>(`/Orders/GetList`, {
            body:q,
            onOnlineSuccess:entities => {
            },
            whenOffline:()=>{
            var entityStore = new OrderStore(this.context.dbService);
            return entityStore.getList(q);
            }
        });
            
    }

    find(id:string)
    {

        var store= new OrderStore(this.context.dbService);

        if(id && !isNaN(id as any))
        {
            // this is in db
            return store.get(+id);
        }
        else
        {
            return this.http.post<Order>(`/Orders/Find`,{
                hokQuery:{
                    id:id

                },
            onOnlineSuccess:entity=>{
                
            },
            whenOffline:()=>{
                return store.get(id);
            }
            });
        }
    }

    
    findOrderWithPostedShipments(id:string)
    {

        var store= new OrderStore(this.context.dbService);

        if(id && !isNaN(id as any))
        {
            // this is in db
            return store.get(+id).then(e => {
                if(e)
                {

                    return {order:e, shipmentOrders:[]} as OrderWithShipments
                }

                return null;
            });
        }
        else
        {
            return this.http.post<OrderWithShipments>(`/Orders/FindOrderWithPostedShipments`,{
                hokQuery:{
                    id:id
                },
                /*
            onOnlineSuccess:entity=>{
                
            },
            whenOffline:()=>{
                return store.get(id);
            }*/
            });
        }
    }


    async save(input:Order,
        post:boolean = false)
    {


        if(typeof input.id === "number")
        {
            // save locally
            input.lastModifiedTime = new Date().hokToLocaleDateTimeOffsetString();
            input.hasOfflineChanges = 1;
            
            var store= new OrderStore(this.context.dbService);
            return await store.put(input);
        }
        else
        {

            return await this.http.post<Order>(`/Orders/Save`,{

                body:convertOrderForSaving(input),
                hokQuery:{
                    post
                },
                whenOffline:async ()=>{
                            
                    var now = new Date().hokToLocaleDateTimeOffsetString();
                    input.lastModifiedTime = now;
                    input.hasOfflineChanges = 1; // save with offline changes
                    if(input.id === undefined)
                    {
                        input.creationTime = now;
                    }
                                
                    var store= new OrderStore(this.context.dbService);
                    return await store.put(input);
                }
            });
        }
    }

    
    async save2(input:SaveOrderInput,
        post:boolean = false)
    {


        
        return await this.http.post<Order>(`/Orders/Save2`,{

            body:input,
            hokQuery:{
                post
            },
        });
    }


    async saveAttachments(attachments:
        {orderId:string,
            file:Blob
        }[])
    {
        var formData = new FormData();
        for(let i = 0; i < attachments.length; ++i)
        {
            formData.append(`Attachments[${i}].OrderId`, attachments[i].orderId);
            formData.append(`Attachments[${i}].File`, attachments[i].file);
        }

        

        return await this.http.post<{}[]>(`/Orders/SaveOrderAttachments`,{
            body:formData});


    }

    postOrder(id:string, post:boolean = true)
    {

        return this.http.post<Order>(`/Orders/PostOrder`,{
            hokQuery:{
                id,
                post
            }
        });
    }

    setCustomStatus(orderId:string, customStatus:string)
    {
        return this.http.post<IdentityResult>(`/Orders/SetCustomStatus`, {

            hokQuery:{

                orderId,
                customStatus
            }
        });
    }
}