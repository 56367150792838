import { ValidationProblemDetails } from "hoksource";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { Entity } from "../models/entities/entities/entity";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { Order } from "../models/entities/orders/order";
import { ModelSyncServiceBase } from "./ModelSyncServiceBase";
import { objectStoreDeleteAsync } from "./ObjectStoreExtensions";
import { StoreIndexNames, StoreNames } from "./StoreNames";
import { SyncService } from "./SyncService";

export class EntitySyncService extends ModelSyncServiceBase<Entity>
{

    constructor(
        private http:DirectoryHttpService,
        private db:IDBDatabase,
        syncService:SyncService)
    {
        super(syncService);
    }

    
    async getEntriesFromStore()
    {
        var entityStore = this.db.transaction(StoreNames.Entities)
        .objectStore(StoreNames.Entities);
        var index:IDBIndex = entityStore.index(StoreIndexNames.EntitiesHasOfflineChangesIndex);
        var entities = await index.hokGetAllAsync<Entity>({count:100});
        return entities;
    }

    
    async uploadEntries(entries: Entity[])
    //: Promise<{ savedEntries: Entity[]; validationProblemDetails?: ValidationProblemDetails; }>
    {
        
        var toSave = entries.map(e => {

            if(typeof e.id === "string")
            {
              return e;
            }else
            {
              var {id, ...copy} = e;
              return copy as Entity;
            }

          });

        var res = await this.http.post<{
            savedEntities:Entity[];
            validationProblemDetails:ValidationProblemDetails;
          }>("/Entities/SaveList", {
              body:{
                entities:toSave,
                saveWithValidationErrors:true
              },
          });





          
          var txn = this.db.transaction([StoreNames.Entities, 
            StoreNames.Orders, StoreNames.JournalEntries
          ], "readwrite");
          var entityStore = txn.objectStore(StoreNames.Entities);
          var orderStore = txn.objectStore(StoreNames.Orders);
          var orderFromEntityIdx = 
            orderStore.index(StoreIndexNames.OrdersFromEntityIdIndex);
          var orderToEntityIdx = 
            orderStore.index(StoreIndexNames.OrdersToEntityIdIndex);

            
          var journalEntryStore = txn.objectStore(StoreNames.JournalEntries);
          var journalEntryFromEntityIdx = 
          journalEntryStore.index(StoreIndexNames.JournalEntriesFromEntityIdIndex);
          var journalEntryToEntityIdx = 
          journalEntryStore.index(StoreIndexNames.JournalEntriesToEntityIdIndex);

            
          // save res to local
          await Promise.all(entries.map(async (prev,idx)=>{

            var next = res.savedEntities[idx];

            entityStore.put(next);
            if(typeof prev.id === "number")
            {
              // delete i
              await Promise.all(


                [
                  objectStoreDeleteAsync(entityStore,prev.id),
                  
                  
                  this.syncService.updateOfflineEntities<Order>(orderStore,
                    orderFromEntityIdx, prev.id, order=>{
                      order.fromEntity.id = next.id
                      return order;
                    }),

                    
                  this.syncService.updateOfflineEntities<Order>(orderStore,
                    orderToEntityIdx, prev.id, order=>{
                      order.toEntity.id = next.id
                      return order;
                    }),

                  this.syncService.updateOfflineEntities<JournalEntry>(journalEntryStore,
                    journalEntryFromEntityIdx, prev.id, e=>{
                      e.fromEntity.id = next.id
                      return e;
                    }),

                  this.syncService.updateOfflineEntities<JournalEntry>(journalEntryStore,
                    journalEntryToEntityIdx, prev.id, e=>{
                      e.toEntity.id = next.id
                      return e;
                    })

                ]
              );
              
            }
          }));
        

        return {
            savedEntries:res.savedEntities,
            validationProblemDetails:res.validationProblemDetails
        };

    }


    
}