import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { DirectoryContext, ErichRoles, JournalTypesRoot } from "..";
import { ActivityLogs } from "../activity-logs/ActivityLogs";
import { JournalType } from "../models/entities/journals/journal-type";
import { Details } from "./Details";
import { Home } from "./Home";
import { JournalTotalAmounts } from "./JournalTotalAmounts";



export function JournalsRoot(props:{journalType:JournalType})
{
    var match = useRouteMatch();

    var ctx = React.useContext(DirectoryContext);

    // var isAdmin = ctx.directoryService.checkUserRoles(ErichRoles.Administrator)

    return <Switch>
        <Route exact path={`${match.path}/`}>
            <Home {...props} />
        </Route>
        
        <Route path={`${match.path}/details`}>
            <Details {...props} />
        </Route>

        
        <Route path={`${match.path}/total-amounts`}>
            <JournalTotalAmounts {...props} />
        </Route>

        
        {/* {isAdmin && */}
        <Route path={`${match.path}/activity-logs`}>
            <ActivityLogs objectTypeName={props.journalType.customModel.name}/>
        </Route>
        {/* } */}

     </Switch>;
}