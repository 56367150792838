import { ISyntheticEvent } from "hok-react";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { JournalEntryTypeInputOption, JournalType } from "../models/entities/journals/journal-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";

interface JournalEntriesInputProps
{
    journalType:JournalType;
    entriesToBalance?:JournalEntry[];
    
    value:JournalEntry[];
    onEntryChange:(evt:ISyntheticEvent<{value:JournalEntry}>, index:number)=>void;

    onRemoveEntryClick:(evt:ISyntheticEvent<{value:JournalEntry}>, index:number)=>void;

    // createNewEntry?():JournalEntry;


}

export class JournalEntriesInputController extends DirectoryComponent
<JournalEntriesInputProps,{
}>
{

    entryTypeInputOptionsMap : {[key:string]:JournalEntryTypeInputOption};

    constructor(props:JournalEntriesInputProps)
    {
        super(props);

        var journalType = props.journalType;
        
        this.entryTypeInputOptionsMap = {};
        for(let i of journalType.entryTypeInputOptions)
        {
            this.entryTypeInputOptionsMap[i.value] = i;
        }
    }


    handleEntryChange = (evt:ISyntheticEvent<{value:JournalEntry}>, index:number) => {

/*
        var entry = evt.target.value;
        var value = this.props.value;
        var prevEntries = value;
        var nextEntries = [...prevEntries.slice(0, index), entry, ...prevEntries.slice(index+1)];
*/
        /*
        if(entry.amount)
        {
            // has amount
            if(index === value.length-1 && this.props.createNewEntry)
            {
                nextEntries.push(this.props.createNewEntry());
            }
        }
        */
        this.props.onEntryChange(evt, index);
    };

    
    handleRemoveEntryClick = (evt:ISyntheticEvent<{value:JournalEntry}>, index:number) => {

        this.props.onRemoveEntryClick(evt, index);
    };

}