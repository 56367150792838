import { DirectoryHttpService } from "../http/DirectoryHttpService";

export class ModelTypeServiceBase<T = any>
{

    protected controllerName:string;

    constructor(protected http:DirectoryHttpService)
    {


    }


    getList()
    {
        return this.http.post<T[]>(`/${this.controllerName}/GetList`, {

        });
    }

    findById(id:string)
    {

        return this.http.post<T>(`/${this.controllerName}/FindById`, {
            hokQuery:{
                id
            }
        });
    }
    
    findByNameId(nameId:string)
    {

        return this.http.post<T>(`/${this.controllerName}/FindByNameId`, {
            hokQuery:{
                nameId
            }
        });
    }

    save(input:T)
    {
        return this.http.post<T>(`/${this.controllerName}/Save`, {
            body:input
        });
    }
}




export interface IModelServiceBase<T = any> 
{

    

    getList() :Promise<T[]>;

    findById(id:string):Promise<T>;
    findByNameId(nameId:string):Promise<T>;

    save(input:T):Promise<T>;
}
