import React from "react";
import { ToastContextModel } from "../../modals/ToastProvider";
import { Order } from "../../models/entities/orders/order";
import { OrderType } from "../../models/entities/orders/order-type";
import { OrderEditC } from "./EditC";
import { useEditOrder } from "./useEditOrder";


export interface OrderEditProps
{
    
    orderType:OrderType;
    toastContext:ToastContextModel;
    processOrderBeforeSaving?:(order:Order)=>Order;

    

}
export function OrderEdit(props:OrderEditProps)
{

    var editOrder = useEditOrder(props.orderType, null);


    return <OrderEditC {...props} editOrder={editOrder}/>;

}