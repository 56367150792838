import { createSmallerImageIfBiggerThanDimensions } from "hoksource";

export function createSmallerImageCanvas(blob:Blob, maxSize:number)
{

    return new Promise<HTMLCanvasElement>(function(resolve)
    {
        var srcUrl = URL.createObjectURL(blob);

        var img = document.createElement("img");
        img.onload = function()
        {
            var resized = createSmallerImageIfBiggerThanDimensions(img, {
                width: maxSize,
                height: maxSize,
                preserveAspectRatio: true
            });
        
            if(resized instanceof HTMLImageElement)
            {
                resized = resized.hokToHTMLCanvasElement();
            }


            URL.revokeObjectURL(srcUrl); // dispose image url before resolving

            resolve(resized);
        
        };

        img.src = srcUrl;




    });
}