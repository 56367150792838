import { Item } from "../../../models/entities/items/item";
import { OrderLine } from "../../../models/entities/orders/order-line";
import { useEditOrder } from "../useEditOrder";

export function checkCreateChildLines(
    editOrder:ReturnType<typeof useEditOrder>,
    item:Item,
    nextOrderLine:OrderLine
    )
{
    if(
        nextOrderLine.item // has item
        && item.defaultChildItems.length
    )
    {

        return {

            ...nextOrderLine,
            childLines:item.defaultChildItems.map(e => ({
                item:e.childItem,
                quantity:e.quantity,
                unitOfMeasureCode:e.childItem.baseUnitOfMeasureCode,
                unitPrice:
                e.unitPrice !== null?e.unitPrice :
                editOrder.computeLineUnitPrice(e.childItem, e.childItem.baseUnitOfMeasureCode),
                sortOrder:e.sortOrder
            }))
        } as OrderLine;

    }
    return nextOrderLine;
}