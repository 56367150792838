import { IdentityResult } from "hoksource";
import { JournalEntryStore } from "../db/JournalEntryStore";
import { DirectoryContextModel } from "../hooks/useDirectoryApp";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { FailedToFetchMessage } from "../http/HttpService";
import { Entity } from "../models/entities/entities/entity";
import { JournalApplicationEntry } from "../models/entities/journals/journal-application-entry";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { convertJournalEntryForSaving } from "./convertJournalEntryForSaving";


export interface GetJournalListFilter
{

    skip:number,
    take:number,
    searchString?:string,
    fromEntityId?:string,
    toEntityId?:string,
    journalType:string,
    entryType?:string,
    includeZeroBalance:boolean,



    openLowerBoundOrderId?:string

}

export class JournalService
{

    http:DirectoryHttpService;
    constructor(
        private context:DirectoryContextModel)
    {
        this.http = context.http;
    }

    
    getJournalEntries(q:GetJournalListFilter)
    {

        

        
      return this.http.post<JournalEntry[]>(`/Journals/GetJournalEntries`, {
            body:q,
            onOnlineSuccess:entities => {
            },
            whenOffline:()=>{
            var entityStore = new JournalEntryStore(this.context.dbService);
            return entityStore.getList(q);
            }
        });
    }

    findJournalEntry(id:string)
    {
        var store= new JournalEntryStore(this.context.dbService);

        if(id && !isNaN(id as any))
        {
            // this is in db
            return store.get(+id);
        }
        else
        {
            return this.http.post<JournalEntry>(`/Journals/FindJournalEntry`,{
                hokQuery:{
                    id:id

                },
            onOnlineSuccess:entity=>{
                
            },
            whenOffline:()=>{
                return store.get(id);
            }
            });
        }
    }

    getJournalEntryWithDetails(id:string)
    {
        type retType = {
            journalEntry:JournalEntry,
            journalBalancingEntries:JournalApplicationEntry[]
        };

        const getOffline = async ()=>{
            var store= new JournalEntryStore(this.context.dbService);

            
            var e = await store.get(+id);

            if(!e)
            {
                throw new TypeError(FailedToFetchMessage);
            }


            return {
                journalEntry:e,
                journalBalancingEntries:[]
            } as retType;
        };

        if(id && !isNaN(id as any))
        {
            // this is in db
            return getOffline();
        }
        
        return this.http.post<retType>(`/Journals/GetJournalEntryWithDetails`,{
            hokQuery:{
                id:id
            },
            whenOffline:getOffline
        });
    }

    saveAndApplyJournalEntries(data:{
        journalEntries:JournalEntry[],
        applyToJournalEntryIds?:string[]
    })
    {

        var existsOffline = data.journalEntries.find(e => typeof e.id==="number");

        const saveOffline = async ()=>{

            var now = new Date().hokToLocaleDateTimeOffsetString();
            data.journalEntries.forEach(e => {

                e.hasOfflineChanges = 1; // save with offline changes
                if(e.id === undefined)
                {
                    e.creationTime = now;
                }
                e.amount = +e.amount;
                e.balance = e.amount;
            });
            var store= new JournalEntryStore(this.context.dbService);
            return await store.putAll(data.journalEntries);
        };


        if(existsOffline)
        {
            return saveOffline();

        }

        return this.http.post<JournalEntry[]>(`/Journals/SaveAndApplyJournalEntries`,{
            body:{
                journalEntries:data.journalEntries
                    .map(convertJournalEntryForSaving),
                applyToJournalEntryIds:data.applyToJournalEntryIds
            },
            whenOffline :()=>{

                    
                return saveOffline();
            }
        });

    }

    
    balanceJournalEntries(data:{
        journalEntryIds:string[]
    })
    {

        
        return this.http.post<IdentityResult>(`/Journals/BalanceJournalEntries`,{
            body:data
        });

    }


    remove(id:string)
    {
        
        if(id && !isNaN(id as any))
        {        
            
            var store= new JournalEntryStore(this.context.dbService);
            // this is in db
            return store.delete(+id);
        }
        else
        {
            
            
            return this.http.post<JournalEntry>(`/Journals/Remove`,{
                hokQuery:{
                    id
                },
                whenOffline:()=>{

                    var store= new JournalEntryStore(this.context.dbService);
                    // this is in db
                    return store.delete(id);
                }
            });

        }
    }


    
    doVoid(id:string)
    {
        
        if(id && !isNaN(id as any))
        {        
            
            var store= new JournalEntryStore(this.context.dbService);
            // this is in db
            return store.delete(+id);
        }
        else
        {
            
            
            return this.http.post<JournalEntry>(`/Journals/Void`,{
                hokQuery:{
                    id
                },
                whenOffline:()=>{

                    var store= new JournalEntryStore(this.context.dbService);
                    // this is in db
                    return store.delete(id);
                }
            });

        }
    }

    getFromEntityIdEntryTypeEntryModeTotalAmounts(filter:{

        journalType:string,
        beginInputTime:string;
        endInputTimeExclusive:string;
        fromEntityId?:string,
        toEntityId?:string;
        entryType?:string;
        entryMode?:string;
    })
    {
        

        
        return this.http.post<{
            fromEntityId:string;
            fromEntity:Entity;
            entryType:string,
            entryMode:string;
            totalAmount:number;
            currencyCode:string;
        }[]>(`/Journals/GetFromEntityIdEntryTypeEntryModeTotalAmounts`,{
            body:filter
        });
    }

}