import { serviceBasePath } from "../ErichConfig";
import { httpService } from "../http/HttpService";
import { AppInitData } from "../models/app-init-data";

export const LocalStorageAppInitDataKey = "appInitData";

function getAppInitDataFromLocalStorage()
{
    
    var initDataJson = localStorage[LocalStorageAppInitDataKey];
    // get from local
    if(initDataJson)
    {
        return JSON.parse(initDataJson) as AppInitData;
    }

    return null;
}
export async function getAppInitData()
{
    
    return await httpService.post<AppInitData>(
        `${serviceBasePath}/api/App/GetAppInitData`, {

            onOnlineSuccess:data=>{

                var prev = getAppInitDataFromLocalStorage();
                // can be write efficient???

                var now = new Date().getTime();

                // optimization
                if(!prev || prev.lastSyncTimeMilliseconds + 5 * 60 * 1000 < now)
                {
                    data.lastSyncTimeMilliseconds = now;
                    localStorage[LocalStorageAppInitDataKey] = JSON.stringify(data);
                }

                
            },

            whenOffline:getAppInitDataFromLocalStorage
        });
    
}