import React from "react";
import { DirectoryContextModel } from "../hooks/useDirectoryApp";


export interface DirectoryContextUser
{
    roles:string[]

}
export const DirectoryContext = React.createContext<DirectoryContextModel>(null);

