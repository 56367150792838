import { Entity } from "../../models/entities/entities/entity";
import { OrderType } from "../../models/entities/orders/order-type";
import { useLegacyState } from 'hok-react';
import { useContext, useEffect, useMemo, useState } from "react";
import { SyncEntryUpdateEvent } from "../../db/SyncService";
import { DirectoryContext } from "../../directory-shared/DirectoryContext";
import { SyncEventType } from "../../db/SyncEventType";
import { GetOrderListFilter, OrderService } from "../OrderService";
import { SalesAgent } from "../../models/entities/entities/sales-agent";


interface UseOrderListFilterState
{

    searchString?:string,
    fromEntity?:Entity,
    toEntity?:Entity,
    customStatus:string,
    beginInputTime?:string;
    endInputTime?:string;
    beginOrderDueTime?:string;
    endOrderDueTime?:string;
    salesAgent?:SalesAgent;
    resetCtr:number;
    // selectedSalesAgent?:SalesAgent,
    // incompleteFlag?:boolean
}

export function useOrderListFilterContext(props:{

    orderType:OrderType,
    nearDueMode?:boolean

})
{
    var context = useContext(DirectoryContext);

    var [state,setState] = useLegacyState<UseOrderListFilterState>({
        
        customStatus:"",
        resetCtr:0
    });

    var [showOtherFilters, setShowOtherFilters] = useState<boolean>();



    useEffect(()=>{

        const handleSyncEntryUpdate = (evt:SyncEntryUpdateEvent<Entity>) =>{
            if(evt.entryType === "Entity" || evt.entryType === "Order")
            {
                setState({resetCtr:state.resetCtr+1});
            }
        };

        context.syncService.addEventListener(SyncEventType.UpdateEntries, handleSyncEntryUpdate);
        

        return ()=>{

            context.syncService.removeEventListener(SyncEventType.UpdateEntries, handleSyncEntryUpdate);
            
        };

    },[props.orderType,state]);
    



    
    var takeCount = 20;



    return useMemo(()=>({


            state:state,
            setState:setState,
            showOtherFilters,
            setShowOtherFilters,
            resetFilter:()=>{

                setState({

                    searchString:"",
                    fromEntity:null,
                    toEntity:null,
                    customStatus:"",
                    beginInputTime:"",
                    endInputTime:"",
                    beginOrderDueTime:"",
                    endOrderDueTime:""


                });

                setShowOtherFilters(false);

            },
            orderType:props.orderType,
            nearDueMode:props.nearDueMode,
            takeCount:takeCount,
            getItems:(filter)=>{

                
                var service = new OrderService(context);

                var requestFilter : GetOrderListFilter= {
                    skip: filter.currentIndex,
                    // lastEntry: filter.lastEntry,
                    take: takeCount,
                    fromEntityId: state.fromEntity?.id,
                    toEntityId:state.toEntity?.id,
                    customStatus: state.customStatus,
                    type : props.orderType.customModel.name,
                };

                var d:Date;
                
                if(props.nearDueMode)
                {
                    requestFilter.orderBy = [{
                        propertyName:"OrderDueTime"
                    }];

                    d = new Date();
                    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                    requestFilter.orderDueTimeStart = d.hokToLocaleDateTimeOffsetString();
                }

                if(state.searchString)
                {
                    requestFilter.searchString = state.searchString;
                }

                if(state.beginInputTime)
                {
                    requestFilter.inputTimeStart = state.beginInputTime;
                }
                if(state.endInputTime)
                {
                    d = new Date(state.endInputTime);
                    d.setDate(d.getDate()+1);
                    requestFilter.inputTimeEndExclusive = d.hokToLocaleDateTimeOffsetString();
                }

                if(state.beginOrderDueTime)
                {
                    requestFilter.orderDueTimeStart = state.beginOrderDueTime;
                }
                if(state.endOrderDueTime)
                {
                    d = new Date(state.endOrderDueTime);
                    d.setDate(d.getDate()+1);
                    requestFilter.orderDueTimeEndExclusive = d.hokToLocaleDateTimeOffsetString();
                }
                if(state.salesAgent)
                {
                    requestFilter.salesAgentId = state.salesAgent.id;
                
                }

                // if(state.incompleteFlag)
                // {

                //     requestFilter.incompletePaymentsFlag = true;
                // }


            
                return service.getList(requestFilter);
            }

        })
    ,[state,
        showOtherFilters,
    props.orderType,
    //props.nearDueMode
    ]);
}