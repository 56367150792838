import { FormGroup, FormGroupContext, FormInput, Input, ValidationSummary } from "hok-react";
import { DirectoryCurrency } from "../models/entities/directories/directory-configuration";
import { DirectoryConfigurationEditController } from "./EditController";

export class DirectoryConfigurationEdit extends DirectoryConfigurationEditController
{

    render()
    {

        return <>
        <h1>Edit</h1>

        <h4>Directory Configuration</h4>
        <hr />
        <div className="row">
        <div className="col-md-4">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input} 
            onChange={e => this.setState({input:e.target.value})}
            childrenProps={{errors:this.state.errors}}
            >
            {g =><>
                <FormInput
                    group={g}
                    groupKey="name"
                    name="Name"
                    labelText="Name"
                />

                                
                <FormInput
                    group={g}
                    groupKey="currencyCode"
                    name="CurrencyCode"
                    labelText="Currency Code"
                />










<h5>Currencies</h5>
<hr/>

<FormGroup
  value={g.value.currencies}
  onChange={this.handleCurrenciesChange}
>{cg => <div>


    
<table>

  <thead>
    <tr>
      <th>Currency Code</th>
      <th></th>
    </tr>
  </thead>
  <tbody>


  {cg.value.map((currency,idx)=>
  <FormGroup
    key={idx}
    group={cg}
    groupKey={idx}
  >{(cGroup:FormGroupContext<DirectoryCurrency>)=><>

  

  <tr>
    <td>
      <Input group={cGroup} groupKey="code"
      required
      />
    </td>
    <td>
      
          <span className="btn btn-light"
          onClick={e => this.removeCurrency(currency)}
          >
          Delete
          </span>
    </td>

  </tr> {/* end of row */}
  </>}
  </FormGroup>)
}

  </tbody>
</table>

</div>
  
}</FormGroup>
<button type="button" className="" onClick={this.addCurrency}>Add Currency</button>
<br/><br/>










            </>}

            </FormGroup>
            
            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>



{/* 
                
        <div>
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
            
        </div> */}
    </>;
    }

}