import { FormGroup, Input, ValidationSummary } from "hok-react";
import { OrderType, OrderTypeCustomStatuses } from "../models/entities/orders/order-type";
import { EditCustomStatusController } from "./EditCustomStatusesController";
import React from 'react'; 

export class EditCustomStatus extends EditCustomStatusController
{

    render()
    {

        if(!this.state.input)
        {
            return <div>Loading...</div>;
        }

        return <>
        <h1>{this.id?"Edit":"Create"}</h1>

        <h4>Custom Statuses</h4>
        <hr />
        <div className="row">
        <div className="col-md-4">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input} 
            onChange={e => this.setState({input:e.target.value})}
            childrenProps={{errors:this.state.errors}}
            >
            {g =><>
                


                <FormGroup<OrderTypeCustomStatuses[], OrderType> group={g} groupKey="customStatuses">
                    {
                        statusesG => 
                        <>
                        <table className="table">
                                <thead>
                                    <tr>
                                    <th>Addresses</th><th>SortOrder</th><th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    statusesG.value.map((a,idx) => 
                                    
                                    <FormGroup<OrderTypeCustomStatuses, OrderTypeCustomStatuses[]>
                                        key={idx}
                                     group={statusesG} groupKey={idx}>{
                                        statusG =>
                                    <tr>
        
                                        <td>
                                            <Input
                                            group={statusG} groupKey="value"
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="number"
                                                group={statusG} groupKey="sortOrder"
                                            />

                                        </td>
                                        <td>
        
        
                                        <button type="button" className="btn btn-secondary"
                                        
                                        onClick={()=>this.removeCustomStatus(idx)}
                                        >Remove</button>
        
        
                                        </td>
                                    </tr>
                                    }
                                    </FormGroup>
                                    )
        
                                }
                                </tbody>
                            </table>


                            
                <button type="button" className="btn btn-primary"
                onClick={this.addCustomStatus}
                >Add Custom Status</button>

                            </>

                    }
                </FormGroup>

            </>}

            </FormGroup>
            
            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>




                
        <div>
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
            
        </div>
    </>;
    }
}