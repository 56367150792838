
import { ValidationProblemDetails } from "hoksource";
import * as React from "react";
import { DirectoryComponent, ToastContextModel } from "../..";
import { Item } from "../../models/entities/items/item";
import { ItemMeasureUnit } from "../../models/entities/items/item-measure-unit";
import { ItemService } from "../ItemService";
interface MeasureUnitsEditProps{

    
        item:Item,
        toastContext:ToastContextModel
        onItemChange:(item:Item)=>void
    
}
interface MeasureUnitsEditState
{

    item?:Item;
    measureUnits?:ItemMeasureUnit[];
    errors?:{[key:string]:string[]};
    isSubmitting?:boolean;

}



export class MeasureUnitsEditController extends DirectoryComponent<
MeasureUnitsEditProps,MeasureUnitsEditState>{

    itemId:string;

      
      
    constructor(props)
    {
        super(props);
        this.state={};
    }



    initInput(item:Item, state:MeasureUnitsEditState = {})
    {
        
        state.item = item;
        state.measureUnits = item.measureUnits;
        
        

        return state;

    }

    

    componentDidMount() {

        
        var state:MeasureUnitsEditState = {
            item:this.props.item,

        };

        this.initInput(this.props.item,state);
        this.setState(state);
    }

    componentDidUpdate(prevProps:MeasureUnitsEditProps)
    {
        if(prevProps.item !== this.props.item)
        {

            this.componentDidMount();
        }
        
    }

    
  
    componentWillUnmount() {
    }
    

    add = ()=>{

        
        var newComponents:ItemMeasureUnit[] = [
            ...this.state.measureUnits,
            {code:""}
        ];

        this.setState({
            measureUnits:newComponents
        });

    };



    removeItem(val:ItemMeasureUnit)
    {

        var components = this.state.measureUnits;
        var index = components.indexOf(val);
        
        var newComponents:ItemMeasureUnit[] = [
            ...components.slice(0, index),
            ...components.slice(index+1)
        ];

        this.setState({
            measureUnits:newComponents
        });
    }

        
    get isAllowedSaving()
    {
        return !this.state.isSubmitting;
    }

    isValidQuantity(val:ItemMeasureUnit)
    {

        var quantity = parseFloat(val.baseUnitOfMeasureQuantity as any);

        
        return quantity > 0;
    }

    async handleSubmit(evt:React.FormEvent<HTMLFormElement>)
    {
        evt.preventDefault();

  
        var service = new ItemService(this.context);

        this.setState({
            errors:null,
            isSubmitting:true
        });
              
        try
        {


            var toSave:Item = {
                ...this.state.item,
                measureUnits:this.state.measureUnits
                    //  .filter(s => s.code)
            };
            var item = await service.saveItemMeasureUnits(toSave);


            this.props.onItemChange(item);
            // this.setState(this.initInput(item));


            this.props.toastContext.setToast({
                header:"Info",
                body:"Saved",
                durationMs:2000
              });
              

        }
        catch(e:any)
        {
            if(e.status === 400)
            {
                
                var err =  e as ValidationProblemDetails;
                this.setState({errors:err.errors});
            }else
            {

                console.log(e);
                alert("Something went wrong");
            }
        }
        this.setState({
            isSubmitting:false
        });
    }


}
