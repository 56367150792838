import { ISyntheticEvent } from "hok-react";
// eslint-disable-next-line
import React from "react";
import { OrderLine } from "../../../models/entities/orders/order-line";
import { checkCreateAssemblyOrderLine } from "./AssembleToOrderFunctions";
import { useEditOrder } from "../useEditOrder";
import { HandleBasicOrderLineChangeEvent } from "./BasicOrderLineInputController";
import { checkCreateChildLines } from "./ChildLinesFunctions";
import { OrderTypeNameIds } from "../../../models/enums/OrderTypeNamesIds";
import { DirectoryComponent } from "../../../directory-shared/DirectoryComponent";


interface OrderLineInputControllerProps
{
    

    editOrder:ReturnType<typeof useEditOrder>,
    
    value:OrderLine;
    onChange(evt:{
        target:{value:OrderLine},
        // preventDefault():void
    }):void;
    onDeleteClick(evt:ISyntheticEvent<{value:OrderLine}>):void;
    readOnly?:boolean;
}


export class OrderLineInputController extends DirectoryComponent<OrderLineInputControllerProps>
{


    get orderType()
    {
        return this.props.editOrder.orderType;
    }

    get orderTypeName()
    {
        return this.orderType.customModel.name;
    }
    
    // get requireUniqueItems()
    // {
    //     return this.orderType.customModel===OrderTypeNameIds.PhysicalInventoryOrder;
    // }


    dispatchOnChange(orderLine:OrderLine)
    {

        this.props.onChange({
            target:{value:orderLine},
            //preventDefault:()=>{}
        });
    }


    componentDidUpdate(prevProps:OrderLineInputControllerProps,
        prevState)
    {
        var prev = prevProps.value;
        var {value} = this.props;

        var itemChanged = prevProps.value.item?.id !== value.item?.id;

        var itemOrQuantityChanged = itemChanged || prev.quantity !== value.quantity;


        if(value.item && itemOrQuantityChanged)
        {
            this.props.editOrder.getItemAndQuantityData(value.item.id)
            .then(e => {

                var ctx = this.context;

                var nextValue = this.props.value;
                
                // only for sales orders
                if(this.orderTypeName 
                     === OrderTypeNameIds.SalesOrder)
                {
                    var entityItem = e?.entityItem;
                        
                    
                    if(
                        entityItem ||
                        (
                        !entityItem && ctx.isOffline))
                    {
                        
                        // only support assembly at top level as of the moment
                        nextValue = checkCreateAssemblyOrderLine(
                            e.entityItem, this.props.editOrder.order, nextValue, 1); 
                    }

                
                }



                if(nextValue !== value)
                {
                    // update if has changes
                    this.dispatchOnChange(nextValue);
                }
            });
        }
    }


    

    validateUniqueItem(evt:HandleBasicOrderLineChangeEvent)
    {
            
        var requireUniqueItems =this.orderType.customModel.name === OrderTypeNameIds.PhysicalInventoryOrder;

        var prev = this.props.value;
        var next = evt.target.value;
        var lines = this.props.editOrder.order.lines;
        var index = lines.indexOf(prev);
        if(requireUniqueItems)
        {
            for(let i of lines)
            {
                if(i !== prev && // different item
                    i.item?.id === next.item?.id // same id
                    )
                {
                    
                    //evt.preventDefault(); // dont allow duplicate items
                    
                    alert("Item entry already exists.");  
                    return false;

                }
            }
        }
        else
        {
            
                
            if(index === lines.length-1)
            {

                for(var i = 0; i< index; ++i)
                {
                    var ln = lines[i];
                    if(ln.item?.id === next.item.id)
                    {      

                        alert("Warning: Item entry already exists.");  
                        break;
                    
                    }
                }
            }
        }

        return true;
    }


    handleLineChange = (evt:HandleBasicOrderLineChangeEvent) =>
    {
        var next = evt.target.value;
        
        var itemChanged = this.props.value.item?.id !== next.item?.id;

        if(itemChanged)
        {
            if(!this.validateUniqueItem(evt))
            {
                evt.preventDefault();
                return;
            }

            if(this.orderTypeName === OrderTypeNameIds.SalesOrder)
            {
                // handle multiple child lines
                next = checkCreateChildLines(
                    this.props.editOrder, next.item, next
                );
            }
        }


        this.dispatchOnChange(next);
    }

    // below needs updates


    handleDeleteChildLineClick=(evt:ISyntheticEvent<{value:OrderLine}>)=>
    {
        var value = evt.target.value;
        if(//value.item && 
            
            window.confirm("Are you sure you want to remove this item?"))
        {
            var lines = this.props.value.childLines;
            var index = lines.indexOf(value);
            var nextLines = lines.slice();
            nextLines.splice(index,1);

            if(value.id)
            {
                // this.deleteLineIds.push(line.id);
            }

            var nextLine:OrderLine = {
                ...this.props.value,
                childLines:nextLines
            };

            
            
                
            this.dispatchOnChange(nextLine);

            // this.props.updateOrder({lines: nextLines});
        }
    }

    handleChildlineChange = (
        prev:OrderLine, evt:{
        target:{value:OrderLine};
        preventDefault():void;
    }) =>
    {

        var nextChild = evt.target.value;
        
        // var itemChanged = prev.item?.id !== nextChild.item?.id;

        var val = this.props.value;
        
        var prevChildLines = val.childLines;
        var index = prevChildLines.indexOf(prev);
        var nextLines = [...prevChildLines.slice(0,index), nextChild, ...prevChildLines.slice(index+1)];


        /*

        if(itemChanged)
        {
            
                
            if(index === prevChildLines.length-1)
            {
                // add to bottom
                nextLines.push(createNewLine(this.orderType));
            }
        }
        */


        this.dispatchOnChange({

            ...val,
            childLines:nextLines
        });

    }


}