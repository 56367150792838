import { ValidationProblemDetails } from "hoksource";
import React from "react";
import { DirectoryUserDetail } from "../models/directory-user-detail";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { DirectoryUserService } from "./DirectoryUserService";

export class MemberDetailsController 
extends DirectoryComponent<{},{

    userDetail:DirectoryUserDetail,
    addUserRoleInput?:string,
    isSubmitting?:boolean
}>
{

    userId:string;

    componentDidMount()
    {
        this.userId = this.getQueryParam("userId");


        var service = new DirectoryUserService(this.http);

        service.getUserDetail(this.userId)
        .then(e => {


            this.setState({userDetail:e});
        });
    }

    

    handleAddUserRoleSubmit = async(evt:React.FormEvent<HTMLFormElement>) =>{

        evt.preventDefault();

        this.setState({isSubmitting:true});
        
        var service = new DirectoryUserService(this.http);

        try
        {

            var res = await service.addUserRole({

                userId:this.userId,
                roleId:this.state.addUserRoleInput
            });

            if(res.succeeded)
            {
                var nextDetail = {...this.state.userDetail};
                nextDetail.userRoles = nextDetail.userRoles.slice();
                nextDetail.userRoles.push(this.state.addUserRoleInput);

                this.setState({

                    addUserRoleInput:"",
                    userDetail:nextDetail
                });
            }else{

                alert(res.errors[0].description);
            }
    
        }catch(err)
        {
            if(err.status === 400)
            {
                alert((err as ValidationProblemDetails).errors[""][0]);
            }
        }

        
        this.setState({isSubmitting:false});
        

    };
}