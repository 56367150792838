import { createOAuth2AuthorizationUri, OAuth2HttpClient } from "hoksource";
import { serviceBasePath } from "../ErichConfig";

export const oauth2RedirectUri = window.location.origin + "/signin-oauth2";


export var oauth2HttpClient = new OAuth2HttpClient({
    authorizationUri:"NOT USED YET",
    tokenEndPointUrl:serviceBasePath + "/Token",

    challenge:()=>{

        localStorage.removeItem("userTokens");

        window.location.href = createOAuth2AuthorizationUri({

            authorizationEndPoint : serviceBasePath + "/Authorize",
            responseType:"code",
            clientId:"NOT_USED_YET",
            redirectUri: oauth2RedirectUri
        })
    }
});


if(localStorage.userTokens)
{
    var tokens = JSON.parse(localStorage.userTokens) as {
        accessToken:string,
        refreshToken:string
    };
    
    oauth2HttpClient.accessToken = tokens.accessToken;
    oauth2HttpClient.refreshToken = tokens.refreshToken;
}