import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { Edit } from "./Edit";
import { Home } from "./Home";

export function CustomFieldsRoot()
{
    var match = useRouteMatch();
    return <>

        
        <Route exact path={`${match.path}/`} component={Home}/>
        <Route path={`${match.path}/edit`} component={Edit}/>
    </>;
}