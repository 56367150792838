import { HokEventTarget, IEventListener, PrimitiveFormUrlEncodedContent, ValidationProblemDetails } from "hoksource";
import { oauth2HttpClient } from "./oauth2HttpClient";

export const FailedToFetchMessage = "Failed to fetch";

export class HttpService extends HokEventTarget<HttpService>
{
    hasConnection = navigator.onLine;

    apiBasePath = "";
    pollRetryConnectionTimeoutId:NodeJS.Timeout;

    constructor()
    {
        super();
        window.addEventListener("online", ()=>{
            this.checkHasConnection();
        });
        window.addEventListener("offline", ()=>{
            this.checkHasConnection();
        });
    }
    
    get isOffline()
    {
        return !this.hasConnection || !navigator.onLine;
    }

    checkHasConnection()
    {
        if(navigator.onLine)
        {
            this.hasConnection = true;    
            
            if(this.pollRetryConnectionTimeoutId)
            {
                delete this.pollRetryConnectionTimeoutId;
            }
            this.dispatchEvent({type:"hasconnectionchange", target:this});
        }else
        {
            this.pollRetryConnection();
        }
    }

    
    pollRetryConnection()
    {
        this.pollRetryConnectionTimeoutId= setTimeout(()=>{
            this.checkHasConnection();

        }, 1*60*1000); // retry after 5 min
    }

    


    private async internalPost<T>(input:string, init?:{

        body?:any,
        hokQuery? : PrimitiveFormUrlEncodedContent
    })
    {
        try
        {
            var res = await oauth2HttpClient.ajax<T>(this.apiBasePath + input, init);

            if(!this.hasConnection)
            {

                this.checkHasConnection();

            }

            return res;
        }catch(res:any)
        {
            if(res.message === "Failed to fetch")
            {
                this.hasConnection = false;
                
                this.dispatchEvent({type:"hasconnectionchange", target:this});

                // start polling retry later
                this.pollRetryConnection();
            }

            if(res.status === 400)
            {
                
                var resData = await res.json() as ValidationProblemDetails;

                throw resData;   
            }

            throw res;
        }

    }

    async post<T>(input:string, init?:{

        body?:any,
        hokQuery? : PrimitiveFormUrlEncodedContent,

        onOnlineSuccess? : (res:T)=>Promise<T>|T|void,
        whenOffline? : ()=> T|PromiseLike<T>

    })
    {


        if(!init) init = {};
        var { onOnlineSuccess, whenOffline: ifOffline, ...internalPostInit } = init;

        
        return await this.internalPost<T>(input, internalPostInit);

        if(!ifOffline) // online only
        {
            return await this.internalPost<T>(input, internalPostInit);
        }

        if(!this.isOffline)
        {
            try
            {
                var data = await this.internalPost<T>(input, internalPostInit);

                // ifOnline

                if(onOnlineSuccess)
                {
                    onOnlineSuccess(data);
                }


                return data;
            }
            catch(e)
            {
                if(!this.isOffline)
                {
                    // save offline
                    
                    throw e;
                        
                }

            }
        }

        
        //if(httpService.isOffline)
        if(ifOffline)
        {
            var p = ifOffline();

            if(p instanceof Promise)
            {
                p = await p;
            }

            if(p)
            {
                return p;
            }
        }

        
        throw new TypeError(FailedToFetchMessage);
    }



    addEventListener(type:"hasconnectionchange", listener:IEventListener<HttpService>)
    {
        super.addEventListener(type, listener);
    }

    removeEventListener(type:"hasconnectionchange", listener:IEventListener<HttpService>)
    {
        super.removeEventListener(type, listener);
    }
}

export var httpService = new HttpService();