import { Item } from "../items/item";
import { Order } from "./order";


export const DefaultOrderLineBase = {
    // decimalField0:0

};
export interface OrderLine
{
    id?:string;
    orderId?:string;
    itemId?:string;
    quantity:number;
    unitOfMeasureCode?:string;
    unitPrice?:number;

    expirationTime?:string;

    sortOrder:number;


    parentOrderLineQuantity?:number;
    parentOrderLineId?:string;
    
    physicalQuantity?:number;

    // not yet used
    // decimalField0?:number;

    
    item?:Item;
    assemblyOrder?:Order;



    
    childLines?:OrderLine[];

}
