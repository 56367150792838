/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import DataSelectModal from './DataSelectModal';
import { MainModalContext } from './ModalContext';






  export function MainModalProvider(props)
  {


    // var [showModal, setShowModal] = useState(false);
    var [dataSelectConfig, setDataSelectConfig] = useState(null);
    var [showDataSelectModal, setShowDataSelectModal] = useState(null);


    // need to memoize to optimize



    return <MainModalContext.Provider value={{

        // setShowModal:setShowModal,
        showDataSelectModal:(

          config
        )=>{

          setDataSelectConfig(config);
          setShowDataSelectModal(true);
        },
        hideDataSelectModal:()=>{

          
          setDataSelectConfig(null);
          setShowDataSelectModal(false);
        }
    }}>
        {props.children}

        


        <DataSelectModal
          showModal={showDataSelectModal}
          configuration={dataSelectConfig}
          setShowModal={setShowDataSelectModal}
        />

    </MainModalContext.Provider>;


  }