import React from "react";
import { toLocaleDateString, getTagDisplayName } from "../functions/DataUtils";
import { OrderType } from "../models/entities/orders/order-type";
import { Order } from "../models/entities/orders/order";
import { OrderLine } from "../models/entities/orders/order-line";
import { OrderLinks } from "./OrderLinks";
import { OrderTypeNameIds } from "..";


function OrderLineComponent(props:{
    order:Order,
    orderLine:OrderLine,
    orderType:OrderType,
    parentOrderLine?:OrderLine
}
    )
{
    var parentLine = props.parentOrderLine;
    var line = props.orderLine;
    return <tr>
    <td>{line.item.tag}</td>
    <td>{line.item.name}</td>
    
    
    
    {props.orderType.customModel.name === OrderTypeNameIds.PhysicalInventoryOrder ?
    <>
    
    <td>{line.physicalQuantity}</td>
    {props.order.postingTime && 
    <>
    
    <td>{line.physicalQuantity+line.quantity}</td>
    <td>{-line.quantity}</td>
    </>
    }
    </>
    
    :
    
    <td>{
        (parentLine?.quantity ?? 1) *
        line.quantity}</td>
    }
    
    {props.orderType.withPrice &&
    <>
    
    <td>
        {line.unitPrice != null ?
        <>
    {/*     
            {item.erpItem.unitPrice != null && 
            item.unitPrice !== item.erpItem.unitPrice &&
            <>
                (
                <span style={{textDecoration:'line-through'}}>
                    {item.erpItem.unitPrice.toFixed(2)} 
                </span>)
                &nbsp;
            </>}
             */}
            {line.unitPrice.toFixed(2)}
        </> : 
        '0.00'}
    </td>
    <td>{((parentLine?.quantity ?? 1) *line.unitPrice * line.quantity)?.toFixed(2)}</td>
    
    </>}
    </tr>;
}

export function OrderDetailsComponent(
    props:{
        order:Order,
        orderType:OrderType
    }
)
{
    
var {order, orderType} = props;
if(!order)
{
    return <div>loading...</div>;
}

var typeName = orderType.customModel.name;
// var so = props.salesTransaction;
// var isSale = so;
return (<>


<h3>{orderType.customModel.displayName}</h3>


<div className="view-transfer-container">

<div className="row">
<div className="col-md-8 row">
    <div className="col-4">
        ID
    </div>
    <div className="col-8">
        {order.id}
    </div>
    {/* {so?.customerPurchaseOrderReferenceId &&
    <>
    
    <div className="col-md-4">
        Customer PO RefId
    </div>
    <div className="col-md-8">
        {so?.customerPurchaseOrderReferenceId}
    </div>
    </>
    } */}
</div>

<div className="col-md-4 row">

    <div className="col-4 col-md-6">
        ReferenceId
    </div>
    <div className="col-8 col-md-6">
        {order.tag}
    </div>
    <div className="col-4 -md-6">
        Date
    </div>
    <div className="col-8 col-md-6">
        {toLocaleDateString(order.inputTime)}
    </div>
</div>


<div className="col-md-8 row">

    <div className="col-4">
    <span>Source Location:</span>
    </div>
    <div className="col-8">
        {order.fromEntity&&getTagDisplayName(order.fromEntity)}
    </div>

    {order.toEntity &&
    <>
        {/* {isSale&&
        <>
            <div className="col-md-4">
            <span>{this.props.transferTypeModule.name} to:</span>
            </div>
            <div className="col-md-8">
                {transfer.toErpEntity.name}
            </div>
        </>} */}
        <div className="col-4">
            <span>{props.orderType.customModel.displayName} to:</span>
        </div>
        <div className="col-8">
            {order.toEntity.name}
        </div>

        
        {order.deliveryAddress?.fullAddress &&
        <>
        <div className="col-4">
            <span>Delivery Address:</span>
        </div>
        <div className="col-8">
            {order.deliveryAddress?.fullAddress}
        </div>
        </>
        }
{/* 
        <div className="col-md-4">
            To Address:
        </div>
        <div className="col-md-8">
            {order.toErpEntity.address}
        </div> */}
    </>}
</div>
{/* 
{isSale &&
<div className="col-md-4 row">
    <div className="col-md-6">Sales Agent:</div>
    <div className="col-md-6">{so.salesAgent?.name}</div>

    <div className="col-md-6">Terms:</div>
    <div className="col-md-6">{so.paymentTermsConfiguration?.name}</div>
</div>}
     */}
</div>


</div>{/* view-transfer-container */}

<br/>

<OrderLinks 
orderType={props.orderType} 
id={order.id}
showBackButton={true}
/>
<table className="table transfer-items-table">

<thead>
<tr>
<th>Tag</th>
<th>Name</th>

{typeName === OrderTypeNameIds.PhysicalInventoryOrder ?
<>
<th>Phy. Qty</th>
{order.postingTime && 
<>

<th>Prev Qty</th>
<th>Qty Added</th>
</>
}
</>

:

<th>Qty</th>
}
{props.orderType.withPrice &&
<>

<th>UnitPrice</th>
<th>Subtotal</th>
</>}
</tr>
</thead>
<tbody>

{order.lines.map(line => 
<React.Fragment key={line.id}>

    <OrderLineComponent order={order} orderLine={line} orderType={orderType}/>

{line.childLines.map(childLine => 
    
    
    <OrderLineComponent order={order} orderLine={childLine} orderType={orderType}
    parentOrderLine={line}
    />
    )}
</React.Fragment>
)}


{props.orderType.withPrice &&

<tr>
<td></td>
<td></td>
<td></td>
<td>Total</td>
<td><b>{(order.totalOrderAmount)?.toFixed(2)}</b></td>
</tr>
}

</tbody>

</table>


</>);
}