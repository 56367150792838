import { ISyntheticEvent } from "hok-react";
import React from "react";
import { getNextSortOrder } from "../../../functions/getNextSortOrder";
import { isNegative } from "../../../functions/validations";
import { DefaultOrderLineBase, OrderLine } from "../../../models/entities/orders/order-line";
import { useEditOrder } from "../useEditOrder";


export interface HandleBasicOrderLineChangeEvent
{

    target:{value:OrderLine, name?:string},
    preventDefault?():void

}

export class BasicOrderLineInputController extends React.Component<{
    editOrder:ReturnType<typeof useEditOrder>,
    
    value:OrderLine;
    parentLineValue?:OrderLine;
    onChange(evt:{
        target:{value:OrderLine},
        preventDefault():void
    }):void;
    // onAddChildLineClick(evt:ISyntheticEvent<{value:OrderLine}>):void;
    onDeleteClick(evt:ISyntheticEvent<{value:OrderLine}>):void;
    readOnly?:boolean;
    required?:boolean;
}>
{
    constructor(props)
    {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    
    get orderType()
    {
        return this.props.editOrder.orderType;
    }
    
    ifNotEnoughStock(line:OrderLine)
    {
        if(!line.id && 
        line.item && line.quantity)
        {
            var entityItem = this.props.editOrder.itemIdFromEntityItemMap[line.item.id];
            if(entityItem?.availableQuantity < line.quantity)
            {
                return false;
            }
        }

        return true;
    }


    get unitPriceCustomValidity()
    {
        var value = this.props.value;
        if(this.orderType.withPrice && value.item)
        {
            if(isNegative(value.unitPrice))
            {
                return "Invalid unit price.";
            }
        }
        return "";
    }

    

    handleLineChange(evt:HandleBasicOrderLineChangeEvent)
    {

        // var prev = this.props.value;
        var next = evt.target.value;
        
        var itemChanged = this.props.value.item?.id !== next.item?.id;

        if(itemChanged && !next.item)
        { 
            evt.preventDefault();
            // evt.target.reset();
            return;
        }// do nothing if no item


        if(itemChanged)
        {
            next.unitOfMeasureCode = next.item?.baseUnitOfMeasureCode ?? "";
        }

        var unitOfMeasureChanged = 
            this.props.value.unitOfMeasureCode !== next.unitOfMeasureCode;

        if(itemChanged || unitOfMeasureChanged)
        {
            
            next.unitPrice = this.props.editOrder.computeLineUnitPrice(
                next.item, next.unitOfMeasureCode
            );
        }


        this.props.onChange({
            target:{value:next},
            preventDefault:()=>{

                evt.preventDefault && evt.preventDefault();

            }
        });
    }
    
    handleChange<T>(evt:{
        target:{value:T, name?:string},
        preventDefault?():void
    }){

        var nextLine={
            ...this.props.value,
            [evt.target.name]:evt.target.value
        };

        this.handleLineChange({
            target:{value:nextLine},
            preventDefault:evt.preventDefault
        })
    };

    handleDeleteClick = ()=>{

        if(this.props.onDeleteClick)
        {

            this.props.onDeleteClick(
                {target:{value:this.props.value}}
                );
        }
    };


    handleAddChild = ()=>{

        var orderType = this.orderType;

        var prevChildLines = this.props.value.childLines;

        var nextLine:OrderLine = {
            ...this.props.value,
            childLines:[...prevChildLines, 
                {
                    
                    ...DefaultOrderLineBase,
                    quantity:orderType.negativeLineQuantities?-1:1,
                    sortOrder:getNextSortOrder(prevChildLines)
                }// as OrderLine
            ]
        };


        
        this.handleLineChange({
            target:{value:nextLine}
        });
    };
}