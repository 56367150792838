import { DateTimeOffsetInput, extendFormGroupProps, FormGroup, FormGroupOrValueProps, Input,  ISyntheticEvent } from "hok-react";
import React from "react";
import { CustomFieldOptionsSelect } from "../components/CustomFieldOptionsSelect";
import { EntitySelect } from "../entities/EntitySelect";
import { Entity } from "../models/entities/entities/entity";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { JournalEntryTypeInputOption, JournalType } from "../models/entities/journals/journal-type";



interface JournalEntryInputProps
<
G = never>


extends FormGroupOrValueProps<JournalEntry, G>
{
    
    entryTypeInputOptionsMap:{[key:string]:JournalEntryTypeInputOption};
    journalType:JournalType;


    onRemoveClick?(evt:ISyntheticEvent<{value:JournalEntry}>):void;
    autoFocusAmount?:boolean;
    
}

export function JournalEntryInput
<
G = never>
(props:JournalEntryInputProps< G>)
{


    props = extendFormGroupProps
    <JournalEntry, G, JournalEntryInputProps< G>>(props);



    var {journalType, 
        entryTypeInputOptionsMap,
        value
    } = props;


    function getAmountLabelError()
    {
        var entryTypeOption = entryTypeInputOptionsMap[value.entryType];
        var sign = entryTypeOption?.amountSign;
        if(value.amount as any === "")
        {
            // ignore

        }else if(!isNaN(value.amount))
        {
            if(sign > 0 && value.amount <= 0)
            {
                return "Amount should be positive.";
            }else if(sign < 0 && value.amount >= 0)
            {
                return "Amount should be negative.";
            }
        }else
        {
            
        }

        return "";
    }


    var amountLabelError = getAmountLabelError();

    var customValidity = amountLabelError;
    if(!customValidity)
    {

        if(value.amount && isNaN(value.amount))
        {
            customValidity = "Invalid amount.";
        }
    }


    const handleToEntityChange = (evt:ISyntheticEvent<{value:Entity}>)=>{


        var next = {...props.value,
            toEntity: evt.target.value,
            currencyCode: evt.target.value.balanceDetail.currencyCode
        } as JournalEntry;

        props.onChange({target:{value:next}});
    };

    return <FormGroup<JournalEntry, any>
            value={props.value} onChange={props.onChange}
        >
        {entryGroup => 
        <tr>
        <td><EntitySelect 
            group={entryGroup}
            groupKey="fromEntity"
            entityTypeNameId="Location"
            style={{"width":"6em"}}
            required={Boolean(value.amount)}
        /></td>
        <td>
            <EntitySelect 
            
            value={props.value.toEntity}
            onChange={handleToEntityChange}
            entityTypeNameId={journalType.toEntityTypeName}
                style={{"width":"12em"}}
                required={Boolean(value.amount)}
        /></td>
        
        <td>
        <CustomFieldOptionsSelect
        
            group={entryGroup}
            groupKey="entryType"
            options={journalType.entryTypeInputOptions}
            required={Boolean(value.amount)}

        />
        </td>
        <td>
            <Input type="number" 
        
        group={entryGroup}
        groupKey="amount"
        style={{"width":"7em"}}
        customValidity={customValidity}
        autoFocus={props.autoFocusAmount}
        />
        {amountLabelError && 
        
            <span className="text-danger">{amountLabelError}</span>
        }
        </td>
        <td>{props.value.currencyCode}</td>
        <td>
        <CustomFieldOptionsSelect
            group={entryGroup}
            groupKey="entryMode"
            style={{"width":"8em"}}
            options={journalType.entryModeInputOptions}
            required={Boolean(value.amount)}

            

        />
        </td>

        <td><DateTimeOffsetInput type="date" 
        
        group={entryGroup}
        groupKey="inputTime"
        required={Boolean(value.amount)}
        preventDefaultOnEnter
        /></td>
        <td><DateTimeOffsetInput type="date" 
        
        group={entryGroup}
        groupKey="dueTime"
        preventDefaultOnEnter
        /></td>

<td><Input 
        
        group={entryGroup}
        groupKey="tag"
        style={{"width":"10em"}}
        /></td>

        <td><Input 
        
        group={entryGroup}
        groupKey="externalDocumentId"
        style={{"width":"10em"}}/></td>

        <td><Input 
        type="textarea"
        group={entryGroup}
        groupKey="note"
        style={{"width":"20em"}}/></td>

        <td>
                    
            
        {value.amount && props.onRemoveClick &&
            <span className="cursor-pointer btn-link"
                onClick={()=>props.onRemoveClick({target:{value}})}>
                Remove
            </span>
        }
        </td>
        </tr>
        

        
        }
        </FormGroup>;
}