import { ValidationProblemDetails } from "hoksource";
import { CustomModelField } from "../models/entities/custom-field";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EditStateBase } from "../types/EditStateBase";
import { CustomFieldService } from "./CustomFieldService";

export class EditController extends DirectoryComponent<{},
EditStateBase<CustomModelField>>
{

    id:string;

    constructor(props)
    {
        super(props);

        this.state = {

            input:{
                modelType:"",
                active:true
            }
        };
    }


    componentDidMount()
    {
        this.id = this.getQueryParam("id");

        if(this.id)
        {
            var service = new CustomFieldService(this.http);
            service.get(this.id).then(e => this.setState({
                input:e
            }));
        }
    }



    
    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>
    {

        
        evt.preventDefault();

        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
                
            var service = new CustomFieldService(this.http);

            await service.save(this.state.input);

            this.history.goBack();

        }catch(err)
        {


            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }


        this.setState({isSubmitting:false});
    }




    
}