import { ISyntheticEvent } from "hok-react";
import { ValidationProblemDetails } from "hoksource";
import { hokDebounce } from "../functions/hokDebounce";
import { Entity } from "../models/entities/entities/entity";
import { JournalType } from "../models/entities/journals/journal-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { JournalService } from "./JournalService";



interface StateType
{
        beginInputTime:string;
        endInputTime:string;
        toEntity?:Entity;
        fromEntity?:Entity;
        entryType?:string;
        entryMode?:string;

        showOtherFilters?:boolean;
    
    
        list?:{
            fromEntity:Entity;
            entryType:string,
            entryMode:string,
            totalAmount:number;
            currencyCode:string;
        }[];
    
        errors?:{[key:string]:string[]};
    
}
export class JournalTotalAmountsController 
extends DirectoryComponent<{
    journalType:JournalType,
}, StateType>
{

    constructor(props)
    {
        super(props);
        var now = new Date();
        var start = new Date(now.getFullYear(), now.getMonth(), 1);
        var nextMonth = new Date(start.getTime());
        nextMonth.setMonth(nextMonth.getMonth()+1);
        nextMonth.setDate(0);

        this.state = {
            beginInputTime : start.hokToLocaleDateTimeOffsetString(),
            endInputTime : nextMonth.hokToLocaleDateTimeOffsetString()
        };
    }



    componentDidMount()
    {
        this.debounceLoad();
    }


    async getList()
    {
        
        
        if(!(this.state.beginInputTime && this.state.endInputTime))
        {
            return;

        }
        var service = new JournalService(this.context);

        var endInputTimeExclusive = new Date(this.state.endInputTime);
        endInputTimeExclusive.setDate(endInputTimeExclusive.getDate()+1);


        try{
            
            var res = await service.getFromEntityIdEntryTypeEntryModeTotalAmounts({
                journalType:this.props.journalType.customModel.name,
                beginInputTime: this.state.beginInputTime,
                endInputTimeExclusive:endInputTimeExclusive.hokToLocaleDateTimeOffsetString(),
                fromEntityId:this.state.fromEntity?.id,
                toEntityId:this.state.toEntity?.id,
                entryType:this.state.entryType,
                entryMode:this.state.entryMode 
            });

            this.setState({list:res});
            
        } catch(err)
        {
            if(err === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }

        }
    }

    debounceLoad()
    {
        hokDebounce(()=>this.getList());
    }

    get totalAmount()
    {
        var sum = 0;
        var list = this.state.list;
        for(let i  of list)
        {

            sum += i.totalAmount;
        }

        return sum;
    }

    handleChange = (evt:ISyntheticEvent<{value:any, name?:string}>) =>{
        console.log(evt);
        this.setState({[evt.target.name]:evt.target.value} as any, ()=>{

            this.debounceLoad();
        });
    };



}