import React from "react";
import { useContext } from "react";
import { EDataSelectListConfiguration } from "../components/EDataSelectList";
import { DirectoryContext } from "../directory-shared/DirectoryContext";
import { getTagDisplayName, inventoryEntityEquals } from "../functions/DataUtils";
import { ItemCategory } from "../models/entities/items/item-category";
import { ItemCategoryService } from "./ItemCategoryService";
import { DataSelectInput, DataSelectInputPropsBase } from "hok-react";
import { EDataSelectWrapper } from "../components/DataSelectWrapper";

export function ItemCategorySelect
<G = never>
(props: DataSelectInputPropsBase<ItemCategory, G> &
    {
className?:string;
style?:React.CSSProperties;

})
{
    var oContext = useContext(DirectoryContext);
    var takeCount = 10;

    
    var config:EDataSelectListConfiguration<ItemCategory> = {

        takeCount: takeCount,
        getDisplayName:getTagDisplayName,
        equals:inventoryEntityEquals,
        getItems: (filter) =>{

                    
            var itemService = new ItemCategoryService(oContext.http);

            var q = {

                skip:filter.currentIndex,
                take:takeCount,
                searchString: filter.searchString,
            };

            // filter.take = takeCount;
            return itemService.getList(q);
        }  
    };



    return <EDataSelectWrapper<ItemCategory, G> 
                group={props.group}
                groupKey={props.groupKey}
                value={props.value}
                onChange={props.onChange}
                name={props.name}
                readOnly={props.readOnly}
                required={props.required}

                
                
                className={props.className}
                style={props.style}
                configuration={config}
            />;
}