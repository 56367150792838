import React from "react";
import { CustomModelField } from "../models/entities/custom-field";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { CustomFieldService } from "./CustomFieldService";

export class Home extends DirectoryComponent<{

    modelType:string,
    basePath:string,
},{
    list:CustomModelField[]
}>
{

    

    componentDidMount()
    {

        var service = new CustomFieldService(this.http);

        service.getList().then(list => {

            this.setState({list});
        });
        
    }



    render()
    {

        
        return <>
            
        <h1>{this.props.modelType} Custom Fields</h1>
        <p>
            <DirectoryLink to={`/custom-fields/edit`}>Create New</DirectoryLink>
        </p>



        
<table className="table">
<thead>
    <tr>
        <th>Model Type</th>
        <th>
            Name
        </th>
        <th></th>
    </tr>
</thead>
<tbody>
    {this.state?.list?.map((item,idx) =>
    <tr key={item.id}>
        <td>{item.modelType}</td>
        <td>{item.name}</td>
        <td>


        <DirectoryLink to={
            `/custom-fields/edit?id=${item.id}`}>Edit</DirectoryLink>


        </td>
    </tr>)}
</tbody>
</table>

        </>;


    }
}