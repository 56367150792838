import { StreamingArrayLoaderGetItemsRequest } from "hoksource";
import { Entity } from "../models/entities/entities/entity";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EntityGroup } from "../entity-groups/entity-group";
import { Item } from "../models/entities/items/item";
import { EntityItemService } from "./EntityItemService";
import { EntityItem } from "../models/entities/entities/entity-item";
import { stringOrNullIfNumber } from "../functions/stringOrNullIfNumber";

export class InventoryCountsHomeController extends DirectoryComponent<any,{


    entityGroup?:EntityGroup,
    entity?:Entity,
    item?:Item,
    itemDefaultVendorEntity?:Entity,
    lowInventoryOnly?:boolean
}>
{


    constructor(props)
    {
        super(props);
        this.state = {

        };
    }
    

    takeCount = 30;

    
    getItems = (filter:
        StreamingArrayLoaderGetItemsRequest<EntityItem>
     )=>{

        var service = new EntityItemService(this.http);
        return service.getList({
            skip : filter.currentIndex,
            take : this.takeCount,

            
            entityGroupId:this.state.entityGroup?.id,
            entityId: stringOrNullIfNumber(this.state.entity?.id),
            itemId:this.state.item?.id,
            itemDefaultVendorEntityId:this.state.itemDefaultVendorEntity?.id,
            lowInventoryOnly:this.state.lowInventoryOnly
        });
    }


}