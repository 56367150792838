import { StreamingArrayLoaderGetItemsRequest } from "hoksource";
import { Entity } from "../models/entities/entities/entity";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { Item } from "../models/entities/items/item";
import { EntityItemLedgerEntryService } from "./EntityItemLedgerEntryService";
import { ItemLedgerEntry } from "../models/entities/entities/item-ledger-entry";
import { stringOrNullIfNumber } from "../functions/stringOrNullIfNumber";
import { ReservationEntry } from "../models/entities/entities/reservation-entry";

export class ReservationEntriesHomeController extends DirectoryComponent<any,{

    // entityGroup?:EntityGroup,
    orderType?:string;
    entity?:Entity,
    item?:Item,
    // lowInventoryOnly?:boolean
}>
{


    constructor(props)
    {
        super(props);
        this.state = {

        };
    }
    

    takeCount = 30;

    
    getItems = (filter:
        StreamingArrayLoaderGetItemsRequest<ReservationEntry>
     )=>{

        var service = new EntityItemLedgerEntryService(this.http);
        return service.getReservationEntries({
            skip : filter.currentIndex,
            take : this.takeCount,


            entityId:stringOrNullIfNumber(this.state.entity?.id),
            itemId:this.state.item?.id,
            orderType: this.state.orderType
        });
    }


}