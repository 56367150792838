import { InfiniteScroll, Input } from "hok-react";
import { EntitySelect } from "../entities/EntitySelect";
import { EntityGroupSelect } from "../entity-groups/EntityGroupSelect";
import { ItemSelect } from "../items/ItemSelect";
import { EntityTypeNameIds } from "../models/enums/EntityTypeNameIds";
import { InventoryCountsHomeController } from "./InventoryCountsHomeController";
import React from 'react';
import { ErichRoles } from "../directory-shared/roles";


export class InventoryCountsHome extends InventoryCountsHomeController
{

    render()
    {

        
    return <>

    <h1>Inventory Counts</h1>

    <div className="row">

        <div className="col-md-3 form-group">
            <label>Location Group</label>
            <EntityGroupSelect value={this.state.entityGroup} onChange={e => this.setState({
                entityGroup:e.target.value
            })}
            className="form-control"
            />
        </div>
        
        <div className="col-md-3 form-group">
            <label>Location</label>
            <EntitySelect entityTypeNameId={EntityTypeNameIds.Location}
                value={this.state.entity}
                onChange={e => this.setState({
                    entity:e.target.value
                })}
                className="form-control"
            />
        </div>
        
        <div className="col-md-3 form-group">
            <label>Item</label>
            <ItemSelect
                value={this.state.item}
                onChange={e=>this.setState({
                    item:e.target.value
                })}
                className="form-control"
            />
        </div>

        {this.context.directoryService.checkUserRoles(ErichRoles.Administrator +
        "," + EntityTypeNameIds.Vendor
        ) &&
        <div className="col-md-3 form-group">
            <label>Default Vendor</label>
            <EntitySelect
                value={this.state.itemDefaultVendorEntity}
                onChange={e=>this.setState({
                    itemDefaultVendorEntity:e.target.value
                })}
                entityTypeNameId={EntityTypeNameIds.Vendor}
                className="form-control"
            />
        </div>
        }
        
        <div className="col-md-3">
            <label>
            <Input type="checkbox"
            value={this.state.lowInventoryOnly}
            onChange={e => this.setState({

                lowInventoryOnly:e.target.checked
            })}
            />
                Low Inventory Only
            </label>
        </div>
    </div>

<table className="table">
    <thead>
        <tr>
            <th>
                Entity
            </th>
            <th>
                Item
            </th>
            <th>Quantity on Hand</th>
            <th>Reserved Quantity</th>
            <th>
                Available Quantity
            </th>
        </tr>
    </thead>
    <tbody>

    <InfiniteScroll
    targetElement={window}
    maxTakeCount={this.takeCount}
    getItems={this.getItems}
    resetDependencyList={[
        this.state.entity,
        this.state.entityGroup,
        this.state.item,
        this.state.itemDefaultVendorEntity,
        this.state.lowInventoryOnly
    ]}
    resetDebounceDelay={400}
    >{infiniteScroll => 
    
    infiniteScroll.loadedItems?.map(e => 
        (

            
            <tr key={e.entity.id + ":"+e.item.id}>
        <td>
            {e.entity.displayName}
        </td>
        <td>
            {e.item.displayName}
        </td>
        
        
        <td className="text-center">{e.totalQuantity}</td>
        <td className="text-center">{e.reservedQuantity ? e.reservedQuantity : ""}</td>
        <td className="text-center">
            {e.availableQuantity}
        </td>
    </tr>
        )
    )
    
    }


    </InfiniteScroll>

    </tbody>
</table>
</>;


    }
    
}