import React from "react";
import { getTagDisplayName } from "../functions/DataUtils";
import { DetailsController } from "./DetailsController";
import { JournalEntryTypeLink } from "./JournalEntryTypeLink";

export class Details extends DetailsController
{

    render()
    {
        var {journalType} = this.props;
        var {journalEntry, //journalBalancingEntries, 
            
            processedJournalBalancingEntries} = this.state;
        if(!journalEntry)
        {
            return <div>Loading...</div>;
        }
        return <>
        
        
<h1>Details</h1>

<div>
    <h4>Journal Entry</h4>
    <hr />
    <dl className="row">
        <dt className="col-sm-2">
            id
        </dt>
        <dd className="col-sm-10">
            {journalEntry.id}
        </dd>
        <dt className="col-sm-2">
            journal type
        </dt>
        <dd className="col-sm-10">
            {journalEntry.journalTypeName}
        </dd>
        
        <dt className="col-sm-2">
            Location
        </dt>
        <dd className="col-sm-10">
            {getTagDisplayName(journalEntry.fromEntity)}
        </dd>
        
        <dt className="col-sm-2">
            {journalType.toEntityTypeName}
        </dt>
        <dd className="col-sm-10">
            {getTagDisplayName(journalEntry.toEntity)}
        </dd>


        <dt className="col-sm-2">
            entry type
        </dt>
        <dd className="col-sm-10">
            <JournalEntryTypeLink journalEntry={journalEntry}/>
        </dd>

        <dt className="col-sm-2">
            amount
        </dt>
        <dd className="col-sm-10">
            {journalEntry.amount.toFixed(2)}
        </dd>

        
        
        <dt className="col-sm-2">
            balance
        </dt>
        <dd className="col-sm-10">
            {journalEntry.balance}
        </dd>

        
        

        <dt className="col-sm-2">
            inputId
        </dt>
        <dd className="col-sm-10">
            {journalEntry.tag}
        </dd>

        <dt className="col-sm-2">
            entry mode
        </dt>
        <dd className="col-sm-10">
            {journalEntry.entryMode}
        </dd>
        
        <dt className="col-sm-2">
            input time
        </dt>
        <dd className="col-sm-10">
            {journalEntry.inputTime}
        </dd>

        
        <dt className="col-sm-2">
            due time
        </dt>
        <dd className="col-sm-10">
            {journalEntry.dueTime}
        </dd>

        
        <dt className="col-sm-2">
            note
        </dt>
        <dd className="col-sm-10">
            {journalEntry.note}
        </dd>

        
        <dt className="col-sm-2">
            time created
        </dt>
        <dd className="col-sm-10">
            {journalEntry.creationTime}
        </dd>

        
        
        <dt className="col-sm-2">
            time modified
        </dt>
        <dd className="col-sm-10">
            {journalEntry.lastModifiedTime}
        </dd>

        
        <dt className="col-sm-2">
            time applied
        </dt>
        <dd className="col-sm-10">
            {journalEntry.appliedTime}
        </dd>

        
    </dl>
</div>
<div>
    {/* <a asp-page="./Edit" asp-route-id="@Model.AppPage.Id">Edit</a> | */}
    {!journalEntry.orderId &&
    !journalEntry.isVoided &&
    <>

    
<span className="btn btn-link cursor-pointer" onClick={this.handleVoidClick}>Void Journal Entry</span>

&nbsp;|&nbsp;
    </>}

    <span className="btn btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
</div>

<br/><br/>
    
<div>
    <h5>Balancing Entries</h5>
    <table className="table">
        <thead>
            <tr>
                {/* <th>journal entry id</th> */}
                <th>loc</th>
                <th>Entry Type</th>
                <th>ref id</th>
                <th>entry mode</th>
                <th>input time</th>
                <th>due time</th>
                <th>amount applied</th>
                <th>balance after applying</th>
                <th>note</th>
            </tr>
        </thead>
        <tbody>
            {processedJournalBalancingEntries?.map((e,idx) => 
            
            {
                var other = e.otherJournalEntry;
            return <tr key={e.id}>
                {/* <td>{other.id}</td> */}

                <td>
                    {getTagDisplayName(other.fromEntity)}
                </td>
                
                <td>
            <JournalEntryTypeLink journalEntry={other}/></td>
                <td>{other.tag}</td>

                
                <td>{other.entryMode}</td>
                
                <td>{other.inputTime.substr(0, 10)}</td>
                <td>{other.dueTime?.substr(0, 10)}</td>
                <td>
                    {e.amountApplied}
                </td>
                <td>{e.balanceAfterApplying}</td>
                <td>{other.note}</td>


            </tr>
            })}
        </tbody>
    </table>
</div>

        
        </>;
    }

}