import { CustomFieldsInput, FormGroup, FormInput, ValidationSummary } from "hok-react";
import React from "react";
import { ModelTypeBase } from "../models/entities/model-type-base";
import { EditController } from "./EditController";

export class ModelTypeEdit<T extends ModelTypeBase> extends EditController<T>
{

    render()
    {

        return <>
        <h1>{this.id?"Edit":"Create"}</h1>

        <h4>{this.props.modelType.customModel.displayName}</h4>
        <hr />
        <div className="row">
        <div className="col-md-4">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input} 
            onChange={e => this.setState({input:e.target.value})}
            childrenProps={{errors:this.state.errors}}
            >
            {g =><>
                <FormGroup group={g} groupKey="customModel">
                    {cmg => <>

                            <FormInput
                        group={cmg}
                        groupKey="name"
                        name="Name"
                        labelText="Name"
                        readOnly={Boolean(this.id)}
                    />

                    <FormInput
                        group={cmg}
                        groupKey="displayName"
                        name="DisplayName"
                        labelText="Display Name"
                    />

                    <FormInput
                        group={cmg}
                        groupKey="collectionDisplayName"
                        name="CollectionDisplayName"
                        labelText="Collection Display Name"
                    />


                    
                    </>}
                </FormGroup>
                
                                
                <CustomFieldsInput
                    group={g}
                    fields={this.props.modelType.customModel.fields}
                />
            </>}

            </FormGroup>

            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>




                
        <div>



                
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
        </div>
    </>;
    }


}