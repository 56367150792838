import { ValidationProblemDetails } from "hoksource";
import { JournalApplicationEntry } from "../models/entities/journals/journal-application-entry";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { JournalType } from "../models/entities/journals/journal-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { JournalService } from "./JournalService";
import { SyncEntryUpdateEvent } from "../db/SyncService";
import { SyncEventType } from "../db/SyncEventType";


export interface ProcessedJournalBalancingEntries
{
    id:string;
    otherJournalEntry :JournalEntry;
    amountApplied: number;
    balanceAfterApplying: number;
}

export class DetailsController extends DirectoryComponent<{
    journalType:JournalType
},{
    
    journalEntry?:JournalEntry,
    journalBalancingEntries?:JournalApplicationEntry[],
    processedJournalBalancingEntries? : ProcessedJournalBalancingEntries[]

}>
{
    id:string;

    constructor(props)
    {
        super(props);
        this.state= {};
    }

    
    updateId(id:string)
    {
        this.id = id;
        this.history.replace(
`/${this.props.journalType.customModel.name}/details?id=${encodeURIComponent(id)}`);

    }

    
    handleSyncEntryUpdate = (evt:SyncEntryUpdateEvent<JournalEntry>) =>{
        console.log(evt, this.id);
        if(evt.entryType === "JournalEntry" && 
            evt.prevValue.id.toString() === this.id)
        {
            this.updateId(evt.nextValue.id);
        }
    };

    


    componentDidMount()
    {
        this.id = this.getQueryParam("id");

        var service = new JournalService(this.context);

        service.getJournalEntryWithDetails(this.id)
        .then(e => {
            var processedEntries:ProcessedJournalBalancingEntries[] = [];

            var currentBalance = e.journalEntry.balance;
            for(var i of e.journalBalancingEntries)
            {
                var processed: ProcessedJournalBalancingEntries;

                if(i.positiveJournalEntry.id === e.journalEntry.id)
                {
                    processed = {
                        id:i.id,
                        otherJournalEntry : i.negativeJournalEntry,
                        amountApplied : -i.amount,
                        balanceAfterApplying : currentBalance
                    };
                }
                else
                {

                    processed = {
                        id:i.id,
                        otherJournalEntry : i.positiveJournalEntry,
                        amountApplied : i.amount,
                        balanceAfterApplying : currentBalance
                    };
                }

                currentBalance -= processed.amountApplied;
                processedEntries.push(processed);
            }

            this.setState({...e,
            processedJournalBalancingEntries:processedEntries
            });
        }).catch(err => {
            console.log(err);
            alert("Something went wrong")
        });
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);

        
    }

    
    componentWillUnmount()
    {
        // remove id change listener
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);
    
    }


    handleVoidClick = async ()=>{


        if(!window.confirm("Confirm void journal entry"))
        {
            return;
        }


        var service = new JournalService(this.context);

        try
        {

            await service.doVoid(this.id);
            // succeeded

            this.goBack();
            // this.history.push(`/${journalType.customModel.name}/`);
        }catch(err:any)
        {
            if(err.status === 400)
            {
                alert((err as ValidationProblemDetails).errors[""][0]);
            }
        }


    };




}