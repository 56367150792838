import { InfiniteScroll } from "hok-react";
import React from "react";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { HomeController } from "./HomeController";

export class Home extends HomeController
{

    

    render()
    {

        
        var type = this.props.entityType;
    return <>

    <h1>{type.customModel.collectionDisplayName}</h1>
    <p>
        <DirectoryLink to={`/${type.customModel.name}/edit`}>Create New</DirectoryLink>
    </p>



        <div className="row">

            <div className="col-md-6">
                search: 
                    
                <input 
                value={this.state.searchString}

                    onChange={e=>{
                        this.setState({searchString:e.target.value})
                    }}/>
            </div>
            
            <div className="col-md-6 text-right">
                <label>

                    
                <input type="checkbox" 
                checked={this.state.includeInactive}
                onChange={e=>{

                    
                    this.setState({includeInactive:e.target.checked})
                }}
                />

                &nbsp;
                show inactive
                </label>
            </div>
        </div>



<table className="table">
    <thead>
        <tr>
            <th>
                Name
            </th>
            <th>
                Tag
            </th>
            <th>
                Active
            </th>
            <th></th>
        </tr>
    </thead>
    <tbody>

    <InfiniteScroll
    targetElement={window}
    maxTakeCount={this.takeCount}
    getItems={this.getItems}
    resetDependencyList={[
        this.state.searchString,
        this.state.includeInactive,
        this.state.resetCtr
    ]}
    resetDebounceDelay={400}
    
    >{infiniteScroll => 
    
    infiniteScroll.loadedItems?.map(e => 
        (

            
            <tr key={e.id}>
        <td>
            
                {/* guid is url safe */}
            <DirectoryLink to={`/${type.customModel.name}/edit?id=${e.id}`}>{e.name}</DirectoryLink>
            
        </td>
        <td>
            {e.tag}
        </td>
        
        <td>
            {e.active+""}
        </td>
        <td>
            {/* {this.context.organizationContext.organizationService.checkUserRoles(Roles.ADMIN) &&
            <>
            <OrganizationLink to={
`/${type.customModel.name}/e/${encodeURIComponent(e.id)}/balance`}>Balance</OrganizationLink>
                
                &nbsp; | &nbsp;
                </>
            } */}
            
            <DirectoryLink to={`/${type.customModel.name}/edit?id=${e.id}`}>Edit</DirectoryLink>
        </td>
    </tr>
        )
    )
    
    }


    </InfiniteScroll>

    </tbody>
</table>
</>;


    }
}