import { StreamingArrayLoaderGetItemsRequest } from "hoksource";
import { Entity } from "../models/entities/entities/entity";
import { EntityType } from "../models/entities/entities/entity-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EntityService } from "./EntityService";
import { SyncEntryUpdateEvent } from "../db/SyncService";
import { SyncEventType } from "../db/SyncEventType";

export class HomeController extends DirectoryComponent<{
entityType:EntityType
},{
    searchString:string,
    includeInactive?:boolean,
    resetCtr:number

    
}>
{


    constructor(props)
    {
        super(props);
        this.state = {

            searchString:"",
            resetCtr:0
        };
    }
    

    takeCount = 30;

    
    getItems = (filter:
        StreamingArrayLoaderGetItemsRequest<Entity>
     )=>{


        
        var service = new EntityService(this.context);
        return service.getList({
            skip : filter.currentIndex,
            take : this.takeCount,
            searchString: this.state.searchString,
            includeInactive: this.state.includeInactive,
            type:this.props.entityType.customModel.name,
            includeReferenceData:true
        });
    }

    handleSyncEntryUpdate = (evt:SyncEntryUpdateEvent<Entity>) =>{
        console.log(evt);
        if(evt.entryType === "Entity")
        {
            this.setState({resetCtr:this.state.resetCtr+1});
        }
    };

    componentDidMount()
    {
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);
    }

    componentWillUnmount()
    {
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);
    }
}