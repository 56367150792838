import { InfiniteScroll } from "hok-react";
import { StreamingArrayLoaderGetItemsRequest } from "hoksource";
import { DirectoryComponent, DirectoryLink, EntitySelect, toLocaleDateString } from "..";
import { ActivityLogService, ActivityLogViewModel, GetActivityLogsInputModel } from "./ActivityLogService";

export class ActivityLogs extends DirectoryComponent<{

    objectTypeName?:string,
    objectId?:string
}>
{



    constructor(props)
    {
        super(props);
        this.state = {

        };
    }
    

    takeCount = 30;

    
    getItems = (filter:
        StreamingArrayLoaderGetItemsRequest<ActivityLogViewModel>
     )=>{

        
        var service = new ActivityLogService(this.http);

        var req:GetActivityLogsInputModel = {
            skip : filter.currentIndex,
            take : this.takeCount,
        };

        if(this.props.objectTypeName)
        {
            req.objectTypeName = this.props.objectTypeName;
                
            if(this.props.objectId)
            {
                req.objectId = this.props.objectId;
            }
        }
        return service.getList(req);
    }



    render()
    {

        
    return <>

    <h1>{this.props.objectTypeName} Activity Logs</h1>

    <InfiniteScroll
    targetElement={window}
    maxTakeCount={this.takeCount}
    getItems={this.getItems}
    resetDependencyList={[]}
    resetDebounceDelay={400}
    >{infiniteScroll => 
    
    infiniteScroll.loadedItems?.map(e => 

        {
            
            var entityUrl = `/${e.activityLog.objectTypeName}/details?id=${e.activityLog.objectId}`;
            
            return (

            <div key={e.activityLog.id}>

                <span title={e.activityLog.logTime}>
                {toLocaleDateString(e.activityLog.logTime)} -&nbsp;
                </span>
                
                
                {e.activityLog.userBasicInfo.firstName}&nbsp;
                {e.activityLog.userBasicInfo.lastName}&nbsp;

                {e.activityLog.actionCode}&nbsp;
                
                
                {e.activityLog.objectTypeName}&nbsp;
                
                {
                    e.order &&
                    (e.order.tag ?
                        <>tagged 
                        <DirectoryLink to={entityUrl}>{e.order.tag}</DirectoryLink>
                        </>
                    :
                        <DirectoryLink to={entityUrl}>{e.activityLog.objectId}</DirectoryLink>
                    )
                }

                                
                {
                    e.journalEntry &&
                    (e.journalEntry.tag ?
                        <>tagged 
                        <DirectoryLink to={entityUrl}>{e.journalEntry.tag}</DirectoryLink>
                        </>
                    :
                        <DirectoryLink to={entityUrl}>{e.activityLog.objectId}</DirectoryLink>
                    )
                }

                &nbsp;
                {e.activityLog.value0 &&

                (
                    e.activityLog.actionCode === "changed custom status" ? 
                    
                    <span>to {e.activityLog.value0}</span>
                    :""
                )}
    </div>
        )

            })
    
    }


    </InfiniteScroll>

</>;


    }

}