import { extendFormGroupProps, FormGroupOrValueProps, InputPropsBase } from "hok-react";
import React, { useContext, useEffect, useRef } from "react";
import { MainModalContext } from "../modals/ModalContext";
import { EDataSelectList, EDataSelectListConfiguration } from "./EDataSelectList";


export function eDataSelectEquals<T>(equals:(a:T,b:T)=>boolean,
a:T,b:T
)
{
    if(equals)
    {
        return equals(a,b);
    }
    return a===b;
    
}

export interface EDataSelectProps<
T, 
G = never
> extends FormGroupOrValueProps<T, G>
{
    
    placeholder?:string;
    className?:string;
    style?:React.CSSProperties;
    readOnly?:boolean;
    required?:boolean;
    configuration:EDataSelectListConfiguration<T>;
    
}

export function EDataSelect<
T, 
G = never
>(props:EDataSelectProps<T,G>)
{
    var modalCtx = useContext(MainModalContext);
    var btnRef = useRef<HTMLInputElement>(null);

    props = extendFormGroupProps<T,G,
    EDataSelectProps<T,G>
    >(props);


    function openDialog(initialSearchString = "")
    {

        modalCtx.showDataSelectModal({
            component:(p)=>{

                return <EDataSelectList<T> 
                value={props.value}
                required={props.required}
                onSelect={(evt)=>{

                    if(!eDataSelectEquals(props.configuration.equals, props.value,evt.target.value))
                    {
                        props.onChange(evt);
                    }
                    modalCtx.hideDataSelectModal();
                }}
                name={props.name}
                configuration={props.configuration}
                initialSearchString={initialSearchString}
                />;
            }

        });
    }

    useEffect(()=>{

        

        if(props.required)
        {
            
            
            
            btnRef.current.setCustomValidity("Please fill out this field.");
            
            if(!props.value)
            {
                btnRef.current.setCustomValidity("Please fill out this field.");
            }else
            {
                
                btnRef.current.setCustomValidity("");
    
            }

        }
    },[props.value, props.required]);



    return <input 
    className={`hok-data-select-button ${props.className||''}`}
    onClick={()=>openDialog()}
    disabled={props.readOnly}
    title={props.configuration.getDisplayName(props.value)}
    ref={btnRef}
    style={props.style}
    placeholder={props.placeholder}

    onKeyDown={(evt)=>{
        console.log(evt);

        var keys = [
            "Enter",
            "Backspace"
        ];
        var isInputChar = 
        evt.key.length === 1 &&
        (
        "a" <= evt.key && evt.key <= "z" ||
    "A" <= evt.key && evt.key <= "Z" ||
    "0" <= evt.key && evt.key <= "9" ||
    evt.key === " "
    );
        if(isInputChar
        
        || keys.indexOf(evt.key) >= 0
        )
        {
            evt.preventDefault();
            openDialog(isInputChar ? evt.key : "");
        }
        /*
        if(evt.key !== "ArrowDown")
        {
          openDialog();
        }*/
    }}
    value={props.value ? props.configuration.getDisplayName(props.value) : ""}
    // onFocus={()=>openDialog()}
    // readOnly
    />;
    

    return <button 
    type="button"
    className={`hok-data-select-button ${props.className||''}`}
    onClick={()=>openDialog()}
    disabled={props.readOnly}
    title={props.configuration.getDisplayName(props.value)}
    ref={btnRef}
    style={props.style}

    onKeyUp={(evt)=>{

        if(evt.key == "ArrowDown")
        {
          openDialog();
        }
    }}>
    {
        props.value&&<span>{props.configuration.getDisplayName(props.value)}</span>
    }
    {

        !props.value&&
        
        (
        props.placeholder?
        <span style={{color:"grey"}}>{props.placeholder}</span> : 
        <span style={{color:"grey"}}>&nbsp;</span>
        )
    }
    
</button>
}