import { CustomFieldsInput, FormGroup, FormInput, Input, ValidationErrors, ValidationSummary } from "hok-react";
import React from "react";
import { DirectoryCurrencyCodeSelect } from "../directory-shared/DirectoryCurrencyCodeSelect";
import { ErichRoles } from "../directory-shared/roles";
import { EntitySelect } from "../entities/EntitySelect";
import { ItemCategorySelect } from "../item-categories/ItemCategorySelect";
import { EntityTypeNameIds } from "../models/enums/EntityTypeNameIds";
import { nonNegativeCustomValidity } from "../validations/nonNegativeCustomValidity";
import { numberCustomValidity } from "../validations/numberCustomValidity";
import { EditController } from "./EditController";
import { ItemTypesList } from "./ItemTypes";

export class Edit extends EditController
{

    render()
    {

        var input = this.state.input;
        return <>
        <h1>{this.id?"Edit":"Create"}</h1>

        <h4>Item</h4>
        <hr />
        <div className="row">
        <div className="col-md-12">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input} 
            onChange={e => this.setState({input:e.target.value})}
            childrenProps={{errors:this.state.errors}}
            >
            {g =><>
            <div className="row">

                <div className="col-md-3">
                    <FormInput
                        group={g}
                        groupKey="name"
                        name="Name"
                        labelText="Name"
                    />
                </div>
                
                <div className="col-md-3">
                    
                <FormInput
                    group={g}
                    groupKey="tag"
                    name="Tag"
                    labelText="Reference Id"
                />
                </div>

                
                <div className="col-md-3">
                    
                <FormInput
                    type="select"
                    group={g}
                    groupKey="type"
                    name="Type"
                    labelText="Type"
                    required
                >{
                    ItemTypesList.map(e => 

                    <option key={e} value={e}>{e}</option>
                    )
                }</FormInput>

                </div>

                
                
                <div className="col-md-3">

                                        
                    <div className="mb-3">
                        <label>Unit Price</label>
                        <Input
                        type="text" 
                        group={g} 
                        groupKey="unitPrice"
                        className="form-control"
                        customValidity={
                            numberCustomValidity(g.value.unitPrice)
                            ||
                            nonNegativeCustomValidity(g.value.unitPrice)
                        }
                        />
                        <ValidationErrors errors={this.state.errors}
                            name="UnitPrice"
                        />
                    </div>
                    
                </div>


                
            </div>
            
            <FormInput
                    // type="text"
                    group={g}
                    groupKey="description"
                    name="Description"
                    labelText="Description"
                />


            <FormInput
                    type="checkbox"
                    group={g}
                    groupKey="active"
                    name="Active"
                    labelText="Active"
                />


            <h5>Advance</h5>
            <hr/>
                <div className="row">
                        
                    <div className="col-md-3">
                    <div className="mb-3">
                        <label>Category</label>
                        <ItemCategorySelect group={g}
                        groupKey="category"
                        className="form-control"
                        />
                    </div>
                            </div>
                            
                            
                    <div className="col-md-3">
                        <FormInput
                            type="number"
                            group={g}
                            groupKey="lowInventoryQuantity"
                            name="LowInventoryQuantity"
                            labelText="Low Inventory Quantity"
                        />
                    </div>

                    <div className="col-md-3">
                                
                        <FormInput
                            group={g}
                            groupKey="baseUnitOfMeasureCode"
                            name="BaseUnitOfMeasureCode"
                            labelText="Base Unit of Measure"
                        />
                        {/*  (Note: changing this does not change inventory quantities) */}
                    </div>


                    
                    
                </div>

                <div className="row">


                    
                {this.context.directoryService.checkUserRoles(ErichRoles.Administrator +
        "," + EntityTypeNameIds.Vendor
        ) &&<>
                    <div className="col-md-3">

                        

                        {this.context.directoryService.checkUserRoles(ErichRoles.Administrator +
                        "," + EntityTypeNameIds.Vendor
                            ) &&
                            <div className="mb-3">
                                <label>Default Vendor</label>
                                <EntitySelect 
                                value={input.defaultVendorEntity}
                                onChange={this.handleDefaultVendorChange}
                                entityTypeNameId={EntityTypeNameIds.Vendor}
                                className="form-control"
                                />
                            </div>
                        }
                                    
                    </div>

                    
                    <div className="col-md-3">
                        <FormInput
                            group={g}
                            groupKey="vendorItemTag"
                            name="VendorItemTag"
                            labelText="Vendor Item Id"
                        />
                    </div>

                    
                    <div className="col-md-3">
                                
                        <FormInput
                            group={g}
                            groupKey="unitCost"
                            name="UnitCost"
                            labelText="Unit Cost"
                            customValidity={
                            numberCustomValidity(g.value.unitCost)
                            ||
                            nonNegativeCustomValidity(g.value.unitCost)
                            }
                        />
                    </div>

                    
                    <div className="col-md-3">
                            
                        <div className="mb-3">
                            <label>Unit Cost Currency Code</label>

                            <DirectoryCurrencyCodeSelect
                            
                            value={input.unitCostCurrencyCode}
                            className="form-control"
                            onChange={evt => g.setValueProperty("unitCostCurrencyCode", evt.target.value)}
                            name="unitCostCurrencyCode"
                            />
                        </div>
                    </div>
                        </>}
                </div>
                
    
 
                <CustomFieldsInput 
                    group={g}
                    fields={this.state.customFields}
                />




            </>}

            </FormGroup>
            
            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>




                
        <div>
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
            
        </div>
    </>;
    }
}