
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { JournalTypeService } from "./JournalTypeService";
import { ModelTypeTypeNameIds } from "../model-types/ModelTypeTypeNameIds";
import { Home } from "./Home";
import { ModelTypesBaseProps } from "../model-types/Root";
import { ModelTypeEdit } from "../model-types/Edit";
import { EntryModeInputOptionsEdit } from "./EntryModeInputOptionsEdit";


export const JournalTypeBaseProps : ModelTypesBaseProps = {
    serviceType: JournalTypeService,
    modelType:{
        customModel:{

            name:ModelTypeTypeNameIds.JournalType,
        displayName:"Journal Type",
        collectionDisplayName:"Journal Types",
        fields:[{
            labelText:"ToEntityTypeName",
            name:"ToEntityTypeName",

        },]
        }
    }
};

export function JournalTypesRoot()
{

    var match = useRouteMatch();

    return <Switch>
        <Route exact path={`${match.path}/`}>
            <Home {...JournalTypeBaseProps}/>
        </Route>
        <Route path={`${match.path}/edit`}>
            <ModelTypeEdit {...JournalTypeBaseProps}/>
        </Route>
        
        <Route path={`${match.path}/edit-details`}>
            <EntryModeInputOptionsEdit/>
        </Route>
        
    </Switch>;

}