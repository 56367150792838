import React from "react";


  
  export const MainModalContext = React.createContext<
  {

    // setShowModal:(show:boolean)=>void,
    showDataSelectModal:(
  
      config
    )=>void,
    hideDataSelectModal:()=>void

  }
  >(null);