import * as React from 'react';
import { Edit } from './Edit';

import { Home } from './Home';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ToastContext } from '../modals/ToastProvider';
import { ItemDetailsRoot } from './Details/ItemDetailsRoot';


export function ItemsRoot()
{

    var match= useRouteMatch();
    
    var toastContext = React.useContext(ToastContext);
    
    return <Switch>
        <Route exact path={`${match.path}/`} component={Home}/>
        <Route path={`${match.path}/edit`} component={Edit}/>
        <Route path={`${match.path}/:itemId`} component={ItemDetailsRoot}/>
        {/* <Route path={`${match.path}/bom`}>
            <BomEdit toastContext={toastContext}/>
        </Route>
        <Route path={`${match.path}/childitems`} >
            <ChildItemsEdit toastContext={toastContext}/>
        </Route> */}

    
    </Switch>;
}