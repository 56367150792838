import * as React from 'react';
import { IRouteProps, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ChildItemsEdit } from './ChilditemsEdit/ChildItemsEdit';
import { ToastContext } from '../../modals/ToastProvider';
import { getTagDisplayName } from '../../functions/DataUtils';
import { Item } from '../../models/entities/items/item';
import { ItemService } from '../ItemService';
import { DirectoryContext } from '../..';
import { DirectoryNavLink } from '../../directory-shared/DirectoryLinks';
import { MeasureUnitsEdit } from './MeasureUnitsEdit';


export function ItemDetailsRoot(props:IRouteProps<{itemId:string}>)
{

    var match= useRouteMatch();
    var [item,setItem] = React.useState<Item>();
    var context = React.useContext(DirectoryContext);
    
    var itemId=props.match.params.itemId;
    console.log(props);
    var toastContext = React.useContext(ToastContext);

    React.useEffect(()=>{


        var service = new ItemService(context);

        
        service.find(itemId).then(e => {
            setItem(e);
        });
    },[]);

    if(!item)
      return <div>Loading...</div>;
    
    return <>
    
    <div className="d-flex">
    <div className="flex-grow-1">
      <h3>{getTagDisplayName(item)}</h3>
    </div>
    <div className="">Edit</div>
</div>
<br/>


<ul className="nav nav-tabs">
  <li className="nav-item">
    <a className="nav-link">
      Details
    </a>
  </li>
  <li className="nav-item">
  <DirectoryNavLink to={`/items/${props.match.params.itemId}/unit-of-measures`}
    className="nav-link">
      Unit of Measures
    </DirectoryNavLink>
  </li>
  {/* <li className="nav-item">
    <a className="nav-link">
      Bills of Material
    </a>
  </li> */}
  <li className="nav-item">
  <DirectoryNavLink to={`/items/${props.match.params.itemId}/childitems`}
    className="nav-link">
      Child Items
    </DirectoryNavLink>
  </li>
</ul>


<br/>
      
    
    <Switch>

        {/* <Route path={`${match.path}/bom`}>
            <BomEdit toastContext={toastContext}/>
        </Route> */}
        <Route path={`${match.path}/unit-of-measures`} >
          <MeasureUnitsEdit toastContext={toastContext} item={item}
          onItemChange={item =>setItem(item)}
          />
            {/* <ChildItemsEdit toastContext={toastContext}/> */}
        </Route>
        <Route path={`${match.path}/childitems`} >
            <ChildItemsEdit toastContext={toastContext} itemId={itemId}/>
        </Route>
{/* 
        <Route exact path={`${match.path}/`} component={Home}/>
        <Route path={`${match.path}/edit`} component={Edit}/>
        <Route path={`${match.path}/bom`}>
            <BomEdit toastContext={toastContext}/>
        </Route>
        <Route path={`${match.path}/childitems`} >
            <ChildItemsEdit toastContext={toastContext}/>
        </Route>

     */}
    </Switch>
    </>
    ;
}