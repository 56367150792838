import { FormGroup, FormInput, ValidationSummary } from "hok-react";
import React from "react";
import { EditController } from "./EditController";

export class Edit extends EditController
{

    render()
    {

        return <>
        <h1>{this.id?"Edit":"Create"}</h1>

        <h4>Custom Field</h4>
        <hr />
        <div className="row">
        <div className="col-md-4">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input} 
            onChange={e => this.setState({input:e.target.value})}
            childrenProps={{errors:this.state.errors}}
            >
            {g =><>
                <FormInput
                    group={g}
                    groupKey="modelType"
                    name="ModelType"
                    labelText="ModelType"
                    readOnly={Boolean(this.id)}
                />


                <FormInput
                    group={g}
                    groupKey="name"
                    name="Name"
                    labelText="Name"
                />
                
                <FormInput
                    group={g}
                    groupKey="alias"
                    name="Alias"
                    labelText="Alias"
                />



                <FormInput
                    group={g}
                    groupKey="dataType"
                    name="DataType"
                    labelText="DataType"
                />

                <FormInput
                    group={g}
                    groupKey="inputType"
                    name="InputType"
                    labelText="InputType"
                />

                                
                                
                <FormInput
                    group={g}
                    groupKey="labelText"
                    name="LabelText"
                    labelText="LabelText"
                />

                                
                <FormInput
                    group={g}
                    groupKey="placeholder"
                    name="Placeholder"
                    labelText="Placeholder"
                />

                                
                                                
                <FormInput
                    group={g}
                    groupKey="defaultValue"
                    name="DefaultValue"
                    labelText="DefaultValue"
                />

                                        
                <FormInput
                    type="checkbox"
                    group={g}
                    groupKey="required"
                    name="Required"
                    labelText="Required"
                />

                                                
                <FormInput
                    type="number"
                    group={g}
                    groupKey="sortOrder"
                    name="SortOrder"
                    labelText="SortOrder"
                />
                                 
                <FormInput
                    type="checkbox"
                    group={g}
                    groupKey="active"
                    name="Active"
                    labelText="Active"
                />
            </>}

            </FormGroup>
            
            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>




                
        <div>
            
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
            
        </div>
    </>;
    }


}