import { extendFormGroupProps, FormGroupOrValueProps, InputPropsBase } from "hok-react";
import React, { useContext, useEffect, useRef } from "react";
import { MainModalContext } from "../modals/ModalContext";
import { EDataSelect, EDataSelectProps } from "./EDataSelect";
import { EDataSelectList, EDataSelectListConfiguration } from "./EDataSelectList";


export function EDataSelectWrapper<
T, 
G = never
>(props:EDataSelectProps<T,G>)
{
    
    return <EDataSelect {...props}/>;
}