import { StoreIndexNames, StoreNames } from "./StoreNames";
import { GetItemsQueryModel } from "../items/GetItemsQueryModel";
import { Item } from "../models/entities/items/item";

export class ItemStore
{

    constructor(private dbService)
    {

    }


    async find(id:string)
    {

      var db = await this.dbService.open();

      var objectStore = db.transaction(StoreNames.Items)
        .objectStore(StoreNames.Items);

      return await objectStore.hokGetAsync(id) as Item;
    }


    async getList(filter:GetItemsQueryModel)
    {

        
        var db = await this.dbService.open();

        var objectStore = db.transaction(StoreNames.Items)
          .objectStore(StoreNames.Items);


        var index:IDBIndex = objectStore.index(StoreIndexNames.ItemsMainIndex);
          
        let searchPredicate = (item:Item) =>
        {
          return item.tag?.toLowerCase().startsWith(filter.searchString.toLowerCase()) || 
            item.name.toLowerCase().indexOf(filter.searchString.toLowerCase()) >= 0;

        };
        console.log("loading data");
        if(filter.openLowerBoundItemId)
        {

          var lastItem:Item = await objectStore
            .hokGetAsync(filter.openLowerBoundItemId);

          if(lastItem)
          {
            
            var lowerBound = IDBKeyRange.lowerBound([
              lastItem.tag, lastItem.name, lastItem.id], true);
            
             return await index.hokOpenCursorToListAsync<Item>({
               query:lowerBound,
               count:filter.take,
               where:searchPredicate
              });
          }else
          {
              
            return await index.hokOpenCursorToListAsync<Item>({
              
              count:filter.take,
              where:searchPredicate
            });

          }

            
        }else
        {
          return await index.hokOpenCursorToListAsync<Item>({
            count:filter.take,
            where:searchPredicate
          });
        }
        
    }
}