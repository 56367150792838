import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { SalesAgent } from "../models/entities/entities/sales-agent";

export class SalesAgentService
{

    controllerName:string = "SalesAgents";

    constructor(private http:DirectoryHttpService)
    {


    }


    getList(filter:{
        searchString?:string,
        skip?:number,
        take?:number,
        includeInactive?:boolean
    } = undefined)
    {
        return this.http.post<SalesAgent[]>(`/${this.controllerName}/GetList`, {
            body:filter
        });
    }

    find(id:string)
    {

        return this.http.post<SalesAgent>(`/${this.controllerName}/Find`, {
            hokQuery:{
                id
            }
        });
    }

    save(input:SalesAgent)
    {
        return this.http.post<SalesAgent>(`/${this.controllerName}/Save`, {
            body:input
        });
    }
}

