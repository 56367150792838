import { Input, ISyntheticEvent } from "hok-react";
import React from "react";
import { Item } from "../models/entities/items/item";

export function ItemUnitOfMeasureSelect(props:{
    item:Item,
    className?:string,
    style?:React.CSSProperties,
    value:string,
    name?:string,
    readOnly?:boolean,
    onChange:(evt:ISyntheticEvent<{value:string, name?:string}>)=>void
})
{

    if(
        !props.item||
        !(props.item.baseUnitOfMeasureCode && props.item.measureUnits.length))
    {
        return <span>{props.value}</span>;

    }else
    {
        return <select 
            className={props.className}
            style={props.style}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            disabled={props.readOnly}
            required
        >

            <option value={props.item.baseUnitOfMeasureCode}>{props.item.baseUnitOfMeasureCode}</option>
            {props.item.measureUnits.map((o,idx)=>
                <option key={o.code} value={o.code}>{o.code}</option>
            )}
        </select>
    }
}