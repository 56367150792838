import React from "react";
import { useContext } from "react";
import { ItemService } from "./ItemService";
import { DataSelectInput, DataSelectInputConfiguration, DataSelectInputPropsBase, ISyntheticEvent } from "hok-react";
import { DirectoryContext } from "../directory-shared/DirectoryContext";
import { getTagDisplayName, inventoryEntityEquals } from "../functions/DataUtils";
import { Item } from "../models/entities/items/item";
import { EDataSelectWrapper } from "../components/DataSelectWrapper";

export function ItemSelect
<

G = never>
(props:
    
    DataSelectInputPropsBase<Item, G> &
    {

        className?:string;
        style?:React.CSSProperties;

onChange?: (evt: ISyntheticEvent<{
    value: Item;
    name?: string;
    setInputValue?:(value:string)=>void;
}>) => void;
        

})
{

    

    var oContext = useContext(DirectoryContext);
    var takeCount = 10;

    
    var config:DataSelectInputConfiguration<Item> = {

        takeCount: takeCount,
        getDisplayName:getTagDisplayName,
        equals:inventoryEntityEquals,
        getItems: (filter) =>{

                    
            var itemService = new ItemService(oContext);
            
            var q = {

                skip:filter.currentIndex,
                take:takeCount,
                searchString: filter.searchString,
                includeReferenceData:true,
                openLowerBoundItemId: filter.lastItem?.id
            };

            // filter.take = takeCount;
        

            return itemService.getItems(q);
        }  
    };


    return <EDataSelectWrapper<Item, G>
         
    group={props.group}
    groupKey={props.groupKey}
    value={props.value}
    onChange={props.onChange}
    name={props.name}
    readOnly={props.readOnly}
    required={props.required}


    
    className={props.className}
    style={props.style}
    configuration={config}
        />;
}