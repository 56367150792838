import { FormDateTimeOffsetInput, Input, FormInput, DateTimeOffsetInput } from "hok-react";
import { EntitySelect, OrderTypeNameIds } from "../../..";
import { EntityTypeNameIds } from "../../../models/enums/EntityTypeNameIds";
import { AssemblyOrderInput } from "../../AssemblyOrderInput";
import { EditOrderHeaderController } from "./EditOrderHeaderController";
import { SalesOrderInput } from "./SalesOrderInput";
import React from 'react';
import { OrderSelect } from "../../OrderSelect";
import { TextInputWithDropdown } from "../../../components/TextInputWithDropdown";
import { AttachmentsInput } from "./AttachmentsInput";
import { DirectoryCurrencyCodeSelect } from "../../../directory-shared/DirectoryCurrencyCodeSelect";
import { UncontrolledCollapse } from "reactstrap";


export class EditOrderHeader extends EditOrderHeaderController
{

    renderMore()
    {
        
        var order = this.props.value;

        return <div className="card">
        <div className="card-header" id="headingOne">
          <h2 className="mb-0">
            <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              More
            </button>
          </h2>
        </div>

        <UncontrolledCollapse toggler="#headingOne">
          <div className="card-body">
            <div className="row">
                <div className="col-md-4">
                    <label>Currency Code</label>
                    <div>
                    <DirectoryCurrencyCodeSelect
                                value={order.currencyCode}
                                className="form-control"
                                onChange={this.handleOrderPropertyChange as any}
                                name="currencyCode"
                            />
                    </div>
                </div>
            </div>
            </div>
        </UncontrolledCollapse>
    
      </div>;
    }

    render()
    {
        var orderType = this.props.orderType;
        var order = this.props.value;
        var isShipmentType = Boolean(orderType.shipmentSourceOrderTypeName);
        return <>
        <div className="row">
    <div className="col-md">

    <h4 className="mb-0">{orderType.customModel.displayName}</h4>
    </div>

<div className="col-md-auto mb-3">
        <label>Order Date</label>
        <div>
        <DateTimeOffsetInput 
            type="date"
            value={order.inputTime}
            className="form-control"
            // onChange={this.handleDateChange}
            
            onChange={this.handleOrderPropertyChange as any}
            name="inputTime"

            readOnly={this.props.readOnly}
            required
        />
        </div>
</div>
                
<div className="col-md-auto mb-3">
    
<label>Due Date</label>
        <div>
        <DateTimeOffsetInput 
            
            type="date"
            className="form-control" 
            value={order.orderDueTime}
            
            onChange={this.handleOrderPropertyChange as any}
            name="orderDueTime"
            // onChange={this.handleDueDateChange}
            
            readOnly={this.props.readOnly}
        />
        </div>
        </div>

        <div className="col-md-auto mb-3">

            
            <label className="control-label">Internal Ref Id</label>
            
        <div>
            <Input className="form-control" value={order.tag}
             onChange={this.handleTagChange}
             
            readOnly={this.props.readOnly}
             />
                
            {this.state.hasDuplicateReferenceId &&
                <div className="text-warning">
                    ReferenceId already exists
                </div>
            }

        </div>
        
        </div>
</div>


<hr className="mt-0 mb-3"/>
<div className="row">
    
{orderType.shipmentSourceOrderTypeName && 
        <div className="col-12 form-group">

            <label>Source {orderType.shipmentSourceOrderTypeName}</label>
            <div>
            <OrderSelect
                orderTypeNameId={orderType.shipmentSourceOrderTypeName}
                value={order.sourceOrder}
                className="form-control"
                name="shipmentSourceOrder"
                onChange={this.handleShipmentSourceOrderChange}
                required
                readOnly={this.props.readOnly}
            />
            </div>
        </div>
        }
    <div className="col-md-6">
    <div className="row">

            <div className="col-md-6">
        
            <div className="form-group">
                <label className="control-label">
                    From Location
                </label>
                <EntitySelect 
                    value={order.fromEntity}
                    onChange={this.handleFromEntityChange}
                    className='form-control' 
                    readOnly={this.props.readOnly || isShipmentType}
                    entityTypeNameId={EntityTypeNameIds.Location}
                    required
                ></EntitySelect>
            </div>
            </div>

            
            {orderType.toEntityTypeName
            && 
            <div className="col-md-6">
                <div className="form-group">

                        
                    <label className="control-label">
                        To {orderType.toEntityTypeName}
                    </label>
                    <div className="d-flex">
                        <div className="flex-grow-1">

                            
                        <EntitySelect 
                        value={order.toEntity}
                        onChange={this.handleToEntityChange}
                        className='form-control' 
                        readOnly={this.props.readOnly || isShipmentType}
                        entityTypeNameId={orderType.toEntityTypeName}
                        required
                    ></EntitySelect>

                        </div>
                        <div>
                            
                    <button type="button"
                        className="btn btn-secondary"
                        onClick={this.props.onCreateNewToEntityClick}
                        disabled={this.props.readOnly || isShipmentType}
                        >
                            <b>+</b>
                        </button>
                        </div>

                    </div>
                    
                </div>

            </div>}

    </div>

    </div>

    <div className="col-md-3">
        <label>External Doc Id</label>
        <Input
            value={order.externalDocumentId}
            onChange={this.handleOrderPropertyChange as any}
            name="externalDocumentId"
            className="form-control"
            
            disabled={this.props.readOnly}
            
            />
            
    </div>
    
    <div className="col-md-3">
                <label>Custom Status</label>
                <select
                className="form-control"
                value={order.customStatus??""}
                onChange={this.handleOrderPropertyChange as any}
                name="customStatus"
                
                disabled={this.props.readOnly}
                >

                    <option disabled></option>                   
            {orderType.customStatuses.map(e => <option key={e.id} value={e.value}>{e.value}</option>)

}
                </select>
            
    </div>

</div> {/* end row */}



{orderType.customModel.name === OrderTypeNameIds.AssemblyOrder &&
    <AssemblyOrderInput
        order={order}
        value={order.assemblyOrder}
        updateOrder={this.props.updateOrder}
        readOnly={this.props.readOnly}

    />
}


{orderType.enableDeliveryAddress &&
<div className="mb-3">
    <label>Delivery Address</label>
    <div>
    <TextInputWithDropdown
        inputClassName="form-control"
        value={order.deliveryAddress?.fullAddress}
        onChange={(evt)=>this.updateFullAddress(evt.target.value)}
        readOnly={this.props.readOnly}
        options={order.toEntity?.addresses?.map(e => e.fullAddress)}
    />
    </div>
</div>}

{orderType.customModel.name === OrderTypeNameIds.SalesOrder &&
    <SalesOrderInput
        value={order}
        updateOrder={this.props.updateOrder}
        readOnly={this.props.readOnly}
        discountRate={this.props.editOrder.getDiscountRate()}
    />
}

{orderType.customModel.name === OrderTypeNameIds.PhysicalInventoryOrder &&
    <div className="mb-3">
        
        <label>
                    
        <input type="checkbox"
            checked={order.zeroNotSpecifiedItems ?? false}

            onChange={this.handleOrderPropertyChange as any}
            name="zeroNotSpecifiedItems"

            disabled={this.props.readOnly}
        /> &nbsp;
        0 Not Specified Items when Posting</label>
    
</div>
}



        
{this.renderMore()}

{orderType.enableAttachments &&
<AttachmentsInput
editOrder={this.props.editOrder}
/>
}


        </>;
    }
}