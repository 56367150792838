import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { ItemLedgerEntry } from "../models/entities/entities/item-ledger-entry";
import { ReservationEntry } from "../models/entities/entities/reservation-entry";

export class EntityItemLedgerEntryService
{

    constructor(private http:DirectoryHttpService)
    {

    }

    
    getList(req:{
        
        skip:number,
        take:number,
        searchString?:string,

        
        orderType?:string,
        entityId?:string,
        itemId?:string,
        isOpen?:boolean
    })
    {
        return this.http.post<ItemLedgerEntry[]>("/EntityItemLedgerEntries/GetList", {
            body:req
        });
    }

    
    getReservationEntries(req:{
        
        skip:number,
        take:number,
        searchString?:string,

        
        orderType?:string,
        entityId?:string,
        itemId?:string,
    })
    {
        return this.http.post<ReservationEntry[]>("/ReservationEntries/GetList", {
            body:req
        });
    }

    


}