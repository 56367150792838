import { ISyntheticEvent } from "hok-react";
import { ValidationProblemDetails } from "hoksource";
import { DirectoryComponent, DirectoryService, ToastContextModel } from "..";
import { DirectoryConfiguration, DirectoryCurrency } from "../models/entities/directories/directory-configuration";
import { EditStateBase } from "../types/EditStateBase";

export class DirectoryConfigurationEditController extends DirectoryComponent<{

    toastContext:ToastContextModel
},
EditStateBase<DirectoryConfiguration> &
{
}>
{

    
    constructor(props)
    {
        super(props);
        this.state = {
            input:{
                name:"",
                currencyCode:"PHP",
                currencies:[]
            }
        };
    }

    
    componentDidMount()
    {
                
        var service = new DirectoryService(this.http);

        service.getDirectoryConfiguration().then(e => {

            //console.log(e);
            
            this.setState({
                input:e
            });
        });

    }

    handleCurrenciesChange = (evt:ISyntheticEvent<{value:DirectoryCurrency[]}>)=>{
        var nextConfig = {...this.state.input, currencies:evt.target.value} as DirectoryConfiguration;

        this.setState({input:nextConfig});
    };

    removeCurrency = (currency:DirectoryCurrency)=>
    {
        
        
        var components = this.state.input.currencies;
        var index = components.indexOf(currency);
        
        var newComponents:DirectoryCurrency[] = [
            ...components.slice(0, index),
            ...components.slice(index+1)
        ];

        var nextConfig = {...this.state.input, currencies:newComponents} as DirectoryConfiguration;

        this.setState({input:nextConfig});
    };

    addCurrency = ()=>{
        
        var nextConfig = {...this.state.input, currencies:this.state.input.currencies.concat({
            code:""
        })} as DirectoryConfiguration;

        this.setState({input:nextConfig});
    };



    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>
    {

        
        evt.preventDefault();

        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
                
            var service = new DirectoryService(this.http);

            await service.saveDirectoryConfiguration(this.state.input);

            // this.history.goBack();

            
            this.props.toastContext.setToast({
                header:"Info",
                body:"Saved",
                durationMs:2000
              });
        }catch(err:any)
        {


            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }


        this.setState({isSubmitting:false});
        
        

    }


} 