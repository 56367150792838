
export const SaleTransactionsBasePath = '/sales1';

export const SalesReturnsBasePath = '/sales-returns';



export const NullOrEmptyStringCode = "NullOrEmpty";



export const StringUpperBound = "\u{10FFFF}\u{10FFFF}\u{10FFFF}\u{10FFFF}\u{10FFFF}";


export const SyncLockName = "dbSyncLock";
export const SyncBroadcastChannelName = "sync_broadcast_channel";