import React from "react";
import { NavLink } from "react-router-dom";
import { DirectoryContext } from "./DirectoryContext";

export function DirectoryNavLink(props:{to:string, 
    exact?:boolean,
    className?:string,
    children?:any})
{

    
    let directoryContext = React.useContext(DirectoryContext);

    return <NavLink 
    
    to={`${directoryContext.directoryBasePath}${props.to}`}
    className={props.className}
    exact={props.exact}
    >{props.children}</NavLink>;
}