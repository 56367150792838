import React from "react";
import { useEffect, useRef } from "react";
import { Order } from "../models/entities/orders/order";
import { OrderType, OrderTypeViewTemplate } from "../models/entities/orders/order-type";

import * as babel from '@babel/standalone';


window["React"] = React;

type PrintTemplateType = React.ComponentType<{
    orderType:OrderType,
    order:Order,
}>;
export function PrintComponent(props:{
    orderTypeViewTemplate:OrderTypeViewTemplate;
    orderType:OrderType,
    order:Order,
})
{
    

    var {order, orderType, orderTypeViewTemplate } = props;

    var componentTypeRef = useRef<PrintTemplateType>();

    if(!componentTypeRef.current)
    {
        var code = babel.transform(
            `(${orderTypeViewTemplate.rawTemplate})`,{presets:["react"]}
            ).code;
        // eslint-disable-next-line
        componentTypeRef.current = eval(code) as PrintTemplateType;
    }

    console.log(code);



    useEffect(()=>{

        // once rendered

    },[]);



    return <componentTypeRef.current
    orderType={orderType}
    order={order}/>;
    

}