import { ISyntheticEvent } from "hok-react";
import React from "react";
import { DirectoryComponent, Order, OrderTypeNameIds } from "../../..";
import { getNextSortOrder } from "../../../functions/getNextSortOrder";
import { OrderLine } from "../../../models/entities/orders/order-line";
import { createNewLine } from "../EditDefs";
import { useEditOrder } from "../useEditOrder";
import { OrderLineInput } from "./OrderLineInput";

export class OrderLinesInput extends DirectoryComponent
<{
    value:OrderLine[],
    editOrder:ReturnType<typeof useEditOrder>,
    readOnly:boolean,
    updateOrder:<K extends keyof Order>(order:Pick<Order, K>) => void;
}>
{

    get orderType(){

        return this.props.editOrder.orderType;
    }


    get requireUniqueItems()
    {
        return this.orderType.customModel===OrderTypeNameIds.PhysicalInventoryOrder;
    }
    
    handleLineChange(prev:OrderLine, evt:{
        target:{value:OrderLine};
        // preventDefault():void;
    })
    {

        // var orderType = this.orderType;
        var next = evt.target.value;
        
        var itemChanged = prev.item?.id !== next.item?.id;
        
        // var itemOrQuantityChanged = itemChanged || prev.quantity !== next.quantity;

        var prevLines = this.props.value;
        var index = prevLines.indexOf(prev);
        var nextLines = [...prevLines.slice(0,index), next, ...prevLines.slice(index+1)];

        // let existEntityItem = false;

        if(itemChanged)
        {
            
                
            if(index === prevLines.length-1)
            {
                // add to bottom
                nextLines.push(createNewLine(this.orderType, getNextSortOrder(nextLines)));
            }
        }

        if(next.id) // mark as modified
        {
            this.props.editOrder.modifiedLineIdsDictionaryRef.current[next.id] = true;
        }


        this.props.updateOrder({
            lines:nextLines
        });

    }


    
    handleDeleteLineClick=(evt:ISyntheticEvent<{value:OrderLine}>)=>
    {
        var value = evt.target.value;
        if(value.item && window.confirm("Are you sure you want to remove this item?"))
        {
            var lines = this.props.value;
            var index = lines.indexOf(value);
            var nextLines = lines.slice();
            nextLines.splice(index,1);

            if(value.id)
            {
                // delete if exists
                delete this.props.editOrder.modifiedLineIdsDictionaryRef.current[value.id];
                this.props.editOrder.deletedLineIdsDictionaryRef.current[value.id] = true;
            }

            this.props.updateOrder({lines: nextLines});
        }
    }

    render()
    {


        return <>
                
            {this.props.value.map((line,idx)=>
            
            
            <OrderLineInput
                key={idx}
                value={line}
                onChange={evt => 
                    this.handleLineChange(line, evt)
                }

                onDeleteClick={this.handleDeleteLineClick}
                
                editOrder={this.props.editOrder}
                
                
                readOnly={this.props.readOnly}
            />
        )}</>;
    }


}