import { StoreIndexNames, StoreNames } from "./StoreNames";
import { StringUpperBound } from "../ErichConstants";
import { DbService } from "./DBService";
import { GetOrderListFilter } from "../orders/OrderService";
import { Order } from "../models/entities/orders/order";

export class OrderStore
{

    constructor(private dbService:DbService)
    {

    }



    async getList(filter:GetOrderListFilter)
    {
        var db = await this.dbService.open();

        var objectStore = db.transaction(StoreNames.Orders)
          .objectStore(StoreNames.Orders);


        var index:IDBIndex = objectStore.index(StoreIndexNames.OrdersMainIndex);

        let searchPredicate = (order:Order) =>
        {
            if(filter.searchString
                )
            {
                if(order.tag?.toLowerCase()!==filter.searchString.toLowerCase())
                {
                    return false;
                }
            }

            if(filter.openLowerBoundOrderId && order.id === filter.openLowerBoundOrderId)
            {
                return false;
            }
            return true;
        };

        if(filter.openLowerBoundOrderId)
        {

          var lastItem:Order = await objectStore
            .hokGetAsync(filter.openLowerBoundOrderId);

          if(lastItem)
          {
            var upperBoundKey = [
                lastItem.typeName,
                lastItem.inputTime,
                lastItem.creationTime];
            // IDBKeyRange.bound()
            var lowerBoundKey = [lastItem.typeName, "", ""];
            
            var bound = IDBKeyRange.bound(lowerBoundKey, upperBoundKey, false, false);
            
             return await index.hokOpenCursorToListAsync<Order>({
               query:bound,
               direction:"prev",
               count:filter.take,
               where:searchPredicate
              });
          }
        }
        
        return await index.hokOpenCursorToListAsync<Order>({
            query:IDBKeyRange.bound([filter.type, "", ""], 
            [filter.type, StringUpperBound,StringUpperBound
            ], false, false),
            direction:"prev",
            count:filter.take,
            where:searchPredicate
          });
          /*
        if(filter.openLowerBoundEntityId)
        {

          var lastItem:Entity = await objectStore
            .hokGetAsync(filter.openLowerBoundEntityId);

          if(lastItem)
          {
            var lowerBoundKey = [
                lastItem.type,
                lastItem.tag,
                lastItem.name];
            // IDBKeyRange.bound()
            var upperBoundKey = [lastItem.type, StringUpperBound, StringUpperBound];
            
            var bound = IDBKeyRange.bound(lowerBoundKey, upperBoundKey, true, false);
            
             return await index.hokOpenCursorToListAsync<Entity>({
               query:bound,
               take:filter.take,
               where:searchPredicate
              });
          }
        }

        
        return await index.hokOpenCursorToListAsync<Entity>({
          query:IDBKeyRange.bound([filter.type, "", ""], 
          [filter.type, StringUpperBound,StringUpperBound
          ], false, false),
          take:filter.take,
          where:searchPredicate
        });*/
    }



    async get(id:string|number)
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.Orders, "readonly");
      var entityStore = txn.objectStore(StoreNames.Orders);

      return await entityStore.hokGetAsync(id) as Order;

    }


    async put(entity:Order)
    {

      var db = await this.dbService.open();
      var txn = db.transaction(StoreNames.Orders, "readwrite");
      var orderStore = txn.objectStore(StoreNames.Orders);

      return await new Promise<Order>((resolve, reject)=>{
        
        var req = orderStore.put(entity);

        req.onsuccess = function(evt){
          
          if(!entity.id)
          {
            resolve({

              ...entity,
              id:req.result as any
            });
            
          }else
          {
            resolve(entity);
          }
        };

        req.onerror = function(evt)
        {
          reject(evt);

        };
      });
    }
}