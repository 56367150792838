export function nonNegativeCustomValidity(n:string|number, errorMessage?:string)
{

    if( n < 0)
    {
        return errorMessage ?? "Negative values are not allowed.";
    }

    return undefined;

}