import { useEffect, useMemo, useState } from "react";
import { DbService } from "../db/DBService";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { DirectoryContextData } from "../models/directory-context-data";



const UserDirectoryDataKeyPrefix = "UserDirectoryData-";
function getUserDirectoryDataFromLocalStorage(directoryTag:string)
{
    
    var initDataJson = localStorage[UserDirectoryDataKeyPrefix + directoryTag.toLowerCase()];
    // get from local
    if(initDataJson)
    {
        return JSON.parse(initDataJson) as DirectoryContextData;
    }

    return null;
}



export function useDirectoryService(
    directoryTag:string,
    http:DirectoryHttpService,
    dbService:DbService)
{

    var [data,setData] = useState<DirectoryContextData>(null);

    


    useEffect(()=>{

            
        const getDirectoryInitData = async () => 
        {
            var data:DirectoryContextData; 

            
            data = await http.post<DirectoryContextData>(
                "/Directory/GetDirectoryInitData",{

                    onOnlineSuccess: resData =>{
                        // save

                        var prev = getUserDirectoryDataFromLocalStorage(directoryTag);
                        // can be write efficient???

                        var now = new Date().getTime();

                        // optimization
                        if(!prev || prev.lastSyncTimeMilliseconds + 5 * 60 * 1000 < now)
                        {
                            resData.lastSyncTimeMilliseconds = now;
                            localStorage[UserDirectoryDataKeyPrefix + directoryTag.toLowerCase()] = JSON.stringify(resData);
                        }

                    },

                    whenOffline:async ()=>{
                        // load from storage

                        return await getUserDirectoryDataFromLocalStorage(directoryTag);
                    }
                });
            
            
            setData(data);

            return data;
        }
        getDirectoryInitData();
        // eslint-disable-next-line
    },[]);

    return useMemo(()=>({

        data,
        checkUserRoles:function(roles)
        {
            roles=roles.split(',');
            for(var i of roles)
            {
                if(data.directoryUserRoles.indexOf(i.trim())>=0)
                {
                    return true;
                }
            }

            return false;
        }
    }),[data]);


}