import React from "react";
import {  requestLock, ValidationProblemDetails } from "hoksource";
import { OrderEditProps } from "./Edit";
import { CustomModel, CustomModelField } from "../../models/entities/custom-field";
import { Entity } from "../../models/entities/entities/entity";
import { OrderService } from "../OrderService";
import { OrderTypeNameIds } from "../../models/enums/OrderTypeNamesIds";
import { DirectoryComponent } from "../../directory-shared/DirectoryComponent";
import { ModelTypeConstants } from "../../models/enums/DefaultModelTypeNameIds";
import { Order } from "../../models/entities/orders/order";
import { getNextSortOrder } from "../../functions/getNextSortOrder";
import { SyncLockName } from "../../ErichConstants";
import { createNewLine, EditState } from "./EditDefs";
import { SyncEventType } from "../../db/SyncEventType";
import { SyncEntryUpdateEvent } from "../../db/SyncService";
import { EntityType } from "../../models/entities/entities/entity-type";
import { EditEntityModal } from "../../entities/Edit/EditEntityModal";
import { useEditOrder } from "./useEditOrder";
import { ImageJpeg } from "./useOrderAttachmentsHelper";
import { SaveOrderInput } from "../../models/inputs/SaveOrderInput";
import { OrderLine } from "../../models/entities/orders/order-line";
import { convertLineForSaving } from "../convertOrderForSaving";


type PropsType = OrderEditProps & {

    editOrder:ReturnType<typeof useEditOrder>
};



export class EditController extends DirectoryComponent
<PropsType,
EditState & {
    lineCustomModel?:CustomModel;
    lineCustomFields?:CustomModelField[];
    toEntityType?:EntityType
}>
{

    id:string;
    // deleteLineIds:string[];

        
    // for history tracking
    paramChangedCount = 0;


    formRef = React.createRef<HTMLFormElement>();
    editEntityModalRef = React.createRef<EditEntityModal>();

    constructor(props:PropsType)
    {
        super(props);
        this.state={};
    }


    componentDidMount()
    {
        
        this.id = this.getQueryParam("id");

        var orderType = this.props.orderType;
        var lineType = 
        orderType.customModel.name
        +ModelTypeConstants.Line;


        var lineCustomModel = this.context.data.customModels.find(e => e.name === lineType);
        var lineCustomFields = lineCustomModel?.fields ?? [];
        var toEntityType = this.context.data.entityTypes
            .find(e => e.customModel.name === orderType.toEntityTypeName);


        this.setState({
            lineCustomModel : lineCustomModel,
            lineCustomFields : lineCustomFields,
            toEntityType:toEntityType
        });
        
        var service = new OrderService(this.context);

        var copyFromId = this.getQueryParam("copyFromId");

        if(this.id)
        {
            // load it
            service.find(this.id)
            .then(e=>{
                this.initInput(e);

            });
        } else if(copyFromId)
        {
            service.find(copyFromId)
            .then(e=>{

                function removeIds(order:Order)
                {
                    delete e.id;
                    delete e.tag;
                    delete e.externalDocumentId;
                    delete e.postingTime;
                    delete e.shipmentCompletionTime;
                    
    
                    e.lines.forEach(l => {
    
                        delete l.id;
                        l.childLines.forEach(cl=>{

                            delete cl.id;
                        });
                        
                        if(l.assemblyOrder)
                        {
                            removeIds(l.assemblyOrder);
                        }
                    });
                }

                removeIds(e);
                



                this.initInput(e);

            });

        }
        else
        {
            var d = new Date();
            //d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

            var initVal:Order = 
            {
                typeName : this.orderTypeNameId,
                inputTime : d.hokToLocaleDateTimeOffsetString(),
                // currency???
                lines:[],
                deliveryAddress:{},
                attachments:[],
                assemblyOrder: this.orderTypeNameId === OrderTypeNameIds.AssemblyOrder ? {
                    quantity:1
                }:null
            };

            var sourceOrderId= this.getQueryParam("sourceOrderId");
            if(sourceOrderId)
            {
                    
                this.initInput(initVal);
                service.find(sourceOrderId).then(s=>{

                    this.props.editOrder.sourceOrderHelper.updateThisShipmentWithSourceOrder(
                        {...this.order,
                        sourceOrder:s
                        }, s);
                });

            }else
            {
                this.initInput(initVal);
            }

        }

        window.addEventListener("keydown", this.onKeyDown);
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);

    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);
    
    }


    updateId(id:string)
    {
        this.id = id;
        this.history.replace(
`/${this.props.orderType.customModel.name}/edit?id=${encodeURIComponent(id)}`);
    }

    
    handleSyncEntryUpdate = (evt:SyncEntryUpdateEvent<{id:string}>) =>{
        console.log(evt, this.id);
        var prevId = evt.prevValue.id;
        var nextId = evt.nextValue.id;

        
        var nextOrder = this.props.editOrder.order;
        if(evt.entryType === "Order" && 
            prevId.toString() === this.id)
        {
            this.updateId(nextId);

            nextOrder = {...nextOrder, id:nextId};
        }

        if(evt.entryType === "Entity")
        {
            if(prevId ===
                this.props.editOrder.order.fromEntity.id)
            {

                // update it
                nextOrder = {

                    ...nextOrder,
                    fromEntity:{

                        ...nextOrder.fromEntity,
                        id:nextId
                    }
                };
            }

            if(prevId ===
                this.props.editOrder.order.toEntity.id
                )
            {
                nextOrder = {

                    ...nextOrder,
                    toEntity:{

                        ...nextOrder.toEntity,
                        id:nextId
                    }
                };

            }
        }


        if(nextOrder !== this.props.editOrder.order)
        {
            this.props.editOrder.setOrder(nextOrder);
        }
    };


    get order()
    {
        return this.props.editOrder.order;
    }


    updateOrder = <K extends keyof Order>(order:Pick<Order, K>) => {

            this.props.editOrder.setOrder((prevOrder)=>{
                var nextOrder = {...prevOrder, ...order};

                if(prevOrder.toEntity?.id !== nextOrder.toEntity?.id) // different to entity id
                {
                    // ajax the entity and put it to data
                    if(nextOrder.toEntity.customer && this.isSalesOrder)
                    {

                        var customer = nextOrder.toEntity.customer;
                        if(customer.defaultSalesAgent)
                        {
                            nextOrder.salesAgent = customer.defaultSalesAgent;
                        }
    
                        if(customer.defaultPaymentTermsConfiguration)
                        {
                            nextOrder.paymentTermsConfiguration = customer.defaultPaymentTermsConfiguration;
                        }
                    }

                    if(this.props.orderType.enableDeliveryAddress)
                    {
                        if(nextOrder.toEntity?.id)
                        {
                            if(nextOrder.toEntity.addresses[0])
                            {
                                nextOrder.deliveryAddress = {
    
                                    fullAddress:nextOrder.toEntity.addresses[0].fullAddress
                                };
                            }
                        }
                    }

                    nextOrder.currencyCode = nextOrder.toEntity.balanceDetail.currencyCode;

                }

                if(
                    nextOrder.inputTime && nextOrder.paymentTermsConfiguration &&
                    (
                        prevOrder.inputTime !== nextOrder.inputTime
                        ||
                        prevOrder.paymentTermsConfiguration?.id !== nextOrder.paymentTermsConfiguration?.id
                    )
                    )
                {
                    // update end time
                    
                    var paymentDueTimeDate = new Date(nextOrder.inputTime);
                    paymentDueTimeDate.setDate(nextOrder.paymentTermsConfiguration.daysToPay);
                    nextOrder.paymentDueTime = paymentDueTimeDate.hokToLocaleDateTimeOffsetString();

                }
                
                
                this.props.editOrder.applyOrderValueExpressions(nextOrder);
                return nextOrder;
            });
        };


    initInput(order:Order)
    {
        order.lines.push(
            createNewLine(this.props.orderType, getNextSortOrder(order.lines))
            );
        
        // this.deleteLineIds=[];
        this.setState({
            hasChanges:false,
            // order:order,
            errors:null
        });

        this.props.editOrder.applyOrderValueExpressions(order);
        this.props.editOrder.setOrder(order);

        
        if(this.props.orderType.shipmentSourceOrderTypeName
            &&
            order.sourceOrder
            )
        {

            if(order.id)
            {
                // just to update posted
                // this is async so needs to be tested
                this.props.editOrder.sourceOrderHelper
                .loadShipmentWithSourceOrder(order.sourceOrder.id);
            }else
            {
                this.props.editOrder.sourceOrderHelper
                    .updateThisShipmentWithSourceOrder(order, order.sourceOrder);

            }
        }
    }

    get canSaveOnly()
    {
        return Boolean(this.order.assemblyOrderParentOrderLineId ||
        this.state.isSubmitting);
    }

    get isReadOnly()
    {
        return Boolean(this.order.postingTime ||
        this.order.assemblyOrderParentOrderLineId ||
        this.state.isSubmitting);
    }

    get isAllowedToUnpost()
    {
        return this.order.postingTime &&
            !this.order.assemblyOrderParentOrderLineId;
    }

    get orderTypeNameId()
    {
        return this.props.orderType.customModel.name;
    }

    get isSalesOrder()
    {
        return this.orderTypeNameId === OrderTypeNameIds.SalesOrder;
    }


    computeTotalPrice()
    {
        var list = this.order.lines;
        var total = 0;
        for(var o of list)
        {
            if(o.quantity && o.unitPrice)
            {
                if(this.props.orderType.customModel.name === OrderTypeNameIds.PhysicalInventoryOrder)
                {

                    total += parseFloat((o.physicalQuantity*o.unitPrice).toFixed(2));
                }
                else
                {

                    total += parseFloat((o.quantity*o.unitPrice).toFixed(2));
                }
            }

            if(o.quantity && o.childLines)
            {
                for(let j of o.childLines)
                {
                    if(j.quantity && j.unitPrice)
                    {

                        total += parseFloat(
                            (o.quantity * j.quantity*j.unitPrice).toFixed(2));
                    }
                }
                
            }
        }

        return total;
    }


    handleOnCreateNewToEntityClick=()=>{

        
        this.editEntityModalRef.current?.openNew();
    };
    
    handleCreatedNewToEntity = (entity:Entity)=>{

        this.updateOrder({toEntity:entity});
    };

    handleDeleteAllLines = ()=>{

        if(!window.confirm("Are you sure you want to delete all lines?"))
            return;
        
        for(let i of this.props.editOrder.order.lines)
        {
            if(i.id)
            {
                this.props.editOrder.deletedLineIdsDictionaryRef.current[i.id] = true;        
            }
        }
        this.props.editOrder.modifiedLineIdsDictionaryRef.current = {};
        
        this.updateOrder({lines:[

            
            createNewLine(this.props.orderType, 0)
        ]});
    };


        
    onKeyDown = (evt:KeyboardEvent) =>
    {
        if(evt.ctrlKey && evt.code==="KeyS")
        {
            evt.preventDefault();

            if(!this.state.isSubmitting)
            {
                this.save();
            }
        }
    }


    createSaveOrderInput(order:Order)
    {
        

    var {
        sourceOrder,
        fromEntity,
        toEntity, 
        salesAgent,
        paymentTermsConfiguration,
        lines,
        ...orderCopy} = order;

    
    orderCopy.sourceOrderId = order.sourceOrder?.id ?? null;
    orderCopy.fromEntityId = order.fromEntity?.id;
    

    if(order.assemblyOrder?.item)
    {
        orderCopy.assemblyOrder = {

            ...order.assemblyOrder,
            itemId:order.assemblyOrder?.item.id
        };
    }
    if(order.toEntity?.id)
    {
        orderCopy.toEntityId = order.toEntity?.id;
    }
    if(order.salesAgent?.id)
    {
        orderCopy.salesAgentId = order.salesAgent?.id;
    }
    if(order.paymentTermsConfiguration?.id)
    {
        orderCopy.paymentTermsConfigurationId = order.paymentTermsConfiguration?.id;
    }

    // copy.lines = copy.lines.map(convertLineForSaving);
        var addModifyLines:OrderLine[] = [];

        for(let i of order.lines)
        {
            if(!i.id
                ||
                this.props.editOrder.modifiedLineIdsDictionaryRef.current[i.id]
                ) // no id
            {
                addModifyLines.push(convertLineForSaving(i));
            }
        }

        var ret = {
            order:orderCopy,
            deleteLineIds:Object.keys(this.props.editOrder.deletedLineIdsDictionaryRef.current),
            addModifyLines
        } as SaveOrderInput;



    return ret;
    }


    async save(post:boolean = false)
    {
        this.setState({isSubmitting:true});

        var saveOrder = {...this.order};

        saveOrder.lines = saveOrder.lines.filter(e => e.item);

        if(this.props.processOrderBeforeSaving)
        {
            saveOrder = this.props.processOrderBeforeSaving(saveOrder); // preprocess
        }

    

        try{

            var resOrder:Order;

            
            await requestLock(SyncLockName, async()=>{ // lock it here

                var service = new OrderService(this.context);


                var saveOrderInput = this.createSaveOrderInput(saveOrder);

                var resData = await service.save2(saveOrderInput, post);

                // succeeded
                this.props.editOrder.modifiedLineIdsDictionaryRef.current = {};
                this.props.editOrder.deletedLineIdsDictionaryRef.current = {};

                resOrder = resData;
                this.initInput(resData);

            
                if(!this.id)
                {
                    this.updateId(resData.id);
                }

                let attachmentsToAdd = this.props.editOrder.attachmentsHelper.attachmentsToAdd;
                if(attachmentsToAdd.length)
                {
                    var blobs = await Promise.all<Blob>(attachmentsToAdd.map((att)=>{

                        if(att.file)
                        {
                            return Promise.resolve(att.file);

                        }else
                        {

                            return new Promise((resolve)=>{
                                att.imageCanvas.toBlob((res)=>{
    
                                    resolve(new File([res],att.fileName, {type:res.type}));
                                }, ImageJpeg)
    
                            })
                        }
                    })

                    );


                    var attachments = await service.saveAttachments(
                        blobs.map(e => ({
                            orderId:resData.id,
                            file:e
                        }))
                    );

                    
                    this.props.editOrder.setOrder(o => {

                        return {

                            ...o,
                            attachments:[...o.attachments, ...attachments]
                        };
                    });       
                    this.props.editOrder.attachmentsHelper.clear();             
                }
            });
            
            this.props.toastContext.setToast({
                header:"Info",
                body:"Saved",
                durationMs:2000
                });
        } catch(response:any)
        {
            
            console.log(response);
            if(response.status === 400)
            {
                var err =  response as ValidationProblemDetails;
                this.setState({errors:err.errors});
            }else{
                alert("Something went wrong error code:" + response.status)
            }
        }
        
        this.setState({isSubmitting:false});
    }
    

    handleSubmit=(evt:React.FormEvent<HTMLFormElement>) =>
    {
        evt.preventDefault();
        this.save();
    }

    handleSaveAndPost=()=>{

        if(this.formRef.current.reportValidity())
        {
            this.save(true);
        }
    };

    handleUnpostClick= async ()=>{

        if(!window.confirm("Are you sure you want to unpost this order?"))
        {
            return;
        }
        this.setState({isSubmitting:true});

        var service = new OrderService(this.context);

        try{

            var resData = await service.postOrder(this.id, false);
            
            this.initInput(resData);

            
            

            
            this.props.toastContext.setToast({
                header:"Info",
                body:"Order unposted.",
                durationMs:2000
                });
        } catch(response:any)
        {
            

            if(response.status === 400)
            {
                var err =  response as ValidationProblemDetails;
                this.setState({errors:err.errors});
                // alert(err.errors[""][0]);
            }
        }
        
        this.setState({isSubmitting:false});
    };
}