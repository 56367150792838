import { ISyntheticEvent } from "hok-react";
import { useMemo, useState } from "react";
import { createSmallerImageCanvas } from "../../functions/images";

var imageMaxSize = 1280; // winner

export const ImageJpeg = "image/jpeg";
type AttachmentInputModel = {
    imageCanvas?:HTMLCanvasElement,
    imageUrl?:string,
    file?:File,
    fileName?:string,
    // contentType:string
};



export function useOrderAttachmentsHelper()
{

    var [attachmentsToAdd, setAttachmentsToAdd] = useState<AttachmentInputModel[]>(()=>[]);
    var [error, setError] = useState<string>();

    console.log(attachmentsToAdd);

    return useMemo(()=>({

        attachmentsToAdd,
        removeAttachmentToAdd:(attachment:AttachmentInputModel)=>{

            var copy = attachmentsToAdd.slice();
            copy.splice(copy.indexOf(attachment), 1);
            setAttachmentsToAdd(copy);

        },
        error,
        
        clear:()=>{
            setAttachmentsToAdd([]);
        },
        handleFileEvent : async (evt:ISyntheticEvent<HTMLInputElement>) => {

                
            var input = evt.target as HTMLInputElement;
            var files:FileList = input.files;

            console.log(files);
            
            var promises:Promise<AttachmentInputModel>[] = [];

            var filesArray : File[] = [];
            
            for(var i = 0, len = files.length; i < len; ++i)
            {
                var file = files[i];
                filesArray.push(file);
                
            }

            filesArray.forEach(file => {

                if(file.type==="application/pdf")
                {
                    promises.push(new Promise((resolve)=>{

                        var f = new FileReader();
                        f.onload=(res)=>{
                            // f.result

                            resolve({
                                file:file,
                                fileName:file.name,
                                imageUrl:f.result as string
                            });


                        };
                        f.readAsDataURL(file);
                    }));
                }else
                {

                    promises.push(
                        createSmallerImageCanvas(file, imageMaxSize)
                            .then(e=>{

                                var name=file.name;
                                var lastIndex = name.lastIndexOf(".");
                                if(lastIndex>=0)
                                {
                                    name=name.substr(0, lastIndex);
                                }
                                name += ".jpg";
                                
                                return {
                                    imageCanvas:e,
                                    imageUrl:e.toDataURL(ImageJpeg),
                                    fileName: name
                                }
                        }));
                }
            });

            

            var res = await Promise.all(promises);

            var nextList = attachmentsToAdd.slice();
            var hasBigFile = false;
            var maxFileSizeMb = 1;
            for(let i of res)
            {
                if(i.file?.size >= maxFileSizeMb << 20)
                {
                    hasBigFile = true;
                }else
                {
                    nextList.push(i);
                }
            }
            
            if(hasBigFile)
            {

                setError(`File size should be less than ${maxFileSizeMb} MB`);
            }else
            {
                
                setError(undefined);
            }

            setAttachmentsToAdd(nextList);

            input.value = "";

        }





        
    }),[attachmentsToAdd, error]);
}