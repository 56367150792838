import React, { useState } from "react";
import { useEffect, useRef } from "react";

export function TextInputWithDropdown(props:{

    // searchInputRef:MutableRefObject<HTMLInputElement>;
    options:string[];
    inputClassName?:string;

    value:string;
    onChange:(evt:{target:{value:string}})=>void;
    readOnly?:boolean;
})
{
    console.log("with dropdown props", props);

    var searchInputRef = useRef<HTMLInputElement>();
    var [showDropdown, setShowDropdown] = useState(false);
    var [activeIndex, setActiveIndex] = useState(0);
    var optionsContainerRef = useRef<HTMLDivElement>();


    const doSelect = (value:string) =>{

        
        props.onChange({target:{value}});
        setShowDropdown(false);
    };

    
    const doShowDropdown = ()=>{
        
        setActiveIndex(-1);
        setShowDropdown(true);
    };
    
    const onKeyDown = (evt:React.KeyboardEvent<HTMLInputElement>) =>
    {
        setShowDropdown(true);

        if(evt.key === "ArrowDown")
        {
            if(props.options &&
                activeIndex + 1 < props.options.length)
            {
                setActiveIndex(activeIndex+1);
            }
        }
        else if(evt.key === "ArrowUp")
        {
            if(
                activeIndex - 1 >= -1)
            {
                setActiveIndex(activeIndex-1);
            }
        }
        else if(evt.key === "Enter")
        {
            evt.preventDefault();

            if(!showDropdown)
            {
                doShowDropdown();

            }else if(
                props.options &&
                activeIndex>=0 && activeIndex < props.options.length)
            {

                doSelect(props.options[activeIndex]);
            }
        }
        else if(evt.key === "Tab")
        {
            setShowDropdown(false);
        }
        else if (evt.key ==="Escape")
        {
            
            setShowDropdown(false);
        }else
        {
        }
        /*     
        else 
        {
            if(searchQuery != searchRef.current.value)
            {
                // isTypingRef.current = true;
            }
        }*/
    }


    useEffect(()=>{

        var listener = (evt:MouseEvent)=>{
            
            if(
                
                optionsContainerRef.current &&

                !(

                    optionsContainerRef.current.contains(evt.target as Element) 
                    ||
                    searchInputRef.current.contains(evt.target as Element)
                )
            )
            {
                // hide this
                setShowDropdown(false);
            }
        };


        window.addEventListener("click",listener);

        return ()=>{

            window.removeEventListener("click", listener);
        };
    },[]);

    useEffect(()=>{

        if(props.options)
        {
            for(var i = 0; i< props.options.length;++i)
            {
                var o = props.options[i];
                if(o.indexOf(props.value)===0)
                {
                    setActiveIndex(i);
                    break;
                }
            }
        }

    },
    // eslint-disable-next-line
    [props.value]);


    return <div className="position-relative">

        <input
            className={props.inputClassName}
            value={props.value??""}
            onChange={props.onChange}
            readOnly={props.readOnly}
            ref={searchInputRef}
            onKeyDown={onKeyDown}
            onFocus={doShowDropdown}
        />


        {showDropdown && 
        <div className='dropdown-menu w-100 show'>
        <div ref={optionsContainerRef}
        className="hok-data-select-option-container-2"
        style={{"overflowY":"auto", maxHeight:"50vh"}}
        >

            {props.options?.map((e,idx) => 
                

                <div key={idx}
                onClick={()=>
                    {

                                
                        doSelect(e);
                    }
                }
                className={
                `cursor-pointer hok-data-select-option ${
                    activeIndex === idx
                    ?'hok-data-select-option-selected':''}`}
                >{e}</div>
                    
                    
                    
            )}

        </div> 
        </div>}
    
    </div>

    ;

}