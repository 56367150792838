import React, { useEffect } from "react";
import { IHistory } from "react-router-dom";
import { getAppInitData } from "./auth/getAppInitData";


export function AppIndex(props:{
    history:IHistory
})
{


    useEffect(()=>{


        getAppInitData().then(res=>{

            if(!res.userBasicInfo)
            {
                props.history.push('/user-basic-info');
                /*
                window.location.href=
                `${serviceBasePath}/Identity/Account/Manage/UserBasicInfo?returnUrl=${encodeURIComponent('/')}`;
                */
            }
            else if(res.directories.length)
            {
                props.history.replace(`/d/${encodeURIComponent(res.directories[0].tag)}`);
            }



        }).catch((res)=>{
            
            // console.log(res);
            if(res.status!==401 && res.status !== 403)
            {
                alert("something went wrong");
            }
        });
    },
    // eslint-disable-next-line
    []);


    return <div>Redirecting...</div>;

}