import { ValidationErrors, ValidationSummary } from "hok-react";
import { ValidationProblemDetails } from "hoksource";
import React from "react";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EditStateBase } from "../types/EditStateBase";
import { DirectoryUserService } from "./DirectoryUserService";

export class AddMember extends DirectoryComponent<{},EditStateBase<string>>
{

    constructor(props)
    {
        super(props);
        this.state = {
            input:""
        };

    }


    handleSubmit= async (evt:React.FormEvent<HTMLFormElement>) =>
    {
        evt.preventDefault();
        
        
        this.setState({
            errors:null,
            isSubmitting:true,
        });

        var service = new DirectoryUserService(this.http);

        try
        {
            await service.addUser(this.state.input);

            this.goBack();
        }
        catch(err)
        {
            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }

        
        this.setState({isSubmitting:false});
        
        
    }

    render()
    {

        
        return <>
        <h1>Add</h1>

        <h4>Directory Member</h4>
        <hr />
        <div className="row">
        <div className="col-md-4">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <div className="form-group">
                <label>Email</label>
                <input value={this.state.input}

                onChange={e => this.setState({input:e.target.value})}/>;
                <ValidationErrors errors={this.state.errors} name="Email"/>

            </div>

            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>
                
        <div>
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
        </div>
    </>;
    }
}