
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Home } from "./Home";
import { Edit } from "./Edit";



export function SalesAgentsRoot()
{

    var match = useRouteMatch();

    return <Switch>
        <Route exact path={`${match.path}/`}>
            <Home />
        </Route>
        <Route path={`${match.path}/edit`}>
            <Edit />
        </Route>
        
    </Switch>;

}