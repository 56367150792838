import React from "react";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { StreamingArrayLoaderGetItemsRequest } from "hoksource";
import { PaymentTermsConfigurationService } from "./PaymentTermsConfigurationService";
import { PaymentTermsConfiguration } from "../models/entities/payment-terms-configuration";
import { InfiniteScroll } from "hok-react";



export class Home extends DirectoryComponent<{},{
    searchString?:string,
    includeInactive?:boolean
}>
{



    constructor(props)
    {
        super(props);
        this.state = {

            searchString:"",
        };
    }
    

    takeCount = 30;

    
    getItems = (filter:
        StreamingArrayLoaderGetItemsRequest<PaymentTermsConfiguration>
     )=>{


        
        var service = new PaymentTermsConfigurationService(this.http);
        return service.getList({
            skip : filter.currentIndex,
            take : this.takeCount,
            searchString: this.state.searchString,
            includeInactive: this.state.includeInactive
        });
    }


    render()
    {

        return <>
            
        <h1>PaymentTermsConfigurations</h1>
        <p>
            <DirectoryLink to={`/PaymentTermsConfigurations/edit`}>Create New</DirectoryLink>
        </p>



        <div className="row">

            <div className="col-md-6">
                search: 
                    
                <input 
                value={this.state.searchString}

                    onChange={e=>{
                        this.setState({searchString:e.target.value})
                    }}/>
            </div>
            
            <div className="col-md-6 text-right">
                <label>

                    
                <input type="checkbox" 
                checked={this.state.includeInactive}
                onChange={e=>{

                    
                    this.setState({includeInactive:e.target.checked})
                }}
                />

                &nbsp;
                show inactive
                </label>
            </div>
        </div>

        
<table className="table">
<thead>
    <tr>
        <th>
            Name
        </th>
        <th>Days To Pay</th>
        <th>Active</th>
        <th></th>
    </tr>
</thead>
<tbody>


    
<InfiniteScroll
    targetElement={window}
    maxTakeCount={this.takeCount}
    getItems={this.getItems}
    resetDependencyList={[
        this.state.searchString,
        this.state.includeInactive
    ]}
    resetDebounceDelay={400}
    >{infiniteScroll => 
    
    infiniteScroll.loadedItems?.map(e => 
        (
            <tr key={e.id}>
        <td>
            {e.name}
        </td>
        
        <td>{e.daysToPay}</td>
        <td>
            {e.active+""}
        </td>
        <td>
            
            <DirectoryLink to={`/PaymentTermsConfigurations/edit?id=${e.id}`}>Edit</DirectoryLink>
        </td>
    </tr>
        )
    )
    
    }


    </InfiniteScroll>

</tbody>
</table>

        </>;




    }
}