import { EntityItem } from "../../../models/entities/entities/entity-item";
import { Item } from "../../../models/entities/items/item";
import { Order } from "../../../models/entities/orders/order";
import { OrderLine } from "../../../models/entities/orders/order-line";
import { OrderTypeNameIds } from "../../../models/enums/OrderTypeNamesIds";

export function computeLineAssemblyQuantity(
    order:Order,
    
    //lines:OrderLine[],
    nextOrderLine:OrderLine,
    availableQuantity:number,
    parentOrderLineQuantity:number
    )
{

    var totalOrderItemQuantity = 0; // total quantity of the item in the order
    var totalOtherAssemblyQuantity = 0; // total assembly quantity of items not equal to nextOrderLine



    function processOrderLine(
        line:OrderLine,
        parentOrderLineQuantity:number,
    )
    {
        
        if(line.item?.id === nextOrderLine.item.id) // same itemid
        {
            if(line !== nextOrderLine) // not the line just add assemblyQuantity
            {
                var assemblyQuantity = line.assemblyOrder?.assemblyOrder?.quantity;
                if(!isNaN(assemblyQuantity))
                {
                    totalOtherAssemblyQuantity += assemblyQuantity;
                }
            }
            if(!isNaN(line.quantity) && !isNaN(parentOrderLineQuantity)) // has quantity
            {
                totalOrderItemQuantity += parentOrderLineQuantity * line.quantity;
            }
        }
    }

    for(let i of order.lines)
    {
        processOrderLine(i, 1);
        if(i.childLines)
        {
            for(let j of i.childLines)
            {
                processOrderLine(j, i.quantity || 0);
            }
        }
    }

    var quantityToAssemble = 
    
    Math.max(0,totalOrderItemQuantity - totalOtherAssemblyQuantity - availableQuantity);

    
    if(!isNaN(nextOrderLine.quantity) && !isNaN(parentOrderLineQuantity))
    {
        quantityToAssemble = Math.min(quantityToAssemble, 
            
            parentOrderLineQuantity * nextOrderLine.quantity);
    }

    return quantityToAssemble;
}


export function createAssemblyOrderLines
(prev:Order, itemWithBom:Item, quantityToAssemble: number)
{

    
    var prevAssemblyLines = prev.lines;

    var nextLines:OrderLine[] = [];
    var bom = itemWithBom.defaultBom;

    for(var i = 0, l = bom.lines.length;
        i < l; ++i)
    {
        var bomLine = bom.lines[i];
        var prevLine = prevAssemblyLines[i];
        var nextLine = {

            ...prevLine,
            item:bomLine.item,
            quantity: bomLine.quantity * quantityToAssemble
        };

        nextLines.push(nextLine);

    }

    return nextLines;
}


export function createLineWithAssemblyOrder(
    parentOrder:Order,
    orderLine:OrderLine, 
    quantityToAssemble: number)
{

    let prevAssemblyOrder:Order = orderLine.assemblyOrder;


    if(!prevAssemblyOrder)
    {
        // create it
        prevAssemblyOrder = {
            fromEntity:parentOrder.fromEntity,
            //entityItemWithBom.entity, // enforces entity
            typeName : OrderTypeNameIds.AssemblyOrder,
            lines:[],
            // fieldDetail:{},
        };
    }
    

    // change it
    var order : Order = {
        ...prevAssemblyOrder, // keeps the id
        assemblyOrder:{
            ...prevAssemblyOrder.assemblyOrder, // keeps the id
            item: orderLine.item,
            quantity:quantityToAssemble,
        },
        lines:createAssemblyOrderLines(prevAssemblyOrder, orderLine.item, quantityToAssemble)
    };

    return {
        ...orderLine,
        assemblyOrder:order
    } as OrderLine;
}



export function checkCreateAssemblyOrderLine(
    entityItemWithBom:EntityItem,
    order:Order,
    nextOrderLine:OrderLine,
    parentOrderLineQuantity:number
    )
{
    var bom = //entityItemWithBom?.item?.defaultBom;
    nextOrderLine.item?.defaultBom;
    var quantityToAssemble = 0;
    if(
        bom?.lines?.length // bom has items
        &&
        nextOrderLine.item // has item
        &&
        !isNaN(nextOrderLine.quantity) // with quantity
    )
    {
        quantityToAssemble = 
                computeLineAssemblyQuantity(
                    order, 
                    nextOrderLine,
                    entityItemWithBom?.availableQuantity ?? 0,
                    parentOrderLineQuantity
                    );
    }

    if(quantityToAssemble)
    {
        return createLineWithAssemblyOrder(
            order,
            nextOrderLine, quantityToAssemble);
    }else
    {
        if(nextOrderLine.assemblyOrder)
        {
            // remove assembly order
            return {...nextOrderLine, assemblyOrder: null};
        }
    }

    return nextOrderLine;
}