import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { convertJournalEntryForSaving } from "../journals/convertJournalEntryForSaving";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { ModelSyncServiceBase } from "./ModelSyncServiceBase";
import { objectStoreDeleteAsync } from "./ObjectStoreExtensions";
import { StoreIndexNames, StoreNames } from "./StoreNames";
import { SyncService } from "./SyncService";

export class JournalEntrySyncService extends ModelSyncServiceBase<JournalEntry>
{

    constructor(
        private http:DirectoryHttpService,
        private db:IDBDatabase,
        syncService:SyncService
        )
    {
        super(syncService);
    }

    
    async getEntriesFromStore()
    {
            
        var journalEntryStore = this.db.transaction(StoreNames.JournalEntries)
        .objectStore(StoreNames.JournalEntries);
        var index:IDBIndex = journalEntryStore.index(StoreIndexNames
          .JournalEntriesHasOfflineChangesIndex);
        var journalEntries = await index.hokGetAllAsync<JournalEntry>({count:100});

        
        return journalEntries;
    }

    
    async uploadEntries(entries: JournalEntry[])
    //: Promise<{ savedEntries: Entity[]; validationProblemDetails?: ValidationProblemDetails; }>
    {
        
        var toSave = entries.map(e => {

            if(typeof e.id === "string")
            {
              return e;
            }else
            {
              var {id, ...copy} = e;
              return copy as JournalEntry;
            }
  
          });
          
        var res = await this.http.post<JournalEntry[]>("/Journals/SaveJournalEntries", {
            body:toSave.map(e => convertJournalEntryForSaving(e)),
        });


          
        var txn = this.db.transaction([//StoreNames.Entities, 
          StoreNames.JournalEntries
        ], "readwrite");
        // var entityStore = txn.objectStore(StoreNames.Entities);
        var journalEntryStore = txn.objectStore(StoreNames.JournalEntries);

          
        // delete from local
        await Promise.all(entries.map(prev => 
          objectStoreDeleteAsync(journalEntryStore, prev.id)
          ));



        return {
            savedEntries:res
        };

    }


    
}