import { OrderType, OrderTypeNameIds } from "../..";
import { DefaultOrderLineBase, OrderLine } from "../../models/entities/orders/order-line";

export interface EditState
{
    
    //order?:Order,
    hasDuplicateReferenceId?:boolean,


    
    //itemIdFromEntityItemMap:{[key:string]:EntityItem},
    //itemIdFromEntityGroupEntityItemsMap:{[key:string]:EntityItem[]},


    hasChanges?:boolean;
    isSubmitting?:boolean;
    errors?:{[key:string]:string[]};
}



export function createNewLine(orderType:OrderType, sortOrder:number)
{
    if(orderType.customModel.name===OrderTypeNameIds.PhysicalInventoryOrder)
    {

        return {
            ...DefaultOrderLineBase,
            quantity:0, physicalQuantity:0, sortOrder:0} as OrderLine;
    }else
    {

        return {
            ...DefaultOrderLineBase,
            quantity:orderType.negativeLineQuantities?-1:1, 
            
            sortOrder:orderType.sortLinesByTagThenName?0:sortOrder} as OrderLine;
    }
}