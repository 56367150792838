import React from "react";
import { Order } from "../models/entities/orders/order";
import { OrderType, OrderTypeViewTemplate } from "../models/entities/orders/order-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { OrderService } from "./OrderService";
import { PrintComponent } from "./PrintComponent";



interface OrderPrintState
{
    order?:Order,
    viewTemplate?:OrderTypeViewTemplate

}
export class OrderPrint extends DirectoryComponent
<{

    orderType:OrderType
},OrderPrintState>
{

    

    id:string;
    templateId:string;


    constructor(props)
    {
        super(props);
        this.state={};
        
        // temporary hack

    }


    componentDidMount()
    {
        
        this.id = this.getQueryParam("id");
        this.templateId = this.getQueryParam("templateId");
                
        var template = this.props.orderType.viewTemplates.find(e => e.id === this.templateId);
        this.setState({viewTemplate:template});

        var service = new OrderService(this.context);

        
        service.find(this.id)
        .then(e=>{
            this.setState({
                order:e
            });
        });

        
    }


    componentDidUpdate(prev,prevState:OrderPrintState)
    {
        
        if(!prevState.order && this.state.order)
        {
            if(this.state.viewTemplate.printOnLoad)
            {
                window.print();
            }
        }
    }


    render()
    {

        if(!this.state.order)
        {
            return <div>Loading...</div>;
        }

        return <>

        

<style>
{`

html{
    height:100%;
}
body{
    height:100%;
    margin:0px;
}

#root
{
    height:100%;
}


#directory-layout-container
{
    max-width:initial!important;
    height:100%;
}

`}
</style>
            <PrintComponent
                orderTypeViewTemplate={this.state.viewTemplate}
                orderType={this.props.orderType}
                order={this.state.order}


            />
        </>;
    }
}