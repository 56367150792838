import React from 'react';
// eslint-disable-next-line
import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter } from 'react-router-dom';
import { AppIndex, SigninOAuth2 } from './erich-lib';
import { DirectoryLayout } from './erich-lib/shared/DirectoryLayout';
import { EditUserBasicInfo } from './erich-lib/users/EditUserBasicInfo';
// import { EddyTest } from './eddy-test/Test';

function App() {


    
  return <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Route exact path='/' component={AppIndex}/>
    <Route path='/user-basic-info' component={EditUserBasicInfo}/>
    <Route path='/signin-oauth2' component={SigninOAuth2}/>
    <Route path='/d/:directoryTag' component={DirectoryLayout}/>
  </BrowserRouter>;
}

export default App;
