import { FormGroup, FormInput, ValidationSummary } from "hok-react";
import React from "react";
import { EditController } from "./EditController";

export class Edit extends EditController
{

    render()
    {

        return <>
        <h1>{this.id?"Edit":"Create"}</h1>

        <h4>ItemCategory</h4>
        <hr />
        <div className="row">
        <div className="col-md-4">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input} 
            onChange={e => this.setState({input:e.target.value})}
            childrenProps={{errors:this.state.errors}}
            >
            {g =><>
                <FormInput
                    group={g}
                    groupKey="name"
                    name="Name"
                    labelText="Name"
                />

                                
                <FormInput
                    type="checkbox"
                    group={g}
                    groupKey="active"
                    name="Active"
                    labelText="Active"
                />

            </>}

            </FormGroup>
            
            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>




                
        <div>
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
            
        </div>
    </>;
    }
}