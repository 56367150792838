import React, { useContext, useMemo, useRef, useState } from "react";

import { DirectoryContext } from "../directory-shared/DirectoryContext";
import { Order } from "../models/entities/orders/order";
import { OrderType } from "../models/entities/orders/order-type";
import OrderSelectModal from "./OrderSelectModal";



export interface OrderSelectContextModel
{

    showOrderSelectModal(props:{
        orderTypeNameId:string,
        currentValue:Order,
        onSelect:(order:Order)=>void,
        
    }):void;
}


export const OrderSelectModalContext = React.createContext<OrderSelectContextModel>(null);


export function OrderSelectModalProvider(props)
{

    var dContext = useContext(DirectoryContext);

    var [value,setValue] = useState<Order>();
    var [orderType, setOrderType] = useState<OrderType>();
    var onSelectCallbackRef = useRef<(order:Order)=>void>();
    // var [onSelectCallback,setOnSelectCallback] = useState<>();
    var [showModal, setShowModal] = useState<boolean>(false);




    function showOrderSelectModal(props:{
        orderTypeNameId:string,
        currentValue:Order,
        onSelect:(order:Order)=>void,
        
    })
    {
        
        setValue(props.currentValue);
        var orderType = dContext.data
            .orderTypes.find(e => e.customModel.name === props.orderTypeNameId);
        
        setOrderType(orderType);
        onSelectCallbackRef.current = (order:Order)=>{
            props.onSelect(order);
        
            setShowModal(false);
        };
        setShowModal(true);
    }

    // eslint-disable-next-line
    var ctx = useMemo(()=>({
        showOrderSelectModal,
    }),
    // eslint-disable-next-line
    [dContext, orderType, value, showModal]);



    return <OrderSelectModalContext.Provider value={ctx}>

        {props.children}
        
        
        <OrderSelectModal className=""
            showModal={showModal}
            setShowModal={setShowModal}
            orderType={orderType}
            value={value}
            onSelect={onSelectCallbackRef.current}


        />

    </OrderSelectModalContext.Provider>;


}