import { extendFormGroupProps, FormGroup, FormGroupOrValueProps, Input } from "hok-react";
import { CustomFieldOptionBase } from "hoksource";
import React from "react";

export function CustomFieldOptionsInput
<
G = never>
(props:FormGroupOrValueProps<CustomFieldOptionBase[],G>)
{


    props = extendFormGroupProps(props as any);

    var addOption = ()=>{
        var newVal = props.value?.slice() || [];

        var max = newVal.reduce((max:number, curr)=>{

            if(max<curr.sortOrder)
            {
                return curr.sortOrder;
            }
            return max;
        },-1);
        
        newVal.push({
            text:"",
            value:"",
            sortOrder:max+1
        });

        props.onChange({target:{value:newVal}});
    };


    var removeOption = (idx:number)=>{
        
        var newVal = props.value.slice();
        newVal.splice(idx,1);
        


        props.onChange({target:{value:newVal}});


    };

    return <>
    <table className="table">
        <thead>
            <tr>
                <th>Value</th>
                <th>Text</th>
                <th>SortOrder</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {props.value &&
            <FormGroup value={props.value} onChange={props.onChange}>
                {listGroup => 
                
                props.value?.map((o,idx)=>

                <FormGroup 
                key={idx}
                group={listGroup} groupKey={idx}>
                    {g => 
                <tr key={idx}>
                    <td>
                        <Input group={g} groupKey="value" className="form-control"/>
                    </td>
                    <td>
                        <Input group={g} groupKey="text" className="form-control"/>
                    </td>
                    <td>
                        <Input group={g} groupKey="sortOrder"
                        type="number"
                        className="form-control"/>
                    </td>
                    <td>
                        <button type="button" className="btn btn-secondary"
                        
                        onClick={()=>removeOption(idx)}
                        >Remove</button>
                    </td>
                </tr>}
                </FormGroup>
            )
                }
            </FormGroup>
            }

        </tbody>
    </table>
    
    
    <button type="button" className="btn btn-primary"
    onClick={addOption}
    >Add Options</button>
    </>
    ;


}