import { CustomFieldBaseService } from "hoksource";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { CustomModelField } from "../models/entities/custom-field";

export class CustomFieldService extends CustomFieldBaseService
{

    constructor(private http:DirectoryHttpService)
    {
        super();

    }

    
    getList(modelType:string = "")
    {
        return this.http.post<CustomModelField[]>("/CustomFields/GetList", {
            hokQuery:{
                modelType
            }
        });
    }

    get(id:string)
    {

        return this.http.post<CustomModelField>("/CustomFields/Get", {
            hokQuery:{
                id
            }
        });
    }

    save(input:CustomModelField)
    {
        return this.http.post<CustomModelField>("/CustomFields/Save", {
            body:input
        });
    }
}