import { FormGroup, FormGroupContext, Input, ValidationSummary } from "hok-react";
import * as React from "react";
import { ChildItemsEditController } from "./ChildItemsEditController";
import {Toast } from 'reactstrap';
import { DirectoryLink } from "../../..";
import { ItemDefaultChildItem } from "../../../models/entities/items/item-default-child-item";
import { ItemSelect } from "../../ItemSelect";

export class ChildItemsEdit extends ChildItemsEditController
{
  toastRef = React.createRef<Toast>();


    render(){

      var item = this.state.item;

      if(!item)
      {
          return <div>loading</div>;
  
  
      }
  

return <>

      

<form onSubmit={e=> this.handleSubmit(e)}>

<FormGroup
  value={this.childItems}
  onChange={e=>this.setChilditems(e.target.value)}
>{g => <div>


    

<div className="row">
    <div className="col-4 col-md-4">
        Items
    </div>
    <div className="col-2 col-md-2">
        Qty
    </div>
    
    <div className="col-2 col-md-2">
      Default Unit Price (optional)
    </div>
</div>
{this.childItems.map((component,idx)=>
  <FormGroup
    key={idx}
    group={g}
    groupKey={idx}
  >{(cGroup:FormGroupContext<ItemDefaultChildItem>)=><>

  

  <div className="row">
    <div className="col-4 col-md-4">
      <ItemSelect
        value={cGroup.value.childItem}
        onChange={e => {
          this.handleComponentItemChange(e, idx);
        }}

        className="w-100"
      ></ItemSelect>
    </div>
    <div className="col-2 col-md-2">
        <Input type="number" className="w-100" 
          group={cGroup}
          groupKey="quantity"
        />

        {!this.isValidComponentQuantity(cGroup.value) &&
          <div className="alert alert-danger">
            Invalid Quantity
          </div>
        }
      
    </div>


    <div className="col-2 col-md-2">
        <Input type="number" className="w-100" 
          group={cGroup}
          groupKey="unitPrice"
        />

        {!this.isValidComponentUnitPrice(cGroup.value) &&
          <div className="alert alert-danger">
            Invalid Unit Price
          </div>
        }
      
    </div>




    
    <div className="col-2 col-md-2">
        {cGroup.value.childItem &&
          <span className="btn  btn-light"
          onClick={e => this.removeItem(component)}
          >
          Delete
          </span>}
    </div>
  </div> {/* end of row */}
  </>}
  </FormGroup>)
}




  </div>
    
  
}</FormGroup>
<br/>


<div className="form-group">
    <input type="submit" value="Save" className="btn btn-primary"
    disabled={this.state.isSubmitting}
    />
    
    &nbsp;&nbsp;

    <DirectoryLink to="/items">Back to List</DirectoryLink>
<br/>
<ValidationSummary errors={this.state.errors} name=""></ValidationSummary>
  

<br/>
</div>


</form>
  
  </>;
} //end of render
}