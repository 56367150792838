import { ISyntheticEvent } from "hok-react";
import { ValidationProblemDetails } from "hoksource";
import React from "react";
import { IModelServiceBase } from "../model-types/ModelTypeBaseService";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EditStateBase } from "../types/EditStateBase";
import { EditForm, IEditFormChildren } from "./EditForm";
import { DirectoryHttpService } from "../http/DirectoryHttpService";

export class EditControllerBase<T> extends DirectoryComponent<{}, EditStateBase<T>>
{


    id:string;
    serviceType:new (http:DirectoryHttpService) => IModelServiceBase<T>;



    componentDidMount()
    {
        this.id = this.getQueryParam("id");

        if(this.id)
        {
            
            var service = new this.serviceType(this.http);
            service.findById(this.id).then(e => this.setState({
                input:e
            }));
        }
    }



    
    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>
    {

        
        evt.preventDefault();

        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
            
            var service = new this.serviceType(this.http);
            await service.save(this.state.input);

            this.history.goBack();

        }catch(err:any)
        {


            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }


        this.setState({isSubmitting:false});
        
        

    }



    handleValueChange = (evt:ISyntheticEvent<{value:T}>)=>{

        this.setState({input:evt.target.value})
    };
    colClassName:string = "col-md-4";
    get title()
    {
        return "";
    }




    renderEditForm(renderer : IEditFormChildren<T>)
    {
        return <EditForm 
            isEdit={Boolean(this.id)}
            title={this.title}
            value={this.state.input}
            errors={this.state.errors}
            onChange={this.handleValueChange}
            onSubmit={this.handleSubmit}
            isSubmitting={this.state.isSubmitting}

            colClassName={this.colClassName}

        >
            {renderer}
        </EditForm>;
    }
}