import { ValidationSummary, FormGroup, FormInput, CustomFieldsInput, Input, ISyntheticEvent } from "hok-react";
import { Customer } from "../../models/entities/entities/customer";
import { Entity, EntityAddress } from "../../models/entities/entities/entity";
import { EntityType } from "../../models/entities/entities/entity-type";
import { EntityTypeNameIds } from "../../models/enums/EntityTypeNameIds";
import { ErrorsType } from "../../models/errors-type";
import { PaymentTermsConfigurationSelect } from "../../payement-terms-configurations/PaymentTermsConfigurationSelect";
import { SalesAgentSelect } from "../../sales-agents/SalesAgentSelect";
import React from 'react';
import { DirectoryCurrencyCodeSelect } from "../../directory-shared/DirectoryCurrencyCodeSelect";

export function EditEntityInput(props:{

    type:EntityType,
    errors:ErrorsType,
    value:Entity,
    onChange:(evt:{target:{value:Entity}})=>void,
    readOnly?:boolean
})
{

    const handleCurrencyCodeChange = (evt:ISyntheticEvent<{value:string}>)=>{
        var nextVal:Entity = {
            ...props.value,
            balanceDetail:{currencyCode:evt.target.value}
        };

        props.onChange({target:{value:nextVal}});
    };
    
    const addAddress = ()=>{
        var nextAddresses = props.value.addresses?.slice() || [];

        nextAddresses.push({
        });

        var nextVal={

            ...props.value,
            addresses:nextAddresses
            
        };

        props.onChange({target:{value:nextVal}});
    };

    
    const removeAddress = (idx:number)=>{
        
        var nextAddresses = props.value.addresses.slice();
        nextAddresses.splice(idx,1);
        

        var nextVal={

            ...props.value,
            addresses:nextAddresses
            
        };

        props.onChange({target:{value:nextVal}});

    };



    
    var isCustomer = props.type.customModel.name === EntityTypeNameIds.Customer;

    return <>
            <ValidationSummary errors={props.errors} name=""/>

            <FormGroup value={props.value} 
            onChange={e => props.onChange({target:{value:e.target.value}})}
            childrenProps={{errors:props.errors}}
            >
            {g =><>
                <FormInput
                    group={g}
                    groupKey="name"
                    name="Name"
                    labelText="Name"
                    readOnly={props.readOnly}
                />

                <FormInput
                    group={g}
                    groupKey="tag"
                    name="Tag"
                    labelText="Input Id/SKU"
                    readOnly={props.readOnly}
                />
                <div className="form-group">
                    <label>Currency Code</label>
                    
                    <DirectoryCurrencyCodeSelect
                                className="form-control"
                                value={props.value.balanceDetail.currencyCode}
                                onChange={handleCurrencyCodeChange}
                            />
                </div>

                <FormInput
                    type="checkbox"
                    group={g}
                    groupKey="active"
                    name="Active"
                    labelText="Active"
                    readOnly={props.readOnly}
                />

                {isCustomer &&

                    <FormGroup<Customer, Entity> group={g}
                    groupKey="customer">
                        {cg => <>
                        
                                
                        <FormInput
                            group={cg}
                            groupKey="discountRate"
                            name="DiscountRate"
                            labelText="DiscountRate, 0.1 = 10% off"
                            readOnly={props.readOnly}
                        />
                        
                        <div className="form-group">
                            <label>Default Payement Terms Configuration</label>
                            <PaymentTermsConfigurationSelect group={cg}
                            groupKey="defaultPaymentTermsConfiguration"
                            readOnly={props.readOnly}
                            />
                        </div>
                        
                        <div className="form-group">
                            <label>Default Sales Agent</label>
                            <SalesAgentSelect group={cg}
                            groupKey="defaultSalesAgent"
                            readOnly={props.readOnly}
                            />
                        </div>
                        
                        
                        
                        
                        
                        </>}
                    </FormGroup>                
                }

                <CustomFieldsInput
                group={g}
                fields={props.type.customModel.fields}
                namePrefix=""
                readOnly={props.readOnly}
                />


                
{(isCustomer 

||
props.type.customModel.name === EntityTypeNameIds.Location)
&&
<>
                <FormGroup<EntityAddress[], Entity> group={g} groupKey="addresses">{

                    addressesG => 
                    <div>
                        <h5>Addresses</h5>
                        <hr/>

{
                            addressesG.value.map((a,idx) => 
                            
                            <FormGroup<EntityAddress, EntityAddress[]>
                                key={idx}
                             group={addressesG} groupKey={idx}>{
                                aG =>
                                <div className="form-group">
                            <div className="input-group">

                                <Input className="form-control"
                                    group={aG} groupKey="fullAddress"
                                    readOnly={props.readOnly}
                                />
                                <div className="input-group-append">


                                <button type="button" className="btn btn-secondary"
                                
                                onClick={()=>removeAddress(idx)}
                                disabled={props.readOnly}
                                >Remove</button>


                                </div>
                            </div></div>
                            }
                            </FormGroup>
                            )

                        }
                    </div>
                    
                }</FormGroup>

                
<button type="button" className="btn btn-primary"
                onClick={addAddress}
                disabled={props.readOnly}
                >Add Address</button>
</>}




            </>}

            </FormGroup>
           
            <br/>
            <br/>
            

    </>;

}