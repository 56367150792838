
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";

import { JournalType } from "../models/entities/journals/journal-type";
import { GetJournalListFilter, JournalService } from "./JournalService";
import { Entity } from "../models/entities/entities/entity";
import { JournalEntriesInputModalConfig } from "./JournalEntriesInputModalController";
import { IdentityResult, ValidationProblemDetails } from "hoksource";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { stringOrNullIfNumber } from "../functions/stringOrNullIfNumber";
import { SyncEventType } from "../db/SyncEventType";
import { SyncEntryUpdateEvent } from "../db/SyncService";

export interface HomeState
{
    searchString?:string,
    fromEntity?:Entity,
    toEntity?:Entity,
    entryType?:string,

    includeZeroBalance?:boolean,
    resetInfiniteScrollCtr:number,


    selectedEntriesMap:{[key:string]:JournalEntry};

    showModal?:boolean,

}

export class HomeController extends 
DirectoryComponent<{journalType:JournalType},HomeState>
{

    journalEntriesInputModalConfig?:JournalEntriesInputModalConfig;

    constructor(props)
    {
        super(props);
        this.state={
            resetInfiniteScrollCtr:0,
            selectedEntriesMap:{}
        };
    }

    handleSyncEntryUpdate = (evt:SyncEntryUpdateEvent<Entity[]>) =>{
        console.log(evt);
        if(evt.entryType === "Entity" || evt.entryType === "JournalEntry")
        {
            this.setState({resetInfiniteScrollCtr:this.state.resetInfiniteScrollCtr+1});
        }
    };

    componentDidMount()
    {
        this.context.syncService.addEventListener(SyncEventType.UpdateEntries, this.handleSyncEntryUpdate);
    }

    componentWillUnmount()
    {
        this.context.syncService.addEventListener(SyncEventType.UpdateEntries, this.handleSyncEntryUpdate);
    }

    componentDidUpdate(prevProps,prevState:HomeState)
    {
        var prevDeps = this.createResetDependencyList(prevState);
        var stateDeps = this.createResetDependencyList(this.state);

        for(let i=0; i < prevDeps.length; ++i)
        {
            if(prevDeps[i] !== stateDeps[i])
            {
                // data changed
                // reset selected
                this.setState({
                    selectedEntriesMap:{}
                });
            }
        }
    }


    takeCount = 20;
    



    getItems = (filter)=>{

        
        var service = new JournalService(this.context);

        var requestFilter : GetJournalListFilter= {
            skip: filter.currentIndex,
            take: this.takeCount,
            fromEntityId: stringOrNullIfNumber(this.state.fromEntity?.id),
            toEntityId: stringOrNullIfNumber(this.state.toEntity?.id),
            journalType : this.props.journalType.customModel.name,
            
            includeZeroBalance : this.state.includeZeroBalance
          };

          if(this.state.entryType)
          {
              requestFilter.entryType = this.state.entryType;
          }

        if(this.state.searchString)
        {
            requestFilter.searchString = this.state.searchString;
        }
    
        return service.getJournalEntries(requestFilter);
    }


    createResetDependencyList(state:HomeState)
    {
        return [
            state.searchString,
            state.fromEntity,
            state.toEntity,
            state.includeZeroBalance,
            state.resetInfiniteScrollCtr
        ];
    }

    hasSelectedEntry()
    {
        for(let i in this.state.selectedEntriesMap)
        {
            if(this.state.selectedEntriesMap[i])
            {
                return true;
            }
        }

        return false;

    }

    singleToEntitySelected = ()=>{

        var toEntityId = null;

        var entries = this.state.selectedEntriesMap;
        for(let i in entries)
        {
            var o = entries[i];
            if(o)
            {

                if(!toEntityId)
                {
                    toEntityId = o.toEntity.id;
                }
    
                if(toEntityId !== o.toEntity.id)
                {
                    return false;
                }
            }
        }

        return toEntityId;
    };

    selectedHasDifferentInputEntryTypeAmountSign = ()=>{

        var journalType = this.props.journalType;

        var entries = this.state.selectedEntriesMap;
        for(let i of journalType.entryTypeInputOptions)
        {
            for(let x in entries)
            {
                var o = entries[x];
                if(o)
                {
                    if(i.amountSign * o.balance < 0)
                    {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    
    entitiesSelectedHaveTheSameBalanceSign = ()=>{

        var sign : number = null;

        var entries = this.state.selectedEntriesMap;
        for(let i in entries)
        {
            var o = entries[i];
            if(o)
            {
                if(!sign)
                {
                    sign = o.balance;
                }
    
                if(sign * o.balance < 0) // different sign
                {
                    return false;
                }
            }
        }

        return true;
    };

    
    entitiesSelectedHaveTheSameCurrencyCode = ()=>{

        var sign : number = null;

        var entries = this.state.selectedEntriesMap;

        var first = true;
        var currencyCode:string;
        for(let i in entries)
        {
            var o = entries[i];
            if(o)
            {
                if(first)
                {
                    currencyCode = o.currencyCode;
                }else if(o.currencyCode?.toLowerCase() !== currencyCode?.toLowerCase())
                {
                    return false;

                }

            }

            first = false;
        }

        return true;
    };


    hasVoidedSelectedEntries = ()=>{

        var entries = this.state.selectedEntriesMap;
        for(let i in entries)
        {
            var o = entries[i];
            if(o)
            {
                if(o.isVoided)
                {
                    return true;
                }
            }
        }

        return false;
    };

    getSelectedEntries = ()=>{

        var selectedEntries:JournalEntry[] = [];
        var selectedMap = this.state.selectedEntriesMap;
        for(var i in selectedMap)
        {
            var o = selectedMap[i];
            if(o)
            {
                selectedEntries.push(o);
            }

        }

        return selectedEntries;

    };

    openJournalEntriesInputModal = (selectedEntries:JournalEntry[]) =>
    {



        this.journalEntriesInputModalConfig = {
            title:selectedEntries?"Settle Journal Entries":"Add Journal Entries",

            applyToJournalEntries: selectedEntries 
        }

        this.setState({
            showModal:true,
        });
    };

    handleAddJournalEntriesClick = () =>
    {
        var selectedEntries =this.getSelectedEntries();
        
        selectedEntries = selectedEntries.length? selectedEntries : null;
        this.openJournalEntriesInputModal(selectedEntries);
    };

    
    handleBalanceSelectedClick = async () =>
    {
        try{
            var service = new JournalService(this.context);

            var res = await service.balanceJournalEntries({journalEntryIds:
                this.getSelectedEntries().map(e => e.id)
                });

            if(res.succeeded)
            {
                    
                this.setState({resetInfiniteScrollCtr:
                    this.state.resetInfiniteScrollCtr+1,
                
                });
            }

        }catch(err:any)
        {
            if(err.status === 400)
            {
                alert((err as ValidationProblemDetails).errors[""][0]);
            }
        }
    };


    /*
    hasInputEntryTypeWithAmountSign = (sign:number)=>{

        var journalType = this.props.journalType;

        for(let i of journalType.entryTypeInputOptions)
        {
            if(i.amountSign * sign > 0)
            {
                return true;
            }
        }

        return false;
    };*/

    
    handleSettleJournalEntryClick = (evt:{target:{value:JournalEntry}}) =>
    {
        
        this.openJournalEntriesInputModal([evt.target.value]);
    };

    handleModalClose = (evt:IdentityResult)=>{



        if(evt?.succeeded)
        {

            this.setState({resetInfiniteScrollCtr:
                this.state.resetInfiniteScrollCtr+1,
            
            });
        }

        this.setState({showModal:false});
    };



}