import React, { useEffect, useMemo, useState } from "react";
import { DirectoryAppProps, saveOAuthTokens } from "..";
import { CustomFieldService } from "../custom-fields/CustomFieldService";
import { DbService } from "../db/DBService";
import { SyncService } from "../db/SyncService";
import { SyncBroadcastChannelName } from "../ErichConstants";
import { mergeErrors } from "../functions/mergeErrors";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { oauth2HttpClient } from "../http/oauth2HttpClient";
import { ErrorsType } from "../models/errors-type";
import { useDirectoryService } from "./useDirectoryService";

export function useDirectoryApp(props:DirectoryAppProps)
{
    
    var directoryTag=props.match.params.directoryTag;


    var [accessToken, setAccessToken] = useState(oauth2HttpClient.accessToken);
    var [isOffline, setIsOffline] = useState(!navigator.onLine);
    var [isSyncing, setIsSyncing] = useState(false);
    var [syncErrors, setSyncErrors] = useState<{[key:string]:string[]}>();

    var httpRef = React.useRef<DirectoryHttpService>();
    var dbServiceRef = React.useRef<DbService>();
    var syncServiceRef = React.useRef<SyncService>();
    var syncBroadcastChannelRef = React.useRef<BroadcastChannel>();
    
    if(!httpRef.current)
    {
        httpRef.current = new DirectoryHttpService(directoryTag);
        dbServiceRef.current = new DbService(directoryTag);

        syncBroadcastChannelRef.current = 
            new BroadcastChannel(SyncBroadcastChannelName);

        syncServiceRef.current = new SyncService(
            httpRef.current,
            dbServiceRef.current, 
            syncBroadcastChannelRef.current);
    }


    var directoryService = useDirectoryService(
        directoryTag, 
        httpRef.current, dbServiceRef.current);
    var data = directoryService.data;

    useEffect(()=>{

        const handleRefreshToken = ()=>{

            setAccessToken(oauth2HttpClient.accessToken);
            saveOAuthTokens();
            
        };

        oauth2HttpClient.addEventListener("refresh", handleRefreshToken);

        return ()=>{
            oauth2HttpClient.addEventListener("refresh",handleRefreshToken);

        };


    },[]);


    /*
    useEffect(()=>{

        async function trySync()
        {
            
            if(!httpRef.current.isOffline)
            {

                setIsSyncing(true);
                try
                {

                    await syncServiceRef.current.doSync();
                }catch(e:any)
                {
                    if(e.message)
                    {
                        let nextErrors:ErrorsType = {...syncErrors, 
                            "":syncErrors?syncErrors[""]?.concat(e.message):[e.message]};
                        
                        
                        setSyncErrors(nextErrors);
                    }

                    if(e.errors)
                    {
                        let nextErrors = mergeErrors(syncErrors, e.errors);

                        setSyncErrors(nextErrors);
                    }

                }
                setIsSyncing(false);


            }
        }


        function updateOffline()
        {
            setIsOffline(httpRef.current.isOffline);
            trySync();
        }


        window.addEventListener("online", updateOffline);
        window.addEventListener("offline", updateOffline);
        httpRef.current.addEventListener("hasconnectionchange", updateOffline);
        trySync();

        return ()=>{

            

            window.removeEventListener("online", updateOffline);
            window.removeEventListener("offline", updateOffline);
            httpRef.current.removeEventListener("hasconnectionchange", updateOffline);
            
            
            syncBroadcastChannelRef.current.close();
        };
        // eslint-disable-next-line
    },[]);*/


    function createDirectoryContext()
    {
        var directoryTag = props.match.params.directoryTag;
        var directoryPath = `/d/${encodeURIComponent(directoryTag)}`;


        
        
        var history = {

            goBack:()=>{
                props.history.goBack();

            },

            push:(path:string)=>{

                props.history.push(directoryPath + path)
            },

            
            replace:(path:string)=>{
                props.history.replace(directoryPath + path)
            },
        };

        

        return {

            isOffline,
            isSyncing,
            syncErrors: syncErrors,
            accessToken,


            directoryTag:directoryTag,
            directoryBasePath: directoryPath,
            history:history, // mutable so it is ok

            // services
            http:httpRef.current, 
            dbService:dbServiceRef.current,
            directoryService:directoryService,
            syncService:syncServiceRef.current,
            customFieldService:new CustomFieldService(httpRef.current),



            data:data,
            location:props.location,
            searchParams:new URLSearchParams(props.location.search)

        }// as DirectoryContextModel
        ;
    }

    // eslint-disable-next-line
    return useMemo(createDirectoryContext, [
        isOffline,
        isSyncing, syncErrors, 
        props.location, data,
        accessToken
    ]);

}


export type DirectoryContextModel = ReturnType<typeof useDirectoryApp>;