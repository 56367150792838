




import { InfiniteScroll } from "hok-react";
import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { ItemCategorySelect } from "../item-categories/ItemCategorySelect";
import { HomeController } from "./HomeController";

export class Home extends HomeController
{

    

    render()
    {

    return <>
        <h1>Items</h1>
        <p>
            <DirectoryLink to="/items/edit">Create New</DirectoryLink>
        </p>
    <div className="row">

        <div className="col-md-4">
            <label>search</label> 
            <input 
            className="form-control"
            value={this.state.searchString}

                onChange={e=>{

                    this.setState({searchString:e.target.value})
                }}/>
        </div>
        
        
        <div className="col-md-4">
            <label>category </label>
            <ItemCategorySelect 
            
            className="form-control"
            value={this.state.category}
            onChange={e => this.setState({category:e.target.value})}

            />
            
        </div>

        
        <div className="col-md-4 text-right">
            <label>
                <input type="checkbox" 
                checked={this.state.includeInactive}
                onChange={e=>{
                    this.setState({includeInactive:e.target.checked});
                }}
                />

                &nbsp;
                show inactive

            </label>
        </div>
    </div>



<table className="table">
<thead>
    <tr>
        <th>
            Name
        </th>
        <th>
            Tag
        </th>
        <th>Category</th>
        <th>UPrice</th>
        <th>
            Active
        </th>
        <th></th>
    </tr>
</thead>
<tbody>

<InfiniteScroll
    targetElement={window}
    maxTakeCount={this.takeCount}
    getItems={this.getItems}
    resetDependencyList={[
        this.state.searchString,
        this.state.category,
        this.state.includeInactive,
    ]}
    resetDebounceDelay={400}
    
    >{infiniteScroll => 
        infiniteScroll.loadedItems?.map(e => 
            (


                <tr key={e.id}>
                <td>
                    {e.name}
                </td>
                <td>
                    {e.tag}
                </td>
                <td>{e.category?.name}</td>
                    <td>{e.unitPrice?.toFixed(2)}</td>
                    <td>
                        {e.active?"Y":"N"}
                    </td>
                <td>

                    
    <UncontrolledDropdown tag="span">
        <DropdownToggle tag="span" caret className="cursor-pointer btn-link">
            Options
        </DropdownToggle>
        <DropdownMenu>
            
            {/* guid is url safe */}
            <DirectoryLink to={`/items/edit?id=${e.id}`}
            
            ><DropdownItem>Edit</DropdownItem>
            </DirectoryLink>

            <DirectoryLink to={`/items/${e.id}/unit-of-measures`}
            
            ><DropdownItem>Measure Units</DropdownItem>
            </DirectoryLink>

            <DirectoryLink to={`/items/${e.id}/childitems`}
            
            ><DropdownItem>Child Items</DropdownItem>
            </DirectoryLink>

{/*             
            <DirectoryLink to={`/items/bom?id=${e.id}`}
            
            ><DropdownItem>Bill of material</DropdownItem>
            </DirectoryLink> */}


        </DropdownMenu>
    </UncontrolledDropdown>

                </td>
            </tr>

            )
            
            
            
            
            )
    }
    </InfiniteScroll>



</tbody>
</table>

        </>
    ;
    }
}