import { HokEventTarget, IEventInterface, IEventListener, requestLock } from "hoksource";
import { SyncLockName } from "../ErichConstants";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { ISyncable } from "../models/base/ISyncable";
import { DbService } from "./DBService";
import { EntitySyncService } from "./EntitySyncService";
import { JournalEntrySyncService } from "./JournalEntrySyncService";
import { objectStorePutAsync } from "./ObjectStoreExtensions";
import { OrderSyncService } from "./OrderSyncService";
import { SyncEventType } from "./SyncEventType";

export interface SyncEntitiesUpdateModel<T = any>
{
  
    type:"entriesupdate",
    entityType:"Entity"|"Order"|"JournalEntry",
    prevEntries:T[],
    nextEntries:T[]
  
  
}

export interface SyncEntryUpdateEvent<T>{
  type:SyncEventType.UpdateEntry,
  target:SyncService,
  entryType:"Entity"|"Order"|"JournalEntry",
  prevValue:T,
  nextValue:T
}

export class SyncService extends HokEventTarget<SyncService>
{

    constructor(
      private http:DirectoryHttpService,
      private dbService:DbService,
      private broadcastChannel:BroadcastChannel
      )
    {
      super();

      this.broadcastChannel.addEventListener("message", (evt)=>{



        var data = evt.data as SyncEntitiesUpdateModel;

        if(data.type === "entriesupdate")
        {
          this.handleSyncEntitiesUpdateEvent(data);
        }
      });
    }

    broadcastEntitiesUpdateEvent<T>(evt:SyncEntitiesUpdateModel<T>)
    {
      this.broadcastChannel.postMessage(evt);
    }

    handleSyncEntitiesUpdateEvent(evt:SyncEntitiesUpdateModel)
    {

      // chop it
      for(let i=0;i<evt.prevEntries.length; ++i) // trigger all of this
      {
        var prev = evt.prevEntries[i];
        var next = evt.nextEntries[i];
        this.dispatchEvent({
          type:SyncEventType.UpdateEntry,
          target:this,
          entryType:evt.entityType,
          prevValue:prev,
          nextValue:next
        });

      }

      
      this.dispatchEvent({
        type:SyncEventType.UpdateEntries,
        target:this,
        entryType:evt.entityType,
        prevValue:evt.prevEntries,
        nextValue:evt.nextEntries
      });

    }

    addEventListener<E extends IEventInterface<SyncService>>
      (type:SyncEventType, listener:(e:E)=>void){
      super.addEventListener(type, listener as IEventListener<SyncService>);
    }
    
    removeEventListener<E extends IEventInterface<SyncService>>
      (type:SyncEventType, listener:(e:E)=>void){
      super.removeEventListener(type, listener as IEventListener<SyncService>);
    }




    async saveEntitiesOffline<T extends ISyncable>(
      storeName:string,
      entities:T[],
      changePropertyPredicates:((t:T)=>any)[]
      )
    {
            
        var db = await this.dbService.open();

        var transaction = db.transaction(storeName, "readwrite");

        var itemStore = transaction
        .objectStore(storeName);

        entities.forEach(next=>{

          // item.lastSyncTime = now;

          var req = itemStore.get(next.id);
          
          req.onsuccess = (evt) =>{
            var prev = req.result as T;

            var hasChanges = !prev;

            if(
              !hasChanges &&
              new Date(prev.lastModifiedTime).getTime() < new Date(next.lastModifiedTime).getTime())
            {
              hasChanges = true;
            }

            if(!hasChanges && changePropertyPredicates)
            {
              for(let i of changePropertyPredicates)
              {
                if(i(prev) !== i(next))
                {
                  hasChanges = true;
                  break;
                }
              }
            }

            if(hasChanges)
            {
              itemStore.put(next);
            }
          };
      });
    }



    



  

    async updateOfflineEntities<T>(
      objectStore:IDBObjectStore,
      idx:IDBIndex, 
      value:string,
      updateFn:(t:T)=>T)
    {
      var entries:T[]  = await idx.hokGetAllAsync({query:IDBKeyRange.only(value)});
//      var req:IDBRequest<T[]> = idx.getAll(IDBKeyRange.only(value));

      return await Promise.all(
        entries
        .map(updateFn)
        .map(e => objectStorePutAsync(objectStore, e))
        );
    }





    async doSync()
    {
      
      await requestLock(SyncLockName, async()=>{ // lock it here
                  
        var db = await this.dbService.open();

        try
        {
          
          var entitySyncService = new EntitySyncService(this.http, db, this);
          await entitySyncService.uploadOfflineEntries();
        }
        catch(e)
        {
          throw new Error("Error syncing entities.");
        }
        
        try
        {
          var orderSyncService = new OrderSyncService(this.http, db, this);
          await orderSyncService.uploadOfflineEntries();
        }
        catch(e:any)
        {
          if(e.errors)
          {
            throw e;
          }
          throw new Error("Error syncing orders.");
        }

        
        try
        {
          
          var journalEntrySyncService = new JournalEntrySyncService(this.http, db, this);
          await journalEntrySyncService.uploadOfflineEntries();
        }
        catch(e:any)
        {
          if(e.errors)
          {
            throw e;
          }

          throw new Error("Error syncing entities.");
        }
      });
    }
}