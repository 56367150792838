import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { PaymentTermsConfiguration } from "../models/entities/payment-terms-configuration";

export class PaymentTermsConfigurationService
{

    controllerName:string = "PaymentTermsConfigurations";

    constructor(private http:DirectoryHttpService)
    {


    }


    getList(filter:{
        searchString?:string,
        skip?:number,
        take?:number,
        includeInactive?:boolean
    } = undefined)
    {
        return this.http.post<PaymentTermsConfiguration[]>(`/${this.controllerName}/GetList`, {
            body:filter
        });
    }

    find(id:string)
    {

        return this.http.post<PaymentTermsConfiguration>(`/${this.controllerName}/Find`, {
            hokQuery:{
                id
            }
        });
    }

    save(input:PaymentTermsConfiguration)
    {
        return this.http.post<PaymentTermsConfiguration>(`/${this.controllerName}/Save`, {
            body:input
        });
    }
}

