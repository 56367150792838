import { DirectoryComponent } from "../../directory-shared/DirectoryComponent";
import { EntityType } from "../../models/entities/entities/entity-type";
import { Entity } from "../../models/entities/entities/entity";
import { EntityService } from "../EntityService";
import { EditStateBase } from "../../types/EditStateBase";
import { SyncEntryUpdateEvent } from "../../db/SyncService";
import { SyncEventType } from "../../db/SyncEventType";


export function createNewEntity(typeName:string)
{
    return {
        typeName:typeName,
        tag:"",
        active:true,
        customer:{},
        addresses:[],
        balanceDetail:{
            currencyCode:"PHP"
        }
    } as Entity;
}

interface EditControllerProps
{
    entityType:EntityType,
    onSave:(entity:Entity)=>void
}

export class EditController extends
DirectoryComponent<EditControllerProps,
EditStateBase<Entity>
>
{

    id:string;

    constructor(props:EditControllerProps)
    {
        super(props);

        this.state=
        
        {
            input:createNewEntity(props.entityType.customModel.name)
        };
    }



    updateId(id:string)
    {
        this.id = id;
        this.history.replace(
`/${this.props.entityType.customModel.name}/edit?id=${encodeURIComponent(id)}`);

        this.setState({input:{
            ...this.state.input,
            id:id
        }});
    }
    
    handleSyncEntryUpdate = (evt:SyncEntryUpdateEvent<Entity>) =>{
        if(evt.entryType === "Entity" && 
            evt.prevValue.id.toString() === this.id)
        {
            this.updateId(evt.nextValue.id);
        }
    };

    
    componentDidMount()
    {
        this.id = this.getQueryParam("id");

        if(this.id)
        {
            var service = new EntityService(this.context);
            service.find(this.id).then(e => this.setState({
                input:e
            }));
        }
        
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);

        // add id change listener
    }

    componentWillUnmount()
    {
        // remove id change listener
        this.context.syncService.addEventListener(SyncEventType.UpdateEntry, this.handleSyncEntryUpdate);
    
    }



}