import React from "react";
import { EntitySelect } from "../entities/EntitySelect";
import { getTagDisplayName } from "../functions/DataUtils";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { JournalType } from "../models/entities/journals/journal-type";
import { EntityTypeNameIds } from "../models/enums/EntityTypeNameIds";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { HomeController } from "./HomeController";
import { JournalEntriesInputModal } from "./JournalEntriesInputModal";
import { JournalEntryTypeLink } from "./JournalEntryTypeLink";
import { InfiniteScroll } from "hok-react";
import { UserBasicInfoName } from "../components/UserBasicInfoName";



export class Home extends HomeController
{

    render()
    {

        var journalType= this.props.journalType;
        return <>

        
<JournalEntriesInputModal

show={this.state.showModal} 
onClose={this.handleModalClose}
journalType={journalType}
config={this.journalEntriesInputModalConfig}

/>


        <h1>
            {journalType.customModel.displayName} Entries
        </h1>
        
         <div className="row">

            
            <div className="col-md-4 d-flex">
              
            <label>EntryType</label>
        
            <input value={this.state.entryType || ""} 
                onChange={evt => this.setState({entryType:evt.target.value})}
                
                className="form-control flex-grow-1"
                />
        
            </div>
            <div className="col-md-4 d-flex">
        
                <label className="">Loc</label>
            
                <EntitySelect
                    value={this.state.fromEntity}
                    onChange={evt => this.setState({fromEntity:evt.target.value})}
                    entityTypeNameId={EntityTypeNameIds.Location}
                    className="form-control flex-grow-1"
                />
            </div>
            <div className="col-md-4 d-flex">

                <label className="">{journalType.toEntityTypeName}</label>
                <EntitySelect value={this.state.toEntity}
                    onChange={e=>this.setState({toEntity:e.target.value})}
                    entityTypeNameId={journalType.toEntityTypeName}
                    className="form-control flex-grow-1"
                />
            </div>

            <div className="col-md-4 d-flex">
              
            <label>RefId</label>
        
            <input value={this.state.searchString} 
                onChange={evt => this.setState({searchString:evt.target.value})}
                
                className="form-control flex-grow-1"
                />
        
            </div>
            <div className="col-md-4">
                    <label>
                    
                    <input type="checkbox" checked={this.state.includeZeroBalance}
                    onChange={evt=>this.setState({includeZeroBalance:evt.target.checked})}
                    />
                    Show 0 balanced entries</label>
                
            </div>
        </div>
        
        
        <div>
            <button onClick={this.handleAddJournalEntriesClick}
            disabled={

                !journalType.entryTypeInputOptions.length || 
                (
                this.hasSelectedEntry() && 
                
                (!this.singleToEntitySelected()
            
                ||
                
                !this.selectedHasDifferentInputEntryTypeAmountSign()
                
                ||
                
                !this.entitiesSelectedHaveTheSameCurrencyCode()

                ||
                this.hasVoidedSelectedEntries()
                )
                
                )
            }

            >{this.hasSelectedEntry() ?
                "Settle Selected Entries"
                :
                "Add Journal Entries"
            }</button>

            &nbsp;&nbsp;
            <button onClick={this.handleBalanceSelectedClick}
            // disabled={this.singleToEntitySelected() && !this.entitiesSelectedHaveTheSameBalanceSign()}
            disabled={
                !(
                this.hasSelectedEntry() &&
            this.singleToEntitySelected() &&
            !this.entitiesSelectedHaveTheSameBalanceSign() &&
            this.entitiesSelectedHaveTheSameCurrencyCode() &&
            !this.hasVoidedSelectedEntries()
            
            )
            }
            
            >Balance Selected</button>
            
        </div>
        
        <table className="table">
          <thead>
              <tr>
                  <th></th>
                  <th>
                      InputTime
                  </th>
                  
                  <th>
                      Loc
                  </th>
                  <th>
                      {journalType.toEntityTypeName}
                  </th>
                  <th>
                      Entry Type
                  </th>
                  <th>
                      Mode
                  </th>
                  
                  
                  <th>
                      Ref Id
                  </th>
                  {/* <th>Created By</th> */}
                  <th>Currency</th>
                  <th>
                        Amount
                  </th>
                  
                  <th>
                        Balance
                  </th>
                  {/* <th>Note</th> */}
                  <th>Created By</th>
                  
                  <th>Is Voided</th>
                  <th>Note</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
        
        
        
          <InfiniteScroll 
                    targetElement={window}
                    maxTakeCount={this.takeCount}
                    getItems={this.getItems}
                    resetDependencyList={this.createResetDependencyList(this.state)}
                    resetDebounceDelay={400}
                    >
                    {infiniteScroll => 
                        infiniteScroll.loadedItems.map((item,idx)=>
                            
                            <JournalEntriesTableRow
                            key={idx}
                            journalEntry={item}
                            journalType={journalType}
                            selected={Boolean(this.state.selectedEntriesMap[item.id])}
                            onSelect={evt=>{

                                            
                                var next = this.state.selectedEntriesMap;

                                if(evt.target.checked)
                                {
                                    next[item.id] = item;
                                }else
                                {
                                    delete next[item.id];
                                }
                                this.setState({

                                    selectedEntriesMap:next
                                });
                            }}

                            onFromEntityClick={e => this.setState({fromEntity:e.target.value.fromEntity})}
                            onToEntityClick={e => this.setState({toEntity:e.target.value.toEntity})}
                            onSettleClick={this.handleSettleJournalEntryClick}
                            />
                            )}
        </InfiniteScroll>
                
          </tbody>
        </table>
        </>
        ;
    }
}



function JournalEntriesTableRow(props:{
    journalType:JournalType,
    journalEntry:JournalEntry,
    selected:boolean,
    onSelect:(evt:{target:{checked:boolean}})=>void,
    onFromEntityClick:(evt:{target:{value:JournalEntry}})=>void,
    onToEntityClick:(evt:{target:{value:JournalEntry}})=>void,
    onSettleClick:(evt:{target:{value:JournalEntry}})=>void,
})
{
    
    var item = props.journalEntry;

    var createdByFullName = "";
    if(item.createdByUserBasicInfo)
    {
        createdByFullName = item.createdByUserBasicInfo.firstName;
        if(item.createdByUserBasicInfo.lastName)
        {
            createdByFullName += " " + item.createdByUserBasicInfo.lastName;
        }
    }


    function hasInputEntryTypeWithAmountSign(sign:number){

        var journalType = props.journalType;

        for(let i of journalType.entryTypeInputOptions)
        {
            if(i.amountSign * sign > 0)
            {
                return true;
            }
        }

        return false;
    };

    return <tr>
        <td>

            <input type="checkbox"
                checked={props.selected}
                onChange={props.onSelect}
                    
            />
        </td>
        <td className="text-nowrap">{item.inputTime.substr(0,10)}</td>
        <td>
            <span className="btn-link cursor-pointer"
            onClick={()=>props.onFromEntityClick({target:{value:item}})
                
                
                //e=> this.setState({fromEntity:item.fromEntity})
                }>
            {getTagDisplayName(item.fromEntity)}
            
            </span>
            </td>
        <td>
            <span className="btn-link cursor-pointer"
            onClick={
                ()=>props.onToEntityClick({target:{value:item}})
                // e=> this.setState({toEntity:item.toEntity})
            
            }
            >
            {getTagDisplayName(item.toEntity)}
            </span>
        </td>
        <td>
            
<JournalEntryTypeLink journalEntry={item}/>
            {/* {item.erpBalanceEntry.entryType === 'sale' ?
            
                <SaleLink saleTransaction={item.saleTransaction} >sale</SaleLink>
            : item.erpBalanceEntry.entryType === 'sales_return' ?
            <OrganizationLink 
            to={`${SalesReturnsBasePath}/details?id=${encodeURIComponent(item.inventoryTransfer.id)}`}>
                sales_return</OrganizationLink>
        
            :item.erpBalanceEntry.entryType
            } */}


        </td>


<td>{item.entryMode}</td>
<td>{item.tag}</td>
{/* <td>{createdByFullName}</td> */}
<td>{item.currencyCode}</td>
<td>{item.amount}</td>
<td>{item.balance}</td>
<td>{UserBasicInfoName(item.createdByUserBasicInfo)}</td>
{/* <td><input value={item.note} readOnly/></td> */}

<td>{item.isVoided?"Voided":""}</td>
<td>{item.note}</td>
<td>


<DirectoryLink 
    to={`/${props.journalType.customModel.name}/details?id=${encodeURIComponent(item.id)}`}
>
Details
</DirectoryLink>

{item.orderId &&
<>{' | '}

<DirectoryLink 
    to={`/${item.entryType}/details?id=${encodeURIComponent(item.orderId)}`}
>
View&nbsp;Order
</DirectoryLink>
</>

}

{
Boolean(item.balance) && 
hasInputEntryTypeWithAmountSign(item.balance>0?-1:1) && 
<>
&nbsp; | &nbsp;

<span className="btn-link cursor-pointer" onClick={

    
()=>props.onSettleClick({target:{value:item}})
// this.handleSettleJournalEntryClick(item)
}>
Settle
</span>


</>


}

</td>
       
</tr>;

}