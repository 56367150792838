import { ItemStore } from "../db/ItemStore";
import { StoreNames } from "../db/StoreNames";
import { DirectoryContextModel } from "../hooks/useDirectoryApp";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { Item } from "../models/entities/items/item";
import { ItemBom } from "../models/entities/items/item-bom";
import { GetItemsQueryModel } from "./GetItemsQueryModel";

export class ItemService
{

  http:DirectoryHttpService;
    constructor(
      
      private context:DirectoryContextModel
      )
    {
      this.http = context.http;

    }

    // get directoryId(){

    //   return this.context.data.directory.id;
    // };

    
  async getItems(filter:GetItemsQueryModel)
  {

    return this.http.post<Item[]>("/Items/GetItems", {
        body:filter,
        onOnlineSuccess:items => {

              
          if(filter.includeReferenceData)
          {
            var syncService = this.context.syncService;

            syncService.saveEntitiesOffline(StoreNames.Items, items,
              [i => i.lastModifiedTime]);
          }
        },
        whenOffline:()=>{

          var itemStore = new ItemStore(this.context.dbService);

          return itemStore.getList(filter);
        }
    });
  }

  find(id:string)
  {

    return this.http.post<Item>("/Items/Find", {

      hokQuery:{id:id}
    });
  }

  save(item:Item)
  {
    return this.http.post<Item>("/Items/Save", {body:item});
  }

  
  getItemBom( itemId:string)
  {
    
    return this.http.post<{
      erpItem:Item,
      erpBom:ItemBom
    }>("/Items/GetErpItemBom", {
        hokQuery:{
                
            erpItemId:itemId
        }
    });
  }

  saveErpItemBom2Async(data:ItemBom)
  {
    
    return this.http.post<ItemBom>("/Items/SaveErpItemBom2",{
      body: data
    });
  }



  
  getItemWithDefaultChildItems( itemId:string)
  {
    
    return this.http.post<Item>("/Items/GetItemWithDefaultChildItems", {
        hokQuery:{
            itemId
        }
    });
  }

  saveItemDefaultChilditems(
    data:Item
    )
  {
    
    return this.http.post<Item>("/Items/SaveItemDefaultChildItems",{
      // hokQuery:{
      //   itemId


      // },
      body: data
    });
  }

  saveItemMeasureUnits(item:Item)
  {
    
    return this.http.post<Item>("/Items/SaveItemMeasureUnits",{
      // hokQuery:{
      //   itemId


      // },
      body: item
    });

  }


  


}