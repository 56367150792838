import { DateTimeOffsetInput, ValidationSummary } from "hok-react";
import React from "react";
import { EntitySelect } from "../entities/EntitySelect";
import { getTagDisplayName } from "../functions/DataUtils";
import { EntityTypeNameIds } from "../models/enums/EntityTypeNameIds";
import { JournalTotalAmountsController } from "./JournalTotalAmountsController";

export class JournalTotalAmounts extends JournalTotalAmountsController
{

    render()
    {
        var {journalType} = this.props;

        if(!this.state.list)
        {
            return <div>Loading...</div>;
        }
        
        return <>
            
        <h1>
            {journalType.customModel.displayName} Total Amounts
        </h1>
        
         <div className="row">

             
            <div className="col-md-3 d-flex">
              
              <label>Begin</label>
          
              <DateTimeOffsetInput type="date" 
                value={this.state.beginInputTime || ""} 
                onChange={this.handleChange}
                name="beginInputTime"
                  className="form-control flex-grow-1"
                  />
          
              </div>

              
            <div className="col-md-3 d-flex">
              
              <label>End</label>
          
              <DateTimeOffsetInput type="date" 
                value={this.state.endInputTime || ""} 
                onChange={this.handleChange}
                name="endInputTime"
                  className="form-control flex-grow-1"
                  />
          
              </div>



              <div className="col-md-3 d-flex">
              
              <label>Loc</label>
          
              <EntitySelect 
                value={this.state.fromEntity} 
                onChange={this.handleChange}
                name="fromEntity"
                entityTypeNameId={EntityTypeNameIds.Location}
                  className="form-control flex-grow-1"
                  />
          
              </div>

              
            <div className="col-md-3 d-flex">

            <label className="">{journalType.toEntityTypeName}</label>
            <EntitySelect value={this.state.toEntity}
                onChange={this.handleChange}
                entityTypeNameId={journalType.toEntityTypeName}
                name="toEntity"
                className="form-control flex-grow-1"
            />
            </div>


            <div className="col-md-3 d-flex">
              
            <label>EntryType</label>
        
            <input value={this.state.entryType || ""} 
                onChange={this.handleChange}
                name="entryType"
                
                className="form-control flex-grow-1"
                />
        
            </div>

            
            
            <div className="col-md-3 d-flex">
              
            <label>EntryMode</label>
        
            <input value={this.state.entryMode || ""} 
                onChange={this.handleChange}
                name="entryMode"
                className="form-control flex-grow-1"
                />
        
            </div>



        </div>
{/* 
        
<div className="accordion">
  <div className="card">
    <div className="card-header">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left" type="button"
        onClick={()=>this.setState({showOtherFilters:!this.state.showOtherFilters})}
        >
          More Filters
        </button>
      </h2>
    </div>
    <Collapse isOpen={this.state.showOtherFilters}>
    <div className="card-body">




    <div className="row">
            
            
            </div>
    </div>
    </Collapse>
  </div>
  
</div>
         */}
        
        <ValidationSummary errors={this.state.errors} name=""/>

        <table className="table">
          <thead>
              <tr>
                  <th>Loc</th>
                  <th>Entry Type</th>
                  <th>Entry Mode</th>
                  
                  <th>Total Amount</th>
              </tr>
          </thead>
          <tbody>
        
                {this.state.list.map((e,idx)=>
                
                <tr>
                    <td>{getTagDisplayName(e.fromEntity)}</td>
                    <td>{e.entryType}</td>
                    <td>{e.entryMode}</td>
                    <td>{e.currencyCode} {e.totalAmount}</td>
                </tr>
                )}
                
          </tbody>
        </table>
        
        <b>Total: {this.totalAmount}</b>
        
        </>;
    }
}