import { ValidationProblemDetails } from "hoksource";
import { FormEvent } from "react";
import { ToastContextModel } from "../modals/ToastProvider";
import { ModelTypesBaseProps } from "../model-types/Root";
import { OrderType, OrderTypeViewTemplate } from "../models/entities/orders/order-type";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { EditStateBase } from "../types/EditStateBase";
import { OrderTypeService } from "./OrderTypeService";

export class EditViewTemplateController
extends DirectoryComponent
<ModelTypesBaseProps<OrderType> & {

    toastContext:ToastContextModel
},
EditStateBase<OrderTypeViewTemplate> & {


}
>
{
    orderTypeId:string;
    id:string;
    componentDidMount()
    {

        this.orderTypeId = this.getQueryParam("orderTypeId");
        this.id = this.getQueryParam("id");

        var service = new OrderTypeService(this.http);
        
        if(this.id)
        {
            service.findViewTemplate(this.id)
            .then(e => {
                this.setState({input:e});
            });


        }else
        {
            this.setState({
                input:{
                    customModelId:this.orderTypeId,

                }
            });

        }
        
        window.addEventListener("keydown", this.onKeyDown);

    }

    
    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
    }



    
    onKeyDown = (evt:KeyboardEvent) =>
    {
        if(evt.ctrlKey && evt.code==="KeyS")
        {
            evt.preventDefault();

            if(!this.state.isSubmitting)
            {
                this.save();
            }
        }
    };



    handleSubmit = async (evt:FormEvent<HTMLFormElement>) =>{

        evt.preventDefault();

        this.save();
                
    };

    async save(){

        
        
        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
            
            var service = new OrderTypeService(this.http);

            var res = await service.saveViewTemplate(this.state.input);



            this.setState({input:res});
            if(!this.id)
            {
                
            var modelTypeType = this.props.modelType.customModel.name;
                this.id = res.id;
                this.history.replace(
`/${modelTypeType}/edit-view-template?orderTypeId=${encodeURIComponent(this.orderTypeId)}&id=${encodeURIComponent(res.id)}`);
            
            }
            
            
            
            this.props.toastContext.setToast({
                header:"Info",
                body:"Saved",
                durationMs:2000
                });

        }catch(err:any)
        {


            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }


        this.setState({isSubmitting:false});
        
    }


}