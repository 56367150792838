import React, { useContext } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ToastContext } from "../modals/ToastProvider";
import { ModelTypeEdit } from "../model-types/Edit";
import { ModelTypeTypeNameIds } from "../model-types/ModelTypeTypeNameIds";
import { ModelTypesBaseProps } from "../model-types/Root";
import { EditCustomStatus } from "./EditCustomStatuses";
import { EditViewTemplate } from "./EditViewTemplate";
import { Home } from "./Home";
import { OrderTypeService } from "./OrderTypeService";
import { ViewTemplates } from "./ViewTemplates";


export const OrderTypeRootProps: ModelTypesBaseProps = 
{
    serviceType: OrderTypeService,
    modelType:{
        customModel:{
            name:ModelTypeTypeNameIds.OrderType,
            displayName:"Order Type",
            collectionDisplayName:"Order Types",
            fields:[{
                labelText:"ToEntityTypeName",
                name:"ToEntityTypeName",
    
            },
            
            {
                inputType:"checkbox",
                labelText:"WithPrice",
                name:"WithPrice",
    
    
            },
            
            {
                inputType:"checkbox",
                labelText:"AutomaticLineUnitPrice",
                name:"AutomaticLineUnitPrice",
    
    
            },
            {
                inputType:"checkbox",
                labelText:"NegativeLineQuantities",
                name:"NegativeLineQuantities",
    
            },
            
            {
                inputType:"checkbox",
                labelText:"UniqueItems",
                name:"UniqueItems",
    
            },
            
            {
                inputType:"checkbox",
                labelText:"SortLinesByTagThenName",
                name:"SortLinesByTagThenName",
    
            },

            {
                inputType:"checkbox",
                labelText:"EnableChildLines",
                name:"EnableChildLines",
    
            },
            {
                inputType:"checkbox",
                labelText:"EnableReserveFromEntity",
                name:"EnableReserveFromEntity",
            },
            
            {
                inputType:"checkbox",
                labelText:"EnableDeliveryAddress",
                name:"EnableDeliveryAddress",
            },
            {
                inputType:"checkbox",
                labelText:"EnableShipmentForThis",
                name:"EnableShipmentForThis",
            },
            
            {
                inputType:"checkbox",
                labelText:"Disable Create Item Ledger Entries On Post",
                name:"DisableCreateItemLedgerEntriesOnPost",
    
    
            },
            {
                labelText:"ToEntityJournalTypeName",
                name:"ToEntityJournalTypeName",
            },
            
            {
                inputType:"checkbox",
                labelText:"EnableExpirationTime",
                name:"EnableExpirationTime",
            },

            {

                labelText:"Shipment Source Order Type Name Id",
                name:"ShipmentSourceOrderTypeName"
            },

            
            {
                inputType:"checkbox",
                labelText:"EnableAttachments",
                name:"EnableAttachments",
            },
            ]

        }
        
    }
};





export function OrderTypesRoot()
{
    var match = useRouteMatch();
    
    var toastContext = useContext(ToastContext);

    return <Switch>
        <Route exact path={`${match.path}/`}>
            <Home {...OrderTypeRootProps}/>
        </Route>
        <Route path={`${match.path}/edit`}>
            <ModelTypeEdit {...OrderTypeRootProps}/>
        </Route>
        <Route path={`${match.path}/view-templates`}>
            <ViewTemplates {...OrderTypeRootProps}/>
        </Route>
        
        <Route path={`${match.path}/edit-view-template`}>
            <EditViewTemplate {...OrderTypeRootProps}
            toastContext={toastContext}
            />
        </Route>


        <Route path={`${match.path}/edit-custom-statuses`} >
            <EditCustomStatus
            />
        </Route>
        
        
        
    </Switch>;

}
