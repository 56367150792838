import { Order } from "../../models/entities/orders/order";
import { OrderLine } from "../../models/entities/orders/order-line";
import { OrderWithShipments } from "../OrderWithShipments";


export function isSimilarOrderLine(
    left:OrderLine, right:OrderLine
) // same item and same child items and same child items quantity
{
    if(left.item?.id !== right.item?.id)
    {
        return false;
    }

    var leftChildLines = left.childLines?.slice() || [];
    var rightChildLines = right.childLines?.slice() || [];

    if(leftChildLines.length !== rightChildLines.length)
    {
        return false;
    }

    // either has content
    for(let i of leftChildLines)
    {
        var hasMatch = false;
        for(var jIndex = 0; jIndex < rightChildLines.length; ++jIndex)
        {
            var j = rightChildLines[jIndex];
            if(i.item.id === j.item.id && i.quantity===j.quantity)
            {
                hasMatch = true;
                rightChildLines.splice(jIndex,1);
                break;
            }
        }

        if(!hasMatch)
        {
            return false;
        }
    }

    return true;
}

function subtract(a:number,b:number)
{
    if(Math.abs(a)<=Math.abs(b))
    {
        return 0;
    }

    return a-b;
}



export function createComplimentOrderLines(
    orderWithShipments:OrderWithShipments)
{
    var complimentOrderLineQuantities:number[] = [];
    var orderLines = orderWithShipments.order.lines;
    var shippedLines:OrderLine[] = [];
    var shippedLinesRemainingQuantities:number[] = [];

    for(let shipmentOrder of orderWithShipments.shipmentOrders)
    {
        for(let l of shipmentOrder.lines)
        {
            shippedLines.push(l);
            shippedLinesRemainingQuantities.push(l.quantity);
        }
    }

    for(let i = 0; i < orderLines.length; ++i)
    {
        let line = orderLines[i];
        let uq = line.quantity;

        for(let j = 0; j < shippedLines.length && uq
            ; ++j)
        {
            let shippedLine = shippedLines[j];
            let sq = shippedLinesRemainingQuantities[j]; // shipped quantity
            if(sq && isSimilarOrderLine(line, shippedLine))
            {
                // same type
                let d = uq >= 0 ? 1 : -1;
                let toSubtract = d*Math.min(d*uq,d*sq);

                uq=subtract(uq,toSubtract);
                shippedLinesRemainingQuantities[j]=
                    subtract(sq,toSubtract);
            }
        }

        complimentOrderLineQuantities.push(uq);
    }


    return orderLines.map((e,idx) => ({
            ...e,
            quantity:complimentOrderLineQuantities[idx],
            assemblyOrder:null,
            childLines:e.childLines.map(c => {

                var {id, ...rest} = c; // remove the id
                return rest;
            })
        } as OrderLine));
}



export function findSimilarOrderline(
    lines:OrderLine[],
    line:OrderLine, nth:number = 0)
{
    if(!lines)
    {
        return undefined;
    }
    var ctr =0;
    for(let i of lines)
    {
        if(isSimilarOrderLine(i, line))
        {
            if(ctr === nth)
            {
                return i;
            }else
            {
                ++ctr;
            }
        }
    }

    return undefined;
}

export function populateShipmentOrderFromSourceOrder(
    
    
    sourceOrder:Order,
    shipmentOrder:Order,
    complimentLines:OrderLine[]
)
{

    var orderLineCopies = shipmentOrder.lines.slice();
    var nextOrderLines:OrderLine[] = [];

    for(let i of complimentLines)
    {
        let existsOrderLine = false;
        for(let j=0; j < orderLineCopies.length; ++j)
        {
            let ol = orderLineCopies[j];

            if(isSimilarOrderLine(i, ol))
            {
                nextOrderLines.push(ol);
                existsOrderLine = true;
                orderLineCopies.splice(j, 1);
                break;
            }
        }

        if(!existsOrderLine)
        {
            nextOrderLines.push(i);
        }
    }


    let nextOrder=  {

        ...shipmentOrder,
        fromEntity:sourceOrder.fromEntity,
        toEntity:sourceOrder.toEntity,
        deliveryAddress:{
            fullAddress:
            sourceOrder.deliveryAddress?.fullAddress
        },
        lines:nextOrderLines,
    } as Order;

    if(new Date(sourceOrder.inputTime).getTime()
        >new Date(nextOrder.inputTime).getTime())
    {
        nextOrder.inputTime = sourceOrder.inputTime;
    }
    return nextOrder;
}