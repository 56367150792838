import React, {useContext, useState } from "react";
import { DirectoryContext, Order } from "..";
import { OrderSelectModalProvider } from "../modals/OrderSelectModalProvider";
import { OrderSelect } from "../orders/OrderSelect";


export function EddyTest()
{

    var [order,setOrder] = useState<Order>();
    var ctx = useContext(DirectoryContext);

    // var orderType = ctx.data.orderTypes.find(e => e.customModel.name === "SalesOrder");

    return <>
    <OrderSelectModalProvider>
      <OrderSelect value={order} onChange={e => setOrder(e.target.value)}
        className="form-control"
        orderTypeNameId={"SalesOrder"}
      />
  </OrderSelectModalProvider>

    </>;
}