import { JournalEntry } from "..";
import { ItemStore } from "../db/ItemStore";
import { StoreNames } from "../db/StoreNames";
import { DirectoryContextModel } from "../hooks/useDirectoryApp";
import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { ActivityLog } from "../models/entities/activity-log";
import { Item } from "../models/entities/items/item";
import { Order } from "../models/entities/orders/order";

export interface GetActivityLogsInputModel
{

  skip?:number;
  take?:number;
    objectTypeName?:string;
    objectId?:string;
}

export interface ActivityLogViewModel
{
    activityLog:ActivityLog;
    order:Order;
    journalEntry:JournalEntry;
}



export class ActivityLogService
{

    constructor(
      private http:DirectoryHttpService
      )
    {

    }

    // get directoryId(){

    //   return this.context.data.directory.id;
    // };

    
  async getList(filter:GetActivityLogsInputModel)
  {

    return this.http.post<ActivityLogViewModel[]>("/ActivityLogs/GetList", {
        body:filter,
    });
  }


  


}