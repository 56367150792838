export function numberCustomValidity(n:string|number, errorMessage?:string)
{

    if(typeof n==="number")
    {
        return undefined;
    }
    if(n && isNaN(n as any))
    {
        return errorMessage??"Invalid number format.";
    }

    return undefined;

}