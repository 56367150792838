import { DetailsController } from "./DetailsController";
import { OrderDetailsComponent } from "./OrderDetailsComponent";
import React from 'react'; 

export class OrderDetails extends DetailsController
{



    render()
    {
        return <OrderDetailsComponent
            order={this.state.order}
            orderType={this.props.orderType}
        />
    }
}