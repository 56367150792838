import React, { ReactElement, ReactNode, useContext } from 'react';
// @ts-ignore
import { Link } from 'react-router-dom';
// @ts-ignore
// import { Route } from 'react-router';
import { DirectoryContext } from './DirectoryContext';
import { useQuery } from '../hooks/useQuery';
import { ToastContext, ToastContextModel } from '../modals/ToastProvider';
import { DirectoryContextModel } from '../hooks/useDirectoryApp';

interface HokRouterProps
{
    routes?:{

        exact?:boolean,
        relativePath:string,
        component: any,
        props?:any
        
    
    }[];
    
    children:React.ReactNode|(()=>React.ReactNode[]);
}



export interface DirectoryRouterContextModel
{
    
    directoryContext:DirectoryContextModel;
    toastContext: ToastContextModel,
    searchParams: URLSearchParams;
}

export const DirectoryRouterContext = 
    React.createContext<DirectoryRouterContextModel>(null);




export function DirectoryRouter(props:HokRouterProps)
{
    let directoryContext = useContext(DirectoryContext);
    let toastContext = useContext(ToastContext);

    let query = useQuery();


    var ctx = {

        directoryContext:directoryContext,
        toastContext:toastContext,
        searchParams:query,
        
        };

    var children:ReactElement[];
    var compiledChildren:ReactNode;
    if(typeof props.children === "function")
    {
        children = props.children();
    }else
    {
        children = props.children as ReactElement[];
    }
    compiledChildren = children;
    
    return (<DirectoryRouterContext.Provider value={ctx}>
            
            {compiledChildren}
        </DirectoryRouterContext.Provider>
        );
}


// move this to directory links
export function DirectoryLink(props:{to:string, 
    className?:string,
    children?:any})
{
    
    let directoryContext = useContext(DirectoryContext);

    return <Link 
    
    to={`${directoryContext.directoryBasePath}${props.to}`}
    className={props.className}
    >{props.children}</Link>;
}
