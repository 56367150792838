import { DirectoryUser } from "../models/entities/directories/directory-user";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { DirectoryUserService } from "./DirectoryUserService";

export class HomeController extends DirectoryComponent
<{},{

    
    list:DirectoryUser[]
}>
{

    
    componentDidMount()
    {
        var service = new DirectoryUserService(this.http);

        service.getUsers().then(e => {

            this.setState({list:e});
        });
    }

}