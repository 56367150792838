


export function getTagDisplayName(o:{
  tag?:string,
  name?:string
})
{
  if(o)
  {
    if(o.tag)
    {
      return o.tag + " - " + o.name;
    }else{
      return o.name;
    }
  }

  return "";
}

export function toLocaleDateString(date:string)
{
  if(date)
  {

    return new Date(date).toLocaleDateString();
  }
  return "";
}



export function inventoryEntityEquals(a,b)
{
    if(a===b)
    {
      return true;
    }else if(a&&b)
    {
      return a.id===b.id;
    }
    else if(!(a||b))
    {
      return true;
    }
    
    return false;
}