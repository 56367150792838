
import { EditController } from "./EditController";
import { EditEntityForm } from "./EditEntityForm";
import React from 'react';

export class Edit extends EditController

{

    render()
    {
        return <>
        <EditEntityForm 
            entityType={this.props.entityType}
            value={this.state.input}
            onChange={e=>this.setState({input:e.target.value})}
            onSave={this.props.onSave}
            formContainerClassName="col-md-4"
        />

                
        <div>
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
        </div>
        </>
        ;
    }
}