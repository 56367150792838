import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../../../src/css/EDataSelectList.css';

import {useInfiniteScroll, useOnScrollBottom} from 'hok-react';
import { hokDebounce } from '../functions/hokDebounce';


export interface EDataSelectListConfiguration<T>// extends HokPaginatedArrayLoaderConfiguration<T>
{
    //placeholder?;
    getDisplayName?:(o:T)=>any;
    equals?:(a:T,b:T)=>boolean;
    takeCount?: number;



    getItems : (filter:{
        currentIndex:number;
        // skip:number,
        // take:number,
        searchString:string,
        lastItem:T
    })=>Promise<T[]>;
}


export function EDataSelectList<T>(props:{

    value:T,
    onSelect:(evt:{

        target:{value:T,
        name?:string
        }
    })=>void,
    required?:boolean,
    name?:string,
    configuration:EDataSelectListConfiguration<T>,
    initialSearchString?:string
})
{

    var configRef = useRef<EDataSelectListConfiguration<T>>();
    configRef.current = props.configuration;

    var searchRef = useRef<HTMLInputElement>(null);
    var optionsContainerRef = useRef<HTMLDivElement>(null);
    
    var [searchString,setSeachString] = useState(props.initialSearchString??"");

    var isTypingRef = useRef(false);
    var [activeOption, setActiveOption] = useState(undefined);
    var [updateScrollFlag, setUpdateScrollFlag] = useState(false);
    var [userChangedActiveFlag, setUserChangedActiveFlag] = useState(false);
    // var [enterPressed, setEnterPressed] = useState(false);
    var enterPressedRef = useRef(false);
    var autoselectFlagRef = useRef(false);
    var completedFlagRef = useRef(false);
    var isResetRef = useRef(false);
    var disposedRef = useRef(false);
    

    
    useEffect(()=>{


      searchRef.current.focus();

      return () =>{

        disposedRef.current = true;
      };
    },[]);



    var infiniteScrollConfig = useMemo(()=> 
    ({

        targetElement:optionsContainerRef.current,
        maxTakeCount: configRef.current.takeCount,
        getItems:filter => {

            var q = {

                currentIndex:filter.currentIndex,
                searchString : searchString,
                lastItem: filter.lastItem
            };

            return configRef.current.getItems(q);//.loadPage(search);
        }
    }),
    [searchString, optionsContainerRef.current, props.configuration]);

    var infiniteScroll = useInfiniteScroll<T>(infiniteScrollConfig);

    const onSelect = (val) =>{

      if(!props.required || val )
      {

        props.onSelect({target:{
          value:val,
        name:props.name
        }});
        completedFlagRef.current = true;
      }

    };

    useEffect(()=>{

        isTypingRef.current = true;


        // init
        //if(!autoselectFlagRef.current) {// meaning it will not autoselect
        

          hokDebounce(()=>{
              isTypingRef.current = false;
              infiniteScroll.reset();
              isResetRef.current = true;

              
              if(enterPressedRef.current)
              {
                autoselectFlagRef.current = true;
              }
          
          });
        //}

    },[searchString]); // reset this son of a bitch

    useEffect(

        ()=>{
            
            var activeOption = getInitActiveOption();

            if(
              //!isResetRef.current &&
              !infiniteScroll.isLoadingNext &&
              !isTypingRef.current &&
              autoselectFlagRef.current)
            {
              if(!props.required || activeOption)
              {

                onSelect(activeOption);
              }else
              {
                autoselectFlagRef.current = false;
              }
            }else
            {
              if(!disposedRef.current)
              {

                if(activeOption)
                {
                  if(activeOption instanceof Array)
                  {
                      
                    setActive(activeOption.slice());
                  }else if(activeOption instanceof Object)
                  {

                    setActive(Object.assign({},activeOption));
                  }else
                  {
                    setActive(activeOption);
                  }
                }else
                {
                  setActive(undefined);
                }
              }
            }


            isResetRef.current = false;




        },[infiniteScroll]
    );


    useEffect(()=>{



        if(updateScrollFlag)
        {
            var e = optionsContainerRef.current;
            var index = getActiveIndex();
            var adjustedIndex = index + 1; // for the display
            var c = e.children[adjustedIndex];
  
            c.scrollIntoView({block: "nearest"});

            
            if(!disposedRef.current)
            {

              setUpdateScrollFlag(false);
            }
        }
    },[

        updateScrollFlag
    ]);






    const equals = (a,b) => {

      if(configRef.current.equals)
        return configRef.current.equals(a,b);
      else
        return a === b;
    };






    
    function setActive(o)
    {

        setActiveOption(o);
        setUpdateScrollFlag(true);

        return o;
    }

    function setActiveIndex(index)
    {
        return setActive(infiniteScroll.loadedItems[index]);
    //   this.setActive(this.dataList[index]);
    }

    function getActiveIndex()
    {
      
      var selectedIndex = infiniteScroll.loadedItems.findIndex((e)=>{
        return equals(e, activeOption);//this.equals(e,this.activeOption);
      });

      return selectedIndex;
    }

    function getFirstOrDefault()
    {
      if(searchString && infiniteScroll.loadedItems.length)
      {

        return infiniteScroll.loadedItems[0];
      }

      return undefined;
    }

    function setActiveFirst()
    {
      if(searchString && infiniteScroll.loadedItems.length)
      {
          setActiveOption(infiniteScroll.loadedItems[0]);
          return infiniteScroll.loadedItems[0];
      }else
      {
          
        setActiveOption(undefined);

        return undefined;
      }
    }

    function getInitActiveOption()
    {

      if(userChangedActiveFlag)
      {
        
        var activeIndex = getActiveIndex();
        if(activeIndex >= 0)
        {

          return infiniteScroll.loadedItems[activeIndex];
        }else
        {

          //none
          return getFirstOrDefault();
        }
      }else
      {
        if(props.value)
        {

          var selectedIndex = infiniteScroll.loadedItems.findIndex((e)=>{

            return equals(e,props.value);
          });


          if(selectedIndex >= 0)
          {
            return infiniteScroll.loadedItems[selectedIndex];
          }else
          {  
            return getFirstOrDefault();
          }
        }else
        {
          // no selected
          
          return getFirstOrDefault();
        }
      }


    }





    const onKeyDown = (evt) =>
    {
        if(evt.key == "ArrowDown")
        {
          var activeIndex = getActiveIndex();
          if(activeIndex + 1 < infiniteScroll.loadedItems.length)
          {
            setActiveIndex(activeIndex + 1);
            setUserChangedActiveFlag(true);
          }
        }
        if(evt.key == "ArrowUp")
        {
          
          var activeIndex = getActiveIndex();

          if(activeIndex - 1 >= 0)
          {
            setActiveIndex(activeIndex - 1);
          }else
          {
            setActive(undefined);
          }

          
          setUserChangedActiveFlag(true);
          
        }

        if(evt.key == "Enter")
        {

          if(isTypingRef.current)
          {
            // enterPressedRef.current= (true);
            autoselectFlagRef.current = true;
            
            // autoselectFlagRef.current = true;
          }else if(infiniteScroll.isLoadingNext)
          {
            
            // enterPressedRef.current= (true);
            autoselectFlagRef.current = true;
          }else
          {
            
            onSelect(activeOption);
          }
          

          
          /*
          if(this.activeOption)
          {
            this.select(this.activeOption);
          }*/
        }
        
    }




















    return <>
    

    
<input ref={searchRef}
autoComplete="off"
className="w-100"
value={searchString}
onChange={evt=>setSeachString(evt.target.value)}
onKeyDown={onKeyDown}
    />

<div ref={optionsContainerRef}
className="hok-data-select-option-container">

    <div 
    
    className={`hok-data-select-option ${equals(undefined, activeOption)?'hok-data-select-option-selected':''}`}
    onClick={()=> onSelect(undefined)}
    
    >&nbsp;</div>

    {infiniteScroll.loadedItems.map((e,idx) => 
            


          
        <div key={idx}
            onClick={()=>onSelect(e)}
            className={`hok-data-select-option ${equals(e, activeOption)?'hok-data-select-option-selected':''}`}
            title={props.configuration.getDisplayName(e)}
        >
          {props.configuration.getDisplayName(e)}
        </div>
            
            
            
            
    )}



    {infiniteScroll.isLoadingNext && 
        <div style={{color:"grey"}}>
            Loading...
        </div>}
</div> 

    </>;

}