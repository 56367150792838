import { FormGroup, FormInput, Input, ValidationSummary } from "hok-react";
import React from "react";
import { EditViewTemplateController } from "./EditViewTemplateController";

export class EditViewTemplate
extends EditViewTemplateController
{

    render()
    {

        if(!this.state?.input)
        {
            return <div>Loading...</div>;
        }
        return <>
        <h1>{this.id?"Edit":"Create"}</h1>

        <h4>Order Type View Template</h4>
        <hr />
        <div className="row">
        <div className="col-md-12">
        <form method="post" onSubmit={this.handleSubmit}>
            <ValidationSummary errors={this.state.errors} name=""/>

            <FormGroup value={this.state.input} 
            onChange={e => this.setState({input:e.target.value})}
            childrenProps={{errors:this.state.errors}}
            >
            {g =><>
                <FormInput
                    group={g}
                    groupKey="name"
                    name="Name"
                    labelText="Name"
                />


                <div className="form-group">
                    <label>Raw Template</label>
                    <Input 
                            
                        type="textarea"
                        group={g as any}
                        groupKey="rawTemplate"
                        className="form-control"
                        style={{minHeight:"50vh"}}
                    />
                </div>

                <FormInput
                    type="checkbox"
                    group={g}
                    groupKey="printOnLoad"
                    name="PrintOnLoad"
                    labelText="Print On Load"
                />
            </>}

            </FormGroup>
            
            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>




                
        <div>



                
            <span className="btn-link cursor-pointer" onClick={this.goBack}>Back to List</span>
        </div>
    </>;
    }
}