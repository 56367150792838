import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { EntityType } from "../models/entities/entities/entity-type";
import { ModelTypeServiceBase } from "./ModelTypeBaseService";

export class EntityTypeService extends 
ModelTypeServiceBase<EntityType>
{

    constructor(http:DirectoryHttpService)
    {
        super(http);
        this.controllerName = "EntityTypes";


    }

}

