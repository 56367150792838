import { BasicOrderLineInput } from "./BasicOrderLineInput";
import { OrderLineInputController } from "./OrderLineInputController";
import React from 'react'; 


export class OrderLineInput extends OrderLineInputController
{


    render(){

        
    var {value} = this.props;
    // var {orderType} = this.props.editOrder;

    

    return <>

    <BasicOrderLineInput
        editOrder={this.props.editOrder}
        value={this.props.value}
        onChange={this.handleLineChange}
        onDeleteClick={this.props.onDeleteClick}
        readOnly={this.props.readOnly}
    />
    


{value.childLines &&


value.childLines.map((line,idx)=>
            
    

    <BasicOrderLineInput
    key={idx}
    editOrder={this.props.editOrder}
    value={line}
    parentLineValue={value}
    onChange={evt => 

        this.handleChildlineChange(line, evt)
        // this.handleLineChange(line, evt)
    }
    onDeleteClick={this.handleDeleteChildLineClick}
    readOnly={this.props.readOnly}
    required

    />

)
}


</>;
}

}