import { InfiniteScroll } from "hok-react";
import { useContext } from "react";
import { Order, DirectoryContext, getTagDisplayName, toLocaleDateString, DirectoryLink } from "../..";
import { OrderType } from "../../models/entities/orders/order-type";
import { OrderLinks } from "../OrderLinks";
import { OrderService } from "../OrderService";
import { OrderListFilterHeader } from "./OrderListFilterHeader";
import { useOrderListFilterContext } from "./useOrderListFilterContext";

export function OrderHome2(props:{
    orderType:OrderType,
    nearDueMode?:boolean
})
{

    var filterCtx = useOrderListFilterContext({
        orderType:props.orderType, 
        nearDueMode:props.nearDueMode
    });


    var orderType = props.orderType;
    return <>

<p>
<DirectoryLink to={`/${orderType.customModel.name}/edit`}>Create New</DirectoryLink>
</p>
<OrderListFilterHeader orderListFilterContext={filterCtx}/>



<table className="table">
<thead>
<tr>
<th>From Location</th>
<th>To {orderType.toEntityTypeName}</th>

<th>Ref Id</th>

<th>Ext Doc Id</th>
<th>Date</th>
<th>Due Date</th>
{/* {isSaleType && 
<>
<th>PaymentStatus</th>
<th>Terms</th>
<th>PaymentDue</th>
<th>SalesMan</th>
</>
} */}
{orderType.withPrice&&
<th>Total</th>

}

<th>Is Posted</th>
{orderType.enableShipmentForThis && 
    <th>Shipment Status</th>

}
<th>Custom Status</th>
<th>Last Modified By</th>
<th></th>
</tr>
</thead>
<tbody>
<InfiniteScroll

targetElement={window}
maxTakeCount={filterCtx.takeCount}
getItems={filterCtx.getItems}
resetDependencyList={[
    filterCtx.state
]}
resetDebounceDelay={400}
>
{infiniteScroll =>
    infiniteScroll.loadedItems.map(
        (e,idx) => <OrdersTableRow key={e.id} 
        orderType={orderType}
        item={e}
        onChange={evt => {

            var nextLoadedItems = [
                ...infiniteScroll.loadedItems.slice(0,idx),
                evt.target.value,
                ...infiniteScroll.loadedItems.slice(idx+1)
            ];
            infiniteScroll.setLoadedItems(nextLoadedItems);


            
        }}
        />)
}
</InfiniteScroll>
</tbody>
</table>
    </>;
}



function OrdersTableRow(props:{
orderType:OrderType,
item:Order,
onChange:(evt:{target:{value:Order}})=>void
})
{


var dContext = useContext(DirectoryContext);
var i = props.item;
// var salesOrder = i.saleTransactionInventoryTransfer?.saleTransaction;
console.log(i);
return <tr>
<td>{getTagDisplayName(i.fromEntity)}</td>
<td>{getTagDisplayName(i.toEntity)}</td>
<td>{i.tag}</td>
<td>{i.externalDocumentId}</td>
<td>{toLocaleDateString(i.inputTime)}</td>
<td>{toLocaleDateString(i.orderDueTime)}</td>
{/* {isSaleType&&
<>
<td>
<span style={salesOrder?.paymentStatus=='completed'?
{color:'green',fontWeight:'bold'}:{}}>

{salesOrder?.paymentStatus}
</span>
</td>
<td>{salesOrder?.paymentTermsConfiguration?.name}</td>

<td>{displayDate(salesOrder?.paymentDueTime)}</td>

<td>{salesOrder?.salesAgent?.name}</td>
</>

} */}
{props.orderType.withPrice &&
<td>{i.totalOrderAmount}</td>}  
<td>{props.item.postingTime?"Y":""}</td>
{props.orderType.enableShipmentForThis && 
    <td>{i.shipmentCompletionTime ? <span className="text-success">COMPLETED</span>:"INCOMPLETE"}</td>

}
<td><select value={i.customStatus || ""}
onChange={(evt)=>{

    var customStatus = evt.target.value;
    props.onChange({target:{value:{
        ...props.item,
        customStatus:customStatus
    }}});

    var service = new OrderService(dContext);
    service.setCustomStatus(i.id,customStatus);


}}
>
<option></option>
{props.orderType.customStatuses
.map((e,idx) => <option key={idx} value={e.value}>{e.value}</option>)}
</select></td>

<td>
{i.lastModifiedByUserBasicInfo?.lastName && i.lastModifiedByUserBasicInfo?.lastName + ", "}
    {i.lastModifiedByUserBasicInfo?.firstName}
</td>
<td>
    <OrderLinks orderType={props.orderType}
        id={i.id}/>
        
</td>
</tr>;
}