import { DirectoryHttpService } from "../http/DirectoryHttpService";
import { EntityItem } from "../models/entities/entities/entity-item";

export class EntityItemService
{

    constructor(private http:DirectoryHttpService)
    {

    }

    
    getList(req:{
        
        skip:number,
        take:number,
        searchString?:string,


        entityGroupId?:string,
        entityId?:string,
        itemId?:string,
        itemDefaultVendorEntityId?:string;
        lowInventoryOnly?:boolean
    })
    {
        return this.http.post<EntityItem[]>("/EntityItems/GetList", {
            body:req
        });
    }


    findWithDefaultBom(q:{entityId:string, itemId:string})
    {
        return this.http.post<EntityItem>("/EntityItems/FindWithDefaultBom", {

            hokQuery:q
        });
    }
}