import { Modal, ModalBody } from "reactstrap";
import { DirectoryComponent } from "../../directory-shared/DirectoryComponent";
import { Entity } from "../../models/entities/entities/entity";
import { EntityType } from "../../models/entities/entities/entity-type";
import { createNewEntity } from "./EditController";
import { EditEntityForm } from "./EditEntityForm";
import React from 'react';

export class EditEntityModal extends DirectoryComponent
<{
  entityType:EntityType,
  onSave:(entity:Entity)=>void,
  className?:string,

}, {

  
  input:Entity;
  errors?:{[key:string]:string[]};
  isSubmitting?:boolean;
  showModal:boolean;
}>
{

  /*
  constructor(props:{
    entityType:EntityType,
    onSave:(entity:Entity)=>void
  })
  {
      super(props);

      this.state=
      
      {
          input:createNewEntity(props.entityType.customModel.name)
      };
  }*/

  openNew = ()=>
  {

    this.setState({
      input:createNewEntity(this.props.entityType.customModel.name),
      showModal:true
    });
    
  };

  toggle=()=>
  {
    this.setState({showModal:!this.state?.showModal});
  };

  handleSaved = (entity:Entity)=>
  {
    
    this.setState({showModal:false}); // hide it
    if(this.props.onSave)
    {
      this.props.onSave(entity);
    }

  };


  render()
  {
    
    return (
      <Modal isOpen={this.state?.showModal} toggle={this.toggle} 
        className={this.props.className}
      onClosed={()=>{

        this.setState({showModal:false})
      }
      }
      fade={false}
      // onExit={()=>{

      //     console.log("Exiting Modal");
      // }}
      >
        <ModalBody>
        {this.state?.input &&
        <EditEntityForm 
            entityType={this.props.entityType}
            value={this.state.input}
            onChange={e=>this.setState({input:e.target.value})}
            onSave={this.handleSaved}
            formContainerClassName="col"
        />
      }
        </ModalBody>
          {/* <props.component/> */}

      </Modal>
  );
  }

}
