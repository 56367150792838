import React from "react";
import { JournalEntry } from "../models/entities/journals/journal-entry";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";

export function JournalEntryTypeLink(props:{

    journalEntry:JournalEntry
})
{
    var {journalEntry} = props;


    return <>
        {journalEntry.order ?
            <DirectoryLink 
        to={`/${journalEntry.order.typeName}/details?id=${encodeURIComponent(journalEntry.orderId)}`}>
            {journalEntry.entryType}</DirectoryLink>
        :
        journalEntry.entryType
        }
    </>;

}