import React from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { EntityType } from "../models/entities/entities/entity-type";
import { Edit } from "./Edit/Edit";
import { Home } from "./Home";

export function EntitiesRoot(props:{
    entityType:EntityType})
{

    var match = useRouteMatch();
    var history = useHistory();

    console.log("i am entities", props, match);
    
    return <>
            <Route exact path={`${match.path}/`}>
                <Home {...props}/>
            </Route>
            
            <Route path={`${match.path}/edit`}>
                <Edit {...props} onSave={()=>history.goBack()}/>
            </Route>
        
        </>;


}