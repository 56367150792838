import { Debouncer } from "hoksource";


var debouncer = new Debouncer();


export function hokDebounce(callback:()=>void, delay:number = 400)
{
    debouncer.debounce(callback, delay);
}

export function clearHokDebounce()
{
    debouncer.clear();
}