

import { EntityStore } from '../db/EntityStore';
import { StoreNames } from '../db/StoreNames';
import { DirectoryContextModel } from '../hooks/useDirectoryApp';
import { DirectoryHttpService } from '../http/DirectoryHttpService';
import { Entity } from '../models/entities/entities/entity';
import { GetEntityListQueryModel } from './GetEntityListQueryModel';

export class EntityService
{

  http:DirectoryHttpService;
    constructor(
      private context:DirectoryContextModel
      /*,
      private http:DirectoryHttpService,
      private dbService:DbService,
      private syncService:SyncService*/
      )
    {

      this.http = context.http;
    }


      
    async getList(q:GetEntityListQueryModel)
    {
      
      return await this.http.post<Entity[]>("/Entities/GetList", {
          body:q,
          onOnlineSuccess:entities => {
            if(q.includeReferenceData)
            {
              this.context.syncService.saveEntitiesOffline(StoreNames.Entities, entities, 
                [e=>e.lastModifiedTime]);
            }
          },
          whenOffline:()=>{
            var entityStore = new EntityStore(this.context.dbService);
            return entityStore.getList(q);
          }
      });
    }

    find(id:string)
    {
      var store= new EntityStore(this.context.dbService);

      if(id && !isNaN(id as any))
      {
        // this is in db
        return store.get(+id);
      }
      else
      {

        return this.http.post<Entity>("/Entities/Find", {
          hokQuery:{id},
          onOnlineSuccess:entity=>{
            
          },
          whenOffline:()=>{
            return store.get(id);
          }
        });
      }
    }

    
    // should lock this while syncing
  async save(entity:Entity)
  {
      // lock
      if(typeof entity.id === "number")
      {
        // save locally
        entity.lastModifiedTime = new Date().hokToLocaleDateTimeOffsetString();
        entity.hasOfflineChanges = 1;
      }else
      {

        entity = await this.http.post<Entity>("/Entities/Save", {
          body:entity,
          whenOffline:()=>{
            if(this.http.isOffline) // save offline
            {
              entity.lastModifiedTime = new Date().hokToLocaleDateTimeOffsetString();
              entity.hasOfflineChanges = 1; // save with offline changes
            }
            return entity;
          }
      });
      }

      var store= new EntityStore(this.context.dbService);

      return await store.put(entity); 
  }

  



}

