import { ModelTypesBaseProps } from "./Root";
import { JournalTypeBaseProps as JournalTypeRootProps } from "../journal-types/Root";
import { EntityTypeRootProps } from "./EntityTypesRoot";
import { OrderTypeRootProps } from "../order-types/Root";


export const ModelTypeTypes:ModelTypesBaseProps[] = [

    EntityTypeRootProps,
    OrderTypeRootProps,
    
    JournalTypeRootProps
];