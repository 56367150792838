import React, { useContext } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { OrderType } from "../models/entities/orders/order-type";
import { DirectoryContext } from "../directory-shared/DirectoryContext";
import { DirectoryLink } from "../directory-shared/DirectoryRouter";
import { ErichRoles } from "../directory-shared/roles";


export function OrderLinks(props:{

    orderType:OrderType,
    id:string,
    showBackButton?:boolean
})
{


    var {orderType} = props;
    var oContext = useContext(DirectoryContext);

    var basePath = "/" + props.orderType.customModel.name;

    var canEdit = oContext.directoryService.checkUserRoles(
        ErichRoles.Administrator+ "," +
        orderType.customModel.name
                    );

    var isAdmin = oContext.directoryService.checkUserRoles(ErichRoles.Administrator);

return <div className="d-print-none">
    <DirectoryLink to={
            `${basePath}/details?id=${encodeURIComponent(props.id)}`
            }
            className="btn-link"
            >Details</DirectoryLink>
            
            { canEdit && <>
            {' | '}
    <DirectoryLink to={
            `${basePath}/edit?id=${encodeURIComponent(props.id)}`
            }
            className="btn-link"
            >Edit</DirectoryLink>
            </>}

                        


    
             {' | '}

    <UncontrolledDropdown tag="span">
        <DropdownToggle tag="span" caret className="cursor-pointer btn-link">
            More
        </DropdownToggle>
        <DropdownMenu>
            {orderType.viewTemplates.map((p,idx)=>
            
            <DirectoryLink 
            key={p.id}
            to={
                `${basePath}/print?templateId=${p.id}&id=${encodeURIComponent(props.id)}`
                }
                className="btn-link"
                >
                <DropdownItem>{p.name}</DropdownItem>
                </DirectoryLink>
            )}


            {isAdmin &&
            <DirectoryLink 
            to={
                `${basePath}/activity-logs?orderId=${props.id}`
                }
                className="btn-link"
                >
                <DropdownItem>Activity Logs</DropdownItem>
                </DirectoryLink>
            }

            {canEdit &&
            
            
    <DirectoryLink to={
            `${basePath}/edit?copyFromId=${encodeURIComponent(props.id)}`
            }
            className="btn-link"
            >
            <DropdownItem>Create Copy</DropdownItem>
            </DirectoryLink>

            }


        </DropdownMenu>
    </UncontrolledDropdown>


    {props.showBackButton && <>

    
        {' | '}
        <span className="cursor-pointer btn-link"
        onClick={()=>oContext.history.goBack()}>Back</span>
        
        </>}
</div>;

}