
import { FormGroup, Input } from "hok-react";
import React from "react";
import { EditControllerBase } from "../components/EditControllerBase";
import { CustomFieldOptionsInput } from "../custom-fields/CustomFieldOptionsInput";
import { JournalEntryTypeInputOption, JournalType } from "../models/entities/journals/journal-type";
import { JournalTypeService } from "./JournalTypeService";

export class EntryModeInputOptionsEdit extends EditControllerBase<JournalType>
{

    
    constructor(props)
    {
        super(props);

        this.state = {
            input: null
        };

        this.serviceType = JournalTypeService;
        this.colClassName = "col-md-8";
    }

    get title()
    {
        return "Journal Type Details";
    }

    

    addTypeOption = ()=>{
        var newVal = this.state.input.entryTypeInputOptions?.slice() || [];

        var max = newVal.reduce((max:number, curr)=>{

            if(max<curr.sortOrder)
            {
                return curr.sortOrder;
            }
            return max;
        },-1);
        
        newVal.push({
            text:"",
            value:"",
            sortOrder:max+1,
            amountSign:-1
        });

        this.setState({input:{

            ...this.state.input,
            entryTypeInputOptions:newVal
            
        }});
    };


    removeTypeOption = (idx:number)=>{
        
        var newVal = this.state.input.entryTypeInputOptions.slice();
        newVal.splice(idx,1);
        


        this.setState({input:{

            ...this.state.input,
            entryTypeInputOptions:newVal
        }});

    };


    render()
    {
        var input = this.state.input;

        if(!input)
        {

            return <div>Loading...</div>;
        }

        return this.renderEditForm(g => 
            <>
            <h5>Journal Type Entry Type Input Options</h5>


    <table className="table">
        <thead>
            <tr>
                <th>Value</th>
                <th>Text</th>
                <th>SortOrder</th>
                <th>AmountSign</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {g.value &&
            <FormGroup<JournalEntryTypeInputOption[], JournalType> 
                group={g} groupKey="entryTypeInputOptions"
            >
                {listGroup => 
                
                listGroup.value?.map((o,idx)=>

                <FormGroup 
                key={idx}
                group={listGroup} groupKey={idx}>
                    {listEntryGroup => 
                <tr key={idx}>
                    <td>
                        <Input group={listEntryGroup} groupKey="value" className="form-control"/>
                    </td>
                    <td>
                        <Input group={listEntryGroup} groupKey="text" className="form-control"/>
                    </td>
                    <td>
                        <Input group={listEntryGroup} groupKey="sortOrder"
                        type="number"
                        className="form-control"/>
                    </td>
                    <td>
                        <Input group={listEntryGroup} groupKey="amountSign"
                        type="number"
                        className="form-control"/>
                    </td>
                    <td>
                        <button type="button" className="btn btn-secondary"
                        
                        onClick={()=>this.removeTypeOption(idx)}
                        >Remove</button>
                    </td>
                </tr>}
                </FormGroup>
            )
                }
            </FormGroup>
            }

        </tbody>
    </table>
    
    
    <button type="button" className="btn btn-primary"
    onClick={this.addTypeOption}
    >Add Entry Type Options</button>

            
            <br/>
            <br/>
            <h5>Journal Type Entry Mode Input Options</h5>
            <CustomFieldOptionsInput<JournalType>
                group={g}
                groupKey="entryModeInputOptions"
            />
            
            <br/>
            <br/>
            





            


            </>
        
        
        );
    }


}