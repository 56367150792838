import { FormGroup, ValidationSummary, CustomFieldsInput } from "hok-react";

import React from "react";
import { EditController } from "./EditController";
import { Prompt } from 'react-router-dom';
import { OrderTypeNameIds } from "../../models/enums/OrderTypeNamesIds";
import { OrderLinesInput } from "./OrderLines/OrderLinesInput";
import { EditEntityModal } from "../../entities/Edit/EditEntityModal";
import { EditOrderHeader } from "./Header/EditOrderHeader";
import { OrderSelectModalProvider } from "../../modals/OrderSelectModalProvider";



export class OrderEditC extends EditController
{


render()
{

    var order = this.order;
    if(!order)
        return <div>loading...</div>;
    

    var orderType = this.props.orderType;


    return <>
    
    {this.state.toEntityType &&
    <EditEntityModal entityType={this.state.toEntityType}
    ref={this.editEntityModalRef}
    onSave={this.handleCreatedNewToEntity}
    />
    }
    <OrderSelectModalProvider>
    <form 
    ref={this.formRef}
    onSubmit={this.handleSubmit}
    >

    <Prompt
        when={this.state.hasChanges}
        message={location =>
          `You are about to leave this page. Are you sure you want to discard changes?`
        }
      />

{/* this form group is not used */}
<FormGroup value={order}
onChange={e => {
    this.updateOrder(e.target.value);
    }}
    
    
    childrenProps={{readOnly:this.isReadOnly}}
    >
{orderGroup => <>

<EditOrderHeader 
orderType={orderType}
value={order}
readOnly={this.isReadOnly}
updateOrder={this.updateOrder}
editOrder={this.props.editOrder}
onCreateNewToEntityClick={this.handleOnCreateNewToEntityClick}
/>
<CustomFieldsInput 
    group={orderGroup}
    fields={orderType.customModel.fields}
/>


<hr/>

<style>
{`
.edit-order-table td
{
    vertical-align:top;
}
`}
</style>

{/* {JSON.stringify(order)} */}

<table className="edit-order-table">
<thead>
<tr>
    <th style={{width:"20em"}}>
        Items
    </th>

    {this.props.orderType.customModel.name===OrderTypeNameIds.PhysicalInventoryOrder?
    <>
        <th>Phy. Qty</th>
        {this.props.editOrder.order.postingTime &&
            <>
            <th className="px-2">
                Prev Qty
            </th>
            <th className="px-2">
                Qty Added
            </th>
            </>
            }
    </>
    :
    <>

<th>
        Qty
    </th>
    
<th>
        Unit&nbsp;&nbsp;
    </th>
    {orderType.withPrice &&
        <th>
            Orig Price&nbsp;&nbsp;
        </th>
    }
    {orderType.withPrice &&
        <>
        <th>
            Unit Price
        </th>
        
        <th>
            Amount&nbsp;&nbsp;
        </th>
        </>
    }

    {orderType.shipmentSourceOrderTypeName &&
    <>
        <th>Qty to Handle</th>
        <th>Qty Handled</th>
        </>

    }

    </>

    }
    
{orderType.enableExpirationTime &&
<th>Expiry Date</th>
}
    
    {this.state.lineCustomFields.map(e=><th key={e.id}>
        {e.name}&nbsp;&nbsp;
    </th>)}
    
    <th>
        {!this.isReadOnly && 
        <span className="cursor-pointer btn-link" onClick={this.handleDeleteAllLines}>Delete all lines</span>
        }
    </th>
</tr>
</thead>

<tbody>
    <OrderLinesInput
        value={this.order.lines}
        readOnly={this.isReadOnly}
        editOrder={this.props.editOrder}
        updateOrder={this.updateOrder}
    />
</tbody>
    

</table>


<div>Total: {this.order.currencyCode} {this.computeTotalPrice().toFixed(2)}</div>


<br/>



<ValidationSummary errors={this.state.errors} name=''/>
<br/>

<div className="form-group">

    <input type="submit" value="Save" className="btn btn-primary"
    disabled={this.canSaveOnly}
    />
   
    &nbsp;&nbsp; 
    <button type="button"
    className="btn btn-primary"
    disabled={this.isReadOnly}
    onClick={this.handleSaveAndPost}
    >Save And Post</button>
   

    &nbsp;&nbsp;

    {this.isAllowedToUnpost &&
    <>
        
    <button type="button"
    className="btn btn-secondary"
    disabled={this.state.isSubmitting}
    onClick={this.handleUnpostClick}
    >Unpost</button>


    &nbsp;&nbsp;
    </>}
    
    <button type="button" className="btn btn-link"
    onClick={this.goBack}
    >Back</button>

</div>
         
</>}
</FormGroup>

</form>
</OrderSelectModalProvider>
</>;

}
}