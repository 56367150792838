import { StreamingArrayLoaderGetItemsRequest } from "hoksource";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { ItemCategory } from "../models/entities/items/item-category";
import { ItemService } from "./ItemService";
import { Item } from "../models/entities/items/item";

export class HomeController extends DirectoryComponent<any,{
    searchString:string,
    includeInactive?:boolean,
    category?:ItemCategory

    
}>
{


    constructor(props)
    {
        super(props);
        this.state = {

            searchString:"",
        };
    }
    

    takeCount = 30;

    
    getItems = (filter:
        StreamingArrayLoaderGetItemsRequest<Item>
     )=>{


        
        var service = new ItemService(this.context);
        return service.getItems({
            skip : filter.currentIndex,
            take : this.takeCount,
            searchString: this.state.searchString,
            includeInactive: this.state.includeInactive,
            itemCategoryId: this.state.category?.id ?? null
        });
    }


}