import { StreamingArrayLoaderGetItemsRequest } from "hoksource";
import { Entity } from "../models/entities/entities/entity";
import { DirectoryComponent } from "../directory-shared/DirectoryComponent";
import { Item } from "../models/entities/items/item";
import { OrderType } from "../models/entities/orders/order-type";
import { OrderLineService } from "./OrderLineService";
import { OrderLineAndOrder } from "../models/view-models/OrderLineAndOrder";

export class AllOrderLinesHomeController extends DirectoryComponent<{orderType:OrderType},{

    fromEntity?:Entity,
    toEntity?:Entity,
    item?:Item,
    orderType?:string
}>
{


    constructor(props)
    {
        super(props);
        this.state = {

        };
    }
    

    takeCount = 20;

    
    getItems = (filter:
        StreamingArrayLoaderGetItemsRequest<OrderLineAndOrder>
     )=>{

        var service = new OrderLineService(this.http);
        return service.getOrderLineAndOrders({
            skip : filter.currentIndex,
            take : this.takeCount,

            
            fromEntityId:this.state.fromEntity?.id,
            toEntityId:this.state.toEntity?.id,
            itemId:this.state.item?.id,
            orderType:this.props.orderType.customModel.name
        });
    }


}