import { OrderType, OrderTypeViewTemplate } from "../models/entities/orders/order-type";
import { ModelTypeServiceBase } from "../model-types/ModelTypeBaseService";
import { DirectoryHttpService } from "../http/DirectoryHttpService";

export class OrderTypeService extends ModelTypeServiceBase<OrderType>
{

    constructor(http:DirectoryHttpService)
    {
        super(http);

        this.controllerName= "OrderTypes";


    }

    findViewTemplate(id:string)
    {
        return this.http.post<OrderTypeViewTemplate>(
            "/OrderTypes/FindViewTemplate", {

                hokQuery:{
                    id
                }
            });
    }

    saveViewTemplate(viewTemplate:OrderTypeViewTemplate)
    {
        return this.http.post<OrderTypeViewTemplate>(

        "/OrderTypes/SaveViewTemplate", {
            body:viewTemplate
        });
    }


}