import { requestLock, ValidationProblemDetails } from "hoksource";
import { SyncLockName } from "../..";
import { DirectoryComponent } from "../../directory-shared/DirectoryComponent";
import { Entity } from "../../models/entities/entities/entity";
import { EntityType } from "../../models/entities/entities/entity-type";
import { ErrorsType } from "../../models/errors-type";
import { EntityService } from "../EntityService";
import { EditEntityInput } from "./EditEntityInput";
import React from 'react';

export class EditEntityForm extends
DirectoryComponent<{

    
    entityType:EntityType,
    onSave:(entity:Entity)=>void,
    value:Entity,
    onChange:(evt:{target:{value:Entity}})=>void,
    formContainerClassName?:string,
    
},
{
    errors?:ErrorsType,
    isSubmitting?:boolean
}
>
{
    constructor(props)
    {
        super(props);
        this.state = {};

    }


    
    handleSubmit = async (evt:React.FormEvent<HTMLFormElement>)=>
    {

        
        evt.preventDefault();

        this.setState({
            errors:null,
            isSubmitting:true,
        });

        
        try
        {
            // success 
                

            var res = await requestLock(SyncLockName, async()=>{ // lock it here
                
                var service = new EntityService(this.context);
                return await service.save(this.props.value);
            });

            if(this.props.onSave)
            {
                this.props.onSave(res);
            }

        }catch(err:any)
        {
            if(err.status === 400)
            {
                this.setState({errors:(err as ValidationProblemDetails).errors});
            }
        }
        this.setState({isSubmitting:false});
    }


    render()
    {
        
        return <>
        <h1>{this.props.value.id?"Edit":"Create"}</h1>

        <h4>{this.props.entityType.customModel.displayName}</h4>
        <hr />
        <div className="row">
        <div className={this.props.formContainerClassName}>
        <form method="post" onSubmit={this.handleSubmit}>
            <EditEntityInput
                type={this.props.entityType}
                value={this.props.value}
                onChange={this.props.onChange}
                errors={this.state.errors}
                readOnly={this.state.isSubmitting}
            />
            <div className="form-group">
                <input type="submit" value="save" className="btn btn-primary"
                disabled={this.state.isSubmitting}
                />
            </div>
        </form>
        </div>
        </div>
                
    </>;
    }
}