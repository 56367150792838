import { JournalEntry } from "../models/entities/journals/journal-entry";

export function convertJournalEntryForSaving(journalEntry:JournalEntry)
{

    if(!journalEntry)
        return null;


    var {
        fromEntity,
        toEntity, 
        ...copy} = journalEntry;

    
    copy.fromEntityId = journalEntry.fromEntity?.id;
    if(journalEntry.toEntity?.id)
    {
        copy.toEntityId = journalEntry.toEntity?.id;
    }
    


    return copy as JournalEntry;
};