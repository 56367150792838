import React, { useContext } from "react";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ToastContext } from "../modals/ToastProvider";
import { OrderType } from "../models/entities/orders/order-type";

import { OrderDetails } from "./Details";
import { OrderPrint } from "./Print";
import { OrderEdit } from "./Edit/Edit";
import { AllOrderLinesHome } from "./AllOrderLinesHome";
import { DirectoryNavLink } from "../directory-shared/DirectoryLinks";
import { OrderHome2 } from "./OrderList/Home2";
import { DirectoryContext, ErichRoles, OrderTypeNameIds } from "..";
import { ActivityLogs } from "../activity-logs/ActivityLogs";
import { useQuery } from "../hooks/useQuery";


export function OrderRoot(props:{orderType:OrderType})
{
    var toastContext = useContext(ToastContext);

    var match = useRouteMatch();

    var loc = useLocation();

    let ctx = useContext(DirectoryContext);

    var isAdmin = ctx.directoryService.checkUserRoles("Administrator");

    var subPath = loc.pathname.substr(match.url.length).toLowerCase();

  var orderType = props.orderType;
  
  let typeName = orderType.customModel.name;

  let query = useQuery();
  var orderId = query.get("orderId");
    return <>
        {(subPath === "" 
        ||
        subPath === "/near-due-orders"
        ||
        subPath==="/order-lines"
        )
        &&
        
            
<ul className="nav nav-tabs">
  <li className="nav-item">
    <DirectoryNavLink to={`/${typeName}`}
    className="nav-link"
    exact
    >    
      All {props.orderType.customModel.collectionDisplayName}
    </DirectoryNavLink>
  </li>
  {typeName === OrderTypeNameIds.SalesOrder &&
  <li className="nav-item">
    
   <DirectoryNavLink to={`/${typeName}/near-due-orders`}
   className="nav-link"
   >    
      Near Due {props.orderType.customModel.collectionDisplayName}
    </DirectoryNavLink>
  </li>
  }
  
  {typeName !== OrderTypeNameIds.PhysicalInventoryOrder &&
  <li className="nav-item">

   <DirectoryNavLink to={`/${typeName}/order-lines`}
   className="nav-link"
   >    
      {props.orderType.customModel.displayName} Lines
    </DirectoryNavLink>
  </li>
  }

  
  {isAdmin &&
  <li className="nav-item">

   <DirectoryNavLink to={`/${typeName}/activity-logs`}
   className="nav-link"
   >    
      Activity Logs
    </DirectoryNavLink>
  </li>
  }
</ul>

        }
        <Route exact path={`${match.path}/`}>
            <OrderHome2 {...props} />
        </Route>

        <Route path={`${match.path}/near-due-orders`}>
            <OrderHome2 {...props} nearDueMode />
        </Route>


        <Route path={`${match.path}/order-lines`}>
            <AllOrderLinesHome {...props} />
        </Route>

        {isAdmin &&
        <Route path={`${match.path}/activity-logs`}>
            <ActivityLogs objectTypeName={typeName} objectId={orderId}/>
        </Route>
        }

        <Route path={`${match.path}/details`}>
            <OrderDetails {...props} />
        </Route>


        {ctx.directoryService.checkUserRoles(ErichRoles.Administrator+ "," +
            orderType.customModel.name) &&
        <Route path={`${match.path}/edit`}>
            <OrderEdit {...props} toastContext={toastContext}/>
        </Route>
        }
        <Route path={`${match.path}/print`}>
            <OrderPrint {...props} />
        </Route>


        
    </>;
}